import React from 'react';
import { usePlaidLink } from "react-plaid-link";
import { exchangeTokens } from "../../api/payout";

const SelectAccount = ({linkToken, nextStep, setLinkToken}) => {
    const onSuccess = React.useCallback(
        (public_token, accounts) => {
            let accountIds = [];
            for (let i=0; i<accounts.accounts.length; i++) {
                accountIds.push(accounts.accounts[i].id);
            }
            exchangeTokens(public_token, accountIds).then((res) => {
                nextStep();
            }).catch(error => {
                setLinkToken();
                alert(error.response.data.message);
            });
        }
    );

    const config = {
        token: linkToken,
        onSuccess
    };

    const { open, ready } = usePlaidLink(config);

    open();

    return (
        <>
        </>
    )
}

export default SelectAccount;
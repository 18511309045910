import { useEffect } from 'react';
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import LoadingScreen from "../util/loadingScreen";
import { getUser } from "../../api/user";

const ProtectedRoutes = () => {
    const { user, signOut, loading, setLoading, registering, setRegistering } = useAuth();
    const location = useLocation();

    useEffect(() => {
        if(user?.username && !location.pathname.includes('/account/manage-plan/')) {
            setLoading(true);
            getUser().then(response => {
                if(!response.data.first_name || !response.data.company_information) setRegistering(true);
                setLoading(false);
            }).catch(error => {
                if(error.response.data.statusCode === 401 && error.response.data.message !== "No token provided") signOut();
                setLoading(false);
            });
        }
    }, [user]);
    return loading 
        ? <LoadingScreen /> 
        : user 
            ? registering 
                ? <Navigate to={'/register/choose-team'}/>
                : <Outlet/>
            : <Navigate to={'/sign-in'}/>
};

export default ProtectedRoutes;
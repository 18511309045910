
import axios from 'axios';

const fetchUser = (userId) => {
    return axios.get(`${process.env.REACT_APP_SERVER_URL}/users`, {
      headers: {
        'user-id': userId
      }
    })
    .then(res => {
      // console.log("***********" + JSON.stringify(res, null, 2));
      return res;
    })
    .catch(err => {
      console.error(err);
    });
};

export async function getUser () {
    return await axios.get(`${process.env.REACT_APP_SERVER_URL}/users`);
};

export async function updateUserCompanyAndLocation (company_information, location) {
  try {
    return await axios.patch(`${process.env.REACT_APP_SERVER_URL}/users`, {
    company_information,
    location,
  });
  } catch (error) {
    console.error('error updating user company & location: ', error);
    return null;
  }
};

export async function updateUserPersonalInfo (first_name, last_name, phone_number) {
  try {
    return await axios.patch(`${process.env.REACT_APP_SERVER_URL}/users/user-info`, {
      first_name,
      last_name,
      phone_number,
    });
  } catch (error) {
    console.error('error updating user personal info: ', error);
    return null;
  }
};

export async function changeUserPassword(old_password, new_password) {
  try {
    return await axios.post(`${process.env.REACT_APP_SERVER_URL}/users/changing-password`, {
      old_password,
      new_password,
    });
  } catch (error) {
    console.error('error changing user password: ', error);
  }
}

export async function getUserLogo() {
  try {
    return await axios.get(`${process.env.REACT_APP_SERVER_URL}/users/logo`);
  } catch (error) {
    console.error('failed to fetch logo: ', error);
    return null;
  }
}

export async function updateUserLogo(formData) {
  try {
    return await axios.put(`${process.env.REACT_APP_SERVER_URL}/users/logo`, formData);
  } catch (error) {
    console.error('error updating user logo: ', error);
    return null;
  }
}

export async function setUserMarkups(merchandise_cost_markup, labor_cost_markup) {
  try {
    return await axios.patch(`${process.env.REACT_APP_SERVER_URL}/users/registration/add-markups`, {
      merchandise_cost_markup,
      labor_cost_markup,
    });
  } catch (error) {
    console.error('error setting user markups: ', error);
    return null;
  }
}

export async function getLinkForSubscription() {
  try {
    return await axios.get(`${process.env.REACT_APP_SERVER_URL}/users/create-checkout-session`);
  } catch (error) {
    console.log('failed to fetch logo: ', error);
    return null;
  }
}

export async function createAccount(email) {
  try {
    return await axios.post(`${process.env.REACT_APP_SERVER_URL}/users/registration/creating-account`, { 
      email 
    });
  } catch (error) {
    console.error('error creating account: ', error);
    return null;
  }
};

export async function registerUserBusinessInfoAndLocation(company_information, location) {
  try {
    return await axios.patch(`${process.env.REACT_APP_SERVER_URL}/users/registration/company-information-and-location`, {
      company_information,
      location
    })
  } catch (error) {
    console.error('error registering user company & location: ', error);
    return null;
  }
}

export async function updateSubscriptionPlan(token) {
  return await axios.post(`${process.env.REACT_APP_SERVER_URL}/users/update-subscription`, {
    subscription_token: token
  });
}

export async function cancelSubscriptionPlan(token) {
  return await axios.post(`${process.env.REACT_APP_SERVER_URL}/users/cancel-subscription`, {});
}

export async function sendConfirmationCode(email) {
  try {
    return await axios.post(`${process.env.REACT_APP_SERVER_URL}/users/registration/send-confirmation-code`, { email });
  } catch (error) {
    console.error('error sending employer confirmation code: ', error);
    return null;
  }
};

export async function createPaymentAccount (company_information, location) {
  try {
    return await axios.post(`${process.env.REACT_APP_SERVER_URL}/users/registration/payment-account`, {
      company_information,
      location,
    });
  } catch (error) {
    console.error('error creating payment account: ', error);
    return null;
  }
};


export async function verifyConfirmationCode (email, verification_code) {
  try {
    return await axios.patch(`${process.env.REACT_APP_SERVER_URL}/users/registration/employer-confirm`, {
      email,
      verification_code
    });
  } catch (error) {
    console.error('failed to verify confirmation code: ', error);
    return null;
  }
};

export async function getEmployerName (employerEmail) {
  try {
    return await axios.get(`${process.env.REACT_APP_SERVER_URL}/users/business-name`, {
      params: {
        email: employerEmail
      }
    });
  } catch (error) {
    console.error('failed to fetch employer name: ', error);
    return null;
  }
};

export async function setEmployeeInfo (first_name, last_name, phone_number, employer_email) {
  try {
    return await axios.patch(`${process.env.REACT_APP_SERVER_URL}/users/registration/employee-information`, {
      first_name,
      last_name,
      phone_number,
      employer_email
    });
  } catch (error) {
    console.error('failed to set company info: ', error);
    return null;
  }
};

export default fetchUser;
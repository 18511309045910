import { MoreHoriz } from "@mui/icons-material";
import { Box, Button, IconButton, InputAdornment, Menu, MenuItem, Modal, TextField, Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { deleteEstimateOrInvoice, sendEstimateOrInvoiceReminder } from "../../api/estimates";
import { sendInvoice } from "../../api/invoice";
import '../payments/PaymentLinkModal.css';

const InvoicePaymentLinkModal = ({showModal, setShowModal, invoiceInfo}) => {

    const [amount, setAmount] = useState(0);
    const [amountError, setAmountError] = useState(false);
    const [method, setMethod] = useState('CARD');
    const [recordButtonText, setRecordButtonText] = useState('Send');

    const handleClose = useCallback(() => {
        setShowModal(false);
    }, [setShowModal]);

    const handleSendPaymentLink = useCallback(async () => {
        if (amount >= 0 && amount <= (invoiceInfo.total_amount - invoiceInfo.partially_payment)) {
            setRecordButtonText('Sending...');
            const response = await sendInvoice(
                invoiceInfo.id, 
                invoiceInfo.email, 
                amount,
                true,
                method
            );
            if (response) {
                setRecordButtonText('Sent Successfully!');
                setTimeout(handleClose, 1000);
            } else {
                setRecordButtonText('Failed to Send');
            }
        } else {
            setAmountError(true);
        }
    }, [amount, invoiceInfo, handleClose, method])

    useEffect(() => {
        if (amount >= 0 && amount <= (invoiceInfo.total_amount - invoiceInfo.partially_payment)) {
            setAmountError(false);
        }
    }, [amount, invoiceInfo]);

    return (
        <Modal
            open={showModal}
            onClose={handleClose}
        >
            <Box className="modal-container">
                <Grid2 container rowGap={4} flexGrow={1} justifyContent={'space-between'}>

                    <Grid2 xs={12}>
                        <Typography variant="h5" color={'primary'} fontWeight={'bold'}>
                            Send Payment Link
                        </Typography>
                    </Grid2>

                    <Grid2 xs={12} container rowGap={2}>
                        <Grid2 xs={12}>
                            <Typography color={'primary'} fontWeight={'bold'}>Customer Information</Typography>
                        </Grid2>
                        <Grid2 xs={6} container>
                            <Typography>{invoiceInfo.client_name}</Typography>
                        </Grid2>
                        <Grid2 xs={6} container justifyContent={'flex-end'}>
                            <Typography>
                                {new Date().toLocaleDateString('en-US', {
                                    month: 'short', year:'numeric', day:'numeric',
                                })}
                            </Typography>
                        </Grid2>
                    </Grid2>

                    <Grid2 xs={12} container rowGap={2}>
                        <Grid2 xs={12}>
                            <Typography color={'primary'} fontWeight={'bold'}>Payment Amount</Typography>
                        </Grid2>
                        <Grid2 xs={12}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                type="number"
                                value={amount}
                                onChange={event => {
                                    setAmount(event.target.value);
                                }}
                                placeholder="Enter Amount"
                                label="Amount"
                                error={amountError}
                                helperText={amountError ? 'Please enter a valid amount in $USD that is less than or equal to the unpaid amount' : ''}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">$</InputAdornment>
                                }}
                            />
                        </Grid2>
                    </Grid2>

                    <Grid2 xs={12} container rowGap={2}>
                        <Grid2 xs={12}>
                            <Typography color={'primary'} fontWeight={'bold'}>Select Payment Method:</Typography>
                        </Grid2>
                        <Grid2 xs={12}>
                            <Button
                                variant={method === 'CARD' ? 'contained' : 'outlined'}
                                fullWidth
                                onClick={() => setMethod('CARD')}
                            >
                                Request Card Payment
                            </Button>
                        </Grid2>
                        <Grid2 xs={12}>
                            <Button
                                variant={method === 'ACH' ? 'contained' : 'outlined'}
                                fullWidth
                                onClick={() => setMethod('ACH')}
                            >
                                Request Bank Transfer
                            </Button>
                        </Grid2>
                        <Grid2 xs={12}>
                            <Button
                                variant={method === 'CARD & ACH' ? 'contained' : 'outlined'}
                                fullWidth
                                onClick={() => setMethod('CARD & ACH')}
                            >
                                Request Both Card Payment & Bank Transfer
                            </Button>
                        </Grid2>
                    </Grid2>

                    <Grid2 xs={12} container justifyContent={'space-between'}>
                        <Grid2 xs={5.5}>
                            <Button
                                variant="contained"
                                fullWidth
                                onClick={async () => await handleSendPaymentLink()}
                            >
                                {recordButtonText}
                            </Button>
                        </Grid2>
                        <Grid2 xs={5.5}>
                            <Button
                                variant="outlined"
                                fullWidth
                                onClick={handleClose}
                            >
                                Cancel
                            </Button>
                        </Grid2>
                    </Grid2>

                </Grid2>
            </Box>
        </Modal>
    );
};


const InvoiceMenuButton = ({invoiceInfo, setRefreshTable, refreshTable}) => {
    
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    

    const handleViewDetails = () => {
        navigate(`/invoices/${invoiceInfo.id}`);
        handleClose();
    }

    const handleEditInvoice = () => {
        navigate(`/invoices/edit/${invoiceInfo.id}`);
        handleClose();
    }

    const [deleteButtonText, setDeleteButtonText] = useState('Delete Invoice');
    const handleDeleteInvoice = async () => {
        setDeleteButtonText('Deleting...');
        const response = await deleteEstimateOrInvoice(invoiceInfo.id);
        if (response) {
            setDeleteButtonText('Deleted!');
        } else {
            setDeleteButtonText('Failed to Delete');
        }
        setTimeout(handleClose, 500);
        setTimeout(setRefreshTable(!refreshTable), 1000);
    }
    
    const [showPaymentLinkModal, setShowPaymentLinkModal] = useState(false);
    const [sendButtonText, setSendButtonText] = useState(invoiceInfo.status === 'PAYMENT_LINK_SENT' ? 'Send Reminder' : 'Send Payment Link');
    const handleSend = async () => {
        let response = null;
        if (invoiceInfo.status === 'PAYMENT_LINK_SENT') {
            setSendButtonText('Sending ...')
            response = await sendEstimateOrInvoiceReminder(invoiceInfo.id);
        }

        if (invoiceInfo.status === 'SENT' || invoiceInfo.status === 'PARTIALLY_PAID') {
            setShowPaymentLinkModal(true);
        } else if (response) {
            setSendButtonText('Success!');
        } else {
            setSendButtonText('Failed to Send');
        }
        setTimeout(handleClose, 500);
        setTimeout(setRefreshTable(!refreshTable), 1000);
    }


    return (
        <Grid2 container>
            <InvoicePaymentLinkModal
                showModal={showPaymentLinkModal}
                setShowModal={setShowPaymentLinkModal}
                invoiceInfo={invoiceInfo}
            />
            <IconButton onClick={handleClick}>
                <MoreHoriz/>
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                <MenuItem onClick={handleViewDetails}>View Invoice Details</MenuItem>
                {(invoiceInfo.status === 'SENT' || invoiceInfo.status === 'PAYMENT_LINK_SENT' || invoiceInfo.status === 'PARTIALLY_PAID') && 
                <MenuItem onClick={handleSend}>{sendButtonText}</MenuItem>}
                {invoiceInfo.status !== 'PAID' && 
                <MenuItem onClick={handleEditInvoice}>
                    {(invoiceInfo.status === 'SENT' || invoiceInfo.status === 'PAYMENT_LINK_SENT' || invoiceInfo.status === 'PARTIALLY_PAID') 
                        ? 'Change Order' 
                        : 'Edit Invoice'}
                </MenuItem>}
                <MenuItem onClick={handleDeleteInvoice}>{deleteButtonText}</MenuItem>
            </Menu>
        </Grid2>
    );
};

export default InvoiceMenuButton;
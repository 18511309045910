import * as React from 'react';
import axios from 'axios';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import parse from 'autosuggest-highlight/parse';
import { debounce } from '@mui/material/utils';

// This key was created specifically for the demo in mui.com.
// You need to create a new one for your application.
const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAP_KEY;

function loadScript(src, position, id) {
  if (!position) {
    return;
  }

  const script = document.createElement('script');
  script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService = { current: null };

function getZipCode(address) {
  const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&key=${GOOGLE_MAPS_API_KEY}`;
    return fetch(apiUrl)
      .then(response => response.json())
      .then(data => {
          if (data.status === 'OK' && data.results.length > 0) {
          // Accessing the first result
          const firstResult = data.results[0];

          // Finding the ZIP code from the address components
          const zipCodeComponent = firstResult.address_components.find(component =>
              component.types.includes('postal_code')
          );

          if (zipCodeComponent) {
              const zipCode = zipCodeComponent.short_name;
              console.log('zipcode: ', zipCode);
              return zipCode;
          } else {
              console.error('ZIP Code not found for the provided address.');
              return null;
          }
          } else {
              console.error('Error in the Geocoding API response.');
              return null;
          }
      })
      .catch(error => console.error('Error fetching data:', error));
}

export default function AddressInput({value, setValue, label, placeholder, error, helperText, variant=null}) {
//   const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState([]);
  const loaded = React.useRef(false);

  if (typeof window !== 'undefined' && !loaded.current) {
    if (!document.querySelector('#google-maps')) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`,
        document.querySelector('head'),
        'google-maps',
      );
    }

    loaded.current = true;
  }

  const fetch = React.useMemo(
    () =>
      debounce((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback);
      }, 400),
    [],
  );

  React.useEffect(() => {
    let active = true;

    if (!autocompleteService.current && window.google) {
      autocompleteService.current =
        new window.google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue }, (results) => {
      if (active) {
        let newOptions = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  return (
    <Autocomplete
      id="google-map-demo"
      getOptionLabel={(option) =>
            typeof option === 'string' ? option : option.structured_formatting.main_text
      }
      fullWidth
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      noOptionsText="No locations"
      onChange={(event, newValue) => {
        setOptions(newValue ? [newValue, ...options] : options);
        if(newValue) {
          getZipCode(typeof newValue === 'string' ? newValue : ( newValue ? newValue.description : '')).then(res => {
            const address = newValue.structured_formatting.secondary_text.split(', ');
            setValue(newValue.structured_formatting.main_text, address[0], address[1], res);
          });
        }
        else {
          setValue(null);
        }
      }}
      onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
      }}
      renderInput={(params) => (
            <TextField {...params}
                label={label}
                placeholder={placeholder}
                error={error}
                helperText={helperText}
                fullWidth
                variant={variant ? variant : 'outlined'}
            />
      )}
      renderOption={(props, option) => {
        const matches =
            option?.structured_formatting?.main_text_matched_substrings || [];

        const parts = parse(
            option?.structured_formatting?.main_text,
            matches.map((match) => [match.offset, match.offset + match.length]),
        );

        return (
          <li {...props}>
            <Grid container alignItems="center">
              <Grid item sx={{ display: 'flex', width: 44 }}>
                <LocationOnIcon sx={{ color: 'text.secondary' }} />
              </Grid>
              <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                {parts.map((part, index) => (
                  <Box
                    key={index}
                    component="span"
                    sx={{ fontWeight: part.highlight ? 'bold' : 'regular' }}
                  >
                    {part.text}
                  </Box>
                ))}
                <Typography variant="body2" color="text.secondary">
                  {option?.structured_formatting?.secondary_text}
                </Typography>
              </Grid>
            </Grid>
          </li>
        );
      }}
    />
  );
}
import React from 'react';
import { styled } from '@mui/material/styles';
import Rating from '@mui/material/Rating';
import StarIcon from '@mui/icons-material/Star';

const StyledRating = styled(Rating)({
	'& .MuiRating-iconFilled': {
		color: '#294D51',
		fontSize: 15
	},
});

  
function ReviewMobile(props) {
  const {
    reviewInfo
  } = props;

  return (
      <div className="reviewcontainer" style={{marginBottom:50, width:'100%'}}>
	  		<div className="flex-box">
					<div className='logoSection' style={{
            width: '28px', minWidth: '28px', marginRight: '10px', display: 'flex',
            flexWrap: 'wrap', height: '28px', borderRadius: '30px', backgroundColor: '#D9D9D9'
          }}>
          </div>
					<div className="" style={{display:'block', textAlign:'left'}}>
						<p style={{fontSize: 16, fontWeight: 500}}>{reviewInfo.client_name}</p>
						{/* <p style={{fontSize: 12, color: '#808080'}}>{'New York, NY'}</p> */}
					</div>
				</div>
				<div className="reviewContents pt-2" >
					<div className = "rating">
						<div className = "ratingStar" style={{marginTop: 5}}>
							<StyledRating
								name="text-feedback"
								value={reviewInfo.rating}
								readOnly
								precision={0.01}
								emptyIcon={<StarIcon style={{ opacity: 0.55, fontSize: 15 }} fontSize="inherit" />}
							/>
						</div>
						<div className="date" style={{fontSize: 15}}>{reviewInfo.created_at}</div>
					</div>
				</div>
				<div className="text-left pt-3">
					<div className="sentence" style={{backgroundColor:'lightgrren' , height: '60%', fontSize: 12}}>{reviewInfo.review}</div>
				</div>
				<div className="information" style={{overflow: "auto"}}>
						{
							reviewInfo.images.length === 0
								? ""
								: reviewInfo.images.map((image, index) => (
									<img key={index}
										src={image.image_url}
										style={{width: '70px', height: '70px', objectFit: 'fill', marginRight:'10%', marginTop: '10px'}}
									/>
								))
						}
				</div>
      </div>
  );
}

export default ReviewMobile;

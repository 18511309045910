import React from 'react';

function QuickBooksAuthError() {
  return (
    <div>
        <p>Error</p>
    </div>
  );
}

export default QuickBooksAuthError;
import { Box, Button, Checkbox, Divider, IconButton, ImageList, ImageListItem, Modal, TextField, Typography } from "@mui/material";
import AppDrawer from "../appdrawer/appdrawer";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { Add, ChevronLeft, Close, Create, Edit, HelpOutline, Save } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { USDollar } from "../../constants/theme";
import { getUser } from "../../api/user";
import { useCallback } from "react";
import isEmpty from "validator/lib/isEmpty";
import isMobilePhone from "validator/lib/isMobilePhone";
import isPostalCode from "validator/lib/isPostalCode";
import isEmail from "validator/lib/isEmail";
import { attachEstimateOrInvoicePhotos, createEstimateOrInvoice, changeOrderEstimateOrInvoice, getEstimateOrInvoice, editEstimateOrInvoice } from "../../api/estimates";
import InvoiceStatusTag from "./InvoiceStatusTag";
import JobDetailModal from "./JobDetailModal";
import '../../pages/invoices/invoices.css';
import { getCustomers } from "../../api/contacts";
import { filterObjectProperties, phoneNumberReformatter } from "../../misc/utilityFunctions";
import AddFromCustomerModal from "../estimates/AddFromCustomerModal";
import { linkInvoice } from "../../api/payments";
import AddressInput from "../util/addressInput";

const TosModal = ({invoiceData, setInvoiceData, showModal, setShowModal}) => {

    const handleClose = () => {
        setShowModal(false);
    }

    return (
        <Modal
            open={showModal}
            onClose={handleClose}
        >
            <Box className="modal-container">
                <Grid2 container gap={4}>
                    <Grid2 xs={12} container>
                        <Typography variant="h6" color={'primary'} fontWeight={'bold'}>
                            Terms of Service/Additional Statement
                        </Typography>
                    </Grid2>
                    <Grid2 xs={12} container>
                        <TextField 
                            variant="outlined"
                            color="primary"
                            fullWidth
                            multiline
                            rows={8}
                            type="text"
                            value={invoiceData?.terms_service}
                            placeholder="Add Terms of Service / Additional Statement"
                            onChange={event => setInvoiceData({...invoiceData, terms_service: event.target.value})}
                        />
                    </Grid2>
                    <Grid2 xs={12} container>
                        <Button variant="contained" fullWidth onClick={handleClose}>
                            Done
                        </Button>
                    </Grid2>
                </Grid2>
            </Box>
        </Modal>
    );
};


const InvoiceEdit = ({editMode}) => {

    const { id, contact_id, payment_id } = useParams();
    const navigate = useNavigate();
    const [userData, setUserData] = useState({merchandise_cost_markup: 0, labor_cost_markup: 0 });
    const [invoiceData, setInvoiceData] = useState({ 
        client_name: '',
        street_address: '',
        phone: '',
        city: '',
        state: '',
        zip_code: '',
        email: '',
        job_items: [],
        invoices_type: 'INVOICE',
        status: 'IN_DRAFT',
        notes: '',
        attach_photos: [],
        terms_service: '',
        partially_payment: 0,
        showing_item_amount: true,
        showing_term_service: false,
        total_amount(isAddFee) {
            return this?.job_items?.reduce((accum, current) => (accum + current?.total_amount_with_markup(isAddFee)), 0)
        }
    });
    const [isAddFee, setIsAddFee] = useState(false);
    const [showAddFromCustomerModal, setShowAddFromCustomerModal] = useState(false);
    const [showTosModal, setShowTosModal] = useState(false);
    const [showJobDetailModal, setShowJobDetailModal] = useState(false);
    const [jobDetailModalIndex, setJobDetailModalIndex] = useState(0);
    const [saveButtonText, setSaveButtonText] = useState(editMode? 'Update Invoice' : 'Save Invoice');
    const [createButtonText, setCreateButtonText] = useState('Create Invoice');
    const [showFeeInfo, setShowFeeInfo] = useState(false);
    const [refreshData, setRefreshData] = useState(false); // value irrelevant, used to trigger re-renders

    const [invoiceValidation, setInvoiceValidation] = useState({
        client_name: false,
        street_address: false,
        phone: false,
        city: false,
        state: false,
        zip_code: false,
        email: false,
        job_items_length: false,
        job_items: [],
    });

    
    const invoiceValidator = useCallback(() => {
        if (isEmpty(invoiceData?.client_name)) {
            setInvoiceValidation({...invoiceValidation, client_name: true});
            return false;
        }
        if (isEmpty(invoiceData?.street_address)) {
            setInvoiceValidation({...invoiceValidation, street_address: true});
            return false;
        }
        if (!isMobilePhone(invoiceData?.phone, ['en-US', 'en-CA'])) {
            setInvoiceValidation({...invoiceValidation, phone: true});
            return false;
        }
        if (isEmpty(invoiceData?.city)) {
            setInvoiceValidation({...invoiceValidation, city: true});
            return false;
        }
        if (isEmpty(invoiceData?.state)) {
            setInvoiceValidation({...invoiceValidation, state: true});
            return false;
        }
        if (!(isPostalCode(invoiceData?.zip_code, 'US') || isPostalCode(invoiceData?.zip_code, 'CA'))) {
            setInvoiceValidation({...invoiceValidation, zip_code: true});
            return false;
        }
        if (!isEmail(invoiceData?.email)) {
            setInvoiceValidation({...invoiceValidation, email: true});
            return false;
        }
        if (invoiceData?.job_items?.length === 0) {
            setInvoiceValidation({...invoiceValidation, job_items_length: true});
            return false;
        }
        for (let i = 0; i < invoiceData?.job_items?.length; i++) { 
            if (isEmpty(invoiceData?.job_items[i]?.job)) { 
                setInvoiceValidation({
                    ...invoiceValidation,
                    job_items: invoiceValidation?.job_items?.map((item, index) => 
                    (i === index
                        ? {...item, job: true}
                        : item
                    ))
                });
                return false;
            }
            if (invoiceData?.job_items[i]?.amount < 0) {
                setInvoiceValidation({
                    ...invoiceValidation,
                    job_items: invoiceValidation?.job_items?.map((item, index) => 
                    (i === index
                        ? {...item, amount: true}
                        : item
                    ))
                });
                return false;
            }
        }
        return true;
    }, [invoiceData, invoiceValidation]);


    

    // Submission function
    const invoiceSubmitter = useCallback(async (isSave) => {
        if ((isSave && invoiceData?.status === 'IN_DRAFT') || invoiceValidator()) {
            if (isSave) {
                setSaveButtonText('Saving...');
            } else {
                setCreateButtonText('Creating...');
            }
            let invoice = {
                ...invoiceData,
                status: isSave ? invoiceData?.status : 'CREATED',
                total_amount: invoiceData?.total_amount(isAddFee),
                notes: invoiceData?.notes ?? 'N/A',
                phone: phoneNumberReformatter(invoiceData?.phone),
                job_items: invoiceData?.job_items?.map((item) => ({
                    ...item,
                    merchandise_cost_markup : item?.merchandise_cost_markup(),
                    labor_cost_markup: item?.labor_cost_markup(),
                    total_amount_with_markup: item?.total_amount_with_markup(isAddFee),
                }))
            };
            invoice = filterObjectProperties(invoice);
            let response;
            if (editMode) {
                if (invoice.status === 'IN_DRAFT' || invoice.status === 'CREATED') {
                    response = await editEstimateOrInvoice(invoice);
                } else {
                    response = await changeOrderEstimateOrInvoice(invoice);
                }
            } else {
                response = await createEstimateOrInvoice(invoice);
            }
            if (response) {
                console.log(response);
                
                if (isSave) {
                    setSaveButtonText('Saved Successfully!');
                    if (payment_id) {
                        const linkResponse = await linkInvoice(response.data.id, [payment_id]);
                        console.log('link response: ', linkResponse);
                    }
                    // navigate to edit view for saved draft if creating new
                    if (!editMode) {
                        navigate(`/invoices/edit/${response.data.id}`);
                    }
                } else {
                    setCreateButtonText('Created Successfully!');
                    if (payment_id) {
                        const linkResponse = await linkInvoice(response.data.id, [payment_id]);
                        console.log('link response: ', linkResponse);
                    }
                    // navigate to detail view for created invoice (to send)
                    navigate(`/invoices/${response.data.id}`);
                }
            } else {
                console.log('INVOICE SAVE/CREATE FAILURE');
                if (isSave) {
                    setSaveButtonText('Error while Saving!');
                } else {
                    setCreateButtonText('Error while Creating!');
                }
            }
            setRefreshData(refreshData => !refreshData);
            setTimeout(() => {
                if (isSave) {
                    setSaveButtonText(editMode? 'Update Invoice' : 'Save Invoice');
                } else {
                    setCreateButtonText('Create Invoice');
                }
            }, 1000);
        }
    }, [invoiceValidator, invoiceData, isAddFee, editMode, payment_id, navigate]);
    
    // useEffect to clear error statuses
    useEffect(() => {
        if (invoiceValidation?.client_name && !isEmpty(invoiceData?.client_name)) {
            setInvoiceValidation({...invoiceValidation, client_name: false});
            return;
        }
        if (invoiceValidation?.street_address && !isEmpty(invoiceData?.street_address)) {
            setInvoiceValidation({...invoiceValidation, street_address: false});
            return;
        }
        if (invoiceValidation?.phone && isMobilePhone(invoiceData?.phone, ['en-US', 'en-CA'])) {
            setInvoiceValidation({...invoiceValidation, phone: false});
            return;
        }
        if (invoiceValidation?.city && !isEmpty(invoiceData?.city)) {
            setInvoiceValidation({...invoiceValidation, city: false});
            return;
        }
        if (invoiceValidation?.state && !isEmpty(invoiceData?.state)) {
            setInvoiceValidation({...invoiceValidation, state: false});
            return;
        }
        if (invoiceValidation?.zip_code && (isPostalCode(invoiceData?.zip_code, 'US') || isPostalCode(invoiceData?.zip_code, 'CA'))) {
            setInvoiceValidation({...invoiceValidation, zip_code: false});
            return;
        }
        if (invoiceValidation?.email && isEmail(invoiceData?.email)) {
            setInvoiceValidation({...invoiceValidation, email: false});
            return;
        }
        for (let i = 0; i < invoiceData?.job_items?.length; i++) { 
            if (invoiceValidation?.job_items[i]?.job && !isEmpty(invoiceData?.job_items[i]?.job)) {
                console.log('job title found');
                setInvoiceValidation({
                    ...invoiceValidation,
                    job_items: invoiceValidation?.job_items?.map((item, index) => 
                    (i === index
                        ? {...item, job: false}
                        : item
                    ))
                });
            }
            if (invoiceValidation?.job_items[i]?.amount && invoiceData?.job_items[i]?.amount >= 0) {
                setInvoiceValidation({
                    ...invoiceValidation,
                    job_items: invoiceValidation?.job_items?.map((item, index) => 
                    (i === index
                        ? {...item, amount: false}
                        : item
                    ))
                });
            }
        }
    }, [invoiceData, invoiceValidation]); 
        

    const attachPhotos = useCallback(async event => {
        const files = Array.from(event.target.files);
        const formData = new FormData();
        files.forEach((file) => {
            formData.append('attach_photos', file);
        });
        const response = await attachEstimateOrInvoicePhotos(formData);
        if (response) {
            setInvoiceData({...invoiceData, attach_photos: response.data.attach_photos});
        } 
    }, [invoiceData]);

    // fetch data
    useEffect(() => {
        (async () => {
            // fetch user data
            let response = await getUser();
            let userData = {merchandise_cost_markup: 0, labor_cost_markup: 0 };
            if (response) {
                userData = response.data;
            }
            setUserData(userData);

            if (editMode) {
                // fetch invoice data for prefill
                response = await getEstimateOrInvoice(id);
                if (response) {
                    let invoiceData = response.data.invoice;
                    // convert properties back into functions
                    const convertedInvoice = {
                        ...invoiceData,
                        total_amount(isAddFee) {
                            return this?.job_items?.reduce((accum, current) => (accum + current?.total_amount_with_markup(isAddFee)), 0)
                        },
                        job_items: invoiceData?.job_items?.map((item) => ({
                            ...item,
                            merchandise_cost_markup() {
                                return this?.add_merchandise_cost_markup
                                ? this?.merchandise_cost * (userData?.merchandise_cost_markup / 100)
                                : 0;
                            },
                            labor_cost_markup() {
                                return this.add_labor_cost_markup 
                                ? this.labor_cost * (userData?.labor_cost_markup / 100)
                                : 0;
                            },
                            total_amount_with_markup(isAddFee) {
                                return isAddFee
                                ? Math.ceil(((this?.amount + this?.labor_cost_markup() + this?.merchandise_cost_markup()) * 1.035) / 5) * 5
                                : this?.amount + this?.labor_cost_markup() + this?.merchandise_cost_markup();
                            },
                        }))
                    };
                    if (convertedInvoice?.status !== 'IN_DRAFT' && convertedInvoice?.status !== 'CREATED') {
                        setSaveButtonText('Change Order');
                    }
                    setInvoiceData(convertedInvoice);
                }
            } else {
                // fetch contact data for prefill (if exists)
                if (contact_id) {
                    response = await getCustomers(0, 0, '');
                    if (response) {
                        const customer = response.data.result.filter((customer) => (customer.id === Number(contact_id)))[0];
                        setInvoiceData(invoiceData => ({
                            ...invoiceData, 
                            client_name: `${customer.first_name} ${customer.last_name}`,
                            street_address: customer.street_address,
                            phone: customer.phone,
                            city: customer.city,
                            state: customer.state,
                            zip_code: customer.zip_code,
                            email: customer.email,
                        }));
                    }
                }
            }
        })();
    }, [contact_id, editMode, id, refreshData]);


    return (
        <Box sx={{ display: 'flex', }}>
            <AppDrawer/>
            <TosModal 
                invoiceData={invoiceData} 
                setInvoiceData={setInvoiceData} 
                showModal={showTosModal} 
                setShowModal={setShowTosModal}
            />
            <JobDetailModal 
                index={jobDetailModalIndex} 
                item={invoiceData?.job_items[jobDetailModalIndex]} 
                invoiceData={invoiceData}
                setInvoiceData={setInvoiceData}
                invoiceValidation={invoiceValidation}
                showModal={showJobDetailModal}
                setShowModal={setShowJobDetailModal}
            />
            <AddFromCustomerModal
                showModal={showAddFromCustomerModal}
                setShowModal={setShowAddFromCustomerModal}
                setInvoiceData={setInvoiceData}
            />
            <Grid2 container padding={4} rowGap={4} flexGrow={1} justifyContent={'space-between'}>
                <Grid2 container lg={6} md={12} xs={12} direction={'column'} alignItems={'flex-start'}>
                    <Button startIcon={<ChevronLeft/>} onClick={() => navigate('/invoices')}>
                        Back
                    </Button>
                    <Typography color={'primary'} variant="h3" fontWeight={'bold'}>
                        {editMode 
                            ? (invoiceData?.status === 'IN_DRAFT' || invoiceData?.status === 'CREATED') ? 'Edit Invoice' : 'Change Order'
                            : 'New Invoice'
                        }
                    </Typography>
                </Grid2>
                <Grid2 container lg={6} md={12} xs={12} justifyContent={'flex-end'} alignItems={'center'} className="payment-button-group">
                    {invoiceData?.status === 'IN_DRAFT' &&
                    <Button 
                        className="payment-button"
                        variant="outlined" 
                        startIcon={<Save/>}
                        onClick={async () => {
                            await invoiceSubmitter(true);
                        }}
                    >
                        {saveButtonText}
                    </Button>}
                </Grid2>
                <Grid2 xs={12} container alignItems={'flex-end'}>
                    <Grid2 lg={6} md={12} xs={12} container alignItems={'center'} gap={2}>
                        <Typography variant="h4">
                            {editMode ? `Proposal #${invoiceData?.invoices_number}` : 'New Proposal'}
                        </Typography>
                        {editMode && <InvoiceStatusTag status={invoiceData?.status}/>}
                    </Grid2>
                    <Grid2 lg={6} md={12} xs={12} container justifyContent={'flex-end'}>
                        <Typography variant="body1" color={'primary'}>
                            DATE: {new Date().toLocaleDateString('en-US', {
                                month: 'short', year:'numeric', day:'numeric', hour: 'numeric', minute: 'numeric', second:'numeric'
                            })}
                        </Typography>
                    </Grid2>
                </Grid2>
                <Grid2 xs={12} container rowGap={2} justifyContent={'space-between'}>
                    <Grid2 lg={5.7} md={12} xs={12} container direction={'column'} alignItems={'flex-end'}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            color="primary"
                            type="text"
                            value={invoiceData?.client_name}
                            error={invoiceValidation?.client_name}
                            helperText={invoiceValidation?.client_name ? "Please enter a full name" : ''}
                            label="Client Name"
                            placeholder="Enter Client Name"
                            onChange={event => setInvoiceData({...invoiceData, client_name: event.target.value})}
                        />
                        {!editMode &&
                        <Button
                            startIcon={<Add/>}
                            onClick={() => setShowAddFromCustomerModal(true)}
                        >
                            Add from Customers
                        </Button>}
                    </Grid2>
                    <Grid2 lg={5.7} md={12} xs={12} container>
                        <AddressInput
                            value={invoiceData?.street_address}
                            setValue={(address, city, state, zip) => address ? setInvoiceData({
                                ...invoiceData,
                                street_address: address,
                                city: city,
                                state: state,
                                zip_code: zip
                            }) : setInvoiceData({
                                ...invoiceData,
                                street_address: address
                            })}
                            label="Street Address"
                            placeholder="Enter Street Address"
                            error={invoiceValidation?.street_address}
                            helperText={invoiceValidation?.street_address ? "Please enter a street address" : ''}
                        />
                    </Grid2>
                    <Grid2 lg={5.7} md={12} xs={12} container>
                        <TextField
                            variant="outlined"
                            fullWidth
                            color="primary"
                            type="text"
                            value={invoiceData?.phone}
                            error={invoiceValidation?.phone}
                            helperText={invoiceValidation?.phone ? "Please enter a valid phone number" : ''}
                            label="Phone Number"
                            placeholder="Enter Phone Number"
                            onChange={event => setInvoiceData({...invoiceData, phone: event.target.value})}
                        />
                    </Grid2>
                    <Grid2 lg={5.7} md={12} xs={12} container justifyContent={'space-between'} rowGap={2}>
                        <Grid2 lg={3.7} md={3.7} xs={12} container>
                            <TextField
                                variant="outlined"
                                fullWidth
                                color="primary"
                                type="text"
                                value={invoiceData?.city}
                                error={invoiceValidation?.city}
                                helperText={invoiceValidation?.city ? "Please enter a city" : ''}
                                label="City"
                                placeholder="Enter City"
                                onChange={event => setInvoiceData({...invoiceData, city: event.target.value})}
                            />
                        </Grid2>
                        <Grid2 lg={3.7} md={3.7} xs={12} container>
                            <TextField
                                variant="outlined"
                                fullWidth
                                color="primary"
                                type="text"
                                value={invoiceData?.state}
                                error={invoiceValidation?.state}
                                helperText={invoiceValidation?.state ? "Please enter a state" : ''}
                                label="State"
                                placeholder="Enter State"
                                onChange={event => setInvoiceData({...invoiceData, state: event.target.value})}
                            />
                        </Grid2>
                        <Grid2 lg={3.7} md={3.7} xs={12} container>
                            <TextField
                                variant="outlined"
                                fullWidth
                                color="primary"
                                type="text"
                                value={invoiceData?.zip_code}
                                error={invoiceValidation?.zip_code}
                                helperText={invoiceValidation?.zip_code ? "Please enter a valid zip code" : ''}
                                label="Zip Code"
                                placeholder="Enter Zip Code"
                                onChange={event => setInvoiceData({...invoiceData, zip_code: event.target.value})}
                            />
                        </Grid2>
                    </Grid2>
                    <Grid2 lg={5.7} md={12} xs={12} container>
                        <TextField
                            variant="outlined"
                            fullWidth
                            color="primary"
                            type="email"
                            value={invoiceData?.email}
                            error={invoiceValidation?.email}
                            helperText={invoiceValidation?.email ? "Please enter a valid email address" : ''}
                            label="Email Address"
                            placeholder="Enter Email Address"
                            onChange={event => setInvoiceData({...invoiceData, email: event.target.value})}
                        />
                    </Grid2>
                </Grid2>
                <Grid2 xs={12} container rowGap={2}>
                    <Grid2 xs={5.5} container p={1} className={'job-item-header-left'}>
                        <Typography color={'#858585'}>Job Title</Typography>
                    </Grid2>
                    <Grid2 xs={6.5} container p={1} className={'job-item-header-right'}>
                        <Typography color={'#858585'}>Amount</Typography>
                    </Grid2>
                    {invoiceValidation?.job_items_length &&
                    <Grid2 xs={12}>
                        <Typography color={"error"}>Please enter at least one job item</Typography>
                    </Grid2>
                    }
                    {invoiceData?.job_items?.map((item, index) => (
                        <Grid2 key={index} xs={12} container justifyContent={'space-evenly'} rowGap={2} >
                            <Grid2 xs={5.2} container p={1} sx={{':hover': {cursor: 'pointer'}}}
                                onClick={() => {
                                    setJobDetailModalIndex(index);
                                    setShowJobDetailModal(true);
                                }}
                            >
                                <Typography 
                                    color={invoiceValidation?.job_items[index]?.job 
                                        ? 'error' 
                                        : item?.job !== '' 
                                            ? 'primary' 
                                            : 'secondary.dark'
                                    }
                                >
                                    {item?.job !== '' ? item?.job : 'Edit to change job title'}
                                </Typography>
                            </Grid2>
                            <Grid2 xs={5.2} container p={1} sx={{':hover': {cursor: 'pointer'}}}
                                onClick={() => {
                                    setJobDetailModalIndex(index);
                                    setShowJobDetailModal(true);
                                }}
                            >
                                <Typography 
                                    color={invoiceValidation?.job_items[index]?.amount 
                                        ? 'error' 
                                        : item?.amount >= 0 
                                            ? 'primary' 
                                            : 'secondary.dark'
                                    }
                                >
                                    {USDollar.format(item?.total_amount_with_markup(isAddFee))}
                                </Typography>
                            </Grid2>
                            <IconButton
                                onClick={() => {
                                    setJobDetailModalIndex(index);
                                    setShowJobDetailModal(true);
                                }}
                            >
                                <Edit/>
                            </IconButton>
                            <IconButton
                                onClick={() => {
                                    setInvoiceData({
                                        ...invoiceData, 
                                        job_items: invoiceData?.job_items?.filter((it, idx) => (idx !== index))
                                    });
                                    setInvoiceValidation({
                                        ...invoiceValidation, 
                                        job_items: invoiceValidation?.job_items?.filter((it, idx) => (idx !== index))
                                    });
                                }}
                            >
                                <Close/>
                            </IconButton>
                            <Grid2 xs={12}>
                                <Divider sx={{ bgcolor: 'gray',}}/>
                            </Grid2>
                        </Grid2>
                    ))}
                    <Grid2 xs={12} container>
                        <Button
                            startIcon={<Add/>}
                            onClick={() => {
                                setInvoiceData({
                                    ...invoiceData, 
                                    job_items: [
                                        ...invoiceData?.job_items,
                                        { 
                                            job: '', 
                                            amount: 0,
                                            details: [],
                                            merchandise_cost: 0, 
                                            add_merchandise_cost_markup: false,
                                            labor_cost: 0,
                                            add_labor_cost_markup: false,
                                            merchandise_cost_markup() {
                                                return this?.add_merchandise_cost_markup
                                                ? this?.merchandise_cost * (userData?.merchandise_cost_markup / 100)
                                                : 0;
                                            },
                                            labor_cost_markup() {
                                                return this.add_labor_cost_markup 
                                                ? this.labor_cost * (userData?.labor_cost_markup / 100)
                                                : 0;
                                            },
                                            total_amount_with_markup(isAddFee) {
                                                return isAddFee
                                                ? Math.ceil(((this?.amount + this?.labor_cost_markup() + this?.merchandise_cost_markup()) * 1.035) / 5) * 5
                                                : this?.amount + this?.labor_cost_markup() + this?.merchandise_cost_markup();
                                            },
                                        },
                                    ]
                                });
                                setInvoiceValidation({
                                    ...invoiceValidation, 
                                    job_items_length: false,
                                    job_items: [
                                        ...invoiceValidation?.job_items,
                                        { job: false, amount: false }
                                    ]
                                });
                            }}
                        >
                            Additional Item
                        </Button>
                    </Grid2>
                    <Grid2 xs={12}>
                        <Divider sx={{ bgcolor: 'gray',}}/>
                    </Grid2>
                </Grid2>
                <Grid2 xs={12} container justifyContent={'space-between'} alignItems={'flex-start'}>
                    <Grid2 lg={5} md={12} xs={12} container rowGap={1}>
                        <Grid2 xs={12} container direction={'column'} alignItems={'flex-start'} >
                            <Typography color={'secondary.dark'}>
                                Notes
                            </Typography>
                            <Grid2 xs={12} container>
                                <TextField 
                                    variant="outlined"
                                    color="primary"
                                    fullWidth
                                    multiline
                                    maxRows={4}
                                    type="text"
                                    value={invoiceData?.notes}
                                    placeholder="Enter Notes"
                                    onChange={event => setInvoiceData({...invoiceData, notes: event.target.value})}
                                />
                            </Grid2>
                        </Grid2>
                        <Grid2 xs={12} container direction={'column'} alignItems={'flex-start'}>
                            <Typography color={'secondary.dark'}>
                                Attachments
                            </Typography>
                            <Grid2 xs={12} container p={1}>
                                <input type="file" multiple onChange={attachPhotos}/>
                            </Grid2>
                            <Grid2 xs={12} container>
                                <ImageList>
                                    {invoiceData?.attach_photos.map((url, index) => (
                                    <ImageListItem key={index}>
                                        <img
                                            src={url}
                                            alt=""
                                            loading="lazy"
                                        />
                                    </ImageListItem>
                                    ))}
                                </ImageList>
                            </Grid2>
                        </Grid2>
                    </Grid2>
                    <Grid2 lg={6} md={12} xs={12} container rowGap={1} alignItems={'flex-end'}>
                        <Grid2 xs={12} container>
                            <Grid2 lg={6} md={12} xs={12}  container gap={1} alignItems={'center'}>
                                <Checkbox 
                                    checked={!invoiceData?.showing_item_amount}
                                    onChange={event => setInvoiceData({...invoiceData, showing_item_amount: !event.target.checked})}
                                />
                                <Typography>
                                    Only show Total Amount
                                </Typography>
                            </Grid2>
                            <Grid2 lg={6} md={12} xs={12}  container gap={1} alignItems={'center'}>
                                <Checkbox 
                                    checked={isAddFee}
                                    onChange={event => {
                                        setIsAddFee(event.target.checked);
                                    }}
                                />
                                <Typography>
                                    Add Processing Fee to Bill
                                </Typography>
                                <IconButton onClick={() => setShowFeeInfo(!showFeeInfo)}>
                                    <HelpOutline/>
                                </IconButton>
                            </Grid2>
                        </Grid2>
                        {showFeeInfo && <Grid2 xs={12} container bgcolor={'secondary.main'} borderRadius={'8px'} p={1} gap={1}>
                            <Grid2 xs={12} container alignItems={'center'} gap={1}>
                                <HelpOutline/>
                                <Typography fontSize={14} fontWeight={'bold'}>Add Processing Fee to Bill</Typography>
                            </Grid2>
                            <Grid2 xs={12}>
                                <Typography align="left" fontSize={12}>
                                    If selected, Tapper will attach a 3.5% transaction fee to the bill and round up each
                                    job item to the closest $5 or $10 to cover nearly all your processing fees.
                                    Customer will only see total amount, and not the processing fee breakndown.
                                </Typography>
                            </Grid2>
                        </Grid2>}
                        <Grid2 xs={6} container alignItems={'flex-end'}>
                            <Typography color={'primary'}> 
                                Total Amount
                            </Typography>
                        </Grid2>
                        <Grid2 xs={6} container justifyContent={'flex-end'}>
                            <Typography variant="h5" color={'primary'} fontWeight={'bold'}>
                                {USDollar.format(invoiceData?.total_amount(isAddFee))}
                            </Typography>
                        </Grid2>
                        <Grid2 xs={12}>
                            <Divider sx={{bgcolor: 'gray'}} />
                        </Grid2>
                        <Grid2 xs={6} container alignItems={'flex-end'}>
                            <Typography color={'secondary.dark'}> 
                                Material Cost
                            </Typography>
                        </Grid2>
                        <Grid2 xs={6} container justifyContent={'flex-end'}>
                            <Typography color={'secondary.dark'}>
                                {USDollar.format(invoiceData?.job_items?.reduce((accum, current) => (accum + current?.merchandise_cost), 0))}
                            </Typography>
                        </Grid2>
                        <Grid2 xs={6} container alignItems={'flex-end'}>
                            <Typography color={'secondary.dark'}> 
                                Labor Cost
                            </Typography>
                        </Grid2>
                        <Grid2 xs={6} container justifyContent={'flex-end'}>
                            <Typography color={'secondary.dark'}>
                            {USDollar.format(invoiceData?.job_items?.reduce((accum, current) => (accum + current?.labor_cost), 0))}
                            </Typography>
                        </Grid2>
                        <Grid2 xs={12} container>
                            <Typography color={'primary.light'}>
                                *Costs are not shown to clients.
                            </Typography>
                        </Grid2>
                        <Grid2 xs={12} container gap={2} alignItems={'center'}>
                            <Checkbox 
                                checked={invoiceData?.showing_term_service}
                                onChange={event => {
                                    setInvoiceData({...invoiceData, showing_term_service: event.target.checked});
                                    setShowTosModal(event.target.checked);
                                }}
                            />
                            <Button startIcon={<Edit/>} onClick={() => setShowTosModal(true)}>
                                Attach Terms of Service/Additional Statement
                            </Button>
                        </Grid2>
                        <Grid2 xs={12} container>
                            <Button
                                variant={'contained'}
                                fullWidth
                                startIcon={invoiceData?.status === 'IN_DRAFT' ? <Create/> : <Save/>}
                                onClick={async () => {
                                    await invoiceSubmitter(invoiceData?.status !== 'IN_DRAFT');
                                }}
                            >
                                {invoiceData?.status === 'IN_DRAFT' ? createButtonText : saveButtonText}
                            </Button>
                        </Grid2>
                    </Grid2>
                </Grid2>
            </Grid2>
        </Box>
    );
}

export default InvoiceEdit;
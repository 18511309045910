import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { useCallback, useEffect, useMemo, useState, useContext } from "react";
import { useMultiStepForm } from "../../misc/useMultiStepForm";
import theme from "../../constants/theme";
import { registerUserBusinessInfoAndLocation, setUserMarkups, updateUserLogo, updateUserPersonalInfo } from "../../api/user";
import isEmpty from "validator/lib/isEmpty";
import isMobilePhone from "validator/lib/isMobilePhone";
import { Button, InputAdornment, TextField, Typography } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import isEmail from "validator/lib/isEmail";
import { phoneNumberReformatter } from "../../misc/utilityFunctions";
import { useAuth } from "../../contexts/AuthContext";
import AddressInput from "../../components/util/addressInput";
import { AppContext } from '../../contexts/AppContext';

const EmployerRegistration = () => {
    const { isTablet } = useContext(AppContext);
    const { setRegistering } = useAuth();
    const [userData, setUserData] = useState({
        first_name: '',
        last_name: '',
        phone_number: '',
        company_information: {
            name: '',
            email: '',
            phone: '',
        },
        location: {
            address_line_1: '',
            city: '',
            state: '',
            zip_code: '',
        },
        merchandise_cost_markup: 0,
        labor_cost_markup: 0,
    });
    const [userValidation, setUserValidation] = useState({
        first_name: false,
        last_name: false,
        phone_number: false,
        email: false,
        company_information: {
            name: false,
            email: false,
            phone: false,
        },
        location: {
            city: false,
            state: false,
            zip_code: false,
            address_line_1: false,
        },
        merchandise_cost_markup: false,
        labor_cost_markup: false,
    });
    const [logoURL, setLogoURL] = useState('');
    const [logoError, setLogoError] = useState(false);


    // Basic Info Form
    const BasicInfoForm = useCallback(() => (
        <Grid2 xs={12} container rowGap={4} justifyContent={'space-between'}>
            <Grid2 xs={12} container>
                <Typography variant="h6" color={'primary'}>What is your...</Typography>
            </Grid2>
            <Grid2 lg={5.5} md={12} xs={12}>
                <TextField
                    variant="standard"
                    fullWidth
                    color="primary"
                    type="text"
                    value={userData?.first_name}
                    error={userValidation?.first_name}
                    helperText={userValidation?.first_name ? "Please enter a first name" : ''}
                    label="First Name"
                    placeholder="Enter First Name"
                    onChange={event => setUserData(userData => ({...userData, first_name: event.target.value}))}
                />
            </Grid2>
            <Grid2 lg={5.5} md={12} xs={12}>
                <TextField
                    variant="standard"
                    fullWidth
                    color="primary"
                    type="text"
                    value={userData?.last_name}
                    error={userValidation?.last_name}
                    helperText={userValidation?.last_name ? "Please enter a last name" : ''}
                    label="Last Name"
                    placeholder="Enter Last Name"
                    onChange={event => setUserData(userData => ({...userData, last_name: event.target.value}))}
                />
            </Grid2>
            <Grid2 xs={12}>
                <TextField
                    variant="standard"
                    fullWidth
                    color="primary"
                    type="text"
                    value={userData?.phone_number}
                    error={userValidation?.phone_number}
                    helperText={userValidation?.phone_number ? "Please enter a valid phone number" : ''}
                    label="Phone Number"
                    placeholder="Enter Phone Number"
                    onChange={event => setUserData(userData => ({...userData, phone_number: event.target.value}))}
                />
            </Grid2>
            <Grid2 xs={12}>
                <TextField
                    variant="standard"
                    fullWidth
                    color="primary"
                    type="text"
                    value={userData?.company_information?.phone}
                    error={userValidation?.company_information?.phone}
                    helperText={userValidation?.company_information?.phone ? "Please enter a valid phone number" : ''}
                    label="Business Phone Number"
                    placeholder="Enter Phone Number"
                    onChange={event => setUserData(userData => ({
                        ...userData,
                        company_information: {
                            ...userData.company_information,
                            phone: event.target.value,
                        }
                    }))}
                />
            </Grid2>
        </Grid2>
    ), [userData?.company_information?.phone, userData?.first_name, userData?.last_name, userData?.phone_number, userValidation?.company_information?.phone, userValidation?.first_name, userValidation?.last_name, userValidation?.phone_number]);

    // Basic Info Validator
    const basicInfoValidator = useCallback(() => {
        if (isEmpty(userData?.first_name)) {
            setUserValidation(userValidation => ({...userValidation, first_name: true}));
            return false;
        }
        if (isEmpty(userData?.last_name)) {
            setUserValidation(userValidation => ({...userValidation, last_name: true}));
            return false;
        }
        if (!isMobilePhone(userData?.phone_number, ['en-US', 'en-CA'])) {
            setUserValidation(userValidation => ({...userValidation, phone_number: true}));
            return false;
        }
        if (!isMobilePhone(userData?.company_information?.phone)) {
            setUserValidation(userValidation => ({
                ...userValidation, 
                company_information: {
                    ...userValidation.company_information,
                    phone: true,
                }
            }));
            return false;
        }
        return true;
    }, [userData?.company_information?.phone, userData?.first_name, userData?.last_name, userData?.phone_number]);

    // Basic Info Submitter
    const basicInfoSubmitter = useCallback(async () => {
        const { first_name, last_name, phone_number} = userData;
        return await updateUserPersonalInfo(first_name, last_name, phoneNumberReformatter(phone_number));
    }, [userData]);

    // Basic Info Error Status Clearer
    useEffect(() => {
        if (!isEmpty(userData?.first_name ? userData?.first_name : '')) {
            setUserValidation(userValidation => ({...userValidation, first_name: false}));
        }
        if (!isEmpty(userData?.last_name ? userData?.last_name : '')) {
            setUserValidation(userValidation => ({...userValidation, last_name: false}));
        }
        if (isMobilePhone(userData?.phone_number ? userData?.phone_number : '', ['en-US', 'en-CA'])) {
            setUserValidation(userValidation => ({...userValidation, phone_number: false}));
        }
        if (isMobilePhone(userData?.company_information?.phone ? userData?.company_information?.phone : '')) {
            setUserValidation(userValidation => ({
                ...userValidation, 
                company_information: {
                    ...userValidation.company_information,
                    phone: false,
                }
            }));
        }
    }, [userData?.company_information?.phone, userData?.first_name, userData?.last_name, userData?.phone_number]);



    // Business Info Form
    const BusinessInfoForm = useCallback(() => (
        <Grid2 xs={12} container rowGap={4} justifyContent={'space-between'}>
            <Grid2 lg={5.5} md={5.5} xs={12}>
                <TextField
                    variant="standard"
                    fullWidth
                    color="primary"
                    type="text"
                    value={userData?.company_information?.name}
                    error={userValidation?.company_information?.name}
                    helperText={userValidation?.company_information?.name ? "Please enter a business name" : ''}
                    label="Business Name"
                    placeholder="Enter Business Name"
                    onChange={event => setUserData(userData => ({
                        ...userData,
                        company_information: {
                            ...userData.company_information,
                            name: event.target.value,
                        }
                    }))}
                />
            </Grid2>
            <Grid2 lg={5.5} md={5.5} xs={12}>
                <TextField
                    variant="standard"
                    fullWidth
                    color="primary"
                    type="email"
                    value={userData?.company_information?.email}
                    error={userValidation?.company_information?.email}
                    helperText={userValidation?.company_information?.email ? "Please enter a valid email address" : ''}
                    label="Business Email Address"
                    placeholder="Enter Email Address"
                    onChange={event => setUserData(userData => ({
                        ...userData,
                        company_information: {
                            ...userData.company_information,
                            email: event.target.value,
                        }
                    }))}
                />
            </Grid2>
            <Grid2 xs={12}>
                <AddressInput
                    value={userData?.location?.address_line_1}
                    setValue={(address, city, state, zip) => address ? setUserData({
                        ...userData,
                        location: {
                            ...userData.location,
                            address_line_1: address,
                            city: city,
                            state: state,
                            zip_code: zip
                        }
                    }) : setUserData({
                        ...userData,
                        location: {
                            ...userData.location,
                            address_line_1: address
                        }
                    })}
                    label="Street Address"
                    placeholder="Enter Street Address..."
                    error={userValidation?.location?.address_line_1}
                    helperText={userValidation?.location?.address_line_1 ? "Please enter a street address" : ''}
                    variant="standard"
                />
            </Grid2>
            <Grid2 xs={12} container justifyContent={'space-between'} rowGap={4}>
                <Grid2 lg={3.5} md={3.5} xs={12}>
                    <TextField
                        variant="standard"
                        fullWidth
                        color="primary"
                        type="text"
                        value={userData?.location?.city}
                        error={userValidation?.location?.city}
                        helperText={userValidation?.location?.city ? "Please enter a city" : ''}
                        label="City"
                        placeholder="Enter City"
                        onChange={event => setUserData(userData => ({
                            ...userData,
                            location: {
                                ...userData.location,
                                city: event.target.value,
                            }
                        }))}
                    />
                </Grid2>
                <Grid2 lg={3.5} md={3.5} xs={12}>
                    <TextField
                        variant="standard"
                        fullWidth
                        color="primary"
                        type="text"
                        value={userData?.location?.state}
                        error={userValidation?.location?.state}
                        helperText={userValidation?.location?.state ? "Please enter a state" : ''}
                        label="State"
                        placeholder="Enter State"
                        onChange={event => setUserData(userData => ({
                            ...userData,
                            location: {
                                ...userData.location,
                                state: event.target.value,
                            }
                        }))}
                    />
                </Grid2>
                <Grid2 lg={3.5} md={3.5} xs={12}>
                    <TextField
                        variant="standard"
                        fullWidth
                        color="primary"
                        type="text"
                        value={userData?.location?.zip_code}
                        error={userValidation?.location?.zip_code}
                        helperText={userValidation?.location?.zip_code ? "Please enter a zip code" : ''}
                        label="Zip Code"
                        placeholder="Enter Zip Code"
                        onChange={event => setUserData(userData => ({
                            ...userData,
                            location: {
                                ...userData.location,
                                zip_code: event.target.value,
                            }
                        }))}
                    />
                </Grid2>
            </Grid2>
        </Grid2>
    ), [userData?.company_information?.email, userData?.company_information?.name, userData?.location?.address_line_1, userData?.location?.city, userData?.location?.state, userData?.location?.zip_code, userValidation?.company_information?.email, userValidation?.company_information?.name, userValidation?.location?.address_line_1, userValidation?.location?.city, userValidation?.location?.state, userValidation?.location?.zip_code]);

    // Business Info Validator
    const businessInfoValidator = useCallback(() => {
        if (isEmpty(userData?.company_information?.name ? userData?.company_information?.name : '')) {
            setUserValidation(userValidation => ({
                ...userValidation, 
                company_information: {
                    ...userValidation.company_information,
                    name: true,
                }
            }));
            return false;
        }
        if (!isEmail(userData?.company_information?.email ? userData?.company_information?.email : '')) {
            setUserValidation(userValidation => ({
                ...userValidation, 
                company_information: {
                    ...userValidation.company_information,
                    email: true,
                }
            }));
            return false;
        }
        if (isEmpty(userData?.location?.address_line_1 ? userData?.location?.address_line_1 : '')) {
            setUserValidation(userValidation => ({
                ...userValidation, 
                location: {
                    ...userValidation.location,
                    address_line_1: true,
                }
            }));
            return false;
        }
        if (isEmpty(userData?.location?.city ? userData?.location?.city : '')) {
            setUserValidation(userValidation => ({
                ...userValidation, 
                location: {
                    ...userValidation.location,
                    city: true,
                }
            }));
            return false;
        }
        if (isEmpty(userData?.location?.state ? userData?.location?.state : '')) {
            setUserValidation(userValidation => ({
                ...userValidation, 
                location: {
                    ...userValidation.location,
                    state: true,
                }
            }));
            return false;
        }
        if (isEmpty(userData?.location?.zip_code ? userData?.location?.zip_code : '')) {
            setUserValidation(userValidation => ({
                ...userValidation, 
                location: {
                    ...userValidation.location,
                    zip_code: true,
                }
            }));
            return false;
        }
        return true;
    }, [userData?.company_information?.email, userData?.company_information?.name, userData?.location?.address_line_1, userData?.location?.city, userData?.location?.state, userData?.location?.zip_code])

    // Business Info Submitter
    const businessInfoSubmitter = useCallback(async () => {
        let { company_information, location } = userData;
        company_information.phone = phoneNumberReformatter(company_information.phone);
        return await registerUserBusinessInfoAndLocation(company_information, location);
    }, [userData]);

    //  Business Info Error Status Clearer
    useEffect(() => {
        if (!isEmpty(userData?.company_information?.name ? userData?.company_information?.name : '')) {
            setUserValidation(userValidation => ({
                ...userValidation, 
                company_information: {
                    ...userValidation.company_information,
                    name: false,
                }
            }));
        }
        if (isEmail(userData?.company_information?.email ? userData?.company_information?.email : '')) {
            setUserValidation(userValidation => ({
                ...userValidation, 
                company_information: {
                    ...userValidation.company_information,
                    email: false,
                }
            }));
        }
        if (!isEmpty(userData?.location?.address_line_1 ? userData?.location?.address_line_1 : '')) {
            setUserValidation(userValidation => ({
                ...userValidation, 
                location: {
                    ...userValidation.location,
                    address_line_1: false,
                }
            }));
        }
        if (!isEmpty(userData?.location?.city ? userData?.location?.city : '')) {
            setUserValidation(userValidation => ({
                ...userValidation, 
                location: {
                    ...userValidation.location,
                    city: false,
                }
            }));
        }
        if (!isEmpty(userData?.location?.state ? userData?.location?.state : '')) {
            setUserValidation(userValidation => ({
                ...userValidation, 
                location: {
                    ...userValidation.location,
                    state: false,
                }
            }));
        }
        if (!isEmpty(userData?.location?.zip_code ? userData?.location?.zip_code : '')) {
            setUserValidation(userValidation => ({
                ...userValidation, 
                location: {
                    ...userValidation.location,
                    zip_code: false,
                }
            }));
        }
    }, [userData?.company_information?.email, userData?.company_information?.name, userData?.location?.address_line_1, userData?.location?.city, userData?.location?.state, userData?.location?.zip_code]);
    


    // Markups Form
    const MarkupsForm = useCallback(() => (
        <Grid2 xs={12} container rowGap={4} justifyContent={'space-between'}>
            <Grid2 xs={12} container>
                <Typography align="left" variant="h6" color={'primary'}>
                    We automatically apply the markups for estimates & invoices on a job-item basis. 
                    You can always change default settings later or edit in invoice.
                </Typography>
            </Grid2>
            <Grid2 xs={6} container p={2}>
                <TextField
                    variant="standard"
                    color="primary"
                    type="number"
                    value={userData?.merchandise_cost_markup}
                    error={userValidation?.merchandise_cost_markup}
                    helperText={userValidation?.merchandise_cost_markup ? "Please enter a valid percentage %" : ''}
                    label="Material Cost Markup"
                    placeholder="Enter Material Cost Markup %"
                    onChange={event => setUserData(userData => ({...userData, merchandise_cost_markup: event.target.value}))}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">%</InputAdornment>
                    }}
                />
            </Grid2>
            <Grid2 xs={6} container p={2}>
                <TextField
                    variant="standard"
                    color="primary"
                    type="number"
                    value={userData?.labor_cost_markup}
                    error={userValidation?.labor_cost_markup}
                    helperText={userValidation?.labor_cost_markup ? "Please enter a valid percentage %" : ''}
                    label="Labor Cost Markup"
                    placeholder="Enter Labor Cost Markup %"
                    onChange={event => setUserData(userData => ({...userData, labor_cost_markup: event.target.value}))}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">%</InputAdornment>
                    }}
                />
            </Grid2>
            
        </Grid2>
    ), [userData?.labor_cost_markup, userData?.merchandise_cost_markup, userValidation?.labor_cost_markup, userValidation?.merchandise_cost_markup]);

    // Markups Validator
    const markupsValidator = useCallback(() => {
        if (userData?.merchandise_cost_markup < 0) {
            setUserValidation(userValidation => ({...userValidation, merchandise_cost_markup: true}));
            return false;
        }
        if (userData?.labor_cost_markup < 0) {
            setUserValidation(userValidation => ({...userValidation, labor_cost_markup: true}));
            return false;
        }
        return true;
    }, [userData?.labor_cost_markup, userData?.merchandise_cost_markup]);

    // Markups Submitter
    const markupsSubmitter = useCallback(async () => {
        const { merchandise_cost_markup, labor_cost_markup } = userData;
        return await setUserMarkups(merchandise_cost_markup, labor_cost_markup);
    }, [userData]);

    // Markups Error Status Clearer
    useEffect(() => {
        if (userData?.merchandise_cost_markup >= 0) {
            setUserValidation(userValidation => ({...userValidation, merchandise_cost_markup: false}));
        }
        if (userData?.labor_cost_markup >= 0) {
            setUserValidation(userValidation => ({...userValidation, labor_cost_markup: false}));
        }
    }, [userData]);



    const attachLogo = useCallback(async event => {
        const files = Array.from(event.target.files);
        const formData = new FormData();
        files.forEach((file) => {
            formData.append('logo', file);
        });
        const response = await updateUserLogo(formData);
        if (response) {
            setLogoURL(response.data);
        } 
    }, []);
    
    // Logo Upload Form
    const LogoUploadForm = useCallback(() => (
        <Grid2 xs={12} container rowGap={4} justifyContent={'space-between'}>
            {logoError &&
            <Grid2 xs={12} container>
                <Typography align="left" variant="h6" color={'error.main'}>
                   Please upload a Logo, or skip if you do not have one ready at the moment.
                </Typography>
            </Grid2>}
             <Grid2 xs={12} container flexDirection={'column'} alignItems={'center'} gap={4}>
                <Grid2 xs={'auto'}>
                    <img
                        src={logoURL}
                        width={250}
                        alt=""
                        loading="lazy"
                    />
                </Grid2>
                <Grid2 xs>
                    <input type="file" onChange={attachLogo}/>
                </Grid2>
            </Grid2>
        </Grid2>
    ), [attachLogo, logoError, logoURL]);

    // Logo Upload Validator
    const logoValidator = useCallback(() => {
        if (!logoURL) {
            setLogoError(true);
            return false;
        }
        return true;
    }, [logoURL]);

    // Logo Upload Submitter
    const logoSubmitter = useCallback(async () => {
        return true;
    }, []);

    // Logo Upload Error Status Clearer
    useEffect(() => {
        if (logoURL) {
            setLogoError(false);
        }
    }, [logoURL])
    

    // multi-step form re-usable custom hook
    const { pageIndex, page, pages, next, back, isLastPage } = useMultiStepForm([
        BasicInfoForm, // first page
        BusinessInfoForm, // second page
        MarkupsForm, // third page
        LogoUploadForm, // fourth page
    ]);

    const validators = useMemo(() => [
        basicInfoValidator, // 0
        businessInfoValidator, // 1
        markupsValidator, // 2
        logoValidator, // 3
    ], [basicInfoValidator, businessInfoValidator, logoValidator, markupsValidator]);
    

    const submitters = useMemo(() => [
        basicInfoSubmitter, // 0
        businessInfoSubmitter, // 1
        markupsSubmitter, // 2
        logoSubmitter, // 3
    ], [basicInfoSubmitter, businessInfoSubmitter, logoSubmitter, markupsSubmitter]);

    const skippables = useMemo(() => [
        false,
        false,
        true,
        true,
    ], []);
    
    const titles = useMemo(() => [
        "Let's Start With The Basics", // 0
        "Some Info About Your Business", // 1
        "Do You Want To Add Markups?", // 2
        "Upload Your Company Logo", // 3
    ], []);

    // page progress indicator
    const ProgressBar = useCallback(() => {
        return (
            <Grid2 xs={12} container gap={2}> 
                {pages.map((value, index) => (
                    <Grid2 
                        key={index+1} 
                        xs 
                        height={10} 
                        bgcolor={
                            (index === pageIndex) 
                            ? theme.palette.primary.main 
                            : theme.palette.secondary.light
                        }
                    />
                ))}
            </Grid2>
        );
    }, [pageIndex, pages]);

    const handleContinueButton = useCallback(async () => {
        if (validators[pageIndex]()) {
            if (await submitters[pageIndex]()) {
                if (isLastPage) {
                    setRegistering(false);
                } else {
                    next();
                }
            }
        }
    }, [isLastPage, next, pageIndex, setRegistering, submitters, validators]);

    return (
        <Grid2 container justifyContent={'center'} p={isTablet ? 3 : 30} py={10} rowGap={10}>

            <Grid2 xs={12} container rowGap={5}>
                <Grid2 xs={12} container justifyContent={'flex-start'}>
                    <Button startIcon={<ArrowBack/>} onClick={back}>
                        Back
                    </Button>
                </Grid2>
                <ProgressBar/>
            </Grid2>

            <Grid2 xs={12} container>
                <Typography align="left" variant="h3" fontWeight={'bold'} color={'primary'}>
                    {titles[pageIndex]}
                </Typography>
            </Grid2>
            
            <Grid2 xs={12} container>
                {page()}
            </Grid2>

            <Grid2 lg={7} md={12} xs={12} container justifyContent={'center'} rowGap={3}>
                {skippables[pageIndex] &&
                <Button 
                    onClick={() => {
                        if (isLastPage) {
                            setRegistering(false);
                        } else {
                            next();
                        }
                    }}
                >
                    <Typography sx={{textDecorationLine: "underline"}}>
                        I'll set it up later.
                    </Typography>
                </Button>}
                <Button
                    variant="contained"
                    fullWidth
                    onClick={handleContinueButton}
                    sx={{p: 4, borderRadius: 40}}
                >
                    {isLastPage ? 'Finish it Up' : 'Continue'}
                </Button>
            </Grid2>
            
        </Grid2>
    );
};

export default EmployerRegistration;
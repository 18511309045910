import { Box, Button, Modal, Radio, Typography } from "@mui/material";
import { useCallback } from "react";
import './PaymentLinkModal.css';
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { DataGrid } from "@mui/x-data-grid";
import { useMemo } from "react";
import InvoiceStatusTag from "../invoices/InvoiceStatusTag";
import { USDollar } from "../../constants/theme";
import { useState } from "react";
import { linkInvoice } from "../../api/payments";
import { getInvoices } from "../../api/invoice";
import { useEffect } from "react";


const LinkToInvoiceModal = ({showModal, setShowModal, paymentID}) => {

    const [paginationModel, setPaginationModel] = useState({
        pageSize: 10,
        page: 0,
    });
    const [invoices, setInvoices] = useState([]);
    const [totalInvoiceCount, setTotalInvoiceCount] = useState(0);
    const [selectedInvoiceID, setSelectedInvoiceID] = useState(-1);
    const [isLoading, setIsLoading] = useState(false);
    const [refreshTable, setRefreshTable] = useState(false); // value irrelevant, used to trigger table re-renders
    const [confirmText, setConfirmText] = useState('Confirm');

    const handleClose = useCallback(() => {
        setShowModal(false);
    }, [setShowModal]);

    const handleLinkToInvoice = useCallback(async (invoiceID, paymentID) => {
        setConfirmText('Linking...');
        const response = await linkInvoice(invoiceID, [paymentID]);
        if (response) {
            setConfirmText('Linked Successfully!');
            setTimeout(handleClose, 500);
            setTimeout(setRefreshTable(!refreshTable), 1000);
        } else {
            setConfirmText('Failed to Link!');
        }
    }, [handleClose, refreshTable]);

    useEffect(() => {
        (async () => {
            // [TODO] Update this for totalInvoiceCount when API gets updated
            setIsLoading(true);
            let response;
            response = await getInvoices(1, 1);
            if (response) {
                setTotalInvoiceCount(response.data.count);
            } else {
                setTotalInvoiceCount(0);
            }
            response = await getInvoices(paginationModel.page + 1, paginationModel.pageSize);
            if (response) {
                setInvoices(response.data.result);
            } else {
                setInvoices([]);
            }
            setIsLoading(false);
        })();
    }, [paginationModel, refreshTable]);

    const cols = useMemo(() => [
        {
            field: 'id',
            headerName: '',
            flex: 10,
            renderCell: ({value, row}) => (
                (row.status !== 'IN_DRAFT' && row.status !== 'CREATED' && row.status !== 'PAID') &&
                <Radio
                    checked={selectedInvoiceID === value}
                    value={value}
                    onChange={event => {
                        setSelectedInvoiceID(value);
                    }}
                />
            ),
        },
        {
            field: 'client_name', 
            headerName: 'Client Name', 
            flex: 35,
        },
        {
            field: 'total_amount', 
            headerName: 'Total Amount', 
            flex: 20, 
            renderCell: ({row, value}) => (
                row.status === 'PARTIALLY_PAID'
                    ? <Grid2 xs={12} container direction={'column'} alignItems={'flex-start'}>
                        <Typography fontSize={14}>
                            {USDollar.format(value)}
                        </Typography>
                        <Typography fontSize={11} fontWeight={'bold'} color={'error.dark'}>
                            Unpaid Amount: {USDollar.format(row.total_amount - row.partially_payment)}
                        </Typography>
                    </Grid2>
                    : USDollar.format(value)
            )
        },
        {
            field: 'created_at', 
            headerName: 'Date', 
            flex: 20, 
            valueGetter: ({value}) => (new Date(value).toLocaleDateString('en-US', {
                month: 'short', year:'numeric', day:'numeric',
            }))
        },
        {
            field: 'status',
            headerName: 'Status',
            flex: 15,
            renderCell: ({row}) => (<InvoiceStatusTag key={row.index} status={row.status}/>)
        },
    ], [selectedInvoiceID]);

    return (
        <Modal
            open={showModal}
            onClose={handleClose}
        >
            <Box className="big-modal-container">
                <Grid2 container gap={4} flexGrow={1}>
                    <Grid2 xs={12}>
                        <Typography variant="h5" color={'primary'} fontWeight={'bold'}>
                            Link to Existing Invoice
                        </Typography>
                    </Grid2>
                    <Grid2 xs={12} className="data-table-wrapper">
                        <DataGrid
                            className="data-table"
                            rows={invoices}
                            rowCount={totalInvoiceCount}
                            columns={cols}
                            pageSizeOptions={[5, 10, 20]}
                            loading={isLoading}
                            paginationMode={"server"}
                            paginationModel={paginationModel}
                            onPaginationModelChange={setPaginationModel}
                            disableRowSelectionOnClick
                        />
                    </Grid2>
                    <Grid2 xs={12} container justifyContent={'space-between'}>
                        <Grid2 xs={5.5}>
                            <Button
                                variant="contained"
                                fullWidth
                                onClick={async () => await handleLinkToInvoice(selectedInvoiceID, paymentID)}
                            >
                                {confirmText}
                            </Button>
                        </Grid2>
                        <Grid2 xs={5.5}>
                            <Button
                                variant="outlined"
                                fullWidth
                                onClick={handleClose}
                            >
                                Cancel
                            </Button>
                        </Grid2>
                    </Grid2>
                </Grid2>
            </Box>
        </Modal>
    );
};

export default LinkToInvoiceModal;
import React, {useEffect, useState} from 'react';
import { AiOutlineMail } from 'react-icons/ai';
import {
    Typography,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import OutputIcon from '@mui/icons-material/Output';
import WifiCalling3Icon from '@mui/icons-material/WifiCalling3';
import LocalPostOfficeIcon from '@mui/icons-material/LocalPostOffice';

function InformationMobile(props) {
    const { user } = props;

    const [userAddress1, setUserAddress1] = useState("");
    const [cityAndState, setCityAndState] = useState("");


    useEffect(() => {
        if (user) {
            const cityAndState =  user.location.city + ", " + user.location.state.toUpperCase() + " " + user.location.zip_code;
            setUserAddress1(user.location.address_line_1)
            setCityAndState(cityAndState);
        }
    }, [user]);

    return (
        <div className="text-left pt-5" style={{width: '100%'}}>
            <div className="py-3">
                {user && <div className="flex-box justify-space-between border-divider">
                    <Typography className="py-3 company-info-text-mobile">{user ? userAddress1 + ', ' : "loading..."} {user ? cityAndState : ""}</Typography>
                </div>}
                {user && user.company_information && user.company_information.website && <div className="flex-box justify-space-between border-divider">
                    <Typography className="py-3 company-info-text-mobile"><u>{user && user.company_information ? user.company_information.website : ""}</u></Typography>
                    <a href={user.company_information.website} target="_blank">
                        <OutputIcon className="company-info-icon" />
                    </a>
                </div>}
                {user && user.phone_number && <div className="flex-box justify-space-between border-divider">
                    <Typography className="py-3 company-info-text-mobile">{user ? user.phone_number : "loading..."}</Typography>
                    <a onClick={() => console.log('Call')}>
                        <WifiCalling3Icon className="company-info-icon" />
                    </a>
                </div>}
                {user && user.company_information && user.company_information.email && <div className="flex-box justify-space-between border-divider">
                    <Typography className="py-3 company-info-text-mobile">{user && user.company_information ? user.company_information.email : ""}</Typography>
                    <a href={`mailto: ${user && user.company_information ? user.company_information.email : ""}`}>
                        <LocalPostOfficeIcon className="company-info-icon" />
                    </a>
                </div>}
            </div>
        </div>
    );
}

export default InformationMobile;

import { Button, Typography, Box } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { useEffect, useState } from "react";

import AppDrawer from '../../components/appdrawer/appdrawer';
import PaymentTable from "../../components/payments/PaymentTable";
import PaymentLinkModal from "../../components/payments/PaymentLinkModal";
import CardIcon from "../../components/icons/CardIcon";
import BankTransferIcon from "../../components/icons/BankTransferIcon";
import { getUser } from "../../api/user";
import SetupPaymentIcon from "../../components/icons/SetupPaymentIcon";
import { useNavigate } from "react-router-dom";
import RecordPaymentModal from "../../components/payments/RecordPaymentModal";
import { Add } from "@mui/icons-material";

const Payments = () => {

    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);
    const [showNewPaymentModal, setShowNewPaymentModal] = useState(false);
    const [method, setMethod] = useState('CARD');
    const [isUserSetup, setIsUserSetup] = useState(false);
    const [refreshTable, setRefreshTable] = useState(false); // value is irrelevant, used to trigger re-renders

    useEffect(() => {
        (async () => {
            const response = await getUser();
            if (response) {
                setIsUserSetup((response.data.is_complited_onboarding));
            }
        })();
    }, []);

    useEffect(() => {
        if (!showModal && !showNewPaymentModal) {
            setRefreshTable(refreshTable => !refreshTable);
        }
    }, [showModal, showNewPaymentModal])

    return (
            <Box sx={{ display: 'flex', }}>
                <AppDrawer/>
                {isUserSetup 
                ? <>
                    <PaymentLinkModal
                        open={showModal}
                        method={method}
                        setShowModal={setShowModal}
                    />
                    <RecordPaymentModal
                        paymentInfo={{id: 0, created_at: (new Date()).toISOString(), customer_name: 'N/A',}}
                        showModal={showNewPaymentModal}
                        setShowModal={setShowNewPaymentModal}
                    />
                    <Grid2 container padding={4} rowGap={8} flexGrow={1}>
                        <Grid2 container xs={12} justifyContent={'space-between'} alignItems={'center'}>
                            <Grid2 container lg={6} md={12} xs={12} direction={'column'} alignItems={'flex-start'}>
                                <Typography color={'primary'} variant="h3" fontWeight={'bold'}>
                                    Payment
                                </Typography>
                                <Typography color={'primary'} variant="body2">
                                    Request payment or view your payment history
                                </Typography>
                            </Grid2>
                            <Grid2 container lg={6} md={12} xs={12} gap={2} justifyContent={'flex-end'}>
                                <Button
                                    variant="contained"
                                    startIcon={<CardIcon/>}
                                    onClick={() => {
                                        setMethod('CARD')
                                        setShowModal(true);
                                    }}
                                >
                                    Request Card Payment
                                </Button>
                                <Button
                                    variant="outlined"
                                    startIcon={<BankTransferIcon/>}
                                    onClick={() => {
                                        setMethod('ACH')
                                        setShowModal(true);
                                    }}
                                >
                                    Request Bank Transfer
                                </Button>
                                <Button
                                    variant="outlined"
                                    startIcon={<Add/>}
                                    onClick={() => {
                                        setShowNewPaymentModal(true);
                                    }}
                                >
                                    Record Manual Payment
                                </Button>
                            </Grid2>
                        </Grid2>
                        <PaymentTable refreshTable={refreshTable} setRefreshTable={setRefreshTable}/>
                    </Grid2>
                </>
                : 
                <Grid2 container padding={4} rowGap={20} flexGrow={1}>
                    <Grid2 container xs={12} direction={'column'} alignItems={'flex-start'}>
                        <Typography color={'primary'} variant="h3" fontWeight={'bold'}>
                            Payment
                        </Typography>
                        <Typography color={'primary'} variant="body2">
                            Request payment or view your payment history
                        </Typography>
                    </Grid2>
                    <Grid2 container xs={12} direction={'column'} alignItems={'center'} rowGap={5}>
                        <SetupPaymentIcon/>
                        <Grid2 container xs={12} direction={'column'} rowGap={5} alignItems={'center'}>
                            <Typography variant="h4" color={'primary'} fontWeight={'bold'}>
                                Set Up Payment
                            </Typography>
                            <Typography>
                                You need to set up payments in order to view the payments screen
                            </Typography>
                            <Button
                                variant="contained"
                                onClick={() => navigate('/payments/setup')}
                            >
                                Set up Payments
                            </Button>
                        </Grid2>
                    </Grid2>
                </Grid2>
                }
            </Box>
    );
};

export default Payments;
import { ArrowBack, CheckCircleOutlined, } from "@mui/icons-material";
import { Button, TextField, Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { useCallback, useEffect, useMemo, useState, useContext } from "react";
import { useMultiStepForm } from "../../misc/useMultiStepForm";
import { useAuth } from "../../contexts/AuthContext";
import isEmail from "validator/lib/isEmail";
import { getEmployerName, sendConfirmationCode, setEmployeeInfo, verifyConfirmationCode } from "../../api/user";
import isNumeric from "validator/lib/isNumeric";
import isEmpty from "validator/lib/isEmpty";
import isMobilePhone from "validator/lib/isMobilePhone";
import { phoneNumberReformatter } from "../../misc/utilityFunctions";
import { AppContext } from '../../contexts/AppContext';

const EmployeeRegistration = () => {
    const { isTablet } = useContext(AppContext);
    const { setRegistering } = useAuth();


    const [employerEmail, setEmployerEmail] = useState('');
    const [employerEmailError, setEmployerEmailError] = useState(false);
    const [emailRequestButtonLabel, setEmailRequestButtonLabel] = useState('Send Confirmation Code');
    const EmailRequestForm = useCallback(() => (
        <Grid2 xs={12} container rowGap={4} justifyContent={'space-between'}>
            <Grid2 xs={12} container>
                <Typography variant="h6" color={'primary'}>
                    What is the registered email address of the existing Tapper business account?
                </Typography>
            </Grid2>
            <Grid2 xs={12}>
                <TextField
                    variant="standard"
                    fullWidth
                    color="primary"
                    type="email"
                    value={employerEmail}
                    error={employerEmailError}
                    helperText={employerEmailError ? "Please a valid email address" : ''}
                    label="Employer Email Address"
                    placeholder="Email Address of Employer's Tapper Account"
                    onChange={event => setEmployerEmail(event.target.value)}
                />
            </Grid2>
        </Grid2>
    ), [employerEmail, employerEmailError]);

    // validator
    const emailRequestValidator = useCallback(() => {
        if (!isEmail(employerEmail)) {
            setEmployerEmailError(true);
            return false;
        }
        return true;
    }, [employerEmail]);

    // submitter
    const emailRequestSubmitter = useCallback(async () => {
        if (emailRequestValidator()) {
            setEmailRequestButtonLabel('Sending...');
            const response = await sendConfirmationCode(employerEmail);
            if (response) {
                setEmailRequestButtonLabel('Send Confirmation Code');
                return true;
            } else {
                setEmailRequestButtonLabel('Error! Try again');
                setTimeout(() => setEmailRequestButtonLabel('Send Confirmation Code'), 1000);
                return false;
            }
        }
    }, [emailRequestValidator, employerEmail])

    // clear error status
    useEffect(() => {
        if (isEmail(employerEmail)) {
            setEmployerEmailError(false);
        }
    }, [employerEmail]);
    

    const [employerName, setEmployerName] = useState('');
    const [confirmationCode, setConfirmationCode] = useState('');
    const [confirmationCodeError, setConfirmationCodeError] = useState(false);
    const [employerConfirmationButtonLabel, setEmployerConfirmationButtonLabel] = useState('Confirm Code');
    const EmployerConfirmationForm = useCallback(() => (
        <Grid2 xs={12} container rowGap={4} justifyContent={'space-between'}>
            <Grid2 xs={12} container>
                <Typography variant="h6" color={'primary'}>
                    Confirmation Code sent to {employerEmail}
                </Typography>
            </Grid2>
            <Grid2 xs={12}>
                <TextField
                    variant="standard"
                    fullWidth
                    color="primary"
                    type="text"
                    value={confirmationCode}
                    error={confirmationCodeError}
                    helperText={confirmationCodeError ? "Please enter a 6 digit number" : ''}
                    label="Confirmation Code"
                    placeholder="Enter Confirmation Code"
                    onChange={event => setConfirmationCode(event.target.value)}
                />
            </Grid2>
        </Grid2>
    ), [confirmationCode, confirmationCodeError, employerEmail]);

    // validator
    const employerConfirmationValidator = useCallback(() => {
        if (!isNumeric(confirmationCode) || confirmationCode.length !== 6) {
            setConfirmationCodeError(true);
            return false;
        }
        return true;
    }, [confirmationCode]);
    
    // submitter
    const employerConfirmationSubmitter = useCallback(async () => {
        setEmployerConfirmationButtonLabel('Confirming...');
        const response = await verifyConfirmationCode(employerEmail, confirmationCode);
        if (response) {
            setEmployerConfirmationButtonLabel('Success!');
            return true;
        } else {
            setEmployerConfirmationButtonLabel('Error! Try again!');
            setTimeout(() => setEmployerConfirmationButtonLabel('Confirm Code'), 1000);
            return false;
        }
    }, [confirmationCode, employerEmail]);

    // clear error status
    useEffect(() => {
        if (isNumeric(confirmationCode) && confirmationCode.length === 6) {
            setConfirmationCodeError(false);
        }
    }, [confirmationCode]);



    const ConfirmationVerifiedPage = useCallback(() => (
        <Grid2 xs={12} container rowGap={4} justifyContent={'center'}>
            <Typography fontSize={250}>
                <CheckCircleOutlined fontSize="inherit"  color="success"/>
            </Typography>
            <Grid2 xs={12} container>
                <Typography variant="h6" color={'primary'}>
                    Your account has been successfully connected to your team on Tapper.
                </Typography>
            </Grid2>
        </Grid2>
    ), []);

    //  validator
    const confirmationVerifiedValidator = useCallback(() => {
        return true;
    }, []);

    //  submitter
    const confirmationVerifiedSubmitter = useCallback(async () => {
        return true;
    }, [])

    

    const [userData, setUserData] = useState({
        first_name: '',
        last_name: '',
        phone_number: '',
    });
    const [userValidation, setUserValidation] = useState({
        first_name: false,
        last_name: false,
        phone_number: false,
    })
    const [employeeInfoButtonLabel, setEmployeeInfoButtonLabel] = useState('Finish it up');
    const EmployeeInfoForm = useCallback(() => (
        <Grid2 xs={12} container rowGap={4} justifyContent={'space-between'}>
            <Grid2 xs={12} container>
                <Typography align="left" variant="h6" color={'primary'}>
                    You are almost done. Tell us a bit more about yourself to get started:
                </Typography>
            </Grid2>
            <Grid2 lg={5.5} md={5.5} xs={12}>
                <TextField
                    variant="standard"
                    fullWidth
                    color="primary"
                    type="text"
                    value={userData?.first_name}
                    error={userValidation?.first_name}
                    helperText={userValidation?.first_name ? "Please enter a first name" : ''}
                    label="First Name"
                    placeholder="Enter First Name"
                    onChange={event => setUserData(userData => ({...userData, first_name: event.target.value}))}
                />
            </Grid2>
            <Grid2 lg={5.5} md={5.5} xs={12}>
                <TextField
                    variant="standard"
                    fullWidth
                    color="primary"
                    type="text"
                    value={userData?.last_name}
                    error={userValidation?.last_name}
                    helperText={userValidation?.last_name ? "Please enter a last name" : ''}
                    label="Last Name"
                    placeholder="Enter Last Name"
                    onChange={event => setUserData(userData => ({...userData, last_name: event.target.value}))}
                />
            </Grid2>
            <Grid2 xs={12}>
                <TextField
                    variant="standard"
                    fullWidth
                    color="primary"
                    type="text"
                    value={userData?.phone_number}
                    error={userValidation?.phone_number}
                    helperText={userValidation?.phone_number ? "Please enter a valid phone number" : ''}
                    label="Phone Number"
                    placeholder="Enter Phone Number"
                    onChange={event => setUserData(userData => ({...userData, phone_number: event.target.value}))}
                />
            </Grid2>
        </Grid2>
    ), [userData, userValidation]);

    //  validator
    const employeeInfoValidator = useCallback(() => {
        if (isEmpty(userData?.first_name ? userData?.first_name : '')) {
            setUserValidation(userValidation => ({...userValidation, first_name: true}));
            return false;
        }
        if (isEmpty(userData?.last_name ? userData?.last_name : '')) {
            setUserValidation(userValidation => ({...userValidation, last_name: true}));
            return false;
        }
        if (!isMobilePhone(userData?.phone_number ? userData?.phone_number : '', ['en-US', 'en-CA'])) {
            setUserValidation(userValidation => ({...userValidation, phone_number: true}));
            return false;
        }
        return true;
    }, [userData?.first_name, userData?.last_name, userData?.phone_number]);

    // submitter
    const employeeInfoSubmitter = useCallback(async () => {
        setEmployeeInfoButtonLabel('Finishing...');
        const { first_name, last_name, phone_number} = userData;
        const response = await setEmployeeInfo(first_name, last_name, phoneNumberReformatter(phone_number), employerEmail);
        if (response) {
            setEmployeeInfoButtonLabel('Success!');
            return true;
        } else {
            setEmployeeInfoButtonLabel('Failed! Try again!');
            setTimeout(() => setEmployeeInfoButtonLabel('Finish it up'), 1000);
            return false;
        }
    }, [employerEmail, userData]);

    // clear error status
    useEffect(() => {
        if (!isEmpty(userData?.first_name ? userData?.first_name : '')) {
            setUserValidation(userValidation => ({...userValidation, first_name: false}));
        }
        if (!isEmpty(userData?.last_name ? userData?.last_name : '')) {
            setUserValidation(userValidation => ({...userValidation, last_name: false}));
        }
        if (isMobilePhone(userData?.phone_number ? userData?.phone_number : '', ['en-US', 'en-CA'])) {
            setUserValidation(userValidation => ({...userValidation, phone_number: false}));
        }
    }, [userData]);

    
    const titles = useMemo(() => [
        "Connect To Your Team On Tapper", // 0
        "Please Enter The Confirmation Code", // 1
        "", // 2
        `Welcome to ${employerName}`, // 3
    ], [employerName]);

    const buttonLabels = useMemo(() => [
        emailRequestButtonLabel,
        employerConfirmationButtonLabel,
        'Continue',
        employeeInfoButtonLabel,
    ], [emailRequestButtonLabel, employeeInfoButtonLabel, employerConfirmationButtonLabel]);

    const { pageIndex, page, next, back, isLastPage } = useMultiStepForm([
        EmailRequestForm,
        EmployerConfirmationForm,
        ConfirmationVerifiedPage,
        EmployeeInfoForm,
    ]);

    const validators = useMemo(() => [
        emailRequestValidator, // 0
        employerConfirmationValidator, // 1
        confirmationVerifiedValidator, // 2
        employeeInfoValidator, // 3
    ], [confirmationVerifiedValidator, emailRequestValidator, employeeInfoValidator, employerConfirmationValidator]);
    

    const submitters = useMemo(() => [
        emailRequestSubmitter, // 0
        employerConfirmationSubmitter, // 1
        confirmationVerifiedSubmitter, // 2
        employeeInfoSubmitter, // 3
    ], [confirmationVerifiedSubmitter, emailRequestSubmitter, employeeInfoSubmitter, employerConfirmationSubmitter]);


    const handleContinueButton = useCallback(async () => {
        if (validators[pageIndex]()) {
            if (await submitters[pageIndex]()) {
                if (isLastPage) {
                    setRegistering(false);
                } else {
                    next();
                }
            }
        }
    }, [isLastPage, next, pageIndex, setRegistering, submitters, validators]);

    useEffect(() => {
        (async () => {
            if (isLastPage) {
                const response = await getEmployerName(employerEmail);
                if (response) {
                    setEmployerName(response.data.businessName);
                } else {
                    console.log('Could not load employer company name');
                }
            }
        })();
    }, [employerEmail, isLastPage]);


    return (
        <Grid2 container justifyContent={'center'} p={isTablet ? 3 : 40} py={10} rowGap={10}>

            {pageIndex < 2 &&
            <Grid2 xs={12} container justifyContent={'flex-start'}>
                <Button startIcon={<ArrowBack/>} onClick={back}>
                    Back
                </Button>
            </Grid2>}

            <Grid2 xs={12} container>
                <Typography align="left" variant="h3" fontWeight={'bold'} color={'primary'}>
                    {titles[pageIndex]}
                </Typography>
            </Grid2>
            
            <Grid2 xs={12} container>
                {page()}
            </Grid2>

            <Grid2 lg={7} md={12} xs={12} container justifyContent={'center'} rowGap={3}>
                <Button
                    variant="contained"
                    fullWidth
                    onClick={handleContinueButton}
                    sx={{p: 4, borderRadius: 40}}
                >
                    {buttonLabels[pageIndex]}
                </Button>
            </Grid2>
            
        </Grid2>
    );
};

export default EmployeeRegistration;
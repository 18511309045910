import { Box, Button, Modal, Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import './ResultModal.css';

/**
 * This component is intended to show a success or failure pop-up message in a variety of scenarios,
 * from API calls to other asynchronous (or even synchronous) actions. Can be customized.
 */
const ResultModal = ({success, setSuccess, showResult, setShowResult, successText, failText}) => {

    const handleClose = () => {
        setSuccess(false);
        setShowResult(false);
    };

    return (
        <Modal
            open={showResult}
            onClose={handleClose}
        >
            <Box className="result-container">
                <Grid2 container gap={4}>
                    <Grid2 xs={12}>
                        <Typography variant="h6" color={'primary'}>
                            {`${success ? successText : failText}`}
                        </Typography>
                    </Grid2>
                    <Grid2 container xs={12} justifyContent={'center'}>
                        <Button variant="outlined" onClick={handleClose}>
                            Okay
                        </Button>
                    </Grid2>
                </Grid2>
            </Box>
        </Modal>
    );
};

export default ResultModal;
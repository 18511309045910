import axios from 'axios';

// const fetchReviews = (userId, pageNum, pageSize) => {
// return  axios.get(`${process.env.REACT_APP_SERVER_URL}/review/get-reviews?user_id=${userId}&page_number=${pageNum}&page_size=${pageSize}`);
// };

const fetchReviews = (userId, pageNum, pageSize) => {
  return axios.get(`${process.env.REACT_APP_SERVER_URL}/review/get-reviews?user_id=${userId}&page_number=${pageNum}&page_size=${pageSize}`)
      .then(res => {
        return res;
      })
      .catch(err => {
        console.error(err);
      });
};



export default fetchReviews;


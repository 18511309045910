import { Search } from "@mui/icons-material";
import { InputAdornment, TextField, Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { useState } from "react";
import { USDollar } from "../../constants/theme";
import { DataGrid } from "@mui/x-data-grid";
import InvoiceMenuButton from "./InvoiceMenuButton";
import { useEffect } from "react";
import { getInvoices, searchInvoices } from "../../api/invoice";
import InvoiceStatusTag from "./InvoiceStatusTag";

const InvoicesTable = () => {

    const [paginationModel, setPaginationModel] = useState({
        pageSize: 10,
        page: 0,
    });
    const [invoices, setInvoices] = useState([]);
    const [totalInvoiceCount, setTotalInvoiceCount] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [search, setSearch] = useState('');
    const [refreshTable, setRefreshTable] = useState(false); // value irrelevant, used to trigger table re-renders
    
    const cols = [
        {
            field: 'client_name', 
            headerName: 'Client Name', 
            flex: 35,
        },
        {
            field: 'total_amount', 
            headerName: 'Total Amount', 
            flex: 20, 
            renderCell: ({row, value}) => (
                row.status === 'PARTIALLY_PAID'
                    ? <Grid2 xs={12} container direction={'column'} alignItems={'flex-start'}>
                        <Typography fontSize={14}>
                            {USDollar.format(value)}
                        </Typography>
                        <Typography fontSize={11} fontWeight={'bold'} color={'error.dark'}>
                            Unpaid Amount: {USDollar.format(row.total_amount - row.partially_payment)}
                        </Typography>
                    </Grid2>
                    : USDollar.format(value)
            )
        },
        {
            field: 'created_at', 
            headerName: 'Date', 
            flex: 20, 
            valueGetter: (params) => (new Date(params.value).toLocaleDateString('en-US', {
                month: 'short', year:'numeric', day:'numeric', hour: 'numeric', minute: 'numeric', second:'numeric'
            }))
        },
        {
            field: 'status',
            headerName: 'Status',
            flex: 15,
            renderCell: ({row}) => (<InvoiceStatusTag key={row.index} status={row.status}/>)
        },
        {
            field: 'action', 
            headerName: 'Action', 
            flex: 10, 
            renderCell: ({row}) => (
                <InvoiceMenuButton 
                    key={row.index} 
                    invoiceInfo={row} 
                    refreshTable={refreshTable}
                    setRefreshTable={setRefreshTable}
                />
            )
        }
    ];

    useEffect(() => {
        (async () => {
            // [TODO] Update this for totalInvoiceCount when API gets updated
            setIsLoading(true);
            let response;
            if (search === '') {
                response = await getInvoices(1, 1);
                if (response) {
                    setTotalInvoiceCount(response?.data.count);
                } else {
                    setTotalInvoiceCount(0);
                }
                response = await getInvoices(paginationModel.page + 1, paginationModel.pageSize);
                if (response) {
                    setInvoices(response.data.result);
                } else {
                    setInvoices([]);
                }
            } else {
                response = await searchInvoices(search);
                if (response) {
                    setInvoices(response.data);
                }
            }
            setIsLoading(false);
        })();
    }, [paginationModel, search, refreshTable]);

    return (
        <Grid2 container xs={12} rowGap={3}>
            <Grid2 container xs={12} justifyContent={'flex-start'}>
                <TextField
                    variant="outlined"
                    color="secondary"
                    type="text"
                    value={search}
                    label="Search"
                    placeholder="Search Invoices"
                    onChange={event => setSearch(event.target.value)}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Search color="secondary"/>
                            </InputAdornment>
                        ),
                    }}
                />
            </Grid2>
            <Grid2 xs={12} className="data-table-wrapper">
                {!isLoading && invoices.length === 0 ? 
                    <Typography variant="h4">No data</Typography>
                    : <DataGrid
                        className="data-table"
                        rows={invoices}
                        rowCount={search === '' ? totalInvoiceCount : undefined}
                        columns={cols}
                        pageSizeOptions={[5, 10, 20]}
                        loading={isLoading}
                        paginationMode={(search === '' ? "server" : "client")}
                        paginationModel={paginationModel}
                        onPaginationModelChange={setPaginationModel}
                        disableRowSelectionOnClick
                    />}
            </Grid2>
        </Grid2>
    );
};

export default InvoicesTable;
import { createContext, useState, useContext, useEffect, useCallback,  } from "react";
import { Amplify, Auth, Hub } from 'aws-amplify';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import axios from "axios";

const AuthContext = createContext(null);

export function useAuth() {
    const value = useContext(AuthContext);

    if (!value) {
        // context API not working correctly
        throw new Error('the root app screen must be wrapped in a <AuthProvider/>');
    }

    return value;
}

async function getCurrentUser() {
    try {
        const user = await Auth.currentAuthenticatedUser({
            bypassCache: false
        });
        return user;
    } catch(error) {
        console.log('getCurrentUser failed: ', error);
        return null;
    }
}

const setTapperAuthToken = (user) => {
    if (user) {
        const bearerToken = user.signInUserSession.accessToken.jwtToken;
        axios.defaults.headers.common['Authorization'] = `Bearer ${bearerToken}`;
    } 
    else {
        delete axios.defaults.headers.common['Authorization']; // clear the auth token
    }
}

const getRefreshTime = (user) => {
    if (user === null) return 60000; // try again in 1 minute

    // calculate expiry in miliseconds 
    const expiryUnixTime = Number(user?.signInUserSession?.accessToken?.payload?.exp) * 1000;
    
    // get current unix time in miliseconds
    const currentUnixTime = Math.floor(Date.now());

    // return expiry time (or now, if expiry time is negative somehow)
    const refreshTime = Math.max(0, expiryUnixTime - currentUnixTime);
    console.log('refreshTime: ', refreshTime);
    return refreshTime;
};



const AuthProvider = ({children}) => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [registering, setRegistering] = useState(false);
    const [customState, setCustomState] = useState(null);

    useEffect(() => {
        Hub.listen('auth', (data) => {
            if (data.payload.event === 'signIn_failure') {
            }
            if (data.payload.event === 'signIn') {
            }
        });

        const unsubscribe = Hub.listen('auth', ({ payload: { event, data } }) => {
          switch (event) {
            case 'signIn':
              setUser(data);
              break;
            case 'signOut':
              setUser(null);
              break;
            case 'customOAuthState':
              setCustomState(data);
          }
        });
    
        Auth.currentAuthenticatedUser()
          .then((currentUser) => setUser(currentUser))
          .catch(() => console.log('Not signed in'));
    
        return unsubscribe;
      }, []);

    const initializeUser = useCallback(async () => {
        // initialize/refresh the user from Amplify
        console.log('refreshing user');
        const user = await getCurrentUser();
        console.log(user);

        // assign useful values to necessary state variables
        setUser(user);
        setTapperAuthToken(user);
        setLoading(false);

        // schedule the next refresh
        setTimeout(async () => await initializeUser(), getRefreshTime(user));
    }, []);
    
    // useEffect to refresh the user
    useEffect(() => {
        (async () => {
            await initializeUser();
        })();
    }, [initializeUser]);

    // automatically sets the auth bearer token for using tapper's own backend API
    useEffect(() => {
        setTapperAuthToken(user);
    }, [user]);
    
    
    return (
        <AuthContext.Provider
        value={{
            signIn: async (username, password) => {
                try {
                    const user = await Auth.signIn(username, password);
                    console.log(user);
                    setUser(user);
                    setTapperAuthToken(user);
                    setLoading(false);
                    return true; // return successful sign-in
                } catch (error) {
                    console.log('error signing in', error);
                    setUser(null);
                    setLoading(false);
                    return false; // return unsuccessful sign-in
                }
            },
            signInWithGoogle: async () => {
                try {
                    await Auth.federatedSignIn({
                        provider: CognitoHostedUIIdentityProvider.Google
                    });
                    return true; // return successful sign-in
                } catch (error) {
                    console.log('error signing in', error);
                    setUser(null);
                    setLoading(false);
                    return false; // return unsuccessful sign-in
                }
            },
            signOut: async () => {
                try {
                    await Auth.signOut();
                    setUser(null);
                    setLoading(false);
                } catch (error) {
                    console.log('error signing out: ', error);
                    setLoading(false);
                }
            },
            signUp: async (username, password) => {
                try {
                    const response = await Auth.signUp({
                        username,
                        password,
                        attributes : {
                            email: username,
                        },
                        autoSignIn: {
                            enabled: false,
                        }
                    });
                    console.log('signup response: ', response);
                    setLoading(false);
                    return true; // return successful signup
                } catch (error) {
                    setLoading(false);
                    console.log('error signing up: ', error);
                    return false; // return unsuccessful signup
                }

            },
            confirmSignUp: async (username, confirmationCode) => {
                try {
                    const response = await Auth.confirmSignUp(username, confirmationCode);
                    console.log('signup confirmation: ', response);
                    setLoading(false);
                    return true; // return successful confirmation?
                } catch (error) {
                    setLoading(false);
                    console.log('error confirming signup: ', error);
                    return false; // return failed confirmation?
                }
            },
            updatePassword: async (oldPassword, newPassword) => {
                try {
                    await Auth.changePassword(user, oldPassword, newPassword);
                    setLoading(false);
                } catch (error) {
                    console.log('error changing password: ', error);
                    setLoading(false);
                }
            },
            user,
            loading,
            setLoading,
            registering, 
            setRegistering, // [TODO] figure out how to utilize registering
        }}
        >
            {children}
        </AuthContext.Provider>
    );
};

// [TODO] google sign-in functionaily (oAuth)
export default AuthProvider;
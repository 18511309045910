import { MoreHoriz } from "@mui/icons-material";
import { IconButton, Menu, MenuItem } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { useState } from "react";
import { deleteCustomer } from "../../api/contacts";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";


const CustomerMenuButton = ({customerInfo, setRefreshTable, refreshTable, setEditCustomerData}) => {
    
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    

    const [deleteButtonText, setDeleteButtonText] = useState('Delete Customer');
    const handleDeleteCustomer = useCallback(async () => {
        setDeleteButtonText('Deleting...');
        const response = await deleteCustomer(customerInfo.id);
        if (response) {
            setDeleteButtonText('Deleted!');
            setTimeout(handleClose, 500);
            setTimeout(setRefreshTable(!refreshTable), 1000);
        } else {
            setDeleteButtonText('Failed to Delete');
        }
    }, [customerInfo.id, refreshTable, setRefreshTable]);

    const handleEditCustomer = useCallback(() => {
        setEditCustomerData(customerInfo);
        handleClose();
    }, [customerInfo, setEditCustomerData]);

    const navigate = useNavigate();
    const handleViewCustomerDetails = useCallback(() => {
        navigate(`/customers/${customerInfo.id}`)
    }, [customerInfo.id, navigate]);

    return (
        <Grid2 container>
            <IconButton
                onClick={handleClick}
            >
                <MoreHoriz/>
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                <MenuItem onClick={handleViewCustomerDetails}>View Customer Details</MenuItem>
                <MenuItem onClick={handleEditCustomer}>Edit Customer</MenuItem>
                <MenuItem onClick={handleDeleteCustomer}>{deleteButtonText}</MenuItem>
            </Menu>
        </Grid2>
    );
};

export default CustomerMenuButton;
import { Button, TextField, Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { useCallback, useEffect, useState, useContext } from "react";
import { createPaymentAccount, getUser, updateUserCompanyAndLocation } from "../../api/user";
import isURL from "validator/lib/isURL";
import isEmpty from "validator/lib/isEmpty";
import isAlphanumeric from "validator/lib/isAlphanumeric";
import { ArrowBack } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { AppContext } from '../../contexts/AppContext';

const PaymentSetup = () => {
    const { isTablet } = useContext(AppContext);
    const navigate = useNavigate();
    const [userData, setUserData] = useState({
        company_information: {
            name: '',
            email: '',
            phone: '',
            website: '',
            service: '',
            descriptor: '',
            short_descriptor: '',
        },
        location: {
            address_line_1: '',
            city: '',
            state: '',
            zip_code: '',
        },
        merchandise_cost_markup: 0,
        labor_cost_markup: 0,
    });
    const [userValidation, setUserValidation] = useState({
        company_information: {
            website: false,
            service: false,
            descriptor: false,
            short_descriptor: false,
        },
    });
    const [sendButtonText, setSendButtonText] = useState('Connect to Stripe');

    // fetch user data
    useEffect(() => {
        (async () => {
            const response = await getUser();
            if (response) {
                let user = response.data;
                if (!user.company_information?.website) user.company_information['website'] = '';
                if (!user.company_information?.service) user.company_information['service'] = '';
                if (!user.company_information?.descriptor) user.company_information['descriptor'] = '';
                if (!user.company_information?.short_descriptor) user.company_information['short_descriptor'] = '';
                setUserData(user);
            }
        })();
    }, [])

    const validator = useCallback(() => {
        if (!isURL(userData?.company_information?.website)) {
            setUserValidation(userValidation => ({
                ...userValidation, 
                company_information: {
                    ...userValidation.company_information,
                    website: true,
                }
            }));
            return false;
        }
        if (isEmpty(userData?.company_information?.service)) {
            setUserValidation(userValidation => ({
                ...userValidation, 
                company_information: {
                    ...userValidation.company_information,
                    service: true,
                }
            }));
            return false;
        }
        if (userData?.company_information?.descriptor.length < 5 || userData?.company_information?.descriptor.length > 22
            || !isAlphanumeric(userData?.company_information?.descriptor, 'en-US', {ignore: "-_"})) {
            setUserValidation(userValidation => ({
                ...userValidation, 
                company_information: {
                    ...userValidation.company_information,
                    descriptor: true,
                }
            }));
            return false;
        }
        if (isEmpty(userData?.company_information?.short_descriptor) || userData?.company_information?.short_descriptor.length >= 10 
            || !isAlphanumeric(userData?.company_information?.short_descriptor, 'en-US', {ignore: "-_"})) {
            setUserValidation(userValidation => ({
                ...userValidation, 
                company_information: {
                    ...userValidation.company_information,
                    short_descriptor: true,
                }
            }));
            return false;
        }
        return true;
    }, [userData?.company_information?.descriptor, userData?.company_information?.service, userData?.company_information?.short_descriptor, userData?.company_information?.website]);

    const submitter = useCallback(async () => {
        if (validator()) {
            setSendButtonText('Connecting...');
            const { company_information, location } = userData;
            let response;
            response = await updateUserCompanyAndLocation(company_information, location);
            try {
                response = await createPaymentAccount(company_information, location);
                setSendButtonText('Connected!');
                window.open(response.data.url);
            } catch (error) {
                setSendButtonText(`ERROR: ${error.response.data.message}`);
                setTimeout(() => setSendButtonText('Connect to Stripe'), 4000);
            }
        }
    }, [userData, validator]);

    // clear error status
    useEffect(() => {
        if (isURL(userData?.company_information?.website)) {
            setUserValidation(userValidation => ({
                ...userValidation, 
                company_information: {
                    ...userValidation.company_information,
                    website: false,
                }
            }));
        }
        if (!isEmpty(userData?.company_information?.service)) {
            setUserValidation(userValidation => ({
                ...userValidation, 
                company_information: {
                    ...userValidation.company_information,
                    service: false,
                }
            }));
        }
        if (userData?.company_information?.descriptor.length >= 5 && userData?.company_information?.descriptor.length <= 22
            && isAlphanumeric(userData?.company_information?.descriptor, 'en-US', {ignore: "-_"})) {
            setUserValidation(userValidation => ({
                ...userValidation, 
                company_information: {
                    ...userValidation.company_information,
                    descriptor: false,
                }
            }));
        }
        if (!isEmpty(userData?.company_information?.short_descriptor) && userData?.company_information?.short_descriptor.length < 10 
            && isAlphanumeric(userData?.company_information?.short_descriptor, 'en-US', {ignore: "-_"})) {
            setUserValidation(userValidation => ({
                ...userValidation, 
                company_information: {
                    ...userValidation.company_information,
                    short_descriptor: false,
                }
            }));
        }
    }, [userData?.company_information?.descriptor, userData?.company_information?.service, userData?.company_information?.short_descriptor, userData?.company_information?.website]);

    return (
        <Grid2 container justifyContent={'center'} px={isTablet ? 3 : 30} py={10} rowGap={8}>

            <Grid2 xs={12} container rowGap={4} textAlign={'left'}>
                <Grid2 xs={12}>
                    <Button startIcon={<ArrowBack/>} onClick={() => navigate('/payments')}>
                        Back
                    </Button>
                </Grid2>
                <Grid2 xs={12}>
                    <Typography align="left" variant="h3" fontWeight={'bold'} color={'primary'}>
                        Set Up Payments To Start Getting Paid Instantly
                    </Typography>
                </Grid2>
                <Grid2 xs={12}>
                    <Typography align="left" variant="h6" color={'primary'}>
                        Tapper partners with Stripe to process secure payments, just need a bit more info to get started.
                    </Typography>
                </Grid2>
            </Grid2>

            <Grid2 xs={12} container justifyContent={'space-between'}>
                <Grid2 lg={5.5} md={12} xs={12}>
                    <TextField
                        variant="standard"
                        fullWidth
                        color="primary"
                        type="text"
                        value={userData?.company_information?.website}
                        error={userValidation?.company_information?.website}
                        helperText={userValidation?.company_information?.website ? "Please enter a website URL" : ''}
                        label="Business Website"
                        placeholder="https://yourbusinesswebsite.com"
                        onChange={event => setUserData(userData => ({
                            ...userData,
                            company_information: {
                                ...userData.company_information,
                                website: event.target.value,
                            }
                        }))}
                    />
                </Grid2>
                <Grid2 lg={5.5} md={12} xs={12}>
                    <TextField
                        variant="standard"
                        fullWidth
                        color="primary"
                        type="text"
                        value={userData?.company_information?.service}
                        error={userValidation?.company_information?.service}
                        helperText={userValidation?.company_information?.service ? "Please enter a description of your product or service" : ''}
                        label="Product or Service Description"
                        placeholder="i.e. Plumbing Services"
                        onChange={event => setUserData(userData => ({
                            ...userData,
                            company_information: {
                                ...userData.company_information,
                                service: event.target.value,
                            }
                        }))}
                    />
                </Grid2>
            </Grid2>

            <Grid2 xs={12} container>
                <Typography align="left" variant="h6" color={'primary'}>
                    How do you want your business name to show up on your customer’s bank statements? 
                </Typography>
            </Grid2>

            <Grid2 xs={12} container rowGap={4}>
                <Grid2 xs={12}>
                    <TextField
                        variant="standard"
                        fullWidth
                        color="primary"
                        type="text"
                        value={userData?.company_information?.descriptor}
                        error={userValidation?.company_information?.descriptor}
                        helperText={userValidation?.company_information?.descriptor ? "Please enter a valid Statement Descriptor. Must be between 5-22 characters and alphanumeric, with hyphens - and underscores _ allowed." : ''}
                        label="Statement Descriptor"
                        placeholder={`Must be between 5-22 characters and alphanumeric, with only hyphens - and underscores _ allowed.`}
                        onChange={event => setUserData(userData => ({
                            ...userData,
                            company_information: {
                                ...userData.company_information,
                                descriptor: event.target.value,
                            }
                        }))}
                    />
                </Grid2>
                <Grid2 xs={12}>
                    <TextField
                        variant="standard"
                        fullWidth
                        color="primary"
                        type="text"
                        value={userData?.company_information?.short_descriptor}
                        error={userValidation?.company_information?.short_descriptor}
                        helperText={userValidation?.company_information?.short_descriptor ? "Please enter a valid Shortened Descriptor. Must be less than 10 characters and alphanumeric, with hyphens - and underscores _ allowed." : ''}
                        label="Shortened Descriptor"
                        placeholder="Must be less than 10 characters and alphanumeric, with hyphens - and underscores _ allowed."
                        onChange={event => setUserData(userData => ({
                            ...userData,
                            company_information: {
                                ...userData.company_information,
                                short_descriptor: event.target.value,
                            }
                        }))}
                    />
                </Grid2>
            </Grid2>

            <Grid2 xs={12}>
                <Button
                    variant="contained"
                    onClick={submitter}
                >
                    {sendButtonText}
                </Button>
            </Grid2>
        </Grid2>
    );
};

export default PaymentSetup;
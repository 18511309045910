import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
import Rating from '@mui/material/Rating';
import StarIcon from '@mui/icons-material/Star';

const StyledRating = styled(Rating)({
	'& .MuiRating-iconFilled': {
		color: '#294D51',
		fontSize: 10
	},
});

function ReviewDetailsMobile({reviewState}) {
	const [reviews, setReviews] = useState([]);
	const [reviewAvg, setReviewAvg] = useState(0);

	useEffect(() => {
		setReviews(reviewState.reviews);
		setReviewAvg(reviewState.avgRating);
	}, [reviewState]);

	return (
		<div className="slider-container" style={{width: '100%'}}>
			<div className="slider-container-header">
				<Typography variant="p" className="section-title">Reviews</Typography>
			</div>
			<div className="flex-box justify-space-between">
				<div>
					<div style={{fontSize: 36}}>{reviewAvg.toFixed(2)}</div>
					<div>
						<StyledRating
							name="text-feedback"
							value={reviewAvg.toFixed(2)}
							readOnly
							precision={0.01}
							emptyIcon={<StarIcon style={{ opacity: 0.55, fontSize: 10 }} fontSize="inherit" />}
						/>
					</div>
					<div className="text-review-number-mobile">{reviews.length} Reviews</div>
				</div>
				<div style={{marginLeft: 10}}>
					<div className="flex-box">
						<div className="review-detail-label-mobile">Cleanliness</div>
						<div className="rating-wrapper">
							<StyledRating
								name="text-feedback"
								value={reviewAvg.toFixed(2)}
								readOnly
								precision={0.01}
								emptyIcon={<StarIcon style={{ opacity: 0.55, fontSize: 10 }} fontSize="inherit" />}
							/>
						</div>
						<div>{reviewAvg.toFixed(2)}</div>
					</div>
					<div className="flex-box">
						<div className="review-detail-label-mobile">Work Quality</div>
						<div className="rating-wrapper">
							<StyledRating
								name="text-feedback"
								value={reviewAvg.toFixed(2)}
								readOnly
								precision={0.01}
								emptyIcon={<StarIcon style={{ opacity: 0.55, fontSize: 10 }} fontSize="inherit" />}
							/>
						</div>
						<div>{reviewAvg.toFixed(2)}</div>
					</div>
					<div className="flex-box">
						<div className="review-detail-label-mobile">Responsiveness</div>
						<div className="rating-wrapper">
							<StyledRating
								name="text-feedback"
								value={reviewAvg.toFixed(2)}
								readOnly
								precision={0.01}
								emptyIcon={<StarIcon style={{ opacity: 0.55, fontSize: 10 }} fontSize="inherit" />}
							/>
						</div>
						<div>{reviewAvg.toFixed(2)}</div>
					</div>
					<div className="flex-box">
						<div className="review-detail-label-mobile">Punctuality</div>
						<div className="rating-wrapper">
							<StyledRating
								name="text-feedback"
								value={reviewAvg.toFixed(2)}
								readOnly
								precision={0.01}
								emptyIcon={<StarIcon style={{ opacity: 0.55, fontSize: 10 }} fontSize="inherit" />}
							/>
						</div>
						<div>{reviewAvg.toFixed(2)}</div>
					</div>
				</div>
			</div>
		</div>
  );
}

export default ReviewDetailsMobile;

import { Box, Button, Modal, TextField, Typography, RadioGroup, Radio, FormControlLabel } from "@mui/material";
import React, { useCallback } from "react";
import './PayOthersInitializeModal.css';
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { useState } from "react";
import isEmpty from "validator/lib/isEmpty";
import isEmail from "validator/lib/isEmail";
import isMobilePhone from "validator/lib/isMobilePhone";
import isPostalCode from "validator/lib/isPostalCode";
import { useEffect } from "react";
import { addCustomer, editCustomer } from "../../api/contacts";
import { phoneNumberReformatter } from "../../misc/utilityFunctions";
import CloseIcon from '@mui/icons-material/Close';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { useNavigate } from 'react-router-dom';
import bankImg from "../../img/bank.png";
import successImg from "../../img/success.png";
import { getPlaidLinkToken } from '../../api/payout';
import { usePlaidLink } from "react-plaid-link";
import SelectAccount from "./SelectAccount";
import ConfirmAddAccount from "./ConfirmAddAccount";

const PayOthersModal = ({ showModal, setShowModal }) => {
    const navigate = useNavigate();
    const [isBusiness, setIsBusiness] = useState(true);
    const [step, setStep] = useState(0);
    const [linkToken, setLinkToken] = useState();
    const [config, setConfig] = useState();
   
    const nextStep = () => {
        if(step < 2) {
            setStep(step + 1);
        }
        else {
            setShowModal(false);
            setStep(0);
        }
    }

    const prevStep = () => {
        if(step > 0) {
            setStep(step - 1);
        }
        else {
            setShowModal(false);
        }
    }

    const connectBankAccount = () => {
        getPlaidLinkToken().then(async (result) => {
            setLinkToken(result.data.link_token);
        }).catch(error => {
            console.log(error);
        });
    }

    return (
        <Modal
            open={showModal}
            onClose={setShowModal}
        >
            <Box className="big-modal-container">
                <Grid2 container gap={4} flexGrow={1} justifyContent={'space-between'}>
                    <Grid2 xs={12} justifyContent={'space-between'} display={'flex'}>
                        <Box>
                            {step !== 2 && <>
                            <Button startIcon={<ChevronLeftIcon/>} onClick={() => prevStep()}>
                                Back
                            </Button>
                            <Typography variant="h5" color={'primary'} fontWeight={'bold'}>
                                Pay Others Initialization
                            </Typography></>}
                        </Box>
                        <CloseIcon className="close-button" onClick={() => setShowModal(false)} />
                    </Grid2>
                    {step === 0 && <>
                        <Grid2 xs={12} container justifyContent={"center"}>
                            <img src={bankImg} alt=""/>
                        </Grid2>
                        <Grid2 xs={12} container justifyContent={"center"}>
                            <Typography variant="h5" color={'primary'} fontWeight={'bold'}>
                                Connect Bank Account To Top Up And Start Paying Bills Instantly
                            </Typography>
                            <Typography variant="p" color={'primary'}>
                                *Tapper Currently Only Supports Business Bank Accounts.
                            </Typography>
                        </Grid2>
                        <Grid2 xs={12} container justifyContent={"flex-end"}>
                            <Button
                                fullWidth
                                variant="contained"
                                onClick={() => connectBankAccount()}
                            >
                                Connect To Bank Account
                            </Button>
                            {linkToken && <SelectAccount linkToken={linkToken} nextStep={nextStep} setLinkToken={setLinkToken} />}
                        </Grid2>
                    </>}
                    {step === 1 && <ConfirmAddAccount nextStep={nextStep} />}
                    {step === 2 && <>
                        <Grid2 xs={12} container justifyContent={"center"}>
                            <img src={successImg} alt=""/>
                        </Grid2>
                        <Grid2 xs={12} container justifyContent={"center"}>
                            <Typography variant="h6" color={'primary'} fontWeight={'bold'}>
                                Congratulation
                            </Typography>
                        </Grid2>
                        <Grid2 xs={12} container justifyContent={"center"}>
                            <Typography variant="p" color={'primary'}>
                                You acount has been successfully connected.
                            </Typography>
                        </Grid2>
                        <Grid2 xs={12} container justifyContent={"flex-end"}>
                            <Button
                                fullWidth
                                variant="contained"
                                onClick={() => {
                                    nextStep();
                                    navigate('/send-payment');
                                }}
                            >
                                Top Up Now
                            </Button>
                        </Grid2>
                    </>}
                </Grid2>
            </Box>
        </Modal>
    );
};

export default PayOthersModal;
import { useEffect, useState } from "react";
import { useAuth } from "../contexts/AuthContext";
import isEmail from "validator/lib/isEmail";
import isEmpty from "validator/lib/isEmpty";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { Box, IconButton, InputAdornment, Link, Paper, TextField, Typography } from "@mui/material";
import { VisibilityOutlined, VisibilityOffOutlined } from "@mui/icons-material";
import googleIcon from "../img/google-icon.svg";
import './signIn.css'
import TermsPDF from "../pdf/Terms.pdf";
import PolicyPDF from "../pdf/Policy.pdf";

const SignIn = () => {
    const [username, setUsername] = useState('');
    const isValidUsername = Boolean(isEmail(username));
    const [showUsernameError, setShowUsernameError] = useState(false);
    
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const isValidPassword = Boolean(!isEmpty(password));
    const [showPasswordError, setShowPasswordError] = useState(false);
    const [signinButtonLabel, setSigninButtonLabel] = useState('Sign In');
    const [isSigning, setIsSigning] = useState(false);

    const formValidator = () => {
        if (!isValidUsername) {
            setShowUsernameError(true);
            return false;
        }
        if (!isValidPassword) {
            setShowPasswordError(true);
            return false;
        }
        return true;
    };

    const formSubmit = async () => {
        if (formValidator()) {
            sessionStorage.removeItem('hasReloaded');
            setIsSigning(true);
            setSigninButtonLabel('Loading...');
            const success = await signIn(username, password);
            if (!success) {
                alert('Sign-in failed');
            }
            setIsSigning(false);
            setSigninButtonLabel('Sign In');
        }
    };

    const handleGoogleSignIn = async () => {
        sessionStorage.removeItem('hasReloaded');
        const success = await signInWithGoogle();
    }

    useEffect(() => {
        if (isValidUsername) setShowUsernameError(false);
        if (isValidPassword) setShowPasswordError(false);
    }, [isValidPassword, isValidUsername]);
    
    const { signIn, signInWithGoogle } = useAuth();

    return (
        <Grid2 container>
            <Grid2 container xs={0} md={3} lg={4} xl={5} bgcolor={'primary.main'}>
                <Grid2 xs={12} container direction={'column'} gap={1} justifyContent={'center'} alignItems={'center'}>
                    <img
                        alt=""
                        src="/logo1x.png"
                        width={200}
                        height={200}
                        // className="tapper-logo"
                    />
                    <Typography variant="h5" color={'white'} fontWeight={'bold'}>
                        The best app for contractors
                    </Typography>
                </Grid2>
                <Grid2 xs={12} container pb={4} direction={'column'} gap={3} justifyContent={'center'} alignItems={'center'}>
                    <Typography color={'white'} fontWeight={'bold'}>
                        Download Mobile App Here
                    </Typography>
                    <img
                        alt=""
                        src="/tapper-qr-code.png"
                        width={160}
                        height={160}
                        // className="tapper-logo"
                    />
                </Grid2>
            </Grid2>
            <Grid2 container xs sm md  bgcolor={'white'} sx={{display: { xs: 'block',}}}>
                <Box className="auth-form">   
                    <Box className="title-text-container">
                        <Typography variant="h3" fontWeight={'bold'} color={'primary'}>
                            Sign In
                        </Typography>
                        <Box className="subtitle-text-container">
                            <Typography variant="h6" color={'primary'}>
                                You don't have a Tapper account yet?
                            </Typography>
                            <Link variant="h6" href="/sign-up">Sign up</Link>
                        </Box>
                    </Box>
                    <Box className="input-container">
                        <TextField
                            variant="standard"
                            type="email"
                            fullWidth
                            error={showUsernameError}
                            helperText={showUsernameError ? 'Please enter a valid email address' : ''}
                            value={username}
                            label="Email Address"
                            placeholder="Enter your email address"
                            onChange={event => {
                                setUsername(event.target.value);
                            }}
                        />
                        <TextField
                            variant="standard"
                            type={showPassword ? 'text' : 'password'}
                            fullWidth
                            error={showPasswordError}
                            helperText={showPasswordError ? 'Please enter a password' : ''}
                            value={password}
                            label="Password"
                            placeholder="Enter your password"
                            onChange={event => {
                                setPassword(event.target.value);
                            }}
                            InputProps={{
                                endAdornment: 
                                    <InputAdornment position="end">
                                      <IconButton
                                        onClick={() => setShowPassword(!showPassword)}
                                        onMouseDown={event => event.preventDefault()}
                                        edge="end"
                                      >
                                        {showPassword ? <VisibilityOffOutlined /> : <VisibilityOutlined />}
                                      </IconButton>
                                    </InputAdornment>
                            }}
                        />
                        <Typography className="terms">By signing in you are agreeing with our <a href={TermsPDF} target="_blank" className="terms-link">Terms</a> and <a href={PolicyPDF} target="_blank" className="terms-link">Privacy Policy</a>.</Typography>
                    </Box>
                    <Box className="auth-button-wrapper">
                        <Paper
                            className="auth-button"
                            elevation={4}
                            // for some reason css wouldnt work here so I added it in manually
                            sx={{
                                backgroundColor: isSigning ? 'primary.light' : 'primary.main', 
                                padding: 2,
                                borderRadius: 28,
                                transition: 'background-color 0.25s',
                                ":hover": {
                                    backgroundColor: 'primary.light',
                                    cursor: "pointer",
                                },
                                margin: 1,
                            }}
                            disabled={isSigning}
                            onClick={async () => {
                                if(!isSigning)
                                    await formSubmit();
                            }}
                        >
                            <Typography variant="h6" color={'white'}>{signinButtonLabel}</Typography>
                        </Paper>
                        <Typography variant="h6" color={'black'}>OR</Typography>
                        <Paper
                            className="auth-button"
                            elevation={4}
                            // for some reason css wouldnt work here so I added it in manually
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                backgroundColor: '#294D511A',
                                padding: 2,
                                borderRadius: 28,
                                transition: 'background-color 0.25s',
                                ":hover": {
                                    backgroundColor: 'white',
                                    cursor: "pointer",
                                },
                                margin: 1,
                            }}
                            onClick={async () => handleGoogleSignIn()}
                        >
                            <img src={googleIcon} alt="" style={{marginRight: 30}}/>
                            <Typography variant="h6" color={'black'}>Continue with Gmail</Typography>
                        </Paper>
                    </Box>
                </Box>
            </Grid2>
        </Grid2>
    );
};

export default SignIn;
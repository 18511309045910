import { IconButton, Menu, MenuItem, } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { useCallback } from "react";
import { useState } from "react";
import { deletePayment, sendPaymentReminder } from "../../api/payments";
import './PaymentLinkModal.css';
import RecordPaymentModal from "./RecordPaymentModal";
import { MoreHoriz } from "@mui/icons-material";
import LinkToInvoiceModal from "./LinkToInvoiceModal";
import { useNavigate } from "react-router-dom";

const PaymentButtonGroup = ({index, paymentInfo, status, setRefreshTable}) => {
    
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    
    const [remindText, setRemindText] = useState('Remind');
    const handleRemind = useCallback(async () => {
        setRemindText('Loading...');
        const response = await sendPaymentReminder(paymentInfo.id);
        if (response) {
            setRemindText('Success!');
        } else {
            setRemindText('Error');
        }
    }, [paymentInfo.id]);
    
    const [showRecord, setShowRecord] = useState(false);
    const handleRecord = useCallback(() => {
        setShowRecord(true);
        handleClose();
    }, []);

    const [showLinkToInvoiceModal, setShowLinkToInvoiceModal] = useState(false);
    const handleLinkToInvoice = useCallback(() => {
        setShowLinkToInvoiceModal(true);
        handleClose();
    }, []);

    const navigate = useNavigate();
    const handleCreateInvoice = useCallback(() => {
        navigate(`/invoices/create/with-payment/${paymentInfo.id}`)
    }, [navigate, paymentInfo.id]);

    const [deleteText, setDeleteText] = useState('Delete Payment');
    const handleDeletePayment = useCallback(async () => {
        setDeleteText('Deleting...');
        const response = await deletePayment(paymentInfo.id);
        if (response) {
            setDeleteText('Successfully Deleted!');
        } else {
            setDeleteText('Failed to Delete!');
        }
        setTimeout(() => handleClose(), 500);
        setTimeout(() => setRefreshTable(refreshTable => !refreshTable), 1000);
    }, [paymentInfo.id, setRefreshTable]);

    return (
        <Grid2 container>
            <RecordPaymentModal
                showModal={showRecord}
                setShowModal={setShowRecord}
                paymentInfo={paymentInfo}
            />
            <LinkToInvoiceModal 
                showModal={showLinkToInvoiceModal}
                setShowModal={setShowLinkToInvoiceModal}
                paymentID={paymentInfo.id}
            />
            <IconButton
                onClick={handleClick}
            >
                <MoreHoriz/>
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                {status === 'OUTSTANDING' && <MenuItem onClick={handleRemind}>{remindText}</MenuItem>}
                {status === 'OUTSTANDING' && <MenuItem onClick={handleRecord}>Record Payment</MenuItem>}
                {(!paymentInfo.customer_name) && <MenuItem onClick={handleLinkToInvoice}>Link to Existing Invoice</MenuItem>}
                {(!paymentInfo.customer_name) && <MenuItem onClick={handleCreateInvoice}>Create Invoice</MenuItem>}
                <MenuItem onClick={handleDeletePayment}>{deleteText}</MenuItem>
            </Menu>
        </Grid2>
    );
};

export default PaymentButtonGroup;
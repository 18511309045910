import { Box, Button, Modal, TextField, Typography } from "@mui/material";
import { useCallback } from "react";
import './PayOthersModal.css';
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { useState } from "react";
import isEmpty from "validator/lib/isEmpty";
import isEmail from "validator/lib/isEmail";
import isMobilePhone from "validator/lib/isMobilePhone";
import isPostalCode from "validator/lib/isPostalCode";
import { useEffect } from "react";
import { addCustomer, editCustomer } from "../../api/contacts";
import { phoneNumberReformatter } from "../../misc/utilityFunctions";
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';

const PayOthersModal = ({ showModal, setShowModal }) => {
    const navigate = useNavigate();
    const [isBusiness, setIsBusiness] = useState(true);

    return (
        <Modal
            open={showModal}
            onClose={setShowModal}
        >
            <Box className="big-modal-container">
                <Grid2 container gap={4} flexGrow={1} justifyContent={'space-between'}>
                    <Grid2 xs={12} justifyContent={'space-between'} display={'flex'}>
                        <Typography variant="h5" color={'primary'} fontWeight={'bold'}>
                            Register Pay Account
                        </Typography>
                        <CloseIcon className="close-button" onClick={() => setShowModal(false)} />
                    </Grid2>
                    <Grid2 xs={12} container>
                        <Button
                            fullWidth
                            variant={isBusiness ? "contained": "outlined"}
                            onClick={() => setIsBusiness(true)}
                        >
                            Business Account
                        </Button>
                    </Grid2>
                    <Grid2 xs={12} container>
                        <Button
                            fullWidth
                            variant={isBusiness ? "outlined" : "contained"}
                            onClick={() => setIsBusiness(false)}
                        >
                            Individual Account
                        </Button>
                    </Grid2>
                    <Grid2 xs={12} container justifyContent={"flex-end"}>
                        <Button
                            variant="contained"
                            onClick={() => isBusiness ? navigate('/payout-registration/business') : navigate('/payout-registration/individual')}
                        >
                            Next
                        </Button>
                    </Grid2>
                </Grid2>
            </Box>
        </Modal>
    );
};

export default PayOthersModal;
import React from 'react';

import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { Box, Button, Typography } from '@mui/material';

import AppDrawer from '../../components/appdrawer/appdrawer';

import './invoices.css'
import { Add, } from '@mui/icons-material';
import InvoicesTable from '../../components/invoices/InvoicesTable';
import { useNavigate } from 'react-router-dom';

const Invoices = () => {

    const navigate = useNavigate();

    return (
        <Box sx={{ display: 'flex', }}>
            <AppDrawer/>
            <Grid2 container padding={4} rowGap={8} style={{width: '100%'}}>
                <Grid2 container xs={12} justifyContent={'space-between'} alignItems={'center'}>
                    <Grid2 container lg={6} md={12} xs={12} direction={'column'} alignItems={'flex-start'}>
                        <Typography color={'primary'} variant="h3" fontWeight={'bold'}>
                            Invoices
                        </Typography>
                        <Typography color={'primary'} variant="body2">
                            Create and manage invoices
                        </Typography>
                    </Grid2>
                    <Grid2 container lg={6} md={12} xs={12} justifyContent={'flex-end'} className="payment-button-group">
                        <Button
                            className="payment-button"
                            variant="contained"
                            startIcon={<Add/>}
                            onClick={() => navigate('/invoices/create')}
                        >
                            New Invoice
                        </Button>
                    </Grid2>
                </Grid2>
                <InvoicesTable />
            </Grid2>
        </Box>
    );
};

export default Invoices;

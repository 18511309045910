import { useCallback } from "react";
import { useState } from "react";
import isCurrency from "validator/lib/isCurrency";
import { recordPayment, recordPaymentWithInvoice } from "../../api/payments";
import { useEffect } from "react";
import { Box, Button, InputAdornment, Modal, TextField, Typography } from "@mui/material";
import './PaymentLinkModal.css';
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import ResultModal from "../util/ResultModal";
import { useNavigate } from "react-router-dom";


const RecordPaymentModal = ({showModal, setShowModal, isInvoice = false, paymentInfo = null, invoiceInfo = null,}) => {

    const navigate = useNavigate()
    const [loading, setLoading] = useState(false);
    const [method, setMethod] = useState('Check');

    const [amount, setAmount] = useState('');
    const [amountError, setAmountError] = useState(false);
    const isValidAmount = Boolean(isCurrency(amount));

    const [success, setSuccess] = useState(false);
    const [showResult, setShowResult] = useState(false);

    const handleClose = useCallback(() => {
        setShowResult(false);
        setSuccess(false);
        setMethod('Check');
        setAmount('');
        setLoading(false);
        setShowModal(false);
    }, [setShowModal]);

    const validator = useCallback(() => {
        if (!isValidAmount) {
            setAmountError(true);
            return false;
        }
        return true;
    }, [isValidAmount]);

    const handleButton = useCallback(async () => {
        if (validator()){
            setLoading(true);
            let response;
            if (isInvoice) {
                response = await recordPaymentWithInvoice(invoiceInfo?.id, method, Number(amount));
            } else {
                response = await recordPayment(paymentInfo?.id, method, Number(amount));
            }
            if (response) {
                setSuccess(true);
            } else {
                setSuccess(false);
            }
            setLoading(false);
            setShowResult(true);
            setTimeout(() => navigate('/invoices'), 1500);
        }
    }, [amount, invoiceInfo?.id, isInvoice, method, navigate, paymentInfo?.id, validator]);

    useEffect(() => {
        if (isValidAmount) setAmountError(false);
    }, [isValidAmount]);

    return (
        <Modal
            open={showModal}
            onClose={handleClose}
        >
            <Box className="modal-container">
                <Grid2 container gap={4}>
                    <Grid2 xs={12}>
                        <Typography variant="h5" color={'primary'} fontWeight={'bold'}>
                            Record Payment
                        </Typography>
                    </Grid2>
                    <Grid2 container xs={12} gap={0.5} justifyContent={'space-between'}>
                        <Grid2 xs={12}>
                            <Typography variant="body1" color={'primary'} fontWeight={'bold'}>
                                Customer Information
                            </Typography>
                        </Grid2>
                        <Grid2 xs={5}>
                            <Typography color={'primary'}>
                                {isInvoice ? invoiceInfo?.client_name : paymentInfo?.customer_name}
                            </Typography>
                        </Grid2>
                        <Grid2 xs={5} >
                            <Typography color={'primary'}>
                                {
                                    new Date(isInvoice ? invoiceInfo?.created_at : paymentInfo?.created_at).toLocaleDateString('en-US', {
                                        month: 'short', year:'numeric', day:'numeric', 
                                        hour: 'numeric', minute: 'numeric', second:'numeric'
                                    })
                                }
                            </Typography>
                        </Grid2>
                    </Grid2>
                    <Grid2 xs={12}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            type="number"
                            value={amount}
                            onChange={event => {
                                setAmount(event.target.value);
                            }}
                            placeholder="Enter Amount"
                            label="Payment Amount"
                            error={amountError}
                            helperText={amountError ? 'Please enter a valid amount in $USD' : ''}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">$</InputAdornment>
                            }}
                        />
                    </Grid2>
                    <Grid2 container xs={12} gap={0.5} justifyContent={'space-between'}>
                        <Grid2 xs={12}>
                            <Typography variant="body1" color={'primary'} fontWeight={'bold'}>
                                Payment Method
                            </Typography>
                        </Grid2>
                        {['Check', 'Venmo', 'Zelle', 'Cash', 'Other'].map((value, index) => (
                            <Button
                                key={index+1}
                                variant={(method === value) ? 'contained' : 'outlined'}
                                onClick={() => setMethod(value)}
                            >
                                {value}
                            </Button>
                        ))}
                    </Grid2>
                    <Grid2 container xs={12} gap={3} justifyContent={'space-between'}>
                        {loading
                            ? <Typography>Loading ...</Typography>
                            : <>
                                <Button 
                                    variant="contained"
                                    sx={{width: '45%'}}
                                    onClick={async () => await handleButton()}
                                    >
                                    Record
                                </Button>
                                <Button 
                                    variant="outlined"
                                    sx={{width: '45%'}}
                                    onClick={handleClose}
                                >
                                    Cancel
                                </Button>
                            </>
                        }
                    </Grid2>
                    <ResultModal 
                        success={success}
                        setSuccess={setSuccess}
                        showResult={showResult}
                        setShowResult={setShowResult}
                        successText={'Payment successfully recorded!'}
                        failText={'Failed to record payment'}
                    />
                </Grid2>
            </Box>
        </Modal>
    );
};

export default RecordPaymentModal;
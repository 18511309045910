import React from 'react';
import Navbar from '../../components/customerReview/Navbar';
import NavbarMobile from '../../components/customerReview/NavbarMobile';
import ReviewHeader from '../../components/review/ReviewHeader';
import ReviewHeaderMobile from '../../components/review/ReviewHeaderMobile';
// import ReviewPortfolio from '../../components/review/ReviewPortfolio';
import ReviewDetails from '../../components/review/ReviewDetails';
import ReviewDetailsMobile from '../../components/review/ReviewDetailsMobile';
import Review from '../../components/review/Review';
import ReviewMobile from '../../components/review/ReviewMobile';
import fetchReviews from '../../api/reviews';
import InfiniteScroll from 'react-infinite-scroll-component';
import './../review.css';
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import axios from "axios";
import { useParams } from "react-router-dom";
import Map from '../../components/review/Map';
import Information from '../../components/review/Information';
import InformationMobile from '../../components/review/InformationMobile';
import { AppContext } from '../../contexts/AppContext';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import "./customReview.css";

function withParams(Component) {
  return props => <Component {...props} params={useParams()} />;
}

class CustomerReview extends React.Component {

  constructor(props) {
    super(props);
    let userId = props.params.userId;

    this.state = {
      reviews: [],
      hasMore: true,
      user: {
        "company_information": {
          "name": '',
          "email": '',
          "phone": ''
        },
        "location": {
          "city": '',
          "state": '',
          "zip_code": '',
          "address_line_1": '',
          "address_line_2": ''
        },
      },
      userId,
      page: 1,
      limit: 10,
      logo: '',
      avgRating: 5,
      reviewNum: 0,
      filterOption: 0
    };

    this.fetchMoreData = this.fetchMoreData.bind(this);
  }

  componentDidMount() {
    this.getUser();
    this.fetchMoreData();
  }

  getUserLogo = async (user) => {
    const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/users/logo`, {
      headers: {
        'user-id': user.user_id
      }
    });
    this.state.setState({ logo: response.data.logoURL });
  };

  async getUser() {
    const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/users`, {
      headers: {
        'user-id': this.state.userId
      }
    });
    this.setState({ user: response.data });
  }

  async fetchMoreData() {
    if (this.state.hasMore) {
      const rawPostData = await fetchReviews(this.state.userId, this.state.page, this.state.limit);
      if (rawPostData.data.rows.length === 0) {
        this.setState({ hasMore: false });
        return;
      }
    
      let avg = this.state.avgRating; 
      const postData = rawPostData.data.rows.reduce((accumulator, review) => {
        const date = new Date(review.created_at)
        const month = date.getMonth() + 1;
        const day = date.getDate();
        const year = date.getFullYear();
        const formattedDate = `${month.toString().padStart(2, '0')}/${day.toString().padStart(2, '0')}/${year.toString()}`;
        review.created_at = formattedDate;
        avg = (avg + Number(review.rating)) / 2;

        if(!this.isInArr(this.state.reviews, review)) accumulator.push(review);

        return accumulator;
      }, []);

      this.setState({ reviews: this.state.reviews.concat(postData), page: this.state.page + 1, avgRating: avg, reviewNum: rawPostData.data.count});
    }
  }

  isInArr(arr, item) {
    for(const itemArr of arr) {
      if(itemArr.id === item.id) {
        return true;
      }
    }
    return false;
  }

  render() {
    return (
      <AppContext.Consumer>
        {contextValue => (
          <>
            {contextValue.isTablet ?
            <>
              <Grid2 container justifyContent={'center'} px={contextValue.isTablet ? 3 : 20} py={0} className="review-header">
                <Grid2 xs={12} container rowGap={5}>
                  <NavbarMobile />
                </Grid2>
              </Grid2>
              <Grid2 container justifyContent={'center'} px={contextValue.isTablet ? 3 : 20} py={0}>
                <Grid2 xs={12} container rowGap={5}>
                  <ReviewHeaderMobile reviewState={this.state} />
                </Grid2>
                <Grid2 xs={12} container rowGap={5}>
                  {this.state.user.location.city && <Map user={this.state.user} mapId={2} />}
                </Grid2>
                <Grid2 xs={12} container rowGap={5}>
                  <InformationMobile user={this.state.user} />
                </Grid2>
                <Grid2 xs={12} container rowGap={5}>
                  <ReviewDetailsMobile reviewState={this.state} />
                </Grid2>
                <Grid2 xs={12} className="border-divider" style={{width: '100%'}}>
                  <div className="flex-box">
                    <div onClick={() => this.setState({filterOption: 0})}
                      className={`filter-review-button-mobile ${this.state.filterOption === 0 
                      ? 'filter-review-button-selected' 
                      : 'filter-review-button-unselected'}`}>All</div>
                    <div onClick={() => this.setState({filterOption: 1})}
                      className={`filter-review-button-mobile ${this.state.filterOption === 1 
                      ? 'filter-review-button-selected' 
                      : 'filter-review-button-unselected'}`}>General customers ({this.state.reviews.length})</div>
                    <div onClick={() => this.setState({filterOption: 2})}
                      className={`filter-review-button-mobile ${this.state.filterOption === 2 
                      ? 'filter-review-button-selected' 
                      : 'filter-review-button-unselected'}`}>Contractor reviews (0)</div>
                  </div>
                  <div className='revireBox' style={{ display: 'flex', width: '100%', marginTop: '2%' }}>
                    <div className="flex flex-col w-full">
                      {
                        this.state.reviews.length > 0 ? (
                          <InfiniteScroll
                            style={{display: this.state.filterOption === 2 ? 'none' : 'block'}}
                            dataLength={this.state.reviews.length}
                            next={this.fetchMoreData()}
                            hasMore={this.state.hasMore}
                            scrollThreshold={0.5}
                            loader={(
                              <div role="status" className='flex justify-center'>
                                <svg aria-hidden="true" class="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-brandGreen-800" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                  <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                                </svg>
                                <span class="sr-only">Loading...</span>
                              </div>
                            )}
                            endMessage={
                              <p style={{ textAlign: 'center' }} className="my-4">
                                <b>Yay! You have seen it all</b>
                              </p>
                            }
                          >
                            {
                              this.state.reviews.map((review, index) => {
                                return (
                                  <ReviewMobile key={index} reviewInfo={review}></ReviewMobile>
                                );
                              })
                            }
                          </InfiniteScroll>
                        ) : (
                          <div className="flex flex-col items-center justify-center w-full h-96">
                            <h1 className="text-2xl font-bold">No reviews yet</h1>
                          </div>
                        )
                      }
                    </div>
                  </div>
                </Grid2>
              </Grid2>
            </>
            :
            <>
              <Grid2 container justifyContent={'center'} px={contextValue.isTablet ? 3 : 20} py={0} className="review-header">
                <Grid2 xs={12} container rowGap={5}>
                  <Navbar />
                </Grid2>
              </Grid2>
              <Grid2 container justifyContent={'center'} px={contextValue.isTablet ? 3 : 20} py={0}>
                <Grid2 xs={12} md={!contextValue.isTablet ? 8 : 12} container rowGap={5}>
                  <Grid2 container style={{width: '100%'}}>
                    <Grid2 xs={12} className="border-divider" style={{width: '100%'}}>
                      <ReviewHeader reviewState={this.state} />
                    </Grid2>
                    {/* <Grid2 xs={12} className="border-divider" style={{width: '100%'}}>
                      <ReviewPortfolio />
                    </Grid2> */}
                    <Grid2 xs={12} className="border-divider" style={{width: '100%'}}>
                      <ReviewDetails reviewState={this.state} />
                    </Grid2>
                    <Grid2 xs={12} className="border-divider pt-4" style={{width: '100%'}}>
                      <div className="flex-box">
                        <div onClick={() => this.setState({filterOption: 0})}
                          className={`filter-review-button ${this.state.filterOption === 0 
                          ? 'filter-review-button-selected' 
                          : 'filter-review-button-unselected'}`}>All</div>
                        <div onClick={() => this.setState({filterOption: 1})}
                          className={`filter-review-button ${this.state.filterOption === 1 
                          ? 'filter-review-button-selected' 
                          : 'filter-review-button-unselected'}`}>General customers ({this.state.reviews.length})</div>
                        <div onClick={() => this.setState({filterOption: 2})}
                          className={`filter-review-button ${this.state.filterOption === 2 
                          ? 'filter-review-button-selected' 
                          : 'filter-review-button-unselected'}`}>Contractor reviews (0)</div>
                      </div>
                      <div className='revireBox' style={{ display: 'flex', width: '100%', marginTop: '2%' }}>
                        <div className="flex flex-col w-full">
                          {
                            this.state.reviews.length > 0 ? (
                              <InfiniteScroll
                                style={{display: this.state.filterOption === 2 ? 'none' : 'block'}}
                                dataLength={this.state.reviews.length}
                                next={this.fetchMoreData()}
                                hasMore={this.state.hasMore}
                                scrollThreshold={0.5}
                                loader={(
                                  <div role="status" className='flex justify-center'>
                                    <svg aria-hidden="true" class="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-brandGreen-800" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                      <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                                    </svg>
                                    <span class="sr-only">Loading...</span>
                                  </div>
                                )}
                                endMessage={
                                  <p style={{ textAlign: 'center' }} className="my-4">
                                    <b>Yay! You have seen it all</b>
                                  </p>
                                }
                              >
                                {
                                  this.state.reviews.map((review, index) => {
                                    return (
                                      <Review key={index} reviewInfo={review}></Review>
                                    );
                                  })
                                }
                              </InfiniteScroll>
                            ) : (
                              <div className="flex flex-col items-center justify-center w-full h-96">
                                <h1 className="text-2xl font-bold">No reviews yet</h1>
                              </div>
                            )
                          }
                        </div>
                      </div>
                    </Grid2>
                  </Grid2>
                </Grid2>
                <Grid2 xs={12} md={!contextValue.isTablet ? 4 : 12} container rowGap={5}>
                  <Box style={{padding: 40, width: '100%'}}>
                    <Card sx={{width: '100%' }}>
                      {this.state.user.location.city && <Information user={this.state.user} />}
                      {this.state.user.location.city && <Map user={this.state.user} mapId={1} />}
                    </Card>
                  </Box>
                </Grid2>
              </Grid2>
            </>}
          </>
        )}
      </AppContext.Consumer>
    );
  }
}

export default withParams(CustomerReview);

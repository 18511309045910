import axios from "axios";

export async function getEstimates(page, size) {
    try {
        return axios.get(`${process.env.REACT_APP_SERVER_URL}/invoices/for-web?page=${page}&size=${size}&type=ESTIMATE`);
    } catch (error) {
        console.error('error fetching estimates: ', error);
        return null;
    }
}

export async function getEstimateOrInvoice(ID) {
    try {
        return axios.get(`${process.env.REACT_APP_SERVER_URL}/invoices/${ID}`);
    } catch (error) {
        console.error(`error fetching estimate/invoice #${ID}: `, error);
        return null;
    }
}

export async function searchEstimates(search) {
    try {
        return await axios.post(`${process.env.REACT_APP_SERVER_URL}/invoices/searching?search=${search}&type=ESTIMATE`);
    } catch (error) {
        console.error('error searching estimates: ', error);
        return null;
    }
}

export async function sendEstimateOrInvoiceReminder(ID) {
    try {
        return await axios.post(`${process.env.REACT_APP_SERVER_URL}/invoices/sending-reminder/${ID}`);
    } catch (error) {
        console.error(`error sending reminder to estimate/invoice #${ID}: `, error);
        return null;
    }
}

export async function attachEstimateOrInvoicePhotos(imageFormData) {
    try {
        return await axios.post(`${process.env.REACT_APP_SERVER_URL}/invoices/upload-attach-photos`, imageFormData);
    } catch (error) {
        console.error('error uploading estimate/invoice attachment photos:', error);
        return null;
    }
}

export async function createEstimateOrInvoice(estimateOrInvoice) {
    try {
        return await axios.post(`${process.env.REACT_APP_SERVER_URL}/invoices`, estimateOrInvoice);
    } catch (error) {
        console.error('error creating new estimate/invoice: ', error);
        return null;
    }
}

export async function changeOrderEstimateOrInvoice(estimateOrInvoice) {
    try {
        return await axios.post(`${process.env.REACT_APP_SERVER_URL}/invoices/change`, estimateOrInvoice);
    } catch (error) {
        console.error('error change ordering estimate/invoice: ', error);
        return null;
    }
}

export async function editEstimateOrInvoice(estimateOrInvoice) {
    let {id, ...body} = estimateOrInvoice;
    try {
        return await axios.patch(`${process.env.REACT_APP_SERVER_URL}/invoices/${id}`, body);
    } catch (error) {
        console.error('error change ordering estimate/invoice: ', error);
        return null;
    }
}


export async function sendEstimate(id, email, amount) {
    const body = {
        id,
        email,
        amount,
    };
    try {
        return await axios.post(`${process.env.REACT_APP_SERVER_URL}/invoices/sending?type=ESTIMATE`, body);
    } catch (error) {
        console.error(`error sending estimate/invoice #${id}: `, error);
        return null;
    }
}

export async function deleteEstimateOrInvoice(ID) {
    try {
        return await axios.delete(`${process.env.REACT_APP_SERVER_URL}/invoices/${ID}`);
    } catch (error) {
        console.error(`error deleting estimate/invoice #${ID}: `, error);
        return null;
    }
}
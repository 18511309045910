import { Box, Button, Divider, Typography } from "@mui/material";
import AppDrawer from "../../components/appdrawer/appdrawer";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import AccountSideBar from "../../components/account/AccountSideBar";
import { useAuth } from "../../contexts/AuthContext";

const SignOut = () => {

    const { signOut } = useAuth();

    return (
        <Box sx={{ display: 'flex', }}>
            <AppDrawer/>
            <Grid2 xs container p={4} gap={4}>

                <Grid2 container xs={12} direction={'column'} alignItems={'flex-start'}>
                    <Typography color={'primary'} variant="h3" fontWeight={'bold'}>
                        Account
                    </Typography>
                    <Typography color={'primary'} variant="body2">
                        Manage your account
                    </Typography>
                </Grid2>

                <AccountSideBar />

                <Grid2 xs={'auto'}>
                    <Divider orientation="vertical" variant="fullWidth" sx={{ bgcolor: 'black'}}/>
                </Grid2>

                <Grid2 xs container p={4} rowGap={4}>
                    <Grid2 xs={12} container gap={1} height={'min-content'}>
                        <Button 
                            variant="contained" 
                            onClick={() => {
                                (async () => {
                                    await signOut();
                                })()
                            }}
                            sx={{borderRadius: 40}}
                        >
                            Sign Out
                        </Button>
                    </Grid2>
                </Grid2>
            </Grid2>    
        </Box>
    );
};

export default SignOut;
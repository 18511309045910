import axios from 'axios';

export async function getQuickbooksAuthStatus() {
    try {
        return axios.get(`${process.env.REACT_APP_SERVER_URL}/quickbooks/auth-status`);
    } catch (error) {
        console.log('error fetching quickbooks auth status: ', error);
        return null;
    }
}

export async function connectToQuickbooks() {
    try {
        return axios.get(`${process.env.REACT_APP_SERVER_URL}/quickbooks/authorize`);
    } catch (error) {
        console.log('error connecting to quickbooks: ', error);
        return null;
    }
}

export async function disconnectFromQuickbooks() {
    try {
        return axios.delete(`${process.env.REACT_APP_SERVER_URL}/quickbooks/disconnect`);
    } catch (error) {
        console.log('error disconnecting from quickbooks: ', error);
        return null;
    }
}
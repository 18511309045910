import { Box, Button, Divider, InputAdornment, TextField, Typography } from "@mui/material";
import AppDrawer from "../../components/appdrawer/appdrawer";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import AccountSideBar from "../../components/account/AccountSideBar";
import { useCallback, useEffect, useMemo, useState, useContext } from "react";
import { getUser, setUserMarkups } from "../../api/user";
import isEmpty from "validator/lib/isEmpty";
import StripeLogo from "../../components/icons/StripeLogo";
import { useNavigate } from "react-router-dom";
import { AppContext } from '../../contexts/AppContext';

const PaymentSettings = () => {
    const { isTablet } = useContext(AppContext);
    const navigate = useNavigate();
    const [userData, setUserData] = useState({
        stripe_account_id: '',
        stripe_location_id: '',
        is_complited_onboarding: false,
    });

    const userHasStripe = useMemo(() => Boolean(
        userData?.stripe_account_id 
        && !isEmpty(userData?.stripe_account_id) 
        && userData?.is_complited_onboarding
    ), [userData?.is_complited_onboarding, userData?.stripe_account_id]);

    // fetch user data
    useEffect(() => {
        (async () => {
            let response = await getUser();
            if (response) {
                setUserData(response.data);
            }
        })();
    }, []);
    
    const handleDashboardButton = useCallback(() => {
        if (userHasStripe) {
            window.open('https://dashboard.stripe.com/payments');
        } else {
            navigate('/payments/setup');
        }
    }, [navigate, userHasStripe]);

    return (
        <Box sx={{ display: 'flex', }}>
            <AppDrawer/>
            <Grid2 xs container p={4} gap={4}>

                <Grid2 container xs={12} direction={'column'} alignItems={'flex-start'}>
                    <Typography color={'primary'} variant="h3" fontWeight={'bold'}>
                        Account
                    </Typography>
                    <Typography color={'primary'} variant="body2">
                        Manage your account info and subscriptions
                    </Typography>
                </Grid2>

                <AccountSideBar />

                {!isTablet && <Grid2 xs={'auto'}>
                    <Divider orientation="vertical" variant="fullWidth" sx={{ bgcolor: 'black'}}/>
                </Grid2>}

                <Grid2 xs container p={1} rowGap={4}>
                    <Grid2 lg={10} md={12} xs={12} container p={4} gap={4} 
                        borderRadius={4} border={1} borderColor={'grey.50'}
                        height={'min-content'} 
                        justifyContent={'space-between'}
                    >
                        <Grid2 lg={7.5} md={7.5} xs={12}>
                            <StripeLogo width={120} height={50}/>
                        </Grid2>
                        {<Grid2 lg={3.5} md={3.5} xs={12} container justifyContent={'flex-end'}>
                            <Button 
                                variant="outlined"
                                onClick={handleDashboardButton}
                            >
                                {userHasStripe ? 'Go To My Payment Dashboard' : 'Payments setup is required'}
                            </Button>
                        </Grid2>}
                        <Grid2 xs={12} container>
                            <Typography color={'grey'}>
                                Currently, Tapper supports using Stripe for payment management
                            </Typography>
                        </Grid2>
                    </Grid2>
                </Grid2>
            </Grid2>    
        </Box>
    );
};

export default PaymentSettings;
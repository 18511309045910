import { Box, Divider, Typography } from "@mui/material";
import AppDrawer from "../../components/appdrawer/appdrawer";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import AccountSideBar from "../../components/account/AccountSideBar";
import { HelpOutline } from "@mui/icons-material";
import { useContext } from "react";
import { AppContext } from '../../contexts/AppContext';

const ContactSupport = () => {
    const { isTablet } = useContext(AppContext);
    return (
        <Box sx={{ display: 'flex', }}>
            <AppDrawer/>
            <Grid2 xs container p={4} gap={4}>

                <Grid2 container xs={12} direction={'column'} alignItems={'flex-start'}>
                    <Typography color={'primary'} variant="h3" fontWeight={'bold'}>
                        Account
                    </Typography>
                    <Typography color={'primary'} variant="body2">
                        Manage your account info and subscriptions
                    </Typography>
                </Grid2>

                <AccountSideBar />

                {!isTablet && <Grid2 xs={'auto'}>
                    <Divider orientation="vertical" variant="fullWidth" sx={{ bgcolor: 'black'}}/>
                </Grid2>}

                <Grid2 xs container p={1} rowGap={4}>
                    <Grid2 lg={10} md={12} xs={12} container p={4} gap={2} 
                        borderRadius={4} bgcolor={'secondary.light'}
                        height={'min-content'} 
                        justifyContent={'space-between'}
                    >
                        <Grid2 xs={12} container alignItems={'center'} gap={1}>
                            <HelpOutline/>
                            <Typography variant="h6" fontWeight={'bold'}>
                                Have questions about Tapper?
                            </Typography>
                        </Grid2>
                        <Grid2 xs={12} container gap={1}>
                            <Typography variant="body1">
                                Contact us by sending an email to:
                            </Typography>
                            <Typography variant="body1" fontWeight={'bold'}>
                                support@tappernow.com
                            </Typography>
                        </Grid2>
                    </Grid2>
                </Grid2>
            </Grid2>    
        </Box>
    );
};

export default ContactSupport;
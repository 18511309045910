import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import './account.css'
import AppDrawer from '../../components/appdrawer/appdrawer';
import { Box, Button, Divider, TextField, Typography } from '@mui/material';
import AccountSideBar from '../../components/account/AccountSideBar';
import { useCallback, useEffect, useState, useContext } from 'react';
import { changeUserPassword, getUser, getUserLogo, updateUserCompanyAndLocation, updateUserLogo, updateUserPersonalInfo } from '../../api/user';
import isEmpty from 'validator/lib/isEmpty';
import isMobilePhone from 'validator/lib/isMobilePhone';
import isEmail from 'validator/lib/isEmail';
import { phoneNumberReformatter } from '../../misc/utilityFunctions';
import AddressInput from '../../components/util/addressInput';
import { AppContext } from '../../contexts/AppContext';

const Account = () => {
    const { isTablet } = useContext(AppContext);
    const [userData, setUserData] = useState({
        first_name: '',
        last_name: '',
        phone_number: '',
        email: '',
        company_information: {
            name: '',
            email: '',
            phone: '',
        },
        location: {
            address_line_1: '',
            city: '',
            state: '',
            zip_code: '',
        },
    });
    const [userValidation, setUserValidation] = useState({
        first_name: false,
        last_name: false,
        phone_number: false,
        email: false,
        company_information: {
            name: false,
            email: false,
            phone: false,
        },
        location: {
            city: false,
            state: false,
            zip_code: false,
            address_line_1: false,
        },
    });
    const [logoURL, setLogoURL] = useState('');
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [passwordError, setPasswordError] = useState(false);


    const passwordValidator = useCallback(() => {
        if (isEmpty(oldPassword) && isEmpty(newPassword)) {
            return false;
        } else if (oldPassword === newPassword) {
            setPasswordError(true);
            return false;
        }
        return true;
    }, [oldPassword, newPassword]);


    const passwordSubmitter = useCallback(async () => {
        let response = { data: null };
        if (passwordValidator()) {
            response = await changeUserPassword(oldPassword, newPassword);
        }
        return response;
    }, [newPassword, oldPassword, passwordValidator]);

    const attachLogo = useCallback(async event => {
        const files = Array.from(event.target.files);
        const formData = new FormData();
        files.forEach((file) => {
            formData.append('logo', file);
        });
        const response = await updateUserLogo(formData);
        if (response) {
            setLogoURL(response.data);
        } 
    }, []);

    // fetch user data
    useEffect(() => {
        (async () => {
            let response = await getUser();
            if (response) {
                setUserData(response.data);
            }
            response = await getUserLogo();
            if (response) {
                setLogoURL(response.data.logoURL);
            }
        })();
    }, []);

    const validator = useCallback(() => {
        if (isEmpty(userData?.first_name ? userData?.first_name : '')) {
            setUserValidation(userValidation => ({...userValidation, first_name: true}));
            return false;
        }
        if (isEmpty(userData?.last_name ? userData?.last_name : '')) {
            setUserValidation(userValidation => ({...userValidation, last_name: true}));
            return false;
        }
        if (!isMobilePhone(userData?.phone_number ? userData?.phone_number : '', ['en-US', 'en-CA'])) {
            setUserValidation(userValidation => ({...userValidation, phone_number: true}));
            return false;
        }
        if (!isEmail(userData?.email ? userData?.email : '')) {
            setUserValidation(userValidation => ({...userValidation, email: true}));
            return false;
        }
        if (isEmpty(userData?.company_information?.name ? userData?.company_information?.name : '')) {
            setUserValidation(userValidation => ({
                ...userValidation, 
                company_information: {
                    ...userValidation.company_information,
                    name: true,
                }
            }));
            return false;
        }
        if (!isEmail(userData?.company_information?.email ? userData?.company_information?.email : '')) {
            setUserValidation(userValidation => ({
                ...userValidation, 
                company_information: {
                    ...userValidation.company_information,
                    email: true,
                }
            }));
            return false;
        }
        if (!isMobilePhone(userData?.company_information?.phone ? userData?.company_information?.phone : '')) {
            setUserValidation(userValidation => ({
                ...userValidation, 
                company_information: {
                    ...userValidation.company_information,
                    phone: true,
                }
            }));
            return false;
        }
        if (isEmpty(userData?.location?.address_line_1 ? userData?.location?.address_line_1 : '')) {
            setUserValidation(userValidation => ({
                ...userValidation, 
                location: {
                    ...userValidation.location,
                    address_line_1: true,
                }
            }));
            return false;
        }
        if (isEmpty(userData?.location?.city ? userData?.location?.city : '')) {
            setUserValidation(userValidation => ({
                ...userValidation, 
                location: {
                    ...userValidation.location,
                    city: true,
                }
            }));
            return false;
        }
        if (isEmpty(userData?.location?.state ? userData?.location?.state : '')) {
            setUserValidation(userValidation => ({
                ...userValidation, 
                location: {
                    ...userValidation.location,
                    state: true,
                }
            }));
            return false;
        }
        if (isEmpty(userData?.location?.zip_code ? userData?.location?.zip_code : '')) {
            setUserValidation(userValidation => ({
                ...userValidation, 
                location: {
                    ...userValidation.location,
                    zip_code: true,
                }
            }));
            return false;
        }
        return ((isEmpty(oldPassword) && isEmpty(newPassword)) || passwordValidator());
    }, [newPassword, oldPassword, passwordValidator, userData]);

    // clear error status
    useEffect(() => {
        if (!isEmpty(userData?.first_name ? userData?.first_name : '')) {
            setUserValidation(userValidation => ({...userValidation, first_name: false}));
        }
        if (!isEmpty(userData?.last_name ? userData?.last_name : '')) {
            setUserValidation(userValidation => ({...userValidation, last_name: false}));
        }
        if (isMobilePhone(userData?.phone_number ? userData?.phone_number : '', ['en-US', 'en-CA'])) {
            setUserValidation(userValidation => ({...userValidation, phone_number: false}));
        }
        if (isEmail(userData?.email ? userData?.email : '')) {
            setUserValidation(userValidation => ({...userValidation, email: false}));
        }
        if (!isEmpty(userData?.company_information?.name ? userData?.company_information?.name : '')) {
            setUserValidation(userValidation => ({
                ...userValidation, 
                company_information: {
                    ...userValidation.company_information,
                    name: false,
                }
            }));
        }
        if (isEmail(userData?.company_information?.email ? userData?.company_information?.email : '')) {
            setUserValidation(userValidation => ({
                ...userValidation, 
                company_information: {
                    ...userValidation.company_information,
                    email: false,
                }
            }));
        }
        if (isMobilePhone(userData?.company_information?.phone ? userData?.company_information?.phone : '')) {
            setUserValidation(userValidation => ({
                ...userValidation, 
                company_information: {
                    ...userValidation.company_information,
                    phone: false,
                }
            }));
        }
        if (!isEmpty(userData?.location?.address_line_1 ? userData?.location?.address_line_1 : '')) {
            setUserValidation(userValidation => ({
                ...userValidation, 
                location: {
                    ...userValidation.location,
                    address_line_1: false,
                }
            }));
        }
        if (!isEmpty(userData?.location?.city ? userData?.location?.city: '')) {
            setUserValidation(userValidation => ({
                ...userValidation, 
                location: {
                    ...userValidation.location,
                    city: false,
                }
            }));
        }
        if (!isEmpty(userData?.location?.state ? userData?.location?.state : '')) {
            setUserValidation(userValidation => ({
                ...userValidation, 
                location: {
                    ...userValidation.location,
                    state: false,
                }
            }));
        }
        if (!isEmpty(userData?.location?.zip_code ? userData?.location?.zip_code : '')) {
            setUserValidation(userValidation => ({
                ...userValidation, 
                location: {
                    ...userValidation.location,
                    zip_code: false,
                }
            }));
        }
        if ((isEmpty(oldPassword) && isEmpty(newPassword)) || oldPassword !== newPassword) {
            setPasswordError(false);
        }
    }, [newPassword, oldPassword, userData]);

    const [saveButtonText, setSaveButtonText] = useState('Save Changes');
    const handleUserSubmit = useCallback(async () => {
        if (validator()) {
            setSaveButtonText('Saving...');
            const { first_name, last_name, phone_number, company_information, location } = userData;
            const responses = await Promise.all([
                updateUserPersonalInfo(first_name, last_name, phoneNumberReformatter(phone_number)),
                updateUserCompanyAndLocation(company_information, location),
                passwordSubmitter(),
            ]);
            console.log(responses);
            if (responses && responses[0] && responses[1] && responses[2]) {
                setSaveButtonText('Successfully Saved!');
            } else {
                setSaveButtonText('Failed to Save!');
            }
        }
    }, [passwordSubmitter, userData, validator]);

    return (
        <Box sx={{ display: 'flex', }}>
            <AppDrawer/>
            <Grid2 xs container p={4} gap={4}>

                <Grid2 container xs={12} direction={'column'} alignItems={'flex-start'}>
                    <Typography color={'primary'} variant="h3" fontWeight={'bold'}>
                        Account
                    </Typography>
                    <Typography color={'primary'} variant="body2">
                        Manage your account info and subscriptions
                    </Typography>
                </Grid2>

                <AccountSideBar />

                {!isTablet && <Grid2 xs={'auto'}>
                    <Divider orientation="vertical" variant="fullWidth" sx={{ bgcolor: 'black'}}/>
                </Grid2>}

                {/** Account Profile */}
                <Grid2 xs container rowGap={4}>

                    <Grid2 xs={12} container rowGap={2} justifyContent={'space-between'}>
                        <Grid2 xs={12} container>
                            <Typography variant='button' color={'primary'} fontWeight={'bold'}>Personal Information</Typography>
                        </Grid2>
                        <Grid2 lg={5.5} md={5.5} xs={12}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                color="primary"
                                type="text"
                                value={userData?.first_name}
                                error={userValidation?.first_name}
                                helperText={userValidation?.first_name ? "Please enter a first name" : ''}
                                label="First Name"
                                placeholder="Enter First Name"
                                onChange={event => setUserData({...userData, first_name: event.target.value})}
                            />
                        </Grid2>
                        <Grid2 lg={5.5} md={5.5} xs={12}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                color="primary"
                                type="text"
                                value={userData?.last_name}
                                error={userValidation?.last_name}
                                helperText={userValidation?.last_name ? "Please enter a last name" : ''}
                                label="Last Name"
                                placeholder="Enter Last Name"
                                onChange={event => setUserData({...userData, last_name: event.target.value})}
                            />
                        </Grid2>
                        <Grid2 lg={5.5} md={5.5} xs={12}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                color="primary"
                                type="text"
                                value={userData?.phone_number}
                                error={userValidation?.phone_number}
                                helperText={userValidation?.phone_number ? "Please enter a valid phone number" : ''}
                                label="Phone Number"
                                placeholder="Enter Phone Number"
                                onChange={event => setUserData({...userData, phone_number: event.target.value})}
                            />
                        </Grid2>
                        <Grid2 lg={5.5} md={5.5} xs={12}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                color="primary"
                                type="email"
                                value={userData?.email}
                                error={userValidation?.email}
                                helperText={userValidation?.email ? "Please enter a valid email address" : ''}
                                label="Email Address"
                                placeholder="Enter Email Address"
                                onChange={event => setUserData({...userData, email: event.target.value})}
                            />
                        </Grid2>
                        <Grid2 xs={12} pt={1}>
                            <Divider variant="fullWidth" sx={{ bgcolor: 'black'}}/>
                        </Grid2>
                    </Grid2>

                    <Grid2 xs={12} container rowGap={2} justifyContent={'space-between'}>
                        <Grid2 xs={12} container>
                            <Typography variant='button' color={'primary'} fontWeight={'bold'}>Business Information</Typography>
                        </Grid2>
                        <Grid2 xs={12} container gap={4}>
                            <Grid2 xs={'auto'}>
                                <img
                                    src={logoURL}
                                    width={250}
                                    alt=""
                                    loading="lazy"
                                />
                            </Grid2>
                            <Grid2 lg={12} md={12} xs={12}>
                                <input type="file" onChange={attachLogo}/>
                            </Grid2>
                        </Grid2>
                        <Grid2 xs={12} container>
                            <Grid2 lg={5.5} md={5.5} xs={12}>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    color="primary"
                                    type="text"
                                    value={userData?.company_information?.name}
                                    error={userValidation?.company_information?.name}
                                    helperText={userValidation?.company_information?.name ? "Please enter a business name" : ''}
                                    label="Business Name"
                                    placeholder="Enter Business Name"
                                    onChange={event => setUserData({
                                        ...userData,
                                        company_information: {
                                            ...userData.company_information,
                                            name: event.target.value,
                                        }
                                    })}
                                />
                            </Grid2>
                        </Grid2>
                        <Grid2 lg={5.5} md={5.5} xs={12}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                color="primary"
                                type="email"
                                value={userData?.company_information?.email}
                                error={userValidation?.company_information?.email}
                                helperText={userValidation?.company_information?.email ? "Please enter a valid email address" : ''}
                                label="Business Email Address"
                                placeholder="Enter Email Address"
                                onChange={event => setUserData({
                                    ...userData,
                                    company_information: {
                                        ...userData.company_information,
                                        email: event.target.value,
                                    }
                                })}
                            />
                        </Grid2>
                        <Grid2 lg={5.5} md={5.5} xs={12}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                color="primary"
                                type="text"
                                value={userData?.company_information?.phone}
                                error={userValidation?.company_information?.phone}
                                helperText={userValidation?.company_information?.phone ? "Please enter a valid phone number" : ''}
                                label="Business Phone Number"
                                placeholder="Enter Phone Number"
                                onChange={event => setUserData({
                                    ...userData,
                                    company_information: {
                                        ...userData.company_information,
                                        phone: event.target.value,
                                    }
                                })}
                            />
                        </Grid2>
                        <Grid2 xs={12}>
                            <AddressInput
                                value={userData?.location?.address_line_1}
                                setValue={(address, city, state, zip) => address ? setUserData({
                                    ...userData,
                                    location: {
                                        ...userData.location,
                                        address_line_1: address,
                                        city: city,
                                        state: state,
                                        zip_code: zip
                                    }
                                }) : setUserData({
                                    ...userData,
                                    location: {
                                        ...userData.location,
                                        address_line_1: address
                                    }
                                })}
                                label="Street Address"
                                placeholder="Enter Street Address"
                                error={userValidation?.location?.address_line_1}
                                helperText={userValidation?.location?.address_line_1 ? "Please enter a street address" : ''}
                            />
                        </Grid2>
                        <Grid2 xs={12} container justifyContent={'space-between'} rowGap={2}>
                            <Grid2 lg={3.5} md={3.5} xs={12}>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    color="primary"
                                    type="text"
                                    value={userData?.location?.city}
                                    error={userValidation?.location?.city}
                                    helperText={userValidation?.location?.city ? "Please enter a city" : ''}
                                    label="City"
                                    placeholder="Enter City"
                                    onChange={event => setUserData({
                                        ...userData,
                                        location: {
                                            ...userData.location,
                                            city: event.target.value,
                                        }
                                    })}
                                />
                            </Grid2>
                            <Grid2 lg={3.5} md={3.5} xs={12}>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    color="primary"
                                    type="text"
                                    value={userData?.location?.state}
                                    error={userValidation?.location?.state}
                                    helperText={userValidation?.location?.state ? "Please enter a state" : ''}
                                    label="State"
                                    placeholder="Enter State"
                                    onChange={event => setUserData({
                                        ...userData,
                                        location: {
                                            ...userData.location,
                                            state: event.target.value,
                                        }
                                    })}
                                />
                            </Grid2>
                            <Grid2 lg={3.5} md={3.5} xs={12}>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    color="primary"
                                    type="text"
                                    value={userData?.location?.zip_code}
                                    error={userValidation?.location?.zip_code}
                                    helperText={userValidation?.location?.zip_code ? "Please enter a zip code" : ''}
                                    label="Zip Code"
                                    placeholder="Enter Zip Code"
                                    onChange={event => setUserData({
                                        ...userData,
                                        location: {
                                            ...userData.location,
                                            zip_code: event.target.value,
                                        }
                                    })}
                                />
                            </Grid2>
                        </Grid2>
                        <Grid2 xs={12} pt={1}>
                            <Divider variant="fullWidth" sx={{ bgcolor: 'black'}}/>
                        </Grid2>
                    </Grid2>

                    <Grid2 xs={12} container rowGap={2} justifyContent={'space-between'}>
                        <Grid2 xs={12} container>
                            <Typography variant='button' color={'primary'} fontWeight={'bold'}>Password</Typography>
                        </Grid2>
                        <Grid2 lg={5.5} md={5.5} xs={12}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                color="primary"
                                type="text"
                                value={oldPassword}
                                error={passwordError}
                                helperText={passwordError ? "Passwords do not match" : ''}
                                label="Old Password"
                                placeholder="Enter Old Password"
                                onChange={event => setOldPassword(event.target.value)}
                            />
                        </Grid2>
                        <Grid2 lg={5.5} md={5.5} xs={12}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                color="primary"
                                type="text"
                                value={newPassword}
                                error={passwordError}
                                helperText={passwordError ? "Passwords do not match" : ''}
                                label="New Password"
                                placeholder="Enter New Password"
                                onChange={event => setNewPassword(event.target.value)}
                            />
                        </Grid2>
                    </Grid2>
                    
                    <Grid2 xs={12} container justifyContent={'space-between'}>
                        <Grid2 xs={12}>
                            <Button
                                variant="contained"
                                fullWidth
                                onClick={async () => await handleUserSubmit()}
                            >
                                {saveButtonText}
                            </Button>
                        </Grid2>
                    </Grid2>
                </Grid2>
            </Grid2>
        </Box>   
    );
};

export default Account;

import { Box, Button, InputAdornment, Modal, TextField, Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { useEffect, useState } from "react";
import isCurrency from "validator/lib/isCurrency";
import isEmail from "validator/lib/isEmail";
import { sendPaymentLink } from "../../api/payments";
import './PaymentLinkModal.css'
import ResultModal from "../util/ResultModal";


const PaymentLinkModal = ({open, method, setShowModal}) => {
    const [email, setEmail] = useState('');
    const isValidEmail = (isEmail(email));
    const [emailError, setEmailError] = useState(false);

    const [amount, setAmount] = useState('');
    const isValidAmount = (isCurrency(amount));
    const [amountError, setAmountError] = useState(false);

    const [loading, setLoading] = useState(false);

    const validator = () => {
        if (!isValidAmount) {
            setAmountError(true);
            return false;
        }
        if (!isValidEmail) {
            setEmailError(true);
            return false;
        }
        return true;
    }

    const handleButton = async () => {
        if (validator()) {
            setLoading(true);
            const success = await sendPaymentLink(amount, email, method);
            if (success) {
                setSuccess(true);
                setShowResult(true);
                setTimeout(() => handleClose(), 1500);
            } else {
                setSuccess(false);
                setShowResult(true);
            }
            setLoading(false);
        }
    };

    const [showResult, setShowResult] = useState(false);
    const [success, setSuccess] = useState(false);

    useEffect(() => {
        if (isValidAmount) setAmountError(false);
        if(isValidEmail) setEmailError(false);
    }, [isValidAmount, isValidEmail]);

    const handleClose = () => {
        setAmount('');
        setEmail('');
        setAmountError(false);
        setEmailError(false);
        setShowModal(false);
    };

    return (
        <Modal
            open={open}
            onClose={handleClose}
        >
            <Box className="modal-container">
                <Grid2 container gap={4}>
                    <Grid2 xs={12}>
                        <Typography variant="h5" color={'primary'} fontWeight={'bold'}>
                            {`Request ${(method === 'CARD') ? 'Card Payment' : 'ACH Bank Transfer'}`}
                        </Typography>
                    </Grid2>
                    <Grid2 xs={12}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            type="number"
                            value={amount}
                            onChange={event => {
                                setAmount(event.target.value);
                            }}
                            placeholder="Enter Amount"
                            label="Amount"
                            error={amountError}
                            helperText={amountError ? 'Please enter a valid amount in $USD' : ''}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">$</InputAdornment>
                            }}
                        />
                    </Grid2>
                    <Grid2 xs={12}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            type="email"
                            value={email}
                            onChange={event => {
                                setEmail(event.target.value);
                            }}
                            placeholder="Enter Email Address"
                            label="Email"
                            error={emailError}
                            helperText={emailError ? 'Please enter a valid email address' : ''}
                        />
                    </Grid2>
                    <Grid2 container xs={12} gap={3} justifyContent={'space-between'}>
                        {loading
                            ? <Typography>Loading ...</Typography>
                            : <>
                                <Button 
                                    variant="contained"
                                    sx={{width: '45%'}}
                                    onClick={async () => await handleButton()}
                                    >
                                    Request
                                </Button>
                                <Button 
                                    variant="outlined"
                                    sx={{width: '45%'}}
                                    onClick={handleClose}
                                >
                                    Cancel
                                </Button>
                            </>
                        }
                    </Grid2>
                </Grid2>
                <ResultModal 
                    success={success} 
                    setSuccess={setSuccess} 
                    showResult={showResult} 
                    setShowResult={setShowResult}
                    successText={'Payment link successfully sent'}
                    failText={'Payment link failed to send'}
                />
            </Box>
        </Modal>
    );
};

export default PaymentLinkModal;
import { List, ListItem, ListItemButton, ListItemText, } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import './AccountSideBar.css';
import { useCallback, useMemo, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import { AppContext } from '../../contexts/AppContext';

const AccountSideBar = () => {
    const { isTablet } = useContext(AppContext);
    const { signOut } = useAuth();
    const { pathname } = useLocation();
    const navigate = useNavigate();

    const navItems = useMemo(() => [
        {
            text: 'Account Profile', 
            route: 'profile', 
            selectedColor: 'black', 
            unselectedColor: 'grey', 
            selectedBackground: 'secondary.main',
            hoverBackground: 'secondary.light',
        }, 
        {
            text: 'Payment Collection Settings', 
            route: 'payment-settings', 
            selectedColor: 'black', 
            unselectedColor: 'grey', 
            selectedBackground: 'secondary.main',
            hoverBackground: 'secondary.light',
        },
        // {
        //     text: 'Sending Payment Settings', 
        //     route: 'sending-settings', 
        //     selectedColor: 'black', 
        //     unselectedColor: 'grey', 
        //     selectedBackground: 'secondary.main',
        //     hoverBackground: 'secondary.light',
        // },
        {
            text: 'Manage Markup', 
            route: 'manage-markup', 
            selectedColor: 'black', 
            unselectedColor: 'grey', 
            selectedBackground: 'secondary.main',
            hoverBackground: 'secondary.light',
        },
        {
            text: 'Contact Support', 
            route: 'contact-support', 
            selectedColor: 'black', 
            unselectedColor: 'grey', 
            selectedBackground: 'secondary.main',
            hoverBackground: 'secondary.light',
        },
        // {
        //     text: 'Manage Plan', 
        //     route: 'manage-plan', 
        //     selectedColor: 'black', 
        //     unselectedColor: 'grey', 
        //     selectedBackground: 'secondary.main',
        //     hoverBackground: 'secondary.light',
        // },
        {
            text: 'Account Deletion', 
            route: 'delete', 
            selectedColor: 'error.dark', 
            unselectedColor: 'error.main', 
            selectedBackground: 'error.main',
            hoverBackground: 'error.light',
        },
        {
            text: 'Sign Out', 
            route: 'sign-out', 
            selectedColor: 'black', 
            unselectedColor: 'grey', 
            selectedBackground: 'secondary.main',
            hoverBackground: 'secondary.light',
        },
    ], []);

    const handleListItemClick = useCallback(async (route) => {
        if (route === 'sign-out') await signOut();
        navigate(`/account/${route}`);
    }, [navigate, signOut]);

    return (
        <>
            {!isTablet && <Grid2 xs={'auto'} container rowGap={4}>
                <List>
                    {navItems.map((listItem, index) => (
                        <ListItem key={index}>
                            <ListItemButton 
                                onClick={() => handleListItemClick(listItem.route)}
                                sx={{
                                    borderRadius: '8px',
                                    bgcolor: pathname.includes(listItem.route) ? listItem.selectedBackground : undefined,
                                    ":hover": {
                                        bgcolor: listItem.hoverBackground,
                                    }
                                }}    
                            >
                                <ListItemText 
                                    primary={listItem.text}
                                    primaryTypographyProps={{
                                        fontWeight: pathname.includes(listItem.route) ? 'bold' : 'normal',
                                        color: pathname.includes(listItem.route) ? listItem.selectedColor : listItem.unselectedColor,
                                    }}
                                />
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
            </Grid2>}
        </>
    );
};

export default AccountSideBar;
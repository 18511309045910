import { useContext, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ReceiptIcon from '@mui/icons-material/Receipt';
import DescriptionIcon from '@mui/icons-material/Description';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import PaidIcon from '@mui/icons-material/Paid';
import PaymentsIcon from '@mui/icons-material/Payments';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { AppContext } from '../../contexts/AppContext';
import { useAuth } from "../../contexts/AuthContext";
import './appdrawer.css'
import QuickbooksLogo from '../icons/QuickbooksLogo';
import { ExpandLess, ExpandMore, Mail } from '@mui/icons-material';

const drawerWidth = 300;

export default function AppDrawer() {
  const { menuOpen, setMenuOpen, isMobile, isTablet } = useContext(AppContext);
  const [open, setOpen] = useState(false);
  const { signOut } = useAuth();

  const location = useLocation();
  
  const navigate = useNavigate();

  const handleListItemClick = (route) => {
    setMenuOpen(false);
    setOpen(false);
    navigate(route);
  };

  const navItems = [
    {text: 'Get Paid', route: '/payments', icon: <PaidIcon sx={{color: 'white'}}/>},
    // {text: 'Send Payment', route: '/send-payment', icon: <PaymentsIcon sx={{color: 'white'}}/>},
    {text: 'Estimates', route: '/estimates', icon:  <DescriptionIcon sx={{color: 'white'}}/>},
    {text: 'Invoices', route: '/invoices', icon:  <ReceiptIcon sx={{color: 'white'}}/>},
    {text: 'Customers', route: '/customers', icon:  <PeopleAltIcon sx={{color: 'white'}}/>},
    {text: 'Quickbooks', route: '/quickbooks', icon:  <QuickbooksLogo/>},
  ];

  const accoutSubItems = [
    {text: 'Account Profile', route: '/account/profile'},
    {text: 'Payment Collection Settings', route: '/account/payment-settings'},
    {text: 'Sending Payment Settings', route: '/account/sending-settings'},
    {text: 'Manage Markup', route: '/account/manage-markup'},
    {text: 'Contact Support', route: '/account/contact-support'},
    {text: 'Manage Plan', route: '/account/manage-plan'},
    {text: 'Account Deletion', route: '/account/delete'},
  ];

  const handleSubMenuClick = () => {
    setOpen(!open);
  };

  return (
    <>
      {(menuOpen || !isMobile) && <Drawer
        sx={{
          backgroundColor: 'primary.main',
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        PaperProps={{
          sx: {
            backgroundColor: 'primary.main'
          }
        }}
        variant="permanent"
        anchor="left"
      >
        <IconButton className="burger-button" aria-label="delete" onClick={() => setMenuOpen(false)} style={{display: 'none'}}>
          <MenuIcon />
        </IconButton>
        <Box display={'flex'} justifyContent={'center'} alignItems={'center'} padding={2} sx={{backgroundColor: 'primary.main'}}>
          <img
              alt=""
              src="/logo1x.png"
              className="tapper-logo"
          />
          <Typography fontSize={24} color={'white'} fontWeight={'bold'} >
              Tapper
          </Typography>
        </Box>
        <Divider id='drawer-divider'/>
        <List sx={{
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: 'primary.main',
            color: 'white',
            marginX: 4,
            gap: 2,
          }}
        >
          {navItems.map((listItem, index) => (
            <ListItem className={location.pathname.includes(listItem.route)  ? 'selected-item' : 'drawer-item'} key={index} disablePadding>
              <ListItemButton onClick={() => handleListItemClick(listItem.route)}>
                <ListItemIcon>
                  {listItem.icon}
                </ListItemIcon>
                <ListItemText primary={listItem.text} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <Divider id='drawer-divider'/>
        <List 
          sx={{
            display: 'flex',
            justifySelf: 'flex-end',
            backgroundColor: 'primary.main',
            color: 'white',
            marginX: 4,
          }}
        >
          {!isTablet && 
          <ListItem className={location.pathname.includes('account/') ? 'selected-item' : 'drawer-item'} key={"Account"} disablePadding> 
            <ListItemButton onClick={() => {handleListItemClick('/account/profile');}}>
              <ListItemIcon className='drawer-item-icon'>
                <AccountCircleIcon className='drawer-item' sx={{color: 'white'}}/>
              </ListItemIcon>
              <ListItemText primary={"Account"} />
              <ListItemIcon className='drawer-item-icon'>
                <NavigateNextIcon className='drawer-item' sx={{color: 'white'}}/>
              </ListItemIcon>
            </ListItemButton>
          </ListItem >}
          {isTablet && <List sx={{
            width: '100%'
          }}>
            <ListItem button onClick={handleSubMenuClick}>
              <ListItemIcon>
                <AccountCircleIcon className='drawer-item' sx={{color: 'white'}}/>
              </ListItemIcon>
              <ListItemText primary={"Account"} />
              {open ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {accoutSubItems.map((accountItem, index) => (
                  <ListItem 
                    button
                    key={index}
                    className={location.pathname.includes(accountItem.route) ? 'selected-item' : 'drawer-item'}
                    onClick={() => handleListItemClick(accountItem.route)}
                  >
                    <ListItemIcon>
                    </ListItemIcon>
                    <ListItemText primary={accountItem.text} />
                  </ListItem>
                ))}
                <ListItem button onClick={() => signOut()}>
                    <ListItemIcon>
                    </ListItemIcon>
                    <ListItemText primary={"Sign Out"} />
                  </ListItem>
              </List>
            </Collapse>
          </List>}
        </List>
      </Drawer>}
    </>
  );
}
import { React } from 'react';
import Navbar from 'react-bootstrap/Navbar';

import './navbar.css'

function TapperNavbar() {
  return (
    <>
      <Navbar className="tapper-navbar bg-body-tertiary" fixed='top'>
        <Navbar.Brand className="d-flex justify-content-center" href="https://www.usetapper.com">
          <img
            alt=""
            src="/logo1x.png"
            width="40"
            height="40"
            className="tapper-logo"
          />{' '}
          <p className='tapper-logo-text'>
            TAPPER
          </p>
        </Navbar.Brand>
      </Navbar>
    </>
  );
}

export default TapperNavbar;
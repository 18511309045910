import React, { useState, useEffect } from 'react';

import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { Box, Button, Typography } from '@mui/material';
import { getCheckList } from '../../api/payout';
import AppDrawer from '../../components/appdrawer/appdrawer';
import upgradeImg from "../../img/upgrade.svg";
import './sendPayment.css'
import { CreditCard, MonetizationOn } from '@mui/icons-material';
import SendPaymentTable from '../../components/sendPayment/SendPaymentTable';
import PayOthersModal from '../../components/sendPayment/PayOthersModal';
import SendPaymentModal from '../../components/sendPayment/SendPaymentModal';
import TopUpModal from '../../components/sendPayment/TopUpModal';
import PayOthersInitializeModal from '../../components/payoutRegistration/PayOthersInitializeModal';
import { getUser } from "../../api/user";
import { useAuth } from "../../contexts/AuthContext";
import { useNavigate } from 'react-router';
import { getAccountWallet } from "../../api/payout";

const SendPayment = () => {
    const navigate = useNavigate();
    const [checks, setChecks] = useState([]);
    const [search, setSearch] = useState('');
    const [totalCheckCount, setTotalCheckCount] = useState(0);
    const [isConnected, setConnected] = useState();
    const [showPayOthersModal, setShowPayOthersModal] = useState(false);
    const [showTopUpModal, setShowTopUpModal] = useState(false);
    const [showSendPaymentModal, setShowSendPaymentModal] = useState(false);
    const [showPayOthersInitializeModal, setShowPayOthersInitializeModal] = useState(false);
    const [userObj, setUserObj] = useState();
    const [balance, setBalance] = useState(0);
    const [prefillEmail, setPrefillEmail] = useState();
    const [paginationModel, setPaginationModel] = useState({
        pageSize: 10,
        page: 0,
    });
    const [paymentOption, setPaymentOption] = useState(true);

    useEffect(() => {
        getAuthUser();
        fetchUserData();
    }, []);

    useEffect(() => {
        if(userObj?.checkbook_user_id) {
            getBalance();
        }
    }, [userObj])

    async function getAuthUser() {
        const res = await getUser();
        if(res.data.checkbook_user_id) {
            setConnected(true);
        }
        else {
            setConnected(false);
        }
    }

    function getBalance() {
        getAccountWallet().then(res => {
            setBalance(res.data.wallets[0].balance);
        }).catch(error => alert(error.response.data.message));
    }

    function fetchUserData() {
        getUser().then((result) => {
            setUserObj(result.data);
        });
    }

    const fetchCheckList = async () => {
        let response;
        if (search === '') {
            response = await getCheckList(paginationModel.page, paginationModel.pageSize, paymentOption);
        } else {
            response = await getCheckList(paginationModel.page, paginationModel.pageSize, paymentOption, search);
        }

        if (response) {
            setTotalCheckCount(response.data.count);
            setChecks(response.data.result);
        } else {
            setTotalCheckCount(0);
            setChecks([]);
        }
    }

    return (
        <Box sx={{ display: 'flex', }}>
            <AppDrawer/>
            <PayOthersModal
                showModal={showPayOthersModal}
                setShowModal={setShowPayOthersModal}
            />
            {userObj?.checkbook_user_id && <TopUpModal
                showModal={showTopUpModal}
                setShowModal={setShowTopUpModal}
                balance={balance}
                fetchUserData={fetchUserData}
                getBalance={getBalance}
                fetchCheckList={fetchCheckList}
                account={userObj.checkbook_account}
                routing={userObj.checkbook_routing}
            />}
            {userObj?.checkbook_user_id && <SendPaymentModal
                showModal={showSendPaymentModal}
                setShowModal={setShowSendPaymentModal}
                balance={balance}
                fetchUserData={fetchUserData}
                getBalance={getBalance}
                fetchCheckList={fetchCheckList}
                prefillEmail={prefillEmail}
                setPrefillEmail={setPrefillEmail}
            />}
            {userObj?.checkbook_user_id && <PayOthersInitializeModal
                showModal={showPayOthersInitializeModal}
                setShowModal={setShowPayOthersInitializeModal}
            />}
            <Grid2 container padding={4} rowGap={8} width="100%">
                <Grid2 container xs={12} justifyContent={'space-between'} alignItems={'center'}>
                    <Grid2 container lg={6} md={12} xs={12} direction={'column'} alignItems={'flex-start'}>
                        <Typography color={'primary'} variant="h3" fontWeight={'bold'}>
                            Send Payment
                        </Typography>
                        <Typography color={'primary'} variant="body2">
                            Send payments instantly using just the recipients email
                        </Typography>
                    </Grid2>
                    {userObj && userObj.plantype === true && <Grid2 container lg={6} md={12} xs={12} justifyContent={'flex-end'} className="payment-button-group">
                        {isConnected === true ? <>
                            <Button
                                className="button payment-button"
                                variant="contained"
                                startIcon={<CreditCard />}
                                onClick={() => {
                                    if(userObj.payout_bank_account_verified) {
                                        setShowSendPaymentModal(true);
                                    } else {
                                        setShowPayOthersInitializeModal(true);
                                    }
                                }}
                            >
                                Send Payment
                            </Button>
                            <Button
                                className="button payment-button"
                                variant="contained"
                                startIcon={<MonetizationOn />}
                                onClick={() => {
                                    if(userObj.payout_bank_account_verified) {
                                        setShowTopUpModal(true);
                                    } else {
                                        setShowPayOthersInitializeModal(true);
                                    }
                                }}
                            >
                                Top Up Amount
                            </Button>
                        </> : 
                            <Button
                                className="button"
                                variant="contained"
                                startIcon={<CreditCard />}
                                onClick={() => setShowPayOthersModal(true)}
                            >
                                Pay Others
                            </Button>}
                    </Grid2>}
                </Grid2>
                {userObj && userObj.plantype === true && userObj.checkbook_user_id && <SendPaymentTable 
                    balance={balance}
                    checks={checks}
                    search={search}
                    setSearch={setSearch}
                    totalCheckCount={totalCheckCount}
                    fetchCheckList={fetchCheckList}
                    paginationModel={paginationModel}
                    setPaginationModel={setPaginationModel}
                    paymentOption={paymentOption}
                    setPaymentOption={setPaymentOption}
                    setPrefillEmail={setPrefillEmail}
                    setShowSendPaymentModal={setShowSendPaymentModal}
                />}
                {userObj && userObj.plantype === false && <Box width={"100%"}>
                    <Grid2 xs container direction={'column'}>
                        <Grid2 xs={12} container height={'min-content'} textAlign={'center'} display={'flex'} justifyContent={'center'} gap={4} p={4}>
                            <img src={upgradeImg} alt="" className="upgrade-img" />
                        </Grid2>
                        <Grid2>
                            <Typography color="primary" variant="h3" p={1}>Upgrade to Pro Tier</Typography>
                            <Typography variant="p" p={1}>Upgrade to our pro tier to sync information from your Tapper account into QuickBooks.</Typography>
                        </Grid2>
                        <Grid2>
                            <Button color="primary" variant="contained" className="upgrade-button" onClick={() => navigate('/account/manage-plan')}>Upgrade Now!</Button>
                        </Grid2>
                    </Grid2>
                </Box>}
            </Grid2>
        </Box>
    );
};

export default SendPayment;

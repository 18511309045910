import { Add, Close } from "@mui/icons-material";
import { Box, Button, Checkbox, Divider, IconButton, InputAdornment, Modal, TextField, Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { USDollar } from "../../constants/theme";

const JobDetailModal = ({item, index, invoiceData, setInvoiceData, invoiceValidation, showModal, setShowModal}) => {

    const handleClose = () => {
        setShowModal(false);
    }

    return (
        <Modal
            open={showModal}
            onClose={handleClose}
        >
            <Box className="modal-container">
                <Grid2 container gap={3}>
                    <Grid2 xs={12} container>
                        <Typography variant="h5" fontWeight={'bold'}>
                            Job #{index+1} Details
                        </Typography>
                    </Grid2>
                    {/**Title & Price */}
                    <Grid2 xs={12}>
                        <Typography fontWeight={'bold'}>
                            Basic Information
                        </Typography>
                    </Grid2>
                    <Grid2 xs={12} container>
                        <TextField 
                            variant="outlined"
                            fullWidth
                            color="primary"
                            type="text"
                            value={item?.job}
                            error={invoiceValidation?.job_items[index]?.job}
                            helperText={invoiceValidation?.job_items[index]?.job ? 'Please enter a title for the Job Item' : ''}
                            label={`Job Title`}
                            placeholder="Enter Job Title"
                            onChange={event => setInvoiceData({
                                ...invoiceData, 
                                job_items: invoiceData?.job_items?.map((it, idx) => 
                                (idx === index 
                                    ? {...it, job: event.target.value} 
                                    : it 
                                ))
                            })}
                        />
                    </Grid2>
                    {/** Additional Details Array*/}
                    <Grid2 xs={12} container rowGap={1}>
                        <Grid2 xs={12}>
                            <Typography fontWeight={'bold'}>
                                Additional Detail
                            </Typography>
                        </Grid2>
                        {item?.details?.map((it, idx) => (
                            <Grid2 key={idx} xs={12} container justifyContent={'space-between'}>
                                <Grid2 xs={8}>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        color="primary"
                                        type="text"
                                        value={it?.item}
                                        label={`Detail #${idx+1}`}
                                        placeholder="Enter Detail Title"
                                        onChange={event => setInvoiceData({
                                            ...invoiceData,
                                            job_items: invoiceData?.job_items?.map((it, id) =>
                                            (id === index
                                                ? {
                                                    ...it,
                                                    details: it?.details.map((i, ix) =>
                                                    (ix === idx
                                                        ? {...i, item: event.target.value}
                                                        : i
                                                    ))
                                                }
                                                : it
                                            ))
                                        })}
                                    />
                                </Grid2>
                                <Grid2 xs={2}>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        color="primary"
                                        type="number"
                                        value={it?.quantity}
                                        label={`Quantity`}
                                        placeholder="Enter Quantity"
                                        onChange={event => setInvoiceData({
                                            ...invoiceData,
                                            job_items: invoiceData?.job_items?.map((it, id) =>
                                            (id === index
                                                ? {
                                                    ...it,
                                                    details: it?.details.map((i, ix) =>
                                                    (ix === idx
                                                        ? {...i, quantity: Number(event.target.value)}
                                                        : i
                                                    ))
                                                }
                                                : it
                                            ))
                                        })}
                                    />
                                </Grid2>
                                <Grid2 xs={1}>
                                    <IconButton
                                        onClick={() => setInvoiceData({
                                            ...invoiceData, 
                                            job_items: invoiceData?.job_items?.map((it, id) => 
                                            (id === index
                                                ? {
                                                    ...it,
                                                    details: it?.details?.filter((i, ix) => ix !== idx)
                                                }
                                                : it
                                            ))
                                        })}
                                    >
                                        <Close/>
                                    </IconButton>
                                </Grid2>
                            </Grid2>
                        ))}
                        <Grid2>
                            <Button
                                startIcon={<Add/>}
                                onClick={() => setInvoiceData({
                                    ...invoiceData, 
                                    job_items: invoiceData?.job_items?.map((it, idx) =>
                                    (idx === index
                                        ? {
                                            ...it,
                                            details: [
                                                ...it?.details,
                                                { item: '', quantity: 1 }
                                            ]
                                        }
                                        : it
                                    ))
                                })}
                            >
                                Add Detail
                            </Button>
                        </Grid2>
                    </Grid2>
                    {/** Expenses */}
                    <Grid2 xs={12} container justifyContent={'space-between'} rowGap={2}>
                        <Grid2 xs={12}>
                            <Typography fontWeight={'bold'}>
                                Expenses
                            </Typography>
                        </Grid2>
                        <Grid2 lg={5.5} md={5.5} xs={12} container>
                            <Grid2 xs={12}>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    color="primary"
                                    type="number"
                                    value={item?.merchandise_cost}
                                    label="Material Cost"
                                    placeholder="Enter Amount"
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">$</InputAdornment>
                                    }}
                                    onChange={event => setInvoiceData({
                                        ...invoiceData,
                                        job_items: invoiceData?.job_items?.map((it, idx) =>
                                        (idx === index
                                            ? {...it, merchandise_cost: Number(event.target.value)}
                                            : it
                                        ))
                                    })}
                                />
                            </Grid2>
                            <Grid2 xs={12} container justifyContent={'flex-end'}>
                                <Typography color={'secondary.dark'}>
                                    Markup Amount: {USDollar.format(item?.merchandise_cost_markup())}
                                </Typography>
                            </Grid2>
                            <Grid2 xs={12} container alignItems={'center'}>
                                <Checkbox 
                                    checked={item?.add_merchandise_cost_markup}
                                    onChange={event => setInvoiceData({
                                        ...invoiceData,
                                        job_items: invoiceData?.job_items?.map((it, idx) =>
                                        (idx === index
                                            ? {...it, add_merchandise_cost_markup: event.target.checked}
                                            : it
                                        ))
                                    })}
                                />
                                <Typography variant="caption">
                                    Add Markup
                                </Typography>
                            </Grid2>
                        </Grid2>
                        <Grid2 lg={5.5} md={5.5} xs={12} container>
                            <Grid2 xs={12}>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    color="primary"
                                    type="number"
                                    value={item?.labor_cost}
                                    label="Labor Cost"
                                    placeholder="Enter Amount"
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">$</InputAdornment>
                                    }}
                                    onChange={event => setInvoiceData({
                                        ...invoiceData,
                                        job_items: invoiceData?.job_items?.map((it, idx) =>
                                        (idx === index
                                            ? {...it, labor_cost: Number(event.target.value)}
                                            : it
                                        ))
                                    })}
                                />
                            </Grid2>
                            <Grid2 xs={12} container justifyContent={'flex-end'}>
                                <Typography color={'secondary.dark'}>
                                    Markup Amount: {USDollar.format(item?.labor_cost_markup())}
                                </Typography>
                            </Grid2>
                            <Grid2 xs={12} container alignItems={'center'}>
                                <Checkbox 
                                    checked={item?.add_labor_cost_markup}
                                    onChange={event => setInvoiceData({
                                        ...invoiceData,
                                        job_items: invoiceData?.job_items?.map((it, idx) =>
                                        (idx === index
                                            ? {...it, add_labor_cost_markup: event.target.checked}
                                            : it
                                        ))
                                    })}
                                />
                                <Typography variant="caption">
                                    Add Markup
                                </Typography>
                            </Grid2>
                        </Grid2>
                    </Grid2>
                    {/** Price + Mark-up price */}
                    <Grid2 xs={12} container rowGap={1} justifyContent={'space-between'}>
                        <Grid2 lg={8} md={8} xs={12} container alignItems={'flex-end'}>
                            <Typography color={'gray'}> 
                                Base Job Price (without mark-ups)
                            </Typography>
                        </Grid2>
                        <Grid2 lg={4} md={4} xs={12} container justifyContent={'flex-end'}>
                            <TextField 
                                variant="outlined"
                                fullWidth
                                color="primary"
                                type="number"
                                value={item?.amount}
                                error={invoiceValidation?.job_items[index]?.amount}
                                helperText={invoiceValidation?.job_items[index]?.amount ? 'Please enter a valid amount in $USD' : ''}
                                label="Base Amount"
                                placeholder="Enter Amount"
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">$</InputAdornment>
                                }}
                                onChange={event => setInvoiceData({
                                    ...invoiceData, 
                                    job_items: invoiceData?.job_items?.map((it, idx) => 
                                    (idx === index 
                                        ? {...it, amount: Number(event.target.value)} 
                                        : it 
                                    ))
                                })}
                            />
                        </Grid2>
                        <Grid2 xs={12}>
                            <Divider sx={{ bgcolor: 'gray',}}/>
                        </Grid2>
                        <Grid2 lg={8} md={8} xs={12} container alignItems={'flex-end'}>
                            <Typography color={'primary'}> 
                                Final Job Price (including mark-ups)
                            </Typography>
                        </Grid2>
                        <Grid2 lg={4} md={4} xs={12} container justifyContent={'flex-end'}>
                            <Typography variant="h5" color={'primary'} fontWeight={'bold'}>
                                {USDollar.format(item?.total_amount_with_markup())}
                            </Typography>
                        </Grid2>
                    </Grid2>
                    <Grid2 xs={12}>
                        <Button fullWidth variant="contained" onClick={handleClose}>
                            Done
                        </Button>
                    </Grid2>
                </Grid2>
            </Box>
        </Modal>
    );
};

export default JobDetailModal;
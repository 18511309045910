
export default function CardIcon() {
    return (
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Card">
        <g id="Group 221">
        <g id="Group 212">
        <path id="Vector 6" d="M4.68103 18.0999L16.1791 16.5029C17.2965 16.3477 18.2323 15.5788 18.6014 14.5128L21.375 6.5L21.75 6.875V17.75L19.875 19.25H4.84428L2.625 18.875L4.3303 18.1929C4.44302 18.1478 4.56079 18.1166 4.68103 18.0999Z" fill="white" fillOpacity="0.3"/>
        <path id="Rectangle 158" d="M2.25 7.25C2.25 6.42157 2.92157 5.75 3.75 5.75H20.25C21.0784 5.75 21.75 6.42157 21.75 7.25V17.8306C21.75 18.6591 21.0784 19.3306 20.25 19.3306H3.75C2.92157 19.3306 2.25 18.6591 2.25 17.8306V7.25Z" stroke="white" strokeWidth="0.768182"/>
        <path id="Line 113" d="M7.96875 11H4.78125C4.62592 11 4.5 11.1259 4.5 11.2812V13.5312C4.5 13.6866 4.62592 13.8125 4.78125 13.8125H7.96875C8.12408 13.8125 8.25 13.6866 8.25 13.5312V11.2813C8.25 11.1259 8.12408 11 7.96875 11Z" fill="white"/>
        <path id="Line 114" d="M4.5 15.875H6.75" stroke="white" strokeWidth="0.975" strokeLinecap="round"/>
        <path id="Line 115" d="M8.72266 15.875H10.9727" stroke="white" strokeWidth="0.975" strokeLinecap="round"/>
        <path id="Line 116" d="M12.9453 15.875H15.1953" stroke="white" strokeWidth="0.975" strokeLinecap="round"/>
        <path id="Line 117" d="M17.25 15.875H19.5" stroke="white" strokeWidth="0.975" strokeLinecap="round"/>
        </g>
        <path id="Line 118" d="M3 9.125L21 9.125" stroke="white" strokeWidth="0.975" strokeLinecap="square"/>
        </g>
        </g>
        </svg>
    );
}
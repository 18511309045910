import { MoreHoriz } from "@mui/icons-material";
import { IconButton, Menu, MenuItem } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { deleteEstimateOrInvoice, sendEstimateOrInvoiceReminder } from "../../api/estimates";

const SendPaymentMenuButton = ({payInfo, setPrefillEmail, setShowSendPaymentModal, setRefreshTable}) => {
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    

    const handlePayAgain = () => {
        setShowSendPaymentModal(true);
        setPrefillEmail(payInfo.name);
        handleClose();
    }
 
    return (
        <Grid2 container>
            <IconButton
                onClick={handleClick}
            >
                <MoreHoriz/>
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                <MenuItem onClick={() => handlePayAgain()}>Pay Again</MenuItem>
            </Menu>
        </Grid2>
    );
};

export default SendPaymentMenuButton;
import React from 'react';

function QuickBooksAuthSuccess() {
  return (
    <div>
        <p>Success!</p>
    </div>
  );
}

export default QuickBooksAuthSuccess;

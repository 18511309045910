import { Box, Button, Modal, TextField, Typography, RadioGroup, Radio, FormControlLabel, InputAdornment, Input } from "@mui/material";
import { useCallback, useRef } from "react";
import './TopUpModal.css';
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { useState } from "react";
import isEmpty from "validator/lib/isEmpty";
import isEmail from "validator/lib/isEmail";
import isMobilePhone from "validator/lib/isMobilePhone";
import isPostalCode from "validator/lib/isPostalCode";
import { useEffect } from "react";
import { addCustomer, editCustomer } from "../../api/contacts";
import { phoneNumberReformatter } from "../../misc/utilityFunctions";
import CloseIcon from '@mui/icons-material/Close';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { useNavigate } from 'react-router-dom';
import bankImg from "../../img/bank.png";
import successImg from "../../img/success.png";
import { getUser } from '../../api/user';
import { setPinCode, getBankAccounts, createDirectCheck } from '../../api/payout';
import { useAuth } from "../../contexts/AuthContext";

const TopUpModal = ({ showModal, setShowModal, balance, fetchUserData, getBalance, fetchCheckList, account, routing }) => {
    const navigate = useNavigate();
    const [step, setStep] = useState(0);
    const [isLoading, setLoading] = useState(true);
    const [hasPin, setHasPin] = useState(false);
    const [bankData, setBankData] = useState();
    const [pin1, setPin1] = useState();
    const [pin2, setPin2] = useState();
    const [pin3, setPin3] = useState();
    const [pin4, setPin4] = useState();
    const [topUpAmount, setTopUpAmount] = useState(0);
    const { user } = useAuth();
    const elementRef = useRef(null);

    useEffect(() => {
        getBankInfo();
    }, [])

    useEffect(() => {
        if(showModal === true) {
            setLoading(true);
            setHasPin(false);
            setPin1();
            setPin2();
            setPin3();
            setPin4();
            const res = getUser().then(res => {
                if(res.data.pin) {
                    setHasPin(true);
                }
                setLoading(false);
            }).catch(error => setLoading(false));
        }
    }, [showModal])

    function getBankInfo() {
        getBankAccounts()
            .then(res => setBankData(res.data.banks[0]))
            .catch(error => alert(error.response.data.message));
    }
    
    const nextStep = () => {
        if(step < 1) {
            setStep(step + 1);
        }
        else {
            setShowModal(false);
            setStep(0);
        }
    }

    const prevStep = () => {
        if(step > 0) {
            setStep(step - 1);
        }
        else {
            setShowModal(false);
        }
    }

    const handleSetPinCode = () => {
        setPinCode(pin1 + pin2 + pin3 + pin4).then((result) => {
            nextStep();
        }).catch((error) => {
            alert(error.response.data.message);
        });
    }

    const handleTopUp = () => {
        createDirectCheck(bankData.type, account, routing, topUpAmount)
            .then(res => {
                nextStep();
                fetchUserData();
                getBalance();
                fetchCheckList();
            })
            .catch(error => alert(error.response.data.message));
    }

    return (
        <Modal
            open={showModal}
            onClose={setShowModal}
        >
            <Box className="big-modal-container" ref={elementRef}>
                <Grid2 container gap={4} flexGrow={1} justifyContent={'space-between'}>
                    <Grid2 xs={12} justifyContent={'space-between'} display={'flex'}>
                        <Box>
                            {step !== 1 &&
                            <Typography variant="h5" color={'primary'} fontWeight={'bold'}>
                                Top Up Account
                            </Typography>}
                        </Box>
                        <CloseIcon className="close-button" onClick={() => setShowModal(false)} />
                    </Grid2>
                    {!isLoading && step === 0 && hasPin !== true && <>
                        <Grid2 xs={12} container justifyContent={"center"} display={"block"} textAlign={"center"}>
                            <Typography variant="h5" color={'primary'} fontWeight={'bold'}>
                                Set Up Your Payment Passcode
                            </Typography>
                            <Typography variant="p" color={'primary'}>
                                (The recipients needs this PIN to access the funds you send them)
                            </Typography>
                        </Grid2>
                        <Grid2 xs={12} container justifyContent={"center"} maxWidth={300} margin={"auto"}>
                            <Grid2 container xs={3} className="text-container">
                                <TextField
                                    fullWidth
                                    color="primary"
                                    type="text"
                                    variant="standard"
                                    inputProps={{
                                        style: {fontSize: 30, textAlign: 'center'},
                                        maxLength: 1,
                                    }}
                                    value={pin1}
                                    onChange={(e) => {
                                        if((e.target.value <= '9' && e.target.value >= '0') || e.target.value === '')
                                            setPin1(e.target.value);
                                    }}
                                />
                            </Grid2>
                            <Grid2 container xs={3} className="text-container">
                                <TextField
                                    fullWidth
                                    color="primary"
                                    type="text"
                                    variant="standard"
                                    inputProps={{
                                        style: {fontSize: 30, textAlign: 'center'},
                                        maxLength: 1,
                                    }}
                                    value={pin2}
                                    onChange={(e) => {
                                        if((e.target.value <= '9' && e.target.value >= '0') || e.target.value === '')
                                            setPin2(e.target.value);
                                    }}
                                />
                            </Grid2>
                            <Grid2 container xs={3} className="text-container">
                                <TextField
                                    fullWidth
                                    color="primary"
                                    type="text"
                                    variant="standard"
                                    inputProps={{
                                        style: {fontSize: 30, textAlign: 'center'},
                                        maxLength: 1,
                                    }}
                                    value={pin3}
                                    onChange={(e) => {
                                        if((e.target.value <= '9' && e.target.value >= '0') || e.target.value === '')
                                            setPin3(e.target.value);
                                    }}
                                />
                            </Grid2>
                            <Grid2 container xs={3} className="text-container">
                                <TextField
                                    fullWidth
                                    color="primary"
                                    type="text"
                                    variant="standard"
                                    inputProps={{
                                        style: {fontSize: 30, textAlign: 'center'},
                                        maxLength: 1,
                                    }}
                                    value={pin4}
                                    onChange={(e) => {
                                        if((e.target.value <= '9' && e.target.value >= '0') || e.target.value === '')
                                            setPin4(e.target.value);
                                    }}
                                />
                            </Grid2>
                        </Grid2>
                        <Grid2 xs={12} container>
                            <Typography variant="p" color={'primary'}>
                                * You can later update the passcode in your account settings.
                            </Typography>
                        </Grid2>
                        <Grid2 xs={12} container justifyContent={"flex-end"}>
                            <Button
                                fullWidth
                                variant="contained"
                                onClick={() => handleSetPinCode()}
                                disabled={!pin1 || !pin2 || !pin3 || !pin4}
                            >
                                Confirm
                            </Button>
                        </Grid2>
                    </>}
                    {!isLoading && step === 1 && hasPin !== true && <>
                        <Grid2 xs={12} container justifyContent={"center"}>
                            <img src={successImg} alt=""/>
                        </Grid2>
                        <Grid2 xs={12} container justifyContent={"center"}>
                            <Typography variant="h6" color={'primary'} fontWeight={'bold'}>
                                Congratulation
                            </Typography>
                        </Grid2>
                        <Grid2 xs={12} container justifyContent={"center"}>
                            <Typography variant="p" color={'primary'}>
                                You passcode has been successfully connected.
                            </Typography>
                        </Grid2>
                        <Grid2 xs={12} container justifyContent={"flex-end"}>
                            <Button
                                fullWidth
                                variant="contained"
                                onClick={() => {setHasPin(true); prevStep(); }}
                            >
                                Start Your First Payment
                            </Button>
                            <Button
                                fullWidth
                                className="bottom-btn"
                                variant="outlined"
                                onClick={() => {setHasPin(true); nextStep();}}
                            >
                                Back to Home Page
                            </Button>
                        </Grid2>
                    </>}
                    {!isLoading && step === 0 && hasPin === true && <>
                        <Grid2 xs={12} container justifyContent={"center"}>
                            <Grid2 container xs={12} className="text-container">
                                <Input
                                    color="primary"
                                    type="number"
                                    variant="standard"
                                    id="standard-adornment-amount"
                                    value={topUpAmount}
                                    onChange={(e) => setTopUpAmount(e.target.value)}
                                    startAdornment={<InputAdornment position="start"><span style={{fontSize: 30}}>$</span></InputAdornment>}
                                    inputProps={{style: {fontSize: 30}}} 
                                />
                                <Typography variant="p" color={'primary'}>*Funds will be available for use in 4 business days</Typography>
                            </Grid2>
                        </Grid2>
                        <Grid2 xs={12} container justifyContent={"flex-end"}>
                            <Button
                                fullWidth
                                variant="contained"
                                onClick={() => handleTopUp()}
                            >
                                Confirm
                            </Button>
                            <Typography className="bottom-btn" variant="p" color={'primary'}>*By Topping Up you authorize Tapper to initiate ACH Debit from your connected bank account(s) for the entered amount. All decisions are final and without refunds.</Typography>
                        </Grid2>
                    </>}
                    {!isLoading && step === 1 && hasPin === true && <>
                        <Grid2 xs={12} container justifyContent={"center"}>
                            <img src={successImg} alt=""/>
                        </Grid2>
                        <Grid2 xs={12} container textAlign={"center"} display={"block"}>
                            <Typography variant="h6" color={'primary'} fontWeight={'bold'}>
                                Congratulation
                            </Typography>
                            <Typography variant="p" color={'primary'}>
                                Your funds are on the way.
                            </Typography>
                        </Grid2>
                        <Grid2 xs={12} container display={"block"}>
                            <Box display={"flex"} justifyContent={'space-between'} className="grey-back top-radius">
                                <Typography variant="h6">Pre-Funding Balance</Typography>
                                <Typography variant="h6">${balance}</Typography>
                            </Box>
                            <Box display={"flex"} justifyContent={'space-between'} className="grey-back">
                                <Typography variant="h6">Funds in Transit</Typography>
                                <Typography variant="h6">${topUpAmount}</Typography>
                            </Box>
                            <Box display={"flex"} justifyContent={'space-between'} className="green-back bottom-radius">
                                <Typography variant="h6" color={'primary'} fontWeight={'bold'}>Post-Funding Balance</Typography>
                                <Typography variant="h6" color={'primary'} fontWeight={'bold'}>${parseFloat(balance) + parseFloat(topUpAmount)}</Typography>
                            </Box>
                        </Grid2>
                        <Grid2 xs={12} container justifyContent={"flex-end"}>
                            <Button
                                fullWidth
                                variant="contained"
                                onClick={() => {nextStep(); setTopUpAmount();}}
                            >
                                Start Your Another Payment
                            </Button>
                            <Button
                                fullWidth
                                className="bottom-btn"
                                variant="outlined"
                                onClick={() => {nextStep(); setTopUpAmount();}}
                            >
                                Back to Home Page
                            </Button>
                        </Grid2>
                    </>}
                </Grid2>
            </Box>
        </Modal>
    );
};

export default TopUpModal;
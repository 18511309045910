import { useCallback, useState } from "react";

export function useMultiStepForm(pages) {
    const [pageIndex, setPageIndex] = useState(0);

    const next = useCallback(() => {
        setPageIndex(i => {
            if (i >= pages.length - 1) return i;
            return i + 1;
        })
    }, [pages.length]);

    const back = useCallback(() => {
        setPageIndex(i => {
            if (i <= 0) return i;
            return i - 1;
        })
    }, []);

    const goTo = useCallback((index) => {
        setPageIndex(index);
    }, []);
    

    return {
        pageIndex,
        page: pages[pageIndex],
        pages,
        goTo,
        next,
        back,
        isFirstPage: pageIndex === 0,
        isLastPage: pageIndex === pages.length - 1,
    };
}
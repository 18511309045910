import { Search } from "@mui/icons-material";
import { InputAdornment, TextField, Button, Box, Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { useState } from "react";
import { USDollar } from "../../constants/theme";
import { DataGrid } from "@mui/x-data-grid";
import SendPaymentMenuButton from "./SendPaymentMenuButton";
import { useEffect } from "react";
import SendPaymentStatusTag from "./SendPaymentStatusTag";
import { getBankAccounts } from "../../api/payout";
import './SendPaymentTable.css';

const SendPaymentTable = ({
        balance,
        checks,
        search,
        setSearch,
        totalCheckCount,
        fetchCheckList,
        paginationModel,
        setPaginationModel,
        paymentOption,
        setPaymentOption,
        setPrefillEmail,
        setShowSendPaymentModal
    }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [refreshTable, setRefreshTable] = useState(false); // value irrelevant, used to trigger table re-renders
    const [bankAccount, setBankAccount] = useState();

    useEffect(() => {
        getBankAccounts()
            .then(res => setBankAccount(res?.data?.banks[0]?.id))
            .catch(error => alert("Error occurred while fetching bank account. " + error.response.data.message));
    }, [])
    
    const payoutCols = [
        {
            field: 'client_name', 
            headerName: 'Vendor Name', 
            flex: 35,
            valueGetter: ({row}) => (row.recipient)
        },
        {
            field: 'total_amount', 
            headerName: 'Amount', 
            flex: 20, 
            valueGetter: ({row}) => (USDollar.format(row.amount))
        },
        {
            field: 'created_at', 
            headerName: 'Date', 
            flex: 20, 
            valueGetter: ({row}) => (new Date(row.date).toLocaleDateString('en-US', {
                month: 'short', year:'numeric', day:'numeric'
            }))
        },
        {
            field: 'status',
            headerName: 'Status',
            flex: 10,
            renderCell: ({row}) => (<SendPaymentStatusTag key={row.index} status={row.status}/>)
        },
        {
            field: 'action', 
            headerName: 'Action', 
            flex: 10, 
            renderCell: ({row}) => (
                <SendPaymentMenuButton 
                    key={row.index}
                    payInfo={row}
                    setPrefillEmail={setPrefillEmail}
                    setShowSendPaymentModal={setShowSendPaymentModal}
                    setRefreshTable={setRefreshTable}
                />
            )
        }
    ];

    const topupCols = [
        {
            field: 'client_name', 
            headerName: '', 
            flex: 35,
            valueGetter: ({row}) => ('Top Up')
        },
        {
            field: 'total_amount', 
            headerName: 'Amount', 
            flex: 20, 
            valueGetter: ({row}) => (USDollar.format(row.amount))
        },
        {
            field: 'created_at', 
            headerName: 'Date', 
            flex: 20, 
            valueGetter: ({row}) => (new Date(row.date).toLocaleDateString('en-US', {
                month: 'short', year:'numeric', day:'numeric'
            }))
        },
        {
            field: 'status',
            headerName: 'Status',
            flex: 10,
            renderCell: ({row}) => (<SendPaymentStatusTag key={row.index} status={row.status}/>)
        }
    ];

    useEffect(() => {
        (async () => {
            // [TODO] Update this for totalCheckCount when API gets updated
            setIsLoading(true);
            await fetchCheckList();
            setIsLoading(false);
        })();
    }, [paginationModel, search, refreshTable, paymentOption]);

    return (
        <Grid2 container xs={12} rowGap={3}>
            <Grid2 container xs={12} className="balance-container">
                <Typography variant="p">Current balance</Typography>
                <Typography variant="h3">${balance}</Typography>
            </Grid2>
            <Grid2 container xs={12} justifyContent={'flex-start'}>
                <Grid2 container lg={6} md={12} sm={12} xs={12} className="switch-button-group">
                    <Button
                        className="tab-left switch-button"
                        variant={paymentOption ? "contained" : "outlined"}
                        onClick={() => setPaymentOption(true)}
                    >
                        Payout
                    </Button>
                    <Button
                        className="tab-right switch-button"
                        variant={paymentOption ? "outlined" : "contained"}
                        onClick={() => setPaymentOption(false)}
                    >
                        Top Up
                    </Button>
                </Grid2>
                <Grid2 container lg={6} md={12} sm={12} xs={12} justifyContent={'flex-end'}>
                    <TextField
                        variant="outlined"
                        color="secondary"
                        type="text"
                        value={search}
                        label="Search"
                        placeholder="Search Payment"
                        onChange={event => setSearch(event.target.value)}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Search color="secondary"/>
                                </InputAdornment>
                            ),
                        }}
                    />
                </Grid2>
            </Grid2>
            <Grid2 xs={12} className="data-table-wrapper">
                {
                    paymentOption ? 
                        <DataGrid
                            className="data-table"
                            rows={checks}
                            rowCount={totalCheckCount}
                            columns={payoutCols}
                            pageSizeOptions={[5, 10, 20]}
                            loading={isLoading}
                            paginationMode={"server"}
                            paginationModel={paginationModel}
                            onPaginationModelChange={setPaginationModel}
                            disableRowSelectionOnClick
                        /> 
                    :
                        <DataGrid
                            className="data-table"
                            rows={checks}
                            rowCount={totalCheckCount}
                            columns={topupCols}
                            pageSizeOptions={[5, 10, 20]}
                            loading={isLoading}
                            paginationMode={"server"}
                            paginationModel={paginationModel}
                            onPaginationModelChange={setPaginationModel}
                            disableRowSelectionOnClick
                        />
                }
            </Grid2>
        </Grid2>
    );
};

export default SendPaymentTable;
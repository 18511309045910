import React, { useState } from 'react';
import { Button, Typography, RadioGroup, Radio, FormControlLabel } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { addBankAccounts } from "../../api/payout";

const ConfirmAddAccount = ({nextStep}) => {
    const [isBusiness, setIsBusiness] = useState(true);

    const handleConfirm = () => {
        if (isBusiness) {
            addBankAccounts()
                .then((res) => nextStep())
                .catch(error => alert(error.response.data.message));
        } else {
            nextStep();
        }
    }

    return (
        <>
            <Grid2 xs={12} container display={"block"}>
                <Typography variant="p" color={'primary'} fontWeight={'bold'}>
                    <b>Is This a Business Bank Account?</b><br/>
                </Typography>
                <Typography variant="p" color={'primary'}>
                    *Tapper Currently Only Supports Business Bank Accounts.
                </Typography>
            </Grid2>
            <Grid2 xs={12} container>
                <RadioGroup
                    row
                    name="row-radio-group"
                    value={isBusiness}
                    onChange={(e) => setIsBusiness(e.target.value)}
                >
                    <FormControlLabel value={true} control={<Radio />} label="Yes" />
                    <FormControlLabel value={false} control={<Radio />} label="No" />
                </RadioGroup>
            </Grid2>
            <Grid2 xs={12} container justifyContent={"flex-end"}>
                <Button
                    fullWidth
                    variant="contained"
                    onClick={() => handleConfirm()}
                >
                    Confirm
                </Button>
            </Grid2>
        </>
    )
}

export default ConfirmAddAccount;
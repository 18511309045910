import { useState } from "react";
import { USDollar } from "../../constants/theme";
import PaymentButtonGroup from "./PaymentButtonGroup";
import { useEffect } from "react";
import { getPaymentsAndCount, searchPayments } from "../../api/payments";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { Button, ButtonGroup, InputAdornment, TextField } from "@mui/material";
import { Search } from "@mui/icons-material";
import { DataGrid } from "@mui/x-data-grid";
import { useCallback } from "react";

const PaymentTable = ({refreshTable, setRefreshTable}) => {
    
    const [paginationModel, setPaginationModel] = useState({
        pageSize: 10,
        page: 0,
    });
    const [payments, setPayments] = useState([]);
    const [totalPaymentCount, setTotalPaymentCount] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [status, setStatus] = useState('OUTSTANDING');
    const [search, setSearch] = useState('');
    
    const cols = [
        {
            field: 'customer_name', 
            headerName: 'Customer Name', 
            flex: 35,
        },
        {
            field: 'amount', 
            headerName: 'Amount', 
            flex: 23, 
            valueGetter: (params) => (USDollar.format(params.value))},
        {
            field: 'created_at', 
            headerName: 'Date', 
            flex: 20, 
            valueGetter: (params) => (new Date(params.value).toLocaleDateString('en-US', {
                month: 'short', year:'numeric', day:'numeric', hour: 'numeric', minute: 'numeric', second:'numeric'
            }))
        },
        {
            field: 'action', 
            headerName: 'Action', 
            flex: 16, 
            renderCell: ({row}) => (
                <PaymentButtonGroup 
                    index={row.index} 
                    paymentInfo={row} 
                    status={status}
                    setRefreshTable={setRefreshTable}
                />
            )
        }
    ];

    // fetch the payments data
    useEffect(() => {
        (async () => {
            setIsLoading(true);

            // get the payments and total payment count for user
            const response = await getPaymentsAndCount(paginationModel.page + 1, paginationModel.pageSize, status);
            if (response) {
                setTotalPaymentCount(response.data.count);
            }

            // get the payments data for the table
            if (search === '') {
                // regular server-sided pagination endpoint when not using search bar
                if (response) {
                    setPayments(response.data.rows);
                } 
                else {
                    setPayments([]);
                }
            } else {
                // client-sided pagination endpoint when using search bar
                const response = await searchPayments(search, status);
                if (response) {
                    setPayments(response.data);
                } else {
                    setPayments([]);
                }
            }
            
            setIsLoading(false);
        })();
    }, [paginationModel, search, status, refreshTable]);
    
    const getRowHeight = useCallback(() => (status === 'OUTSTANDING' ? 'auto' : null), [status]);
    
    return (
        <Grid2 container xs={12} rowGap={3}>
            <Grid2 container xs={12}  justifyContent={'space-between'} alignItems={'center'}>
                <Grid2 container xs={4}>
                    <ButtonGroup fullWidth>
                        <Button
                            variant={(status === 'OUTSTANDING') ? 'contained' : 'outlined'}
                            onClick={() => {
                                setStatus('OUTSTANDING');
                            }}
                        >
                            OUTSTANDING
                        </Button>
                        <Button
                            variant={(status === 'OUTSTANDING') ? 'outlined' : 'contained'}
                            onClick={() => {
                                setStatus('PAID');
                            }}
                        >
                            PAID
                        </Button>
                    </ButtonGroup>
                </Grid2>
                <Grid2 xs={4}>
                    <TextField
                        variant="outlined"
                        color="secondary"
                        type="text"
                        fullWidth
                        value={search}
                        label="Search"
                        placeholder="Search Payment"
                        onChange={event => setSearch(event.target.value)}
                        InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <Search color="secondary"/>
                              </InputAdornment>
                            ),
                          }}
                    />
                </Grid2>
            </Grid2>
            <Grid2 xs={12} className="data-table-wrapper">
                <DataGrid
                    className="data-table"
                    rows={payments}
                    getRowHeight={getRowHeight}
                    rowCount={search === '' ? totalPaymentCount : undefined}
                    columns={cols}
                    pageSizeOptions={[5, 10, 20]}
                    loading={isLoading}
                    paginationMode={(search === '' ? "server" : "client")}
                    paginationModel={paginationModel}
                    onPaginationModelChange={setPaginationModel}
                    disableRowSelectionOnClick
                    sx={{
                        '.MuiDataGrid-cell': { py: '8px' },
                    }}
                />
            </Grid2>
        </Grid2>
    );
}

export default PaymentTable;
import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Amplify } from 'aws-amplify';
import awsExports from './aws-exports'; // [WARN] Must configure amplify to obtain this file 
import AuthProvider from './contexts/AuthContext';
import AppProvider from './contexts/AppContext';
import { ThemeProvider } from '@mui/material';
import theme from './constants/theme';

const updatedAwsConfig = {
  ...awsExports,
  aws_user_pools_web_client_id: process.env.REACT_APP_AWS_USER_POOL_CLIENT_ID, // [WARN] new ENV variable required
  // [TODO] future changes required here for oauth (redirect URLs)
};

Amplify.configure(updatedAwsConfig);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <AppProvider>
      <AuthProvider>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </AuthProvider>
    </AppProvider>
  </React.StrictMode>
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React from 'react';
import "./Navbar.css"
import SearchIcon from '@mui/icons-material/Search';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';

function NavbarMobile() {

    return (
    <div className="review-navbar flex-box" style={{width: '100%'}}>
      <div className="flex-box">
          <img className="logo-img" src="/logo3x.png"  alt='' style={{float: 'left', marginRight: '10px'}} />
      </div>
      <div className="ml-2" style={{width: '100%'}}>
        <Paper
          sx={{display: 'flex', alignItems: 'center', width: '100%', borderRadius: 30 }}
        >
          <InputBase
            sx={{ ml: 1, flex: 1, borderRadius: 30 }}
            placeholder="Search contractors"
            inputProps={{ 'aria-label': 'Search Contractors' }}
          />
          <IconButton type="button" sx={{ p: '10px', color: '#587E81' }} aria-label="search">
            <SearchIcon />
          </IconButton>
        </Paper>
      </div>
    </div>
  );
}

export default NavbarMobile;

import './App.css';
import { useContext } from 'react';
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import CustomerReview from './pages/customerReview/customerReview';
// import CustomerReview from './pages/customerReview_';
import EstimatePreview from './pages/estimatePreview';
import ReviewForm from "./pages/reviewForm";
import QuickBooksAuthSuccess from './pages/quickbooksAuthSuccess';
import QuickBooksAuthError from './pages/quickbooksAuthError';
import Referral from "./pages/referral";
import SignIn from './pages/signIn';
import ProtectedRoutes from './components/auth/protectedRoutes';
import AuthRoutes from './components/auth/authRoutes';
import SignUp from './pages/signUp';
import Payments from './pages/payments/payments';
import Account from './pages/account/account';
import Customers from './pages/customers/customers';
import Estimates from './pages/estimates/estimates';
import Invoices from './pages/invoices/invoices';
import SendPayment from './pages/sendPayment/sendPayment';
import PayoutRegistrationBusiness from './pages/payoutRegistration/payoutRegistrationBusiness';
import PayoutRegistrationIndividual from './pages/payoutRegistration/payoutRegistrationIndividual';
import EstimateDetails from './components/estimates/EstimateDetails';
import EstimateEdit from './components/estimates/EstimateEdit';
import InvoiceEdit from './components/invoices/InvoiceEdit';
import InvoiceDetails from './components/invoices/InvoiceDetails';
import CustomerDetails from './components/customers/CustomerDetails';
import PaymentSettings from './pages/account/paymentSettings';
import ManagePlan from './pages/account/managePlan';
import ManageMarkup from './pages/account/manageMarkup';
import UpdatePlan from './pages/account/updatePlan';
import ContactSupport from './pages/account/contactSupport';
import AccountDeletion from './pages/account/accountDeletion';
import SendingSettings from './pages/account/sendingSettings';
import SignOut from './pages/account/signOut';
import Quickbooks from './pages/quickbooks/quickbooks';
import ChooseTeam from './pages/registration/chooseTeam';
import EmployerRegistration from './pages/registration/employer';
import PaymentSetup from './pages/payments/paymentSetup';
import EmployeeRegistration from './pages/registration/employee';
import { AppContext } from './contexts/AppContext';
import Layout from './layout/layout';
import SocialAccountVerification from './pages/socialAccountVerification';
import LoadingOverlay from 'react-loading-overlay-ts';

function App() {
  const { loading } = useContext(AppContext);
  return (
    <div className="App">
      <LoadingOverlay
        active={loading}
        spinner
        text='Loading ...'
      >
        <Layout>
          <Router>
            <Routes>
              <Route element={<ProtectedRoutes />}>
                <Route path="/payments" element={<Payments />} />
                <Route path="/payments/setup" element={<PaymentSetup />} />
                <Route path="/estimates" element={<Estimates />} />
                <Route path="/estimates/create" element={<EstimateEdit editMode={false} />} />
                <Route path="/estimates/create/:contact_id" element={<EstimateEdit editMode={false} />} />
                <Route path="/estimates/edit/:id" element={<EstimateEdit editMode={true} />} />
                <Route path="/estimates/:id" element={<EstimateDetails />} />
                <Route path="/invoices" element={<Invoices />} />
                <Route path="/invoices/create" element={<InvoiceEdit editMode={false} />} />
                <Route path="/invoices/create/:contact_id" element={<InvoiceEdit editMode={false} />} />
                <Route path="/invoices/create/with-payment/:payment_id" element={<InvoiceEdit editMode={false} />} />
                <Route path="/invoices/edit/:id" element={<InvoiceEdit editMode={true} />} />
                <Route path="/invoices/:id" element={<InvoiceDetails />} />
                <Route path="/customers" element={<Customers />} />
                <Route path="/customers/:id" element={<CustomerDetails />} />
                <Route path="/account/profile" element={<Account />} />
                <Route path="/account/payment-settings" element={<PaymentSettings />} />
                <Route path="/account/manage-plan" element={<ManagePlan />} />
                <Route path="/account/manage-markup" element={<ManageMarkup />} />
                <Route path="/account/sending-settings" element={<SendingSettings />} />
                <Route path="/account/manage-plan/:token" element={<UpdatePlan />} />
                <Route path="/account/contact-support" element={<ContactSupport />} />
                <Route path="/account/delete" element={<AccountDeletion />} />
                <Route path="/account/sign-out" element={<SignOut />} />
                <Route path="/quickbooks" element={<Quickbooks/>} />
                <Route path="/send-payment" element={<SendPayment />} />
                <Route path="/payout-registration/business" element={<PayoutRegistrationBusiness />} />
                <Route path="/payout-registration/individual" element={<PayoutRegistrationIndividual />} />
              </Route>

              <Route element={<AuthRoutes />}>
                <Route path="/sign-in" element={<SignIn />} />
                <Route path="/sign-up" element={<SignUp />} />
                <Route path="/register/choose-team" element={<ChooseTeam />} />
                <Route path="/register/employer" element={<EmployerRegistration/>} />
                <Route path="/register/employee" element={<EmployeeRegistration />} />
              </Route>
              
              <Route path="/" element={<ReviewForm />} />
              <Route path='/review/:userId' element={<CustomerReview/>}/>
              <Route path="/quickbooks/success" element={<QuickBooksAuthSuccess />} />
              <Route path="/quickbooks/error" element={<QuickBooksAuthError />} />
              <Route path="/referral" element={<Referral />} />
              <Route path="/preview/:userId/:estimateId" element={<EstimatePreview  />} />
              <Route path="/account/social-verification" element={<SocialAccountVerification />} />
            </Routes>
          </Router>
        </Layout>
      </LoadingOverlay>
    </div>
  );
}

export default App;

import { React, useState } from 'react';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Stack from 'react-bootstrap/Stack';
import { BsXCircleFill } from 'react-icons/bs';
import SuccessCreate from './successCreateReferral';


import { createReferral } from '../../api/referral';

import './createReferral.css'

function CreateReferralModal() {
  const [show, setShow] = useState(false);
  const [showVerificationCode, setShowVerificationCode] = useState(false);
  const [verificationCode, setVerificationCode] = useState('');
  const [showErrorMsg, setShowErrorMsg] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');


  async function handleSubmit(event) {
    event.preventDefault();
    try {
      const verificationCode = await createReferral({
        first_name: event.target.form.elements.firstName.value,
        last_name: event.target.form.elements.lastName.value,
        email: event.target.form.elements.email.value,
        referral_email: event.target.form.elements.proEmail.value
      })

      if (verificationCode) {
        setVerificationCode(verificationCode.verification_code)
        setShowVerificationCode(true)
      }
    } catch (e) {
      setErrorMsg(e.response.data.message)
      setShowErrorMsg(true)
    }

  };
  const handleShow = () => setShow(true);
  const handleClose = () => {
    setVerificationCode('')
    setShowVerificationCode(false)
    setShow(false);
    setErrorMsg('');
    setShowErrorMsg(false)
  }

  const errorStyle = {
    fontSize: 'smaller',
    paddingLeft: '5px'
  };

  return (
    <>
      <Button className='tapper-btn-primary' variant='primary' onClick={handleShow}>
        Create Referral
      </Button>

      <Modal show={show} onHide={handleClose}>
        {showVerificationCode
          ?
          <SuccessCreate verificationCode={verificationCode} />
          : <><Modal.Header>
            <Modal.Title>Create Referral</Modal.Title>
          </Modal.Header><Modal.Body>
              <Form id='create-referral-form'>
                <Form.Group className='mb-1' size='sm' controlId='firstName'>
                  <Form.Label size='sm'>First name</Form.Label>
                  <Form.Control type='text' size='sm' />
                </Form.Group>
                <Form.Group className='mb-1' size='sm' controlId='lastName'>
                  <Form.Label size='sm'>Last name</Form.Label>
                  <Form.Control type='text' size='sm' />
                </Form.Group>
                <Form.Group className='mb-1' size='sm' controlId='email'>
                  <Form.Label size='sm'>Email</Form.Label>
                  <Form.Control type='email' size='sm' />
                </Form.Group>
                <Form.Group className='mb-1' size='sm' controlId='proEmail'>
                  <Form.Label size='sm'>Home Service Pro Email</Form.Label>
                  <Form.Control type='email' size='sm' />
                </Form.Group>
              </Form>
              {showErrorMsg
                ?
                <Stack direction='horizontal'>
                  <BsXCircleFill style={{ color: "red" }} />
                  <p style={{ fontSize: 'smaller', paddingLeft: '5px' }}>{errorMsg}</p>
                </Stack>
                : <p></p>}
            </Modal.Body><Modal.Footer>
              <Button className='tapper-btn-secondary' variant='secondary' size='sm' onClick={handleClose}>
                Close
              </Button>
              <Button className='tapper-btn-primary' variant='primary' size='sm' type='submit' form='create-referral-form' onClick={handleSubmit}>
                Submit
              </Button>
            </Modal.Footer></>
        }
      </Modal>
    </>
  );
}

export default CreateReferralModal;
import { useEffect, useState } from 'react';
import { Box, Button, Divider, Typography, List, ListItem, ListItemText, ListItemIcon } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import Stack from '@mui/material/Stack';
import CheckIcon from '@mui/icons-material/Check';

import AccountSideBar from '../../components/account/AccountSideBar';
import AppDrawer from '../../components/appdrawer/appdrawer';
import { updateSubscriptionPlan } from '../../api/user';
import { useNavigate, useParams } from "react-router-dom";

const UpdatePlan = () => {
    const { token } = useParams();
    const navigate = useNavigate();
    const [message, setMessage] = useState();
    const [seconds, setSeconds] = useState(3);
    const [isFirst, setFirst] = useState(true);
    useEffect(() => {
        if(isFirst) {
            setFirst(false);
            setMessage('Upgrading your plan...');
            updateSubscriptionPlan(token).then((res) => {
                setMessage('Your plan successfully upgraded!');
            }).catch((error) => {
                setMessage(error.response.data);
            })
        }
    }, [token])

    return (
        <Box sx={{ display: 'flex', }}>
            <AppDrawer/>
            <Grid2 xs container p={4} gap={4}>

                <Grid2 container xs={12} direction={'column'} alignItems={'flex-start'}>
                    <Typography color={'primary'} variant='h3' fontWeight={'bold'}>
                        Account
                    </Typography>
                    <Typography color={'primary'} variant='body2'>
                        Manage your account info and subscriptions
                    </Typography>
                </Grid2>

                <AccountSideBar />

                <Grid2 xs={'auto'}>
                    <Divider orientation='vertical' variant='fullWidth' sx={{ bgcolor: 'black'}}/>
                </Grid2>

                <Grid2 xs container p={4} gap={10}>
                    {message && <Box sx={{textAlign: 'left'}}>
                        <Typography variant='h3' color="primary">{message}</Typography>
                        <Typography variant='p' color="primary" style={{cursor: 'pointer'}} onClick={() => navigate('/account/manage-plan')}><u>Click here to return manage plan page.</u></Typography>
                    </Box>}
                </Grid2>
            </Grid2>    
        </Box>
    );
};

export default UpdatePlan;
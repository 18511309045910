import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import AppDrawer from '../../components/appdrawer/appdrawer';
import './customers.css'
import { Box, Button, Typography } from '@mui/material';
import { Add } from '@mui/icons-material';
import { useState } from 'react';
import CustomersTable from '../../components/customers/CustomersTable';
import EditCustomerModal from '../../components/customers/EditCustomerModal';
import { useEffect } from 'react';
import { useCallback } from 'react';

const Customers = () => {

    const [showEditCustomerModal, setShowEditCustomerModal] = useState(false);
    const [editCustomerData, setEditCustomerData] = useState(null);

    const handleNewCustomer = useCallback(() => {
        setEditCustomerData(null);
        setShowEditCustomerModal(true);
    }, []);

    useEffect(() => {
        if (editCustomerData) {
            setShowEditCustomerModal(true);
        }
        if (!showEditCustomerModal) {
            setEditCustomerData(null);
        }
    }, [editCustomerData, showEditCustomerModal]);

    return (
        <Box sx={{ display: 'flex', }}>
            <AppDrawer/>
            <EditCustomerModal
                showModal={showEditCustomerModal}
                setShowModal={setShowEditCustomerModal}
                editCustomerData={editCustomerData}
            />
            <Grid2 container padding={4} rowGap={8} flexGrow={1}>
                <Grid2 container xs={12} justifyContent={'space-between'} alignItems={'center'}>
                    <Grid2 container lg={6} md={12} xs={12} direction={'column'} alignItems={'flex-start'}>
                        <Typography color={'primary'} variant="h3" fontWeight={'bold'}>
                            Customers
                        </Typography>
                        <Typography color={'primary'} variant="body2">
                            Manage your customers
                        </Typography>
                    </Grid2>
                    <Grid2 container lg={6} md={12} xs={12} justifyContent={'flex-end'} className="payment-button-group">
                        <Button
                            className="payment-button"
                            variant="contained"
                            startIcon={<Add/>}
                            onClick={handleNewCustomer}
                        >
                            New Customer
                        </Button>
                    </Grid2>
                </Grid2>
                <CustomersTable isModalVisible={showEditCustomerModal} setEditCustomerData={setEditCustomerData}/>
            </Grid2>
        </Box>
    );
};

export default Customers;

import { Box, Button, Modal, TextField, Typography, InputAdornment } from "@mui/material";
import './SendPaymentModal.css';
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { useState } from "react";
import { useEffect } from "react";
import CloseIcon from '@mui/icons-material/Close';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { getUser } from '../../api/user';
import successImg from "../../img/success.png";
import { getAccountWallet, createDigitalCheck, setPinCode } from "../../api/payout";

const SendPaymentModal = ({ showModal, setShowModal, balance, fetchUserData, getBalance, fetchCheckList, prefillEmail, setPrefillEmail }) => {
    const [isLoading, setLoading] = useState(true);
    const [step, setStep] = useState(0);
    const [amount, setAmount] = useState(0);
    const [amountError, setAmountError] = useState(false);
    const [amountErrorMsg, setAmountErrorMsg] = useState();
    const [email, setEmail] = useState(prefillEmail);
    const [emailError, setEmailError] = useState(false);
    const [bankWallet, setBankWallet] = useState();
    const [hasPin, setHasPin] = useState(false);
    const [pin1, setPin1] = useState();
    const [pin2, setPin2] = useState();
    const [pin3, setPin3] = useState();
    const [pin4, setPin4] = useState();

    useEffect(() => {
        getAccountWallet()
            .then(res => setBankWallet(res.data.wallets[0].id))
            .catch(error => alert("Error occurred while fetching bank account. " + error.response.data.message));
    }, []);

    useEffect(() => {
        if(showModal === true) {
            setLoading(true);
            setHasPin(false);
            setPin1();
            setPin2();
            setPin3();
            setPin4();
            getUser().then(res => {
                if(res.data.pin) {
                    setHasPin(true);
                }
                setLoading(false);
            }).catch(error => setLoading(false));
        }
    }, [showModal]);

    useEffect(() => {
        setEmail(prefillEmail);
    }, [prefillEmail])

    const validateMakePayment = () => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if(!amount || amount == 0) {
            setAmountError(true);
            setAmountErrorMsg('Amount value is required.');
            return false;
        }
        if(amount > balance) {
            setAmountError(true);
            setAmountErrorMsg('The balance is not enough for your payment.');
            return false;
        }
        if(!emailRegex.test(email)) {
            setEmailError(true);
            return false;
        }
        return true;
    }

    const resetError = () => {
        setAmountError(false);
        setAmountErrorMsg();
        setEmailError(false);
    }

    const handleConfirmClick = () => {
        resetError();
        if(!validateMakePayment()) return;
        nextStep();
    }

    const handleSendPayment = () => {
        createDigitalCheck(bankWallet, amount, email).then(res => {
            nextStep();
        }).catch(error => alert(error.response.data.message));
    }

    const fetchData = () => {
        setAmount(0);
        setEmail();
        setPrefillEmail();
        fetchUserData();
        getBalance();
        fetchCheckList();
    }

    const handleSetPinCode = () => {
        setPinCode(pin1 + pin2 + pin3 + pin4).then((result) => {
            nextStep();
        }).catch((error) => {
            alert(error.response.data.message);
        });
    }
    
    const nextStep = () => {
        if(step < 2) {
            setStep(step + 1);
        }
        else {
            onClose();
        }
    }

    const prevStep = () => {
        if(step > 0) {
            setStep(step - 1);
        }
        else {
            onClose();
        }
    }

    const onClose = () => {
        setShowModal(false);
        setStep(0);
        fetchData();
    }

    return (
        <Modal
            open={showModal}
            onClose={setShowModal}
        >
            <Box className="big-modal-container">
                <Grid2 container gap={4} flexGrow={1} justifyContent={'space-between'}>
                    <Grid2 xs={12} justifyContent={'space-between'} display={'flex'}>
                        <Box>
                            {step !== 2 && hasPin === true &&
                            <>
                                <Button startIcon={<ChevronLeftIcon/>} onClick={() => prevStep()}>
                                    Back
                                </Button>
                                <Typography variant="h5" color={'primary'} fontWeight={'bold'}>
                                    Make A Payment
                                </Typography>
                                <Typography variant="p" color={'primary'}>
                                    Current balance: ${balance}
                                </Typography>
                            </>}
                        </Box>
                        <CloseIcon className="close-button" onClick={() => onClose()} />
                    </Grid2>
                    {!isLoading && step === 0 && hasPin !== true && <>
                        <Grid2 xs={12} container justifyContent={"center"} display={"block"} textAlign={"center"}>
                            <Typography variant="h5" color={'primary'} fontWeight={'bold'}>
                                Set Up Your Payment Passcode
                            </Typography>
                            <Typography variant="p" color={'primary'}>
                                (The recipients needs this PIN to access the funds you send them)
                            </Typography>
                        </Grid2>
                        <Grid2 xs={12} container justifyContent={"center"} maxWidth={300} margin={"auto"}>
                            <Grid2 container xs={3} className="text-container">
                                <TextField
                                    fullWidth
                                    color="primary"
                                    type="text"
                                    variant="standard"
                                    inputProps={{
                                        style: {fontSize: 30, textAlign: 'center'},
                                        maxLength: 1,
                                    }}
                                    value={pin1}
                                    onChange={(e) => {
                                        if((e.target.value <= '9' && e.target.value >= '0') || e.target.value === '')
                                            setPin1(e.target.value);
                                    }}
                                />
                            </Grid2>
                            <Grid2 container xs={3} className="text-container">
                                <TextField
                                    fullWidth
                                    color="primary"
                                    type="text"
                                    variant="standard"
                                    inputProps={{
                                        style: {fontSize: 30, textAlign: 'center'},
                                        maxLength: 1,
                                    }}
                                    value={pin2}
                                    onChange={(e) => {
                                        if((e.target.value <= '9' && e.target.value >= '0') || e.target.value === '')
                                            setPin2(e.target.value);
                                    }}
                                />
                            </Grid2>
                            <Grid2 container xs={3} className="text-container">
                                <TextField
                                    fullWidth
                                    color="primary"
                                    type="text"
                                    variant="standard"
                                    inputProps={{
                                        style: {fontSize: 30, textAlign: 'center'},
                                        maxLength: 1,
                                    }}
                                    value={pin3}
                                    onChange={(e) => {
                                        if((e.target.value <= '9' && e.target.value >= '0') || e.target.value === '')
                                            setPin3(e.target.value);
                                    }}
                                />
                            </Grid2>
                            <Grid2 container xs={3} className="text-container">
                                <TextField
                                    fullWidth
                                    color="primary"
                                    type="text"
                                    variant="standard"
                                    inputProps={{
                                        style: {fontSize: 30, textAlign: 'center'},
                                        maxLength: 1,
                                    }}
                                    value={pin4}
                                    onChange={(e) => {
                                        if((e.target.value <= '9' && e.target.value >= '0') || e.target.value === '')
                                            setPin4(e.target.value);
                                    }}
                                />
                            </Grid2>
                        </Grid2>
                        <Grid2 xs={12} container>
                            <Typography variant="p" color={'primary'}>
                                * You can later update the passcode in your account settings.
                            </Typography>
                        </Grid2>
                        <Grid2 xs={12} container justifyContent={"flex-end"}>
                            <Button
                                fullWidth
                                variant="contained"
                                onClick={() => handleSetPinCode()}
                                disabled={!pin1 || !pin2 || !pin3 || !pin4}
                            >
                                Confirm
                            </Button>
                        </Grid2>
                    </>}
                    {!isLoading && step === 1 && hasPin !== true && <>
                        <Grid2 xs={12} container justifyContent={"center"}>
                            <img src={successImg} alt=""/>
                        </Grid2>
                        <Grid2 xs={12} container justifyContent={"center"}>
                            <Typography variant="h6" color={'primary'} fontWeight={'bold'}>
                                Congratulation
                            </Typography>
                        </Grid2>
                        <Grid2 xs={12} container justifyContent={"center"}>
                            <Typography variant="p" color={'primary'}>
                                You passcode has been successfully connected.
                            </Typography>
                        </Grid2>
                        <Grid2 xs={12} container justifyContent={"flex-end"}>
                            <Button
                                fullWidth
                                variant="contained"
                                onClick={() => {setHasPin(true); setStep(0);}}
                            >
                                Start Your First Payment
                            </Button>
                            <Button
                                fullWidth
                                className="bottom-btn"
                                variant="outlined"
                                onClick={() => {setHasPin(true); setStep(0); onClose();}}
                            >
                                Back to Home Page
                            </Button>
                        </Grid2>
                    </>}
                    {!isLoading && step === 0 && hasPin === true && <>
                        <Grid2 xs={12} container justifyContent={"center"}>
                            <Grid2 container xs={12}>
                                <TextField
                                    label="Payment Amount"
                                    id="outlined-start-adornment"
                                    type="number"
                                    variant="outlined"
                                    placeholder="Enter Amount..."
                                    value={amount}
                                    onChange={(e) => setAmount(e.target.value)}
                                    error={amountError}
                                    helperText={amountError ? amountErrorMsg : ''}
                                    fullWidth
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">$</InputAdornment>
                                    }}
                                    inputProps={{
                                        min: 0,
                                        step: 0.01
                                    }}
                                />
                            </Grid2>
                        </Grid2>
                        <Grid2 xs={12} container justifyContent={"center"}>
                            <Grid2 container xs={12}>
                                <TextField
                                    label="Recipient Email Address"
                                    id="outlined-start-adornment"
                                    type="text"
                                    variant="outlined"
                                    placeholder="Enter Email..."
                                    fullWidth
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    error={emailError}
                                    helperText={emailError ? 'Please input valid email address' : ''}
                                />
                            </Grid2>
                        </Grid2>
                        <Grid2 xs={12} container justifyContent={"flex-end"}>
                            <Button
                                fullWidth
                                variant="contained"
                                onClick={() => {
                                    handleConfirmClick();
                                }}
                            >
                                Confirm
                            </Button>
                        </Grid2>
                    </>}
                    {!isLoading && step === 1 && hasPin === true && <>
                        <Grid2 xs={12} container justifyContent={"center"}>
                            <Grid2 container xs={12}>
                                <TextField
                                    label="Payment Amount"
                                    id="outlined-start-adornment"
                                    type="number"
                                    variant="standard"
                                    placeholder="Enter Amount..."
                                    fullWidth
                                    value={amount}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                    }}
                                    disabled
                                />
                            </Grid2>
                        </Grid2>
                        <Grid2 xs={12} container justifyContent={"center"}>
                            <Grid2 container xs={12}>
                                <TextField
                                    label="Recipient Email Address"
                                    id="outlined-start-adornment"
                                    type="text"
                                    variant="standard"
                                    placeholder="Enter Email..."
                                    fullWidth
                                    disabled
                                    value={email}
                                />
                            </Grid2>
                        </Grid2>
                        <Grid2 xs={12} container justifyContent={"center"}>
                            <span><b>Recipient will be able to deposit the funds through:</b> Direct Deposit in 1 day or instantly with <span class="zelle-purple"><b>Zelle</b></span></span>
                        </Grid2>
                        <Grid2 xs={12} container justifyContent={"flex-end"}>
                            <Button
                                fullWidth
                                variant="contained"
                                onClick={() => handleSendPayment()}
                            >
                                Confirm
                            </Button>
                        </Grid2>
                    </>}
                    {!isLoading && step === 2 && hasPin === true && <>
                        <Grid2 xs={12} container justifyContent={"center"}>
                            <img src={successImg} alt=""/>
                        </Grid2>
                        <Grid2 xs={12} container textAlign={"center"} display={"block"}>
                            <Typography variant="h6" color={'primary'} fontWeight={'bold'}>
                                Congratulation
                            </Typography>
                            <Typography variant="p" color={'primary'}>
                                Your funds are on the way.
                            </Typography>
                        </Grid2>
                        <Grid2 xs={12} container display={"block"}>
                            <Box display={"flex"} justifyContent={'space-between'} className="grey-back top-radius">
                                <Typography variant="h6">Pre-pay Balance</Typography>
                                <Typography variant="h6">${balance}</Typography>
                            </Box>
                            <Box display={"flex"} justifyContent={'space-between'} className="grey-back">
                                <Typography variant="h6">Payment in Transit</Typography>
                                <Typography variant="h6">${amount}</Typography>
                            </Box>
                            <Box display={"flex"} justifyContent={'space-between'} className="green-back bottom-radius">
                                <Typography variant="h6" color={'primary'} fontWeight={'bold'}>Current Balance</Typography>
                                <Typography variant="h6" color={'primary'} fontWeight={'bold'}>${parseFloat(balance) - parseFloat(amount)}</Typography>
                            </Box>
                        </Grid2>
                        <Grid2 xs={12} container justifyContent={"flex-end"}>
                            <Button
                                fullWidth
                                variant="contained"
                                onClick={() => {
                                    setStep(0);
                                    fetchData();
                                    setPrefillEmail();
                                }}
                            >
                                Make another payment
                            </Button>
                            <Button
                                fullWidth
                                className="bottom-btn"
                                variant="outlined"
                                onClick={() => {
                                    fetchData();
                                    nextStep();
                                    setPrefillEmail();
                                }}
                            >
                                Back to Home Page
                            </Button>
                        </Grid2>
                    </>}
                </Grid2>
            </Box>
        </Modal>
    );
};

export default SendPaymentModal;
import { Box, Button, Typography } from "@mui/material"
import Grid2 from "@mui/material/Unstable_Grid2/Grid2"
import AppDrawer from "../../components/appdrawer/appdrawer";
import QuickbooksLogo from "../../components/icons/QuickbooksLogo";
import { useCallback, useEffect, useState } from "react";
import { connectToQuickbooks, disconnectFromQuickbooks, getQuickbooksAuthStatus } from "../../api/quickbooks";
import { getUser } from "../../api/user";
import upgradeImg from "../../img/upgrade.svg";
import { useNavigate } from 'react-router';

const Quickbooks = () => {
    const navigate = useNavigate();
    const [authStatus, setAuthStatus] = useState(null);
    const [user, setUser] = useState();

    const fetchAuthStatus = useCallback(async () => {
        let response = await getQuickbooksAuthStatus();
        if (response) {
            setAuthStatus(response.data.user_auth_status);
        }
    }, []);

    useEffect(() => {
        fetchAuthStatus();
    }, [fetchAuthStatus]);

    useEffect(() => {
        getUser().then((res) => {
            setUser(res.data);
        })
    }, [])

    const handleQuickbooksButton = useCallback(async () => {
        if (authStatus !== null) {
            let response;
            if (authStatus) {
                response = await disconnectFromQuickbooks();
                fetchAuthStatus();
            } else {
                response = await connectToQuickbooks();
                if (response) {
                    window.open(response.data.url);
                }
            }
        }
    }, [authStatus, fetchAuthStatus]);

    return (
        <Box sx={{ display: 'flex', }}>
            <AppDrawer/>
            <Grid2 xs container p={4} gap={4}>
                <Grid2 container xs={12} direction={'column'} alignItems={'flex-start'}>
                    <Typography color={'primary'} variant="h3" fontWeight={'bold'}>
                        QuickBooks
                    </Typography>
                    <Typography color={'primary'} variant="body2">
                        Connect to your QuickBooks Online account to automatically sync payments, estimates and invoices from Tapper
                    </Typography>
                </Grid2>

                {user && user.plantype === true && <Grid2 xs container p={1} rowGap={4}>
                    <Grid2 lg={10} md={12} xs={12} container p={4} gap={4} 
                        borderRadius={4} border={1} borderColor={'grey.50'}
                        height={'min-content'} 
                        justifyContent={'space-between'}
                    >
                        <Grid2 lg={7.5} md={7.5} xs={12} container gap={2} alignItems={'center'}>
                            <QuickbooksLogo size={48}/>
                            <Typography variant="h4">QuickBooks</Typography>
                        </Grid2>
                        <Grid2 lg={3.5} md={3.5} xs={12} container justifyContent={'flex-end'} alignItems={'center'}>
                            {authStatus !== null && 
                            <Button 
                                variant="outlined"
                                onClick={handleQuickbooksButton}
                            >
                                {authStatus ? 'Disconnect from Quickbooks' : 'Connect to Quickbooks'}
                            </Button>}
                        </Grid2>
                        <Grid2 xs={12} container direction={'column'} gap={1} alignItems={'flex-start'}>
                            <Typography color={'grey'}>
                                Tapper now integrates with Quickbooks! 
                            </Typography>
                            <Typography color={'grey'}>
                                All payments acceptance, estimates, and invoices will feed directly into your QBO account to keep everything updated automatically
                            </Typography>
                        </Grid2>
                    </Grid2>
                </Grid2>}
                {user && user.plantype === false && <Grid2 xs container direction={'column'}>
                    <Grid2 xs={12} container height={'min-content'} textAlign={'center'} display={'flex'} justifyContent={'center'} gap={4} p={4}>
                        <img src={upgradeImg} alt="" className="upgrade-img" />
                    </Grid2>
                    <Grid2>
                        <Typography color="primary" variant="h3" p={1}>Upgrade to Pro Tier</Typography>
                        <Typography variant="p" p={1}>Upgrade to our pro tier to sync information from your Tapper account into QuickBooks.</Typography>
                    </Grid2>
                    <Grid2>
                        <Button color="primary" variant="contained" className="upgrade-button" onClick={() => navigate('/account/manage-plan')}>Upgrade Now!</Button>
                    </Grid2>
                </Grid2>}
            </Grid2>
        </Box>
    );
};

export default Quickbooks;
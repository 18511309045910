import React from 'react';
import "./Navbar.css"
import SearchIcon from '@mui/icons-material/Search';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';

function Navbar() {

    return (
    <div className="review-navbar flex-box">
      <div className="flex-box">
          <img className="logo-img" src="/logo3x.png"  alt='' style={{float: 'left', marginRight: '10px'}} />
          <div style={{textAlign: 'start',fontWeight: 'bold', fontSize:'15px'}}>
              <span className="logo-title">Tapper</span>
          </div>
      </div>
      <div className="search-box-desktop">
        <Paper
          sx={{display: 'flex', alignItems: 'center', width: 400 }}
        >
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder="Search contractors"
            inputProps={{ 'aria-label': 'Search Contractors' }}
          />
          <IconButton type="button" sx={{ p: '10px', background: '#587E81', borderRadius: '4px !important', color: 'white' }} aria-label="search">
            <SearchIcon />
          </IconButton>
        </Paper>
      </div>
    </div>
  );
}

export default Navbar;

import axios from "axios";

// gets a list of payments and total count using server-sided pagination
export async function getPaymentsAndCount(page, size, status) {
    try {
        return await axios.get(`${process.env.REACT_APP_SERVER_URL}/payments/findAndCount?page=${page}&size=${size}&status=${status}`);
    } catch (error) {
        console.error('error fetching payments: ', error);
        return null;
    }
};

// gets the entire list of payments that match a search query
export async function searchPayments(search, status) {
    try {
        return await axios.get(`${process.env.REACT_APP_SERVER_URL}/payments/searching?search=${search}&status=${status}`);
    } catch (error) {
        console.error('error searching payments: ', error);
        return null;
    }
};

export async function sendPaymentReminder(paymentID) {
    try {
        return await axios.post(`${process.env.REACT_APP_SERVER_URL}/payments/sending-reminder/${paymentID}`);
    } catch (error) {
        console.error('error sending payment reminder: ', error);
        return null;
    }
};

export async function recordPayment(paymentID, method, amount) {
    try {
        return await axios.post(`${process.env.REACT_APP_SERVER_URL}/payments/recording-payment`, {
            id: paymentID,
            payment_method: method,
            amount,
        });
    } catch (error) {
        console.error('error recording payment: ', error);
        return null;
    }
};

export async function recordPaymentWithInvoice(invoiceID, method, amount) {
    try {
        return await axios.post(`${process.env.REACT_APP_SERVER_URL}/payments/record-and-link-invoice`, {
            invoice_id: invoiceID,
            payment_method: method,
            amount,
        });
    } catch (error) {
        console.error(`error recording payment with invoice #${invoiceID}: `, error);
        return null;
    }
};

export async function linkInvoice(invoiceID, paymentIDs) {
    try {
        return await axios.patch(`${process.env.REACT_APP_SERVER_URL}/payments/link-invoice`, {
            invoice_id: invoiceID,
            payment_session_ids: paymentIDs, //! must be an array
        });
    } catch (error) {
        console.error(`error linking payment(s) to invoice #${invoiceID}: `, error);
        return null;
    }
};

// sends a payment link
export async function sendPaymentLink(amount, email, paymentMethod) {
    try {
        return await axios.post(`${process.env.REACT_APP_SERVER_URL}/payments/checkout-session`, {
            amount,
            email,
            payment_method: paymentMethod
        });
    } catch (error) {
        console.error('failed to send payment link: ', error);
        return null;
    }
};

export async function deletePayment(payment_id) {
    try {
        return await axios.delete(`${process.env.REACT_APP_SERVER_URL}/payments/${payment_id}`);
    } catch (error) {
        console.error(`error deleting payment #${payment_id}: `, error);
        return null;
    }
}
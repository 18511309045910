import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { useState } from "react";
import CustomerMenuButton from "./CustomerMenuButton";
import { useEffect } from "react";
import { getCustomers } from "../../api/contacts";
import { DataGrid } from "@mui/x-data-grid";
import isEmpty from "validator/lib/isEmpty";
import { InputAdornment, TextField, Typography } from "@mui/material";
import { Search } from "@mui/icons-material";

// [TODO] Rework this when customers/contacts gets pagination + searching
const CustomersTable = ({isModalVisible, setEditCustomerData}) => {
    
    const [paginationModel, setPaginationModel] = useState({
        pageSize: 10,
        page: 0,
    });
    const [customers, setCustomers] = useState([]);
    const [search, setSearch] = useState('');
    const [totalCount, setTotalCount] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [refreshTable, setRefreshTable] = useState(false); // value irrelevant, used to trigger table re-renders

    const cols = [
        {
            field: 'first_name', 
            headerName: 'Customer Name', 
            flex: 35,
            valueGetter: ({row}) => (`${row.first_name} ${row.last_name}`),
        },
        {
            field: 'city', 
            headerName: 'Location', 
            flex: 35,
            valueGetter: ({row}) => (`${row.city}, ${row.state}`),
        },
        {
            field: 'action', 
            headerName: 'Action', 
            flex: 10, 
            renderCell: ({row}) => (
                <CustomerMenuButton 
                    key={row.index} 
                    customerInfo={row} 
                    refreshTable={refreshTable}
                    setRefreshTable={setRefreshTable}
                    setEditCustomerData={setEditCustomerData}
                />
            )
        }
    ];

    // fetch customers
    useEffect(() => {
        (async () => {
            // [TODO] Update this for when API maybe gets updated
            setIsLoading(true);
            let response = await getCustomers(paginationModel.page, paginationModel.pageSize, !isEmpty(search) ? search : '');
                if (response) {
                    setTotalCount(response.data.count);
                    setCustomers(response.data.result);
                } else {
                    setCustomers([]);
                    setTotalCount(0);
                }
            setIsLoading(false);
        })();
    }, [paginationModel, refreshTable, isModalVisible, search]);

    return (
        <Grid2 container xs={12} rowGap={3}>
            <Grid2 container xs={12} justifyContent={'flex-start'}>
                <TextField
                    variant="outlined"
                    color="secondary"
                    type="text"
                    value={search}
                    label="Search"
                    placeholder="Search Customers"
                    onChange={event => setSearch(event.target.value)}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Search color="secondary"/>
                            </InputAdornment>
                        ),
                    }}
                />
            </Grid2>
            <Grid2 xs={12} className="data-table-wrapper">
                {!isLoading && totalCount === 0 ? 
                    <Typography variant="h4">No data</Typography>
                : <DataGrid
                    className="data-table"
                    rows={customers}
                    rowCount={totalCount}
                    columns={cols}
                    pageSizeOptions={[5, 10, 20]}
                    loading={isLoading}
                    paginationMode={"server"}
                    paginationModel={paginationModel}
                    onPaginationModelChange={setPaginationModel}
                    disableRowSelectionOnClick
                />}
            </Grid2>
        </Grid2>
    );
};

export default CustomersTable;
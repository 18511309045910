import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import './account.css'
import AppDrawer from '../../components/appdrawer/appdrawer';
import { Box, Button, Divider, TextField, Typography } from '@mui/material';
import AccountSideBar from '../../components/account/AccountSideBar';
import { useEffect, useState, useContext } from 'react';
import { getCheckbookUser, updatePinCode } from '../../api/payout';
import SendingSettingsIndividual from './sendingSettingsIndividual';
import SendingSettingsBusiness from './sendingSettingsBusiness';
import { AppContext } from '../../contexts/AppContext';

const SendingSettings = () => {
    const { isTablet } = useContext(AppContext);
    const [user, setUser] = useState();
    const [oldPin, setOldPin] = useState();
    const [oldPinError, setOldPinError] = useState();
    const [newPin, setNewPin] = useState();
    const [newPinError, setNewPinError] = useState();

    useEffect(() => {
        getCheckbookUser().then(res => {
            setUser(res.data.message);
        }).catch(error => alert(error.response.data.message));
    }, []);

    const resetErrorFields = () => {
        setOldPinError(false);
        setNewPinError(false);
    }

    const handleUpdatePinCode = () => {
        resetErrorFields();
        if(!updatePinCodeValidate()) return;
        updatePinCode(oldPin, newPin).then(res => {
            setNewPin('');
            setOldPin('');
            alert('Pin Code Updated Successfully!');
        }).catch(error => alert(error.response.data.message));
    }

    const updatePinCodeValidate = () => {
        const regex = /^\d{4}$/;
        if (!regex.test(oldPin)) {
            setOldPinError(true);
            return false;
        }

        if (!regex.test(newPin)) {
            setNewPinError(true);
            return false;
        }

        return true;
    }

    return (
        <Box sx={{ display: 'flex', }}>
            <AppDrawer/>
            <Grid2 xs container p={4} gap={4}>

                <Grid2 container xs={12} direction={'column'} alignItems={'flex-start'}>
                    <Typography color={'primary'} variant="h3" fontWeight={'bold'}>
                        Account
                    </Typography>
                    <Typography color={'primary'} variant="body2">
                        Manage your account info and subscriptions
                    </Typography>
                </Grid2>

                <AccountSideBar />

                {!isTablet && <Grid2 xs={'auto'}>
                    <Divider orientation="vertical" variant="fullWidth" sx={{ bgcolor: 'black'}}/>
                </Grid2>}

                {/** Account Profile */}
                <Grid2 xs container rowGap={4}>
                    {user && user?.user?.business_name && <SendingSettingsBusiness user={user} />}
                    {user && !user?.user?.business_name && <SendingSettingsIndividual user={user} />}

                    {user && <Grid2 xs={12} container rowGap={2} justifyContent={'space-between'}>
                        <Grid2 xs={12} container>
                            <Typography variant='button' color={'primary'} fontWeight={'bold'}>PIN</Typography>
                        </Grid2>
                        <Grid2 lg={5.5} md={12} xs={12}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                color="primary"
                                type="text"
                                label="Old 4 Digit PIN"
                                placeholder="Enter Old PIN"
                                error={oldPinError}
                                helperText={oldPinError ? "Pin Code should be 4 digits." : ''}
                                value={oldPin}
                                onChange={(e) => setOldPin(e.target.value)}
                            />
                        </Grid2>
                        <Grid2 lg={5.5} md={12} xs={12}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                color="primary"
                                type="text"
                                label="New 4 Digit PIN"
                                placeholder="Enter New PIN"
                                error={newPinError}
                                helperText={newPinError ? "Pin Code should be 4 digits." : ''}
                                value={newPin}
                                onChange={(e) => setNewPin(e.target.value)}
                            />
                        </Grid2>
                    </Grid2>}
                    
                    {user && <Grid2 xs={12} container justifyContent={'space-between'}>
                        <Grid2 xs={12}>
                            <Button
                                variant="contained"
                                fullWidth
                                onClick={() => handleUpdatePinCode()}
                            >
                                Update PIN
                            </Button>
                        </Grid2>
                    </Grid2>}
                </Grid2>
            </Grid2>
        </Box>   
    );
};

export default SendingSettings;

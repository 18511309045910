import React from 'react';
import "./NavbarForm.css"

function Navbar() {

    return (
    <div className="navbar">
        <div className = "title">
            <img className="companyLogo mobile" src="/logo3x.png"  alt='' style={{float: 'left', marginRight: '10px'}} />
            <div style={{textAlign: 'start',fontWeight: 'bold', fontSize:'15px'}}>
                Powered by<br/><span style={{fontSize:'25px',fontWeight: 'bold', textAlign: 'start'}}>Tapper</span>
            </div>
        </div>
        <div className="tapperUrl">
                <a href="https://www.usetapper.com" target="_blank" rel="noreferrer">
                    www.usetapper.com
                </a>
        </div>
    </div>
  );
}

export default Navbar;

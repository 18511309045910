import { MoreHoriz } from "@mui/icons-material";
import { IconButton, Menu, MenuItem } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { deleteEstimateOrInvoice, sendEstimateOrInvoiceReminder } from "../../api/estimates";


const EstimateMenuButton = ({estimateInfo, setRefreshTable}) => {
    
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    

    const handleViewDetails = () => {
        navigate(`/estimates/${estimateInfo.id}`);
        handleClose();
    }

    const handleEditEstimate = () => {
        navigate(`/estimates/edit/${estimateInfo.id}`);
        handleClose();
    }

    const [deleteButtonText, setDeleteButtonText] = useState('Delete Estimate');
    const handleDeleteEstimate = async () => {
        setDeleteButtonText('Deleting...');
        const response = await deleteEstimateOrInvoice(estimateInfo.id);
        if (response) {
            setDeleteButtonText('Deleted!');
        } else {
            setDeleteButtonText('Failed to Delete');
        }
        setTimeout(handleClose, 500);
        setTimeout(setRefreshTable(refreshTable => !refreshTable), 1000);
    }
 
    const [reminderButtonText, setReminderButtonText] = useState('Send Reminder');
    const handleSendReminder = async () => {
        setReminderButtonText('Sending ...')
        const response = await sendEstimateOrInvoiceReminder(estimateInfo.id);
        if (response) {
            setReminderButtonText('Success!');
        } else {
            setReminderButtonText('Failed to Send');
        }
        setTimeout(handleClose, 500);
        setTimeout(setRefreshTable(refreshTable => !refreshTable), 1000);
    }


    return (
        <Grid2 container>
            <IconButton
                onClick={handleClick}
            >
                <MoreHoriz/>
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                <MenuItem onClick={handleViewDetails}>View Estimate Details</MenuItem>
                {estimateInfo.status === 'SENT' && <MenuItem onClick={handleSendReminder}>{reminderButtonText}</MenuItem>}
                {estimateInfo.status !== 'ACCEPTED' && 
                <MenuItem onClick={handleEditEstimate}>
                    {(estimateInfo.status === 'SENT' || estimateInfo.status === 'REJECTED')
                        ? 'Change Order' 
                        : 'Edit Estimate'}
                </MenuItem>}
                <MenuItem onClick={handleDeleteEstimate}>{deleteButtonText}</MenuItem>
            </Menu>
        </Grid2>
    );
};

export default EstimateMenuButton;
import { Box, Button, InputAdornment, Modal, TextField, Typography } from "@mui/material";
import AppDrawer from "../appdrawer/appdrawer";
import { useNavigate, useParams } from "react-router-dom";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { Add, ChevronLeft, Edit, } from "@mui/icons-material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { getCustomerEstimates, getCustomerInvoices, getCustomers } from "../../api/contacts";
import EditCustomerModal from "./EditCustomerModal";
import { DataGrid } from "@mui/x-data-grid";
import { USDollar } from "../../constants/theme";
import InvoiceStatusTag from "../invoices/InvoiceStatusTag";
import './EditCustomerModal.css';
import { sendPaymentLink } from "../../api/payments";
import EstimateStatusTag from "../estimates/EstimateStatusTag";

const NewPaymentModal = ({showModal, setShowModal, customerInfo}) => {

    const [amount, setAmount] = useState(0);
    const [amountError, setAmountError] = useState(false);
    const [method, setMethod] = useState('CARD');
    const [sendButtonText, setSendButtonText] = useState('Send');

    const handleClose = useCallback(() => {
        setShowModal(false);
    }, [setShowModal]);

    const handleSendPaymentLink = useCallback(async () => {
        if (amount >= 0) {
            setSendButtonText('Sending...');
            const response = await sendPaymentLink(amount, customerInfo.email, method);
            if (response) {
                setSendButtonText('Sent Successfully!');
                setTimeout(handleClose, 1000);
            } else {
                setSendButtonText('Failed to Send');
            }
        } else {
            setAmountError(true);
        }
    }, [amount, customerInfo.email, handleClose, method])

    useEffect(() => {
        if (amount >= 0) {
            setAmountError(false);
        }
    }, [amount])

    return (
        <Modal
            open={showModal}
            onClose={handleClose}
        >
            <Box className="modal-container">
                <Grid2 container rowGap={4} flexGrow={1} justifyContent={'space-between'}>

                    <Grid2 xs={12}>
                        <Typography variant="h5" color={'primary'} fontWeight={'bold'}>
                            New Payment
                        </Typography>
                    </Grid2>

                    <Grid2 xs={12} container rowGap={2}>
                        <Grid2 xs={12}>
                            <Typography color={'primary'} fontWeight={'bold'}>Customer Information</Typography>
                        </Grid2>
                        <Grid2 xs={6} container>
                            <Typography>{`${customerInfo.first_name} ${customerInfo.last_name}`}</Typography>
                        </Grid2>
                        <Grid2 xs={6} container justifyContent={'flex-end'}>
                            <Typography>
                                {new Date().toLocaleDateString('en-US', {
                                    month: 'short', year:'numeric', day:'numeric',
                                })}
                            </Typography>
                        </Grid2>
                    </Grid2>

                    <Grid2 xs={12} container rowGap={2}>
                        <Grid2 xs={12}>
                            <Typography color={'primary'} fontWeight={'bold'}>Payment Amount</Typography>
                        </Grid2>
                        <Grid2 xs={12}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                type="number"
                                value={amount}
                                onChange={event => {
                                    setAmount(event.target.value);
                                }}
                                placeholder="Enter Amount"
                                label="Amount"
                                error={amountError}
                                helperText={amountError ? 'Please enter a valid amount in $USD' : ''}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">$</InputAdornment>
                                }}
                            />
                        </Grid2>
                    </Grid2>

                    <Grid2 xs={12} container rowGap={2}>
                        <Grid2 xs={12}>
                            <Typography color={'primary'} fontWeight={'bold'}>Select Payment Method:</Typography>
                        </Grid2>
                        <Grid2 xs={12}>
                            <Button
                                variant={method === 'CARD' ? 'contained' : 'outlined'}
                                fullWidth
                                onClick={() => setMethod('CARD')}
                            >
                                Request Card Payment
                            </Button>
                        </Grid2>
                        <Grid2 xs={12}>
                            <Button
                                variant={method === 'ACH' ? 'contained' : 'outlined'}
                                fullWidth
                                onClick={() => setMethod('ACH')}
                            >
                                Request Bank Transfer
                            </Button>
                        </Grid2>
                    </Grid2>

                    <Grid2 xs={12} container justifyContent={'space-between'}>
                        <Grid2 xs={5.5}>
                            <Button
                                variant="contained"
                                fullWidth
                                onClick={async () => await handleSendPaymentLink()}
                            >
                                {sendButtonText}
                            </Button>
                        </Grid2>
                        <Grid2 xs={5.5}>
                            <Button
                                variant="outlined"
                                fullWidth
                                onClick={handleClose}
                            >
                                Cancel
                            </Button>
                        </Grid2>
                    </Grid2>

                </Grid2>
            </Box>
        </Modal>
    );
};

const CustomerDetails = () => {


    const { id } = useParams();
    const navigate = useNavigate();
    const [paginationModelInvoice, setPaginationModelInvoice] = useState({
        pageSize: 10,
        page: 0,
    });
    const [paginationModelEstimate, setPaginationModelEstimate] = useState({
        pageSize: 10,
        page: 0,
    });
    const [invoices, setInvoices] = useState([]);
    const [estimates, setEstimates] = useState([]);
    const [totalInvoiceCount, setTotalInvoiceCount] = useState(0);
    const [totalEstimateCount, setTotalEstimateCount] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [customerInfo, setCustomerInfo] = useState({
        first_name: 'Loading...',
        last_name: 'Loading...',
        phone: 'Loading...',
        email: 'Loading...',
        street_address: 'Loading...',
        city: 'Loading...',
        state: 'Loading...',
        zip_code: 'Loading...',
        id,
    });
    const [showEditCustomerModal, setShowEditCustomerModal] = useState(false);
    const [showNewPaymentModal, setShowNewPaymentModal] = useState(false);

    const colsInvoice = useMemo(() => [
        {
            field: 'client_name', 
            headerName: 'Client Name', 
            flex: 35,
        },
        {
            field: 'total_amount', 
            headerName: 'Total Amount', 
            flex: 20, 
            renderCell: ({row, value}) => (
                row.status === 'PARTIALLY_PAID'
                    ? <Grid2 xs={12} container direction={'column'} alignItems={'flex-start'}>
                        <Typography fontSize={14}>
                            {USDollar.format(value)}
                        </Typography>
                        <Typography fontSize={11} fontWeight={'bold'} color={'error.dark'}>
                            Unpaid Amount: {USDollar.format(row.total_amount - row.partially_payment)}
                        </Typography>
                    </Grid2>
                    : USDollar.format(value)
            )
        },
        {
            field: 'created_at', 
            headerName: 'Date', 
            flex: 20, 
            valueGetter: ({value}) => (new Date(value).toLocaleDateString('en-US', {
                month: 'short', year:'numeric', day:'numeric',
            }))
        },
        {
            field: 'status',
            headerName: 'Status',
            flex: 15,
            renderCell: ({row}) => (<InvoiceStatusTag key={row.index} status={row.status}/>)
        },
    ], []);

    const colsEstimate = useMemo(() => [
        {
            field: 'client_name', 
            headerName: 'Client Name', 
            flex: 35,
        },
        {
            field: 'total_amount', 
            headerName: 'Total Amount', 
            flex: 20, 
            renderCell: ({row, value}) => (
                row.status === 'PARTIALLY_PAID'
                    ? <Grid2 xs={12} container direction={'column'} alignItems={'flex-start'}>
                        <Typography fontSize={14}>
                            {USDollar.format(value)}
                        </Typography>
                        <Typography fontSize={11} fontWeight={'bold'} color={'error.dark'}>
                            Unpaid Amount: {USDollar.format(row.total_amount - row.partially_payment)}
                        </Typography>
                    </Grid2>
                    : USDollar.format(value)
            )
        },
        {
            field: 'created_at', 
            headerName: 'Date', 
            flex: 20, 
            valueGetter: ({value}) => (new Date(value).toLocaleDateString('en-US', {
                month: 'short', year:'numeric', day:'numeric',
            }))
        },
        {
            field: 'status',
            headerName: 'Status',
            flex: 15,
            renderCell: ({row}) => (<EstimateStatusTag key={row.index} status={row.status}/>)
        },
    ], []);

    useEffect(() => {
        (async () => {
            if (!showEditCustomerModal) {
                // [TODO] Update this for totalInvoiceCount & totalEstimateCount when API gets updated
                setIsLoading(true);
                // fetch all data
                let responses = await Promise.all([
                    getCustomers(0, 0, ''),
                    getCustomerInvoices(1, 1, id), 
                    getCustomerEstimates(1, 1, id),
                    getCustomerInvoices(paginationModelInvoice.page + 1, paginationModelInvoice.pageSize, id),
                    getCustomerEstimates(paginationModelEstimate.page + 1, paginationModelEstimate.pageSize, id),
                ]);
                
                console.log('RESPONSES: ', responses);

                // set customer info
                if (responses[0]) {
                    setCustomerInfo(responses[0].data.result.filter((customer) => (customer.id === Number(id)))[0]);
                } else {
                    setCustomerInfo({
                        first_name: 'Error',
                        last_name: 'Error',
                        phone: 'Error',
                        email: 'Error',
                        street_address: 'Error',
                        city: 'Error',
                        state: 'Error',
                        zip_code: 'Error',
                        id,
                    });
                }

                // set fake invoices size 
                if (responses[1]) {
                    setTotalInvoiceCount(responses[1].data.count);
                } else {
                    setTotalInvoiceCount(0);
                }
                // set fake estimates size 
                if (responses[2]) {
                    setTotalEstimateCount(responses[2].data.count);
                } else {
                    setTotalEstimateCount(0);
                }

                // set customer invoices
                if (responses[3]) {
                    setInvoices(responses[3].data.result);
                } else {
                    setInvoices([]);
                }
                setIsLoading(false);
                // set customer estimates
                if (responses[4]) {
                    setEstimates(responses[4].data.result);
                } else {
                    setEstimates([]);
                }
                setIsLoading(false);
            }
        })();
    }, [id, paginationModelEstimate, paginationModelInvoice, showEditCustomerModal]);

    return (
        <Box sx={{ display: 'flex', }}>
            <AppDrawer/>
            <EditCustomerModal
                showModal={showEditCustomerModal}
                setShowModal={setShowEditCustomerModal}
                editCustomerData={customerInfo}
            />
            <NewPaymentModal
                showModal={showNewPaymentModal}
                setShowModal={setShowNewPaymentModal}
                customerInfo={customerInfo}
            />
            <Grid2 container p={4} rowGap={4} flexGrow={1} justifyContent={'space-between'}>

                <Grid2 container xs={6} direction={'column'} alignItems={'flex-start'}>
                    <Button startIcon={<ChevronLeft/>} onClick={() => navigate('/customers')}>
                        Back
                    </Button>
                    <Typography color={'primary'} variant="h3" fontWeight={'bold'}>
                        Customer Details
                    </Typography>
                </Grid2>
                <Grid2 container xs={6} justifyContent={'flex-end'} alignItems={'center'}>
                    <Button 
                        variant="outlined" 
                        startIcon={<Edit/>}
                        onClick={() => setShowEditCustomerModal(true)}
                    >
                        Edit Customer Info
                    </Button>
                </Grid2>

                <Grid2 xs={12} container>
                    <Typography variant="h4">{customerInfo?.first_name} {customerInfo?.last_name}</Typography>
                </Grid2>
                <Grid2 xs={6} container direction={'column'} alignItems={'flex-start'}>
                    <Typography variant="h6" fontWeight={'bold'} color={'primary'}>Location Information:</Typography>
                    <Typography>{customerInfo.street_address}</Typography>
                    <Typography>{customerInfo.city}, {customerInfo.state}, {customerInfo.zip_code}</Typography>
                </Grid2>
                <Grid2 xs={6} container direction={'column'} alignItems={'flex-end'}> 
                    <Typography variant="h6" fontWeight={'bold'} color={'primary'}>Contact Information:</Typography>
                    <Typography>{customerInfo.email}</Typography>
                    <Typography>{customerInfo.phone}</Typography>
                </Grid2>

                <Grid2 xs={6} container>
                    <Typography variant="h5" fontWeight={'bold'} color={'primary'}>History</Typography>
                </Grid2>
                <Grid2 xs={6} container gap={2} justifyContent={'flex-end'}>
                    <Button 
                        variant="outlined" 
                        startIcon={<Add/>}
                        onClick={() => navigate(`/estimates/create/${id}`)}
                    >
                        New Estimate
                    </Button>
                    <Button 
                        variant="outlined" 
                        startIcon={<Add/>}
                        onClick={() => navigate(`/invoices/create/${id}`)}
                    >
                        New Invoice
                    </Button>
                    <Button 
                        variant="outlined" 
                        startIcon={<Add/>}
                        onClick={() => setShowNewPaymentModal(true)}
                    >
                        New Payment
                    </Button>
                </Grid2>

                <Grid2 xs={12} py={1} className="data-table-wrapper">
                    <Typography align="left" variant="h6" fontWeight={'bold'} color={'primary'}>Invoices</Typography>
                    <DataGrid
                        className="data-table"
                        rows={invoices}
                        rowCount={totalInvoiceCount}
                        columns={colsInvoice}
                        pageSizeOptions={[5, 10, 20]}
                        loading={isLoading}
                        paginationMode={"server"}
                        paginationModel={paginationModelInvoice}
                        onPaginationModelChange={setPaginationModelInvoice}
                        disableRowSelectionOnClick
                    />
                </Grid2>

                <Grid2 xs={12} py={1}>
                    <Typography align="left" variant="h6" fontWeight={'bold'} color={'primary'}>Estimates</Typography>
                    <DataGrid
                        className="data-table"
                        rows={estimates}
                        rowCount={totalEstimateCount}
                        columns={colsEstimate}
                        pageSizeOptions={[5, 10, 20]}
                        loading={isLoading}
                        paginationMode={"server"}
                        paginationModel={paginationModelEstimate}
                        onPaginationModelChange={setPaginationModelEstimate}
                        disableRowSelectionOnClick
                    />
                </Grid2>

            </Grid2>
        </Box>
    );
};

export default CustomerDetails;
import { Box, Button, Modal, Radio, Typography } from "@mui/material";
import { useCallback } from "react";
import '../payments/PaymentLinkModal.css';
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { DataGrid } from "@mui/x-data-grid";
import { useMemo } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { getCustomers } from "../../api/contacts";


const AddFromCustomerModal = ({showModal, setShowModal, setInvoiceData}) => {

    const [paginationModel, setPaginationModel] = useState({
        pageSize: 10,
        page: 0,
    });
    const [customers, setCustomers] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [selectedCustomerID, setSelectedCustomerID] = useState(-1);
    const [selectedCustomerData, setSelectedCustomerData] = useState({
        first_name: '',
        last_name: '',
        phone: '',
        email: '',
        street_address: '',
        city: '',
        state: '',
        zip_code: '',
    });
    const [isLoading, setIsLoading] = useState(false);

    const handleClose = useCallback(() => {
        setSelectedCustomerID(-1);
        setSelectedCustomerData({
            first_name: '',
            last_name: '',
            phone: '',
            email: '',
            street_address: '',
            city: '',
            state: '',
            zip_code: '',
        });
        setShowModal(false);
    }, [setShowModal]);

    const handlePrefillCustomer = useCallback(() => {
        const {
            first_name,
            last_name,
            phone,
            email,
            street_address,
            city,
            state,
            zip_code,
        } = selectedCustomerData; 
        setInvoiceData(invoiceData => ({
            ...invoiceData,  
            client_name: `${first_name} ${last_name}`,
            phone,
            email,
            street_address,
            city,
            state,
            zip_code,
        }));
        handleClose();
    }, [handleClose, selectedCustomerData, setInvoiceData]);

    // fetch customers
    useEffect(() => {
        (async () => {
            // [TODO] Update this for when API maybe gets updated
            setIsLoading(true);
            let response = await getCustomers(paginationModel.page, paginationModel.pageSize, '');
                if (response) {
                    setCustomers(response.data.result);
                    setTotalCount(response.data.count);
                } else {
                    setCustomers([]);
                    setTotalCount(0);
                }
            setIsLoading(false);
        })();
    }, [paginationModel]);

    const cols = useMemo(() => [
        {
            field: 'id',
            headerName: '',
            flex: 10,
            renderCell: ({value, row}) => (
                <Radio
                    checked={selectedCustomerID === value}
                    value={value}
                    onChange={() => {
                        setSelectedCustomerID(value);
                        setSelectedCustomerData(row);
                    }}
                />
            ),
        },
        {
            field: 'first_name', 
            headerName: 'Customer Name', 
            flex: 35,
            valueGetter: ({row}) => (`${row.first_name} ${row.last_name}`),
        },
        {
            field: 'city', 
            headerName: 'Location', 
            flex: 35,
            valueGetter: ({row}) => (`${row.city}, ${row.state}`),
        },
    ], [selectedCustomerID]);

    return (
        <Modal
            open={showModal}
            onClose={handleClose}
        >
            <Box className="big-table-modal-container">
                <Grid2 container gap={4} flexGrow={1}>
                    <Grid2 xs={12}>
                        <Typography variant="h5" color={'primary'} fontWeight={'bold'}>
                            Add a Contact
                        </Typography>
                    </Grid2>
                    <Grid2 xs={12} className="data-table-wrapper">
                        <DataGrid
                            className="data-table"
                            rows={customers}
                            rowCount={totalCount}
                            columns={cols}
                            pageSizeOptions={[5, 10, 20]}
                            loading={isLoading}
                            paginationMode={"server"}
                            paginationModel={paginationModel}
                            onPaginationModelChange={setPaginationModel}
                            disableRowSelectionOnClick
                        />
                    </Grid2>
                    <Grid2 xs={12} container justifyContent={'space-between'}>
                        <Grid2 xs={5.5}>
                            <Button
                                variant="contained"
                                fullWidth
                                onClick={handlePrefillCustomer}
                            >
                                Add Info
                            </Button>
                        </Grid2>
                        <Grid2 xs={5.5}>
                            <Button
                                variant="outlined"
                                fullWidth
                                onClick={handleClose}
                            >
                                Cancel
                            </Button>
                        </Grid2>
                    </Grid2>
                </Grid2>
            </Box>
        </Modal>
    );
};

export default AddFromCustomerModal;
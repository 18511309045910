import { Box, Button, Divider, InputAdornment, TextField, Typography, Switch, FormControlLabel } from "@mui/material";
import AppDrawer from "../../components/appdrawer/appdrawer";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import AccountSideBar from "../../components/account/AccountSideBar";
import { useCallback, useEffect, useMemo, useState, useContext } from "react";
import { getUser, setUserMarkups } from "../../api/user";
import isEmpty from "validator/lib/isEmpty";
import StripeLogo from "../../components/icons/StripeLogo";
import { useNavigate } from "react-router-dom";
import { AppContext } from '../../contexts/AppContext';

const ManageMarkup = () => {
    const { isTablet } = useContext(AppContext);
    const navigate = useNavigate();
    const [userData, setUserData] = useState({
        merchandise_cost_markup: 0,
        labor_cost_markup: 0,
    });
    const [userValidation, setUserValidation] = useState({
        merchandise_cost_markup: false,
        labor_cost_markup: false,
    });

    const validator = useCallback(() => {
        if (userData?.merchandise_cost_markup < 0) {
            setUserValidation(userValidation => ({...userValidation, merchandise_cost_markup: true}));
            return false;
        }
        if (userData?.labor_cost_markup < 0) {
            setUserValidation(userValidation => ({...userValidation, labor_cost_markup: true}));
            return false;
        }
        return true;
    }, [userData]);

    // clear error status
    useEffect(() => {
        if (userData?.merchandise_cost_markup >= 0) {
            setUserValidation(userValidation => ({...userValidation, merchandise_cost_markup: false}));
        }
        if (userData?.labor_cost_markup >= 0) {
            setUserValidation(userValidation => ({...userValidation, labor_cost_markup: false}));
        }
    }, [userData]);

    // fetch user data
    useEffect(() => {
        (async () => {
            let response = await getUser();
            if (response) {
                setUserData(response.data);
            }
        })();
    }, []);

    const [saveButtonText, setSaveButtonText] = useState('Save Changes');
    const handleSaveChanges = useCallback(async () => {
        if (validator()) {
            setSaveButtonText('Saving...');
            const { merchandise_cost_markup, labor_cost_markup } = userData;
            let response = await setUserMarkups(merchandise_cost_markup, labor_cost_markup);
            console.log(response);
            if (response) {
                setSaveButtonText('Saved Successfully!');
            } else {
                setSaveButtonText('Failed to Save!');
            }
            setTimeout(() => setSaveButtonText('Save Changes'), 1500);
        }
    }, [userData, validator]);

    return (
        <Box sx={{ display: 'flex', }}>
            <AppDrawer/>
            <Grid2 xs container p={4} gap={4}>

                <Grid2 container xs={12} direction={'column'} alignItems={'flex-start'}>
                    <Typography color={'primary'} variant="h3" fontWeight={'bold'}>
                        Account
                    </Typography>
                    <Typography color={'primary'} variant="body2">
                        Manage your account info and subscriptions
                    </Typography>
                </Grid2>

                <AccountSideBar />

                {!isTablet && <Grid2 xs={'auto'}>
                    <Divider orientation="vertical" variant="fullWidth" sx={{ bgcolor: 'black'}}/>
                </Grid2>}

                <Grid2 xs container p={1} rowGap={4}>
                    <Grid2 lg={10} md={12} xs={12} container p={2} gap={2} 
                        borderRadius={4} bgcolor={'secondary.light'}
                        height={'min-content'} 
                        justifyContent={'space-between'}
                    >
                        <Grid2 xs={12} container alignItems={'center'} gap={1}>
                            <Typography variant="h6" fontWeight={'bold'}>
                                Markups
                            </Typography>
                        </Grid2>
                        <Grid2 xs={12} container gap={1}>
                            <Typography variant="body1">
                                Control markups on estimates & invoices to protect your bottom-line.
                            </Typography>
                        </Grid2>
                    </Grid2>
                    <Grid2 lg={10} md={12} xs={12} container rowGap={8}>
                        <Grid2 lg={6} md={6} xs={12} textAlign={"left"}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                color="primary"
                                type="number"
                                value={userData?.merchandise_cost_markup}
                                error={userValidation?.merchandise_cost_markup}
                                helperText={userValidation?.merchandise_cost_markup ? "Please enter a valid percentage %" : ''}
                                label="Material Cost Markup"
                                placeholder="Enter Material Cost Markup %"
                                onChange={event => setUserData({...userData, merchandise_cost_markup: event.target.value})}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">%</InputAdornment>
                                }}
                            />
                        </Grid2>
                        <Grid2 lg={6} md={6} xs={12} textAlign="right">
                            <FormControlLabel
                                value="Auto-applied"
                                control={<Switch color="primary" />}
                                label="Auto-applied"
                                labelPlacement="start"
                            />
                        </Grid2>
                        <Grid2 lg={6} md={6} xs={12} textAlign={"left"}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                color="primary"
                                type="number"
                                value={userData?.labor_cost_markup}
                                error={userValidation?.labor_cost_markup}
                                helperText={userValidation?.labor_cost_markup ? "Please enter a valid percentage %" : ''}
                                label="Labor Cost Markup"
                                placeholder="Enter Labor Cost Markup %"
                                onChange={event => setUserData({...userData, labor_cost_markup: event.target.value})}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">%</InputAdornment>
                                }}
                            />
                        </Grid2>
                        <Grid2 lg={6} md={6} xs={12} textAlign="right">
                            <FormControlLabel
                                value="Auto-applied"
                                control={<Switch color="primary" />}
                                label="Auto-applied"
                                labelPlacement="start"
                            />
                        </Grid2>
                        <Grid2 xs={12}>
                            <Button
                                variant="contained"
                                fullWidth
                                onClick={handleSaveChanges}
                            >
                                {saveButtonText}
                            </Button>
                        </Grid2>
                    </Grid2>
                </Grid2>
            </Grid2>    
        </Box>
    );
};

export default ManageMarkup;
export default function SetupPaymentIcon() {
    return (
        <svg width="227" height="205" viewBox="0 0 227 205" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Group">
        <g id="Group_2">
        <path id="Vector" d="M110.446 191.803C156.308 191.803 193.486 154.624 193.486 108.763C193.486 62.901 156.308 25.7227 110.446 25.7227C64.5841 25.7227 27.4058 62.901 27.4058 108.763C27.4058 154.624 64.5841 191.803 110.446 191.803Z" fill="white" stroke="#587E81" strokeWidth="1.45429" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <g id="Group_3">
        <path id="Vector_2" d="M83.3961 111.235C83.3961 111.235 90.0858 114.871 92.2672 118.07C94.4487 121.415 93.1398 123.888 99.975 129.268C106.81 134.649 108.119 136.395 108.555 138.285C108.992 140.03 92.1218 135.231 87.7589 132.032C83.2506 128.832 81.9418 127.814 79.324 123.16C76.7063 118.652 76.27 111.09 76.27 111.09H83.3961V111.235Z" fill="#00C49C" stroke="#587E81" strokeWidth="1.45429" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path id="Vector_3" d="M127.316 142.506C127.316 142.506 131.97 142.215 137.787 144.833C143.604 147.45 147.967 141.779 148.403 144.833C148.839 147.887 142.441 154.14 137.932 151.377C133.279 148.759 127.025 144.833 127.316 142.506Z" fill="#00C49C" stroke="#B7E3DA" strokeWidth="1.45429" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path id="Vector_4" d="M143.458 134.064C143.458 134.064 140.841 123.447 143.458 121.993C146.076 120.684 148.403 114.285 149.712 115.012C151.021 115.74 159.019 111.086 157.274 115.012C155.529 118.939 155.093 125.047 157.565 126.792C160.037 128.537 167.018 129.701 164.836 131.882C162.51 134.064 143.895 136.1 143.458 134.064Z" fill="#00C49C" stroke="#B7E3DA" strokeWidth="1.45429" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path id="Vector_5" d="M159.02 144.83C159.02 144.83 160.619 141.194 165.128 142.503C169.636 143.812 166.582 150.211 159.02 144.83Z" fill="#00C49C" stroke="#B7E3DA" strokeWidth="1.45429" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path id="Vector_6" d="M127.316 27.3233C127.316 27.3233 123.971 33.7222 123.098 35.6127C122.226 37.3579 114.663 46.2291 110.446 47.9742C106.228 49.7194 106.665 50.1557 100.847 53.3551C95.0303 56.4091 97.7934 59.1723 97.7934 59.1723C97.7934 59.1723 93.8668 86.6584 97.3571 90.4395C100.993 94.2207 100.993 106.291 100.993 106.291C100.993 106.291 92.9943 104.546 83.6868 98.2927C74.3793 92.0392 83.6868 98.2927 79.7602 92.0393C75.5428 85.6404 64.0539 84.3315 60.8544 82.5864C57.8004 80.8412 47.0386 83.4589 44.8572 82.5864C42.6758 81.7138 34.5317 74.4423 34.5317 74.4423C47.6204 45.6474 76.5608 25.5781 110.3 25.5781C116.263 25.5781 121.789 26.1598 127.316 27.3233Z" fill="#00C49C" stroke="#B7E3DA" strokeWidth="1.45429" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path id="Vector_7" d="M148.403 182.642C137.06 188.459 124.116 191.804 110.446 191.804C104.774 191.804 99.1026 191.222 93.7217 190.059C94.3034 189.477 103.32 182.205 119.172 179.297C135.605 176.243 128.043 173.48 129.788 171.734C131.533 169.989 134.296 172.171 137.932 177.552C141.422 182.642 147.821 182.642 148.403 182.642Z" fill="#00C49C" stroke="#587E81" strokeWidth="1.45429" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path id="Vector_8" d="M193.486 108.762C193.486 110.943 193.341 112.979 193.195 115.161L172.981 97.7094C172.981 97.7094 170.363 92.9103 168.763 91.456C167.163 90.1471 173.999 83.8937 172.981 78.2219C171.963 72.4048 165.127 60.4796 164.691 59.1707C164.255 57.8618 166.727 48.6998 167.018 47.9727C183.306 63.0973 193.486 84.7662 193.486 108.762Z" fill="#00C49C" stroke="#587E81" strokeWidth="1.45429" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        </g>
        <path id="Vector_9" d="M193.486 108.759C193.486 110.504 193.486 112.249 193.34 113.849C167.454 113.704 146.512 92.7619 146.512 66.8755C146.512 56.4046 149.857 46.8063 155.674 38.9531C178.507 53.9323 193.486 79.5279 193.486 108.759Z" fill="#DBF9F3"/>
        </g>
        <g id="Group_4">
        <path id="Vector_10" d="M103.32 151.084H9.95411C5.00952 151.084 0.9375 147.012 0.9375 142.067V118.071C0.9375 113.127 5.00952 109.055 9.95411 109.055H103.32C108.264 109.055 112.336 113.127 112.336 118.071V142.067C112.191 147.012 108.264 151.084 103.32 151.084Z" fill="white" stroke="#587E81" strokeWidth="1.45429" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path id="Vector_11" d="M45.2934 134.652H23.3336C21.4431 134.652 19.9888 133.198 19.9888 131.307V128.544C19.9888 126.654 21.4431 125.199 23.3336 125.199H45.4389C47.3295 125.199 48.7837 126.654 48.7837 128.544V131.307C48.7837 133.198 47.184 134.652 45.2934 134.652Z" fill="white" stroke="#00C49C" strokeWidth="1.45429" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path id="Vector_12" d="M64.9266 134.653C67.5771 134.653 69.7258 132.504 69.7258 129.854C69.7258 127.203 67.5771 125.055 64.9266 125.055C62.2761 125.055 60.1274 127.203 60.1274 129.854C60.1274 132.504 62.2761 134.653 64.9266 134.653Z" fill="#DBF9F3" stroke="#B7E3DA" strokeWidth="1.45429" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path id="Vector_13" d="M84.1229 134.653C86.7734 134.653 88.9221 132.504 88.9221 129.854C88.9221 127.203 86.7734 125.055 84.1229 125.055C81.4724 125.055 79.3237 127.203 79.3237 129.854C79.3237 132.504 81.4724 134.653 84.1229 134.653Z" fill="#00C49C" stroke="#B7E3DA" strokeWidth="1.45429" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        </g>
        <g id="Group_5">
        <path id="Vector_14" d="M179.089 94.9473C205.032 94.9473 226.062 73.9164 226.062 47.9736C226.062 22.0308 205.032 1 179.089 1C153.146 1 132.115 22.0308 132.115 47.9736C132.115 73.9164 153.146 94.9473 179.089 94.9473Z" fill="white" stroke="#587E81" strokeWidth="1.45429" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path id="Vector_15" d="M179.089 81.7174C197.723 81.7174 212.829 66.6117 212.829 47.9778C212.829 29.344 197.723 14.2383 179.089 14.2383C160.455 14.2383 145.35 29.344 145.35 47.9778C145.35 66.6117 160.455 81.7174 179.089 81.7174Z" fill="#DBF9F3" stroke="#B7E3DA" strokeWidth="1.45429" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path id="Vector_16" d="M181.853 43.0284C178.508 41.7196 177.054 40.847 177.054 39.9744C177.054 38.6655 178.799 38.5201 179.817 38.5201C182.58 38.5201 184.325 39.3927 185.779 40.1198L187.961 41.1378L190.578 32.4121L188.979 31.6849C187.234 30.8124 185.198 30.2306 183.162 29.9398V25.1406H174.727V30.5215C169.782 31.9758 166.583 35.9024 166.583 40.847C166.583 47.3913 171.963 50.1544 176.763 51.8996C180.835 53.3539 180.835 54.5173 180.835 55.099C180.835 56.5533 179.09 56.9896 177.635 56.9896C174.436 56.9896 171.818 55.6808 170.8 55.099L168.473 53.6447L165.855 62.3705L167.164 63.2431C168.909 64.4065 171.673 65.2791 174.436 65.7154V70.8054H182.871V65.1337C188.106 63.6794 191.451 59.6073 191.451 54.3719C191.306 47.5367 186.216 44.7736 181.853 43.0284Z" fill="#00C49C" stroke="#B7E3DA" strokeWidth="1.45429" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        </g>
        <g id="Group_6">
        <path id="Vector_17" d="M155.383 203.877H62.0171C57.0725 203.877 53.0005 199.805 53.0005 194.86V170.864C53.0005 165.92 57.0725 161.848 62.0171 161.848H155.383C160.327 161.848 164.399 165.92 164.399 170.864V194.86C164.254 199.95 160.327 203.877 155.383 203.877Z" fill="white" stroke="#587E81" strokeWidth="1.45429" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path id="Vector_18" d="M97.3564 187.59H75.3966C73.5061 187.59 72.0518 186.135 72.0518 184.245V181.482C72.0518 179.591 73.5061 178.137 75.3966 178.137H97.5019C99.3924 178.137 100.847 179.591 100.847 181.482V184.245C100.847 185.99 99.247 187.59 97.3564 187.59Z" fill="white" stroke="#00C49C" strokeWidth="1.45429" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path id="Vector_19" d="M116.99 187.446C119.64 187.446 121.789 185.297 121.789 182.647C121.789 179.996 119.64 177.848 116.99 177.848C114.339 177.848 112.19 179.996 112.19 182.647C112.19 185.297 114.339 187.446 116.99 187.446Z" fill="#DBF9F3" stroke="#B7E3DA" strokeWidth="1.45429" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path id="Vector_20" d="M136.186 187.446C138.836 187.446 140.985 185.297 140.985 182.647C140.985 179.996 138.836 177.848 136.186 177.848C133.535 177.848 131.387 179.996 131.387 182.647C131.387 185.297 133.535 187.446 136.186 187.446Z" fill="#00C49C" stroke="#B7E3DA" strokeWidth="1.45429" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        </g>
        <path id="Vector_21" d="M23.77 151.082V170.86C23.77 176.823 28.5692 181.622 34.5318 181.622H53.1467" stroke="#587E81" strokeWidth="1.45429" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path id="Vector_22" d="M115.681 129.85H119.753C123.389 129.85 126.734 127.959 128.624 124.905L152.329 86.5117" stroke="#B7E3DA" strokeWidth="1.45429" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path id="Vector_23" d="M23.7693 169.987C26.5001 169.987 28.7139 167.773 28.7139 165.042C28.7139 162.311 26.5001 160.098 23.7693 160.098C21.0385 160.098 18.8247 162.311 18.8247 165.042C18.8247 167.773 21.0385 169.987 23.7693 169.987Z" fill="#00C49C" stroke="#587E81" strokeWidth="1.45429" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        </g>
        </svg>
    );
}
import React, { useState, useContext } from 'react';

import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { Box, Button, Typography, TextField } from '@mui/material';

import AppDrawer from '../../components/appdrawer/appdrawer';
import PayOthersInitializeModal from '../../components/payoutRegistration/PayOthersInitializeModal';

import './payoutRegistration.css'
import { ChevronLeft } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { createCheckbookWallet } from '../../api/payout';
import { AppContext } from '../../contexts/AppContext';
import AddressInput from '../../components/util/addressInput';

const PayoutRegistrationIndividual = () => {
    const { setLoading } = useContext(AppContext);
    const navigate = useNavigate();
    const [dob, setDob] = useState('2000-01-01')
    const [dobError, setDobError] = useState(false)
    const [showModal, setShowModal] = useState(false);
    const [firstName, setFirstName] = useState();
    const [firstNameError, setFirstNameError] = useState(false);
    const [lastName, setLastName] = useState();
    const [lastNameError, setLastNameError] = useState(false);
    const [ssn, setSsn] = useState();
    const [ssnError, setSsnError] = useState(false);
    const [address, setAddress] = useState();
    const [addressError, setAddressError] = useState(false);
    const [city, setCity] = useState();
    const [cityError, setCityError] = useState(false);
    const [state, setState] = useState();
    const [stateError, setStateError] = useState(false);
    const [zip, setZip] = useState();
    const [zipError, setZipError] = useState(false);

    const resetErrorFields = () => {
        setFirstNameError(false);
        setLastNameError(false);
        setSsnError(false);
        setAddressError(false);
        setCityError(false);
        setStateError(false);
        setZipError(false);
        setDobError(false);
    }

    const onClickCreateAccount = () => {
        resetErrorFields();
        if (!registerValidate()) return;
        setLoading(true);
        createCheckbookWallet(
            false,
            firstName,
            lastName,
            address,
            city,
            state,
            zip,
            ssn,
            dob
        ).then(result => {
            setLoading(false);
            setShowModal(true)
        })
        .catch(error => {
            setLoading(false);
            alert(error.response.data.message);
        });
    }

    const registerValidate = () => {
        const regex = /^\d{4}$/;
        if (!firstName) {
            setFirstNameError(true);
            return false;
        }
        if (!lastName) {
            setLastNameError(true);
            return false;
        }
        if (!dob) {
            setDobError(true);
            return false;
        }
        if (!regex.test(ssn)) {
            setSsnError(true);
            return false;
        }
        if (!address) {
            setAddressError(true);
            return false;
        }
        if (!city) {
            setCityError(true);
            return false;
        }
        if (!state) {
            console.log(state);
            setStateError(true);
            return false;
        }
        if (!zip) {
            setZipError(true);
            return false;
        }
        return true;
    }

    return (
        <Box sx={{ display: 'flex', }}>
            <AppDrawer/>
            <PayOthersInitializeModal showModal={showModal} setShowModal={setShowModal} />
            <Grid2 container padding={4} rowGap={8}>
                <Grid2 container xs={12} justifyContent={'space-between'} alignItems={'center'}>
                    <Grid2 container xs={12} direction={'column'} alignItems={'flex-start'}>
                        <Button startIcon={<ChevronLeft/>} onClick={() => navigate('/send-payment')}>
                            Back
                        </Button>
                        <Typography color={'primary'} variant="h3" fontWeight={'bold'}>
                            Payment Registration
                        </Typography>
                    </Grid2>
                </Grid2>
                <Grid2 container xs={12}>
                    <Grid2 container xs={12}>
                        <Typography variant="h4">Personal Information</Typography>
                    </Grid2>
                    <Grid2 container item lg={6} md={12} xs={12} className="text-container">
                        <TextField
                            variant="outlined"
                            fullWidth
                            color="primary"
                            type="text"
                            label="First Name *"
                            placeholder="Enter First Name..."
                            error={firstNameError}
                            helperText={firstNameError ? "First Name is required." : ''}
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                        />
                    </Grid2>
                    <Grid2 container item lg={6} md={12} xs={12} className="text-container">
                        <TextField
                            variant="outlined"
                            fullWidth
                            color="primary"
                            type="text"
                            label="Last Name *"
                            placeholder="Enter Last Name..."
                            error={lastNameError}
                            helperText={lastNameError ? "Last Name is required." : ''}
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                        />
                    </Grid2>
                    <Grid2 container lg={6} md={12} xs={12} className="text-container">
                        <TextField
                            variant="outlined"
                            fullWidth
                            color="primary"
                            type="date"
                            label="Date of Birth *"
                            placeholder="MM/DD/YYYY"
                            error={dobError}
                            helperText={dobError ? "Date of Birth is required." : ''}
                            value={dob}
                            onChange={(e) => setDob(e.target.value)}
                        />
                    </Grid2>
                    <Grid2 container lg={6} md={12} xs={12} className="text-container">
                        <TextField
                            variant="outlined"
                            fullWidth
                            color="primary"
                            type="text"
                            label="Last 4 digits of SSN *"
                            placeholder="Enter SSN..."
                            // helperText={"*For identity verification only."}
                            error={ssnError}
                            helperText={ssnError ? "Please a valid SSN number" : ''}
                            value={ssn}
                            onChange={(e) => setSsn(e.target.value)}
                        />
                    </Grid2>
                    <Grid2 container item xs={12} className="text-container">
                        <AddressInput
                            value={address}
                            setValue={(address, city, state, zip) => {
                                if(address) {
                                    setAddress(address);
                                    setCity(city);
                                    setState(state);
                                    setZip(zip);
                                }
                                else {
                                    setAddress(address);
                                }
                            }}
                            label="Street Address"
                            placeholder="Enter Street Address..."
                            error={addressError}
                            helperText={addressError ? "Address is required." : ''}
                        />
                    </Grid2>
                    <Grid2 container item lg={4} md={12} xs={12} className="text-container">
                        <TextField
                            variant="outlined"
                            fullWidth
                            color="primary"
                            type="text"
                            label="City"
                            placeholder="Enter City..."
                            error={cityError}
                            helperText={cityError ? "City is required." : ''}
                            InputLabelProps={{ shrink: city ? true : false }}
                            value={city}
                            onChange={(e) => setCity(e.target.value)}
                        />
                    </Grid2>
                    <Grid2 container item lg={4} md={12} xs={12} className="text-container">
                        <TextField
                            variant="outlined"
                            fullWidth
                            color="primary"
                            type="text"
                            label="State"
                            placeholder="Enter State..."
                            error={stateError}
                            helperText={stateError ? "State is required." : ''}
                            InputLabelProps={{ shrink: state ? true : false }}
                            value={state}
                            onChange={(e) => setState(e.target.value)}
                        />
                    </Grid2>
                    <Grid2 container item lg={4} md={12} xs={12} className="text-container">
                        <TextField
                            variant="outlined"
                            fullWidth
                            color="primary"
                            type="text"
                            label="Zip Code"
                            placeholder="Enter Zip Code..."
                            error={zipError}
                            helperText={zipError ? "Zip Code is required." : ''}
                            InputLabelProps={{ shrink: zip ? true : false }}
                            value={zip}
                            onChange={(e) => setZip(e.target.value)}
                        />
                    </Grid2>
                </Grid2>
                <Grid2 container xs={12} justifyContent={"flex-end"}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => onClickCreateAccount()}
                    >Next</Button>
                </Grid2>
            </Grid2>
        </Box>
    );
};

export default PayoutRegistrationIndividual;

import { createTheme } from "@mui/material";

const theme = createTheme({
    palette: {
        primary: {
            main: '#294D51',
            light: '#587E81',
        },
        secondary: {
            dark: '#92B8BC',
            main: '#B7DEE3',
            light: '#DBEFF1',
        },
        success: {
            main: '#00C49C',
            light: '#B3EDE1',
        },
        error: {
            dark: '#E63A27',
            main: '#FF7D6E',
            light: '#FFD0CB',
        },
        warning: {
            main: '#FFB24D',

        },
        grey: {
            "50": '#BFCACB',
        }
    },
    // [TODO] Figure out font licensing
    // typography: {
    //     fontFamily: 'Avenir'
    // }
});

export const USDollar = Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

export default theme;
import { useState, createContext, useContext } from "react";
import ReactDOM from "react-dom/client";

export const AppContext = createContext();

export default function AppProvider({children}) {
  const [loading, setLoading] = useState(false);
  const [menuOpen, setMenuOpen] = useState(true);
  const [isMobile, setIsMobile] = useState(false);
  const [isTablet, setIsTablet] = useState(false);

  return (
    <AppContext.Provider value={{loading, setLoading, menuOpen, setMenuOpen, isMobile, setIsMobile, isTablet, setIsTablet}}>
      {children}
    </AppContext.Provider>
  );
}
import { Box, Typography } from "@mui/material";

const LoadingScreen = () => {
    return (
        <Box className="container">
            <Typography variant="h1">
                Loading ...
            </Typography>
        </Box>
    );
};

export default LoadingScreen;
import { useContext, useLayoutEffect } from 'react';
import { Box, IconButton } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import { AppContext } from '../contexts/AppContext';

const Layout = ({children}) => {
    const { setMenuOpen, setIsMobile, setIsTablet } = useContext(AppContext);
    useLayoutEffect(() => {
        function updateSize() {
            if (window.innerWidth > 768) {
                setIsMobile(false);
            }
            else {
                setIsMobile(true);
            }

            if (window.innerWidth > 1200) {
                setIsTablet(false);
            }
            else {
                setIsTablet(true);
            }
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    return (
        <Box>
            {!window.location.pathname.includes('/review/') && <IconButton className="burger-button-black" aria-label="delete" onClick={() => setMenuOpen(true)} style={{display: 'none'}}>
                <MenuIcon />
            </IconButton>}
            {children}
        </Box>
    );
};

export default Layout;
import { useEffect, useState } from "react";
import { createAccount, getUser } from '../api/user';
import { useNavigate } from 'react-router';
import { useAuth } from "../contexts/AuthContext";
import axios from 'axios';

const SocialAccountVerification = () => {
    const navigate = useNavigate();
    const { user, signOut, setRegistering } = useAuth();

    useEffect(() => {
        if(user?.signInUserSession?.accessToken?.jwtToken) {
            getUser().then((res) => {
                if(res.data.first_name && res.data.last_name && res.data.company_information) {
                    setRegistering(false);
                    navigate('/payments');
                } else {
                    setRegistering(true);
                    navigate('/register/choose-team');
                }
                
            }).catch((error) => {
                if(error.response.status === 404) {
                    setRegistering(true);
                    if(user?.attributes?.email) {
                        createAccount(user.attributes.email)
                        .then(res => {navigate('/register/choose-team');})
                        .catch(error => {
                            alert('user registration failed!');
                            signOut();
                        })
                    }
                }
            })
        }
    }, [user]);

    return (
        <></>
    );
};

export default SocialAccountVerification;
import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import theme from "../../constants/theme";


const InvoiceStatusTag = ({status}) => {
    const [statusText, setStatusText] = useState('Loading...');
    const [tagColor, setTagColor] = useState('white');

    useEffect(() => {
        switch (status) {
            case 'CREATED':
                setTagColor(theme.palette.grey[50]);
                setStatusText('Created');
                break;
            case 'IN_DRAFT':
                setTagColor(theme.palette.grey[50]);
                setStatusText('In Draft');
                break;
            case 'SENT':
                setTagColor(theme.palette.secondary.main);
                setStatusText('Sent');
                break;
            case 'PAYMENT_LINK_SENT':
                setTagColor(theme.palette.secondary.main);
                setStatusText('Payment Link Sent');
                break;
            case 'PAID':
                setTagColor(theme.palette.success.main);
                setStatusText('Paid');
                break;
            case 'PARTIALLY_PAID':
                setTagColor(theme.palette.warning.main);
                setStatusText('Partially Paid');
                break;
            default:
                break;
        }
    }, [status]);

    return (
        <Box sx={{ borderRadius: '4px', px: '8px', py: '4px', backgroundColor: tagColor}}>
            <Typography fontSize={12}>
                {statusText}
            </Typography>
        </Box>
    );
};

export default InvoiceStatusTag;
import { ArrowForward } from "@mui/icons-material";
import { Button, Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AppContext } from '../../contexts/AppContext';

const ChooseTeam = () => {
    const { isTablet } = useContext(AppContext);
    const navigate = useNavigate();
    const [isEmployer, setIsEmployer] = useState(true);

    return (
        <Grid2 container justifyContent={'center'} p={isTablet ? 3 : 30} rowGap={10}>

            <Grid2 xs={12} container rowGap={2}>
                <Grid2 xs={12}>
                    <Typography variant="h3" fontWeight={'bold'} color={'primary'}>
                        Welcome to Tapper!
                    </Typography>
                </Grid2>
                <Grid2 xs={12}>
                    <Typography color={'primary'}>
                        Are you registering as a:
                    </Typography>
                </Grid2>
            </Grid2>

            <Grid2 lg={7} md={12} xs={12} container rowGap={6} justifyContent={'center'}>
                <Grid2 xs={12}>
                    <Button
                        variant={isEmployer ? 'contained' : 'outlined'}
                        fullWidth
                        sx={{p: 2}}
                        onClick={() => setIsEmployer(true)}
                    >
                        New Tapper Team
                    </Button>
                </Grid2>
                <Grid2 xs={12}>
                    <Button
                        variant={isEmployer ? 'outlined' : 'contained'}
                        fullWidth
                        sx={{p: 2}}
                        onClick={() => setIsEmployer(false)}
                    >
                        Member of an existing Tapper Team
                    </Button>
                </Grid2>
            </Grid2>

            <Grid2 lg={7} md={12} xs={12} container justifyContent={'center'}>
                <Button 
                    endIcon={<ArrowForward/>}
                    onClick={() => navigate(isEmployer ? '/register/employer' : '/register/employee')}
                >
                    {isEmployer ? 'Register as a New Tapper Team' : 'Register as a Team Member'}
                </Button>
            </Grid2>

            <Grid2>
                
            </Grid2>

        </Grid2>
    );
};

export default ChooseTeam;
import { VisibilityOffOutlined, VisibilityOutlined } from "@mui/icons-material";
import { Box, IconButton, InputAdornment, Link, Paper, TextField, Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { useCallback, useEffect, useState } from "react";
import isEmail from "validator/lib/isEmail";
import isEmpty from "validator/lib/isEmpty";
import './signUp.css';
import { useAuth } from "../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import { createAccount } from "../api/user";
import isNumeric from "validator/lib/isNumeric";
import googleIcon from "../img/google-icon.svg";
import TermsPDF from "../pdf/Terms.pdf";
import PolicyPDF from "../pdf/Policy.pdf";

const SignUp = () => {

    const { signUp, signIn, confirmSignUp, setRegistering, signInWithGoogle } = useAuth();
    const navigate = useNavigate();

    const [username, setUsername] = useState('');
    const isValidUsername = Boolean(isEmail(username));
    const [showUsernameError, setShowUsernameError] = useState(false);
    
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const isValidPassword = Boolean(!isEmpty(password));
    const [showPasswordError, setShowPasswordError] = useState(false);

    const [confirmPassword, setConfirmPassword] = useState('');
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const isValidConfirmPassword = Boolean(!isEmpty(confirmPassword));
    const [showConfirmPasswordError, setShowConfirmPasswordError] = useState(false);

    const isPasswordMatching = Boolean(password === confirmPassword);
    const [passwordMatchError, setPasswordMatchError] = useState(false);

    const signUpValidator = useCallback(() => {
        if (!isValidUsername) {
            setShowUsernameError(true);
            return false;
        }
        if (!isValidPassword) {
            setShowPasswordError(true);
            return false;
        }
        if (!isValidConfirmPassword) {
            setShowConfirmPasswordError(true);
            return false;
        }
        if (!isPasswordMatching) {
            setPasswordMatchError(true);
            return false
        }
        return true;
    }, [isPasswordMatching, isValidConfirmPassword, isValidPassword, isValidUsername]);


    const [confirmationCode, setConfirmationCode] = useState('');
    const isValidConfirmationCode = Boolean(confirmationCode.length === 6 && isNumeric(confirmationCode));
    const [showConfirmationCodeError, setShowConfirmationCodeError] = useState(false);
    const [showConfirmationCode, setShowConfirmationCode] = useState(false);

    const confirmationCodeValidator = useCallback(() => {
        if (!isValidConfirmationCode) {
            setShowConfirmationCodeError(true);
            return false;
        }
        return true;
    }, [isValidConfirmationCode]);

    const [signUpButtonText, setSignUpButtonText] = useState('Get Started');

    const handleSignUpButton = useCallback(async () => {
        if (showConfirmationCode) {
            if (confirmationCodeValidator()) {
                setSignUpButtonText('Confirming...');
                const confirmationResult = await confirmSignUp(username, confirmationCode);
                if (confirmationResult) {
                    setSignUpButtonText('Confirmed!');
                    let signInResult;
                    do {
                        signInResult = await signIn(username, password);
                        if (signInResult) {
                            const response = await createAccount(username);
                            console.log('createAccount response: ', response);
                            navigate('/register/choose-team');
                            break;
                        } else {
                            setSignUpButtonText('Sign-in Failed! Trying again...');
                            setTimeout(() => {}, 1000);
                        }
                    } while (!signInResult)
                } else {
                    setSignUpButtonText('Confirmation Failed! Try again!');
                    setTimeout(() => setSignUpButtonText('Confirm Code'), 1000);
                }
            }
        } else {
            if (signUpValidator()) {
                setSignUpButtonText('Signing Up...');
                const signUpResult = await signUp(username, password);
                setShowConfirmationCode(signUpResult);
                if (signUpResult) {
                    setSignUpButtonText('Confirm Code');
                    setRegistering(true);
                } else {
                    setSignUpButtonText('Sign Up Failed! Try again!');
                    setTimeout(() => setSignUpButtonText('Get Started'), 1000);
                }
            }
        }
    }, [
        showConfirmationCode, 
        confirmationCodeValidator, 
        confirmSignUp, 
        username, 
        confirmationCode, 
        signIn, 
        password, 
        navigate, 
        signUpValidator, 
        signUp,
        setRegistering,
    ]);


    useEffect(() => {
        if (isValidUsername) setShowUsernameError(false);
        if (isValidPassword) setShowPasswordError(false);
        if (isPasswordMatching) setPasswordMatchError(false);
        if (isValidConfirmPassword) setShowConfirmPasswordError(false);
        if (isValidConfirmationCode) setShowConfirmationCodeError(false);
    }, [isPasswordMatching, isValidConfirmPassword, isValidConfirmationCode, isValidPassword, isValidUsername]);
    
    return (
        <Grid2 container>
            <Grid2 container xs={0} md={3} lg={4} xl={5} bgcolor={'primary.main'}>
                <Grid2 xs={12} container direction={'column'} gap={1} justifyContent={'center'} alignItems={'center'}>
                    <img
                        alt=""
                        src="/logo1x.png"
                        width={200}
                        height={200}
                        // className="tapper-logo"
                    />
                    <Typography variant="h5" color={'white'} fontWeight={'bold'}>
                        The best app for contractors
                    </Typography>
                </Grid2>
                <Grid2 xs={12} container pb={4} direction={'column'} gap={3} justifyContent={'center'} alignItems={'center'}>
                    <Typography color={'white'} fontWeight={'bold'}>
                        Download Mobile App Here
                    </Typography>
                    <img
                        alt=""
                        src="/tapper-qr-code.png"
                        width={160}
                        height={160}
                        // className="tapper-logo"
                    />
                </Grid2>
            </Grid2>
            <Grid2 container xs sm md  bgcolor={'white'} sx={{display: { xs: 'block',}}}>
                <Box className="auth-form">   
                    <Box className="title-text-container">
                        <Typography variant="h3" fontWeight={'bold'} color={'primary'}>
                            Create An Account
                        </Typography>
                        <Box className="subtitle-text-container">
                            <Typography variant="h6" color={'primary'}>
                                Already have a Tapper account?
                            </Typography>
                            <Link variant="h6" href="/sign-in">Sign in</Link>
                        </Box>
                    </Box>
                    <Box className="input-container">
                        <TextField
                            variant="standard"
                            type="email"
                            fullWidth
                            error={showUsernameError}
                            helperText={showUsernameError ? 'Please enter a valid email address' : ''}
                            value={username}
                            label="Email Address"
                            placeholder="Enter your email address"
                            onChange={event => {
                                setUsername(event.target.value);
                            }}
                        />
                        <TextField
                            variant="standard"
                            type={showPassword ? 'text' : 'password'}
                            fullWidth
                            error={(showPasswordError || passwordMatchError)}
                            helperText={
                                showPasswordError 
                                    ? 'Please enter a password' 
                                    : passwordMatchError
                                        ? 'Passwords do not match'
                                        : ''
                            }
                            value={password}
                            label="Password"
                            placeholder="Enter your password"
                            onChange={event => {
                                setPassword(event.target.value);
                            }}
                            InputProps={{
                                endAdornment: 
                                    <InputAdornment position="end">
                                      <IconButton
                                        onClick={() => setShowPassword(!showPassword)}
                                        onMouseDown={event => event.preventDefault()}
                                        edge="end"
                                      >
                                        {showPassword ? <VisibilityOffOutlined /> : <VisibilityOutlined />}
                                      </IconButton>
                                    </InputAdornment>
                            }}
                        />
                        <TextField
                            variant="standard"
                            type={showConfirmPassword ? 'text' : 'password'}
                            fullWidth
                            error={(showConfirmPasswordError || passwordMatchError)}
                            helperText={
                                showConfirmPasswordError 
                                    ? 'Please confirm your password' 
                                    : passwordMatchError
                                        ? 'Passwords do not match'
                                        : ''
                            }
                            value={confirmPassword}
                            label="Confirm Password"
                            placeholder="Enter your password again"
                            onChange={event => {
                                setConfirmPassword(event.target.value);
                            }}
                            InputProps={{
                                endAdornment: 
                                    <InputAdornment position="end">
                                      <IconButton
                                        onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                        onMouseDown={event => event.preventDefault()}
                                        edge="end"
                                      >
                                        {showConfirmPassword ? <VisibilityOffOutlined /> : <VisibilityOutlined />}
                                      </IconButton>
                                    </InputAdornment>
                            }}
                        />
                        {showConfirmationCode &&
                        <TextField
                            variant="standard"
                            type="text"
                            fullWidth
                            error={showConfirmationCodeError}
                            helperText={showConfirmationCodeError ? 'Please enter a 6 digit code' : ''}
                            value={confirmationCode}
                            label="Confirmation Code"
                            placeholder="Enter the code sent to your email"
                            onChange={event => {
                                setConfirmationCode(event.target.value);
                            }}
                        />}
                        <Typography className="terms">By signing in you are agreeing with our <a href={TermsPDF} target="_blank" className="terms-link">Terms</a> and <a href={PolicyPDF} target="_blank" className="terms-link">Privacy Policy</a>.</Typography>
                    </Box>
                    <Box className="auth-button-wrapper">
                        <Paper 
                            elevation={4}
                            // for some reason css wouldnt work here so I added it in manually
                            sx={{
                                backgroundColor: 'primary.main', 
                                padding: 2,
                                borderRadius: 28,
                                transition: 'background-color 0.25s',
                                ":hover": {
                                    backgroundColor: 'primary.light',
                                    cursor: "pointer",
                                },
                                margin: 1,
                            }}
                            onClick={handleSignUpButton}
                        >
                            <Typography variant="h6" color={'white'}>{signUpButtonText}</Typography>
                        </Paper>
                        <Typography variant="h6" color={'black'}>OR</Typography>
                        <Paper 
                            elevation={4}
                            // for some reason css wouldnt work here so I added it in manually
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                backgroundColor: '#294D511A',
                                padding: 2,
                                borderRadius: 28,
                                transition: 'background-color 0.25s',
                                ":hover": {
                                    backgroundColor: 'white',
                                    cursor: "pointer",
                                },
                                margin: 1,
                            }}
                            onClick={() => signInWithGoogle()}
                        >
                            <img src={googleIcon} alt="" style={{marginRight: 30}}/>
                            <Typography variant="h6" color={'black'}>Sign Up with Gmail</Typography>
                        </Paper>
                    </Box>
                </Box>
            </Grid2>
        </Grid2>
    );
};

export default SignUp;
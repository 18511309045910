import { Box, Button, Checkbox, Divider, FormControl, FormControlLabel, IconButton, ImageList, ImageListItem, Radio, RadioGroup, Typography } from "@mui/material";
import { useCallback, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AppDrawer from "../appdrawer/appdrawer";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { useState } from "react";
import { getEstimateOrInvoice, sendEstimateOrInvoiceReminder } from "../../api/estimates";
import { sendInvoice } from "../../api/invoice";
import { ChevronLeft, ChevronRightSharp, Download, Edit, HelpOutline, Send } from "@mui/icons-material";
import InvoiceStatusTag from "./InvoiceStatusTag";
import { getUser } from "../../api/user";
import { USDollar } from "../../constants/theme";
import RecordPaymentModal from "../payments/RecordPaymentModal";


/**
 * Rough design:
 * {Title/Back | Edit Invoice button}
 * {Invoice details}
 * {Job Item array}
 * {Attachments | Cost + send button}
 */

const InvoiceDetails = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [invoiceInfo, setInvoiceInfo] = useState({});
    const [userInfo, setUserInfo] = useState({});
    const [sendButtonText, setSendButtonText] = useState('Send Invoice');
    const [showRecordPayment, setShowRecordPayment] = useState(false);
    const [includePaymentLink, setIncludePaymentLink] = useState(false);
    const [showFeeInfo, setShowFeeInfo] = useState(false);
    const [paymentMethod, setPaymentMethod] = useState('CARD');

    useEffect(() => {
        (async () => {
            let response = await getEstimateOrInvoice(id);
            if (response) {
                setInvoiceInfo(response.data);
                setSendButtonText(response.data.invoice.status === 'PAYMENT_LINK_SENT' ? 'Send Again' : 'Send Invoice');
            } else {
                setInvoiceInfo({});
            }
            response = await getUser();
            if (response) {
                setUserInfo(response.data);
            } else {
                setUserInfo({});
            }
        })();
    },[id]);

    const materialTotal = invoiceInfo?.invoice?.job_items.reduce((accum, current) => (accum + current?.merchandise_cost), 0)
    const laborTotal = invoiceInfo?.invoice?.job_items.reduce((accum, current) => (accum + current?.labor_cost), 0)

    const handleSendButton = useCallback(async () => {
        setSendButtonText('Sending ...');
        let response;
        if (invoiceInfo?.invoice?.status === 'CREATED' || invoiceInfo?.invoice?.status === 'SENT' || invoiceInfo?.invoice?.status === 'PARTIALLY_PAID') {
            response = await sendInvoice(
                invoiceInfo?.invoice?.id, 
                invoiceInfo?.invoice?.email, 
                invoiceInfo?.invoice?.total_amount - invoiceInfo?.invoice?.partially_payment,
                includePaymentLink,
                paymentMethod
            );
            console.log(response);
        } else if (invoiceInfo?.invoice?.status === 'PAYMENT_LINK_SENT') {
            response = await sendEstimateOrInvoiceReminder(invoiceInfo?.invoice?.id);
        }
        if (response) {
            setSendButtonText('Sent Successfully!');
        } else {
            setSendButtonText('Failed to Send');
        }
    }, [invoiceInfo, includePaymentLink, paymentMethod]);

    return (
        <Box sx={{ display: 'flex', }}>
            <AppDrawer/>
            <RecordPaymentModal
                showModal={showRecordPayment}
                setShowModal={setShowRecordPayment}
                isInvoice={true}
                invoiceInfo={invoiceInfo?.invoice}
            />
            <Grid2 container padding={4} rowGap={6} flexGrow={1}>
                <Grid2 xs={12} container alignItems={'flex-end'}>
                    <Grid2 container xs={6} direction={'column'} alignItems={'flex-start'}>
                        <Button startIcon={<ChevronLeft/>} onClick={() => navigate('/invoices')}>
                            Back
                        </Button>
                        <Typography color={'primary'} variant="h3" fontWeight={'bold'}>
                            Invoice Details
                        </Typography>
                    </Grid2>
                    <Grid2 xs={6} container justifyContent={'flex-end'}>
                        {invoiceInfo?.invoice?.status !== 'PAID' &&
                        <Button
                            variant="outlined"
                            startIcon={<Edit/>}
                            onClick={() => navigate(`/invoices/edit/${id}`)}
                        >
                            {(invoiceInfo?.invoice?.status === 'SENT' || invoiceInfo?.invoice?.status === 'PARTIALLY_PAID' || invoiceInfo?.invoice?.status === 'PAYMENT_LINK_SENT') 
                                ? 'Change Order' 
                                : 'Edit Invoice'}
                        </Button>}
                    </Grid2>
                </Grid2>
                <Grid2 xs={12} container gap={6}>
                    <Grid2 xs={12} container alignItems={'flex-end'}>
                        <Grid2 xs={6} container alignItems={'center'} gap={2}>
                            <Typography variant="h4">
                                Proposal #{invoiceInfo?.invoice?.invoices_number}
                            </Typography>
                            <InvoiceStatusTag status={invoiceInfo?.invoice?.status}/>
                        </Grid2>
                        <Grid2 xs={6} container justifyContent={'flex-end'}>
                            <Typography variant="body1" color={'primary'}>
                                DATE: {new Date(invoiceInfo?.invoice?.created_at).toLocaleDateString('en-US', {
                                    month: 'short', year:'numeric', day:'numeric', hour: 'numeric', minute: 'numeric', second:'numeric'
                                })}
                            </Typography>
                        </Grid2>
                    </Grid2>
                    <Grid2 xs={6} container gap={3} >
                        <img 
                            src={invoiceInfo?.logo}
                            alt=""
                            width={170}
                            height={170}
                        />
                        <Grid2 container direction={'column'} alignItems={'flex-start'} justifyContent={'flex-end'} gap={0.5}>
                            <Typography variant="h5" fontWeight={'bold'}>
                                {userInfo?.company_information?.name}
                            </Typography>
                            <Typography>
                                {userInfo?.location?.address_line_1} 
                            </Typography>
                            <Typography>
                                {userInfo?.location?.city}, {userInfo?.location?.state}, {userInfo?.location?.zip_code}
                            </Typography>
                            <Typography>
                                {userInfo?.company_information?.phone}
                            </Typography>
                            <Typography>
                                {userInfo?.company_information?.email}
                            </Typography>
                        </Grid2>
                    </Grid2>
                    <Grid2 xs={5} container gap={3} >
                        <Divider orientation="vertical" variant="fullWidth" sx={{ bgcolor: 'black'}}/>
                        <Grid2 container direction={'column'} alignItems={'flex-start'} gap={0.5}>
                            <Typography fontSize={14} fontWeight={'bold'} color={'primary'}>
                                INVOICE FOR
                            </Typography>
                            <Typography variant="h5" fontWeight={'bold'}>
                                {invoiceInfo?.invoice?.client_name}
                            </Typography>
                            <Typography>
                                {invoiceInfo?.invoice?.street_address} 
                            </Typography>
                            <Typography>
                                {invoiceInfo?.invoice?.city}, {invoiceInfo?.invoice?.state}, {invoiceInfo?.invoice?.zip_code}
                            </Typography>
                            <Typography>
                                {invoiceInfo?.invoice?.phone}
                            </Typography>
                            <Typography>
                                {invoiceInfo?.invoice?.email}
                            </Typography>
                        </Grid2>
                    </Grid2>
                </Grid2>
                <Grid2 xs={12} container>
                    {/**Job Item Array */}
                    <Grid2 xs={6} container p={1} className={'job-item-header-left'}>
                        <Typography color={'#858585'}>Item Name</Typography>
                    </Grid2>
                    <Grid2 xs={6} container justifyContent={'flex-end'} p={1} className={'job-item-header-right'}>
                        <Typography color={'#858585'}>{invoiceInfo?.invoice?.showing_item_amount && `Amount`}</Typography>
                    </Grid2>
                    {invoiceInfo?.invoice?.job_items.map((item, index) => (
                        <Grid2 key={index} xs={12} container justifyContent={'center'}>
                            <Grid2 xs={6} container p={1}>
                                <Typography color={'primary'} fontWeight={'bold'}>
                                    {item?.job}
                                </Typography>
                            </Grid2>
                            <Grid2 xs={6} container p={1} justifyContent={'flex-end'}>
                                <Typography color={'primary'} fontWeight={'bold'}>
                                {invoiceInfo?.invoice?.showing_item_amount && 
                                    USDollar.format(item?.total_amount_with_markup)}
                                </Typography>
                            </Grid2>
                            {item?.details.map((it, idx) => (
                                <Grid2 key={idx} xs={12} container justifyContent={'center'}>
                                    <Grid2 xs={'auto'} container p={0.25}>
                                        <ChevronRightSharp color={'primary.light'}/>
                                    </Grid2>
                                    <Grid2 xs container p={0.25}>
                                        <Typography color={'primary.light'}>{it?.item}</Typography>
                                    </Grid2>
                                    <Grid2 xs container p={0.25} justifyContent={'flex-end'}>
                                        <Typography color={'primary.light'}>x{it?.quantity}</Typography>
                                    </Grid2>
                                </Grid2>
                            ))}
                            <Grid2 xs={12}>
                                <Divider sx={{ bgcolor: 'gray',}}/>
                            </Grid2>
                        </Grid2>
                    ))}
                </Grid2>
                <Grid2 xs={12} container justifyContent={'space-between'} alignItems={'flex-start'}>
                    <Grid2 xs={5} container>
                        {/**Note + Attachments */}
                        <Grid2 xs={12} container direction={'column'} alignItems={'flex-start'} >
                            <Typography color={'secondary.dark'}>
                                Notes
                            </Typography>
                            <Typography>
                                {invoiceInfo?.invoice?.notes ?? ''}
                            </Typography>
                        </Grid2>
                        <Grid2 xs={12} container direction={'column'} alignItems={'flex-start'}>
                            <Typography color={'secondary.dark'}>
                                Attachments
                            </Typography>
                            {invoiceInfo?.invoice?.attach_photos
                            ? <ImageList>
                                {invoiceInfo?.invoice?.attach_photos.map((url, index) => (
                                    <ImageListItem key={index}>
                                        <img
                                            src={url}
                                            alt=""
                                            loading="lazy"
                                        />
                                    </ImageListItem>
                                ))}
                            </ImageList>
                            : <Typography>N/A</Typography>
                            }
                        </Grid2>
                    </Grid2>
                    <Grid2 xs={6} container rowGap={1} alignItems={'flex-end'} >
                        {/** Invoiced Total */}
                        <Grid2 xs={6} container>
                            <Typography color={'primary'}> 
                                Invoiced Total
                            </Typography>
                        </Grid2>
                        <Grid2 xs={6} container justifyContent={'flex-end'}>
                            <Typography variant="h5" color={'primary'} fontWeight={'bold'}>
                                {USDollar.format(invoiceInfo?.invoice?.total_amount)}
                            </Typography>
                        </Grid2>
                        <Grid2 xs={12}>
                            <Divider sx={{bgcolor: 'gray'}} />
                        </Grid2>
                        {/** Material Cost */}
                        <Grid2 xs={6} container>
                            <Typography color={'secondary.dark'}> 
                                Material Cost
                            </Typography>
                        </Grid2>
                        <Grid2 xs={6} container justifyContent={'flex-end'}>
                            <Typography color={'secondary.dark'}>
                                {USDollar.format(materialTotal)}
                            </Typography>
                        </Grid2>
                        {/** Labor Cost */}
                        <Grid2 xs={6} container>
                            <Typography color={'secondary.dark'}> 
                                Labor Cost
                            </Typography>
                        </Grid2>
                        <Grid2 xs={6} container justifyContent={'flex-end'}>
                            <Typography color={'secondary.dark'}>
                                {USDollar.format(laborTotal)}
                            </Typography>
                        </Grid2>
                        <Grid2 xs={12} container>
                            <Typography color={'primary.light'}>
                                *Costs are not shown to clients.
                            </Typography>
                        </Grid2>
                        <Grid2 xs={12} container>
                            <Grid2 xs={12} container gap={1} alignItems={'center'}>
                                <Checkbox 
                                    checked={includePaymentLink}
                                    onChange={event => setIncludePaymentLink(event.target.checked)}
                                />
                                <Typography>
                                    Include Payment Link (Processing Fees Apply)
                                </Typography>
                                <IconButton onClick={() => setShowFeeInfo(!showFeeInfo)}>
                                    <HelpOutline/>
                                </IconButton>
                            </Grid2>
                            {includePaymentLink && 
                            <Grid2 xs={12} container pl={4}>
                                <FormControl >
                                    <RadioGroup
                                        row
                                        value={paymentMethod}
                                        onChange={event => setPaymentMethod(event.target.value)}
                                    >
                                        <FormControlLabel value={'CARD'} control={<Radio/>} label={'Card'}/>
                                        <FormControlLabel value={'ACH'} control={<Radio/>} label={'ACH'}/>
                                        <FormControlLabel value={'CARD & ACH'} control={<Radio/>} label={'CARD & ACH'}/>
                                    </RadioGroup>
                                </FormControl>
                            </Grid2>}
                            {showFeeInfo && <Grid2 xs={12} container bgcolor={'secondary.main'} borderRadius={'8px'} p={1} gap={1}>
                                <Grid2 xs={12} container alignItems={'center'} gap={1}>
                                    <HelpOutline/>
                                    <Typography fontSize={14} fontWeight={'bold'}>Using Payment Links</Typography>
                                </Grid2>
                                <Grid2 xs={12}>
                                    <Typography fontSize={12}>
                                        Checking this box will include a Payment Link when you send the invoice.
                                        Payment Links allow your client to send you payment via Credit Card, or ACH Bank Transfer.
                                        They simply click the link and are forwarded to a secure website where they can make the payment
                                        using the chosen payment method. If the client uses one of these payment links, there may be 
                                        minor processing fees taken from the client's payment.
                                    </Typography>
                                </Grid2>
                            </Grid2>}
                        </Grid2>
                        {(invoiceInfo?.invoice?.status !== 'SENT' || invoiceInfo?.invoice?.status !== 'PAYMENT_LINK_SENT' || invoiceInfo?.invoice?.status !== 'PARTIALLY_PAID') && 
                        <Grid2 xs={12}>
                            <Button
                                variant="outlined"
                                fullWidth
                                startIcon={<Download/>}
                                onClick={() => setShowRecordPayment(true)}
                            >
                                Record Payment
                            </Button>
                        </Grid2>}
                        {(invoiceInfo?.invoice?.status !== 'IN_DRAFT' && invoiceInfo?.invoice?.status !== 'PAID') &&
                        <Grid2 xs={12} container justifyContent={'center'}>
                            <Button fullWidth variant="contained" startIcon={<Send/>} onClick={handleSendButton}>
                                {sendButtonText}
                            </Button>
                        </Grid2>}
                    </Grid2>
                </Grid2>
            </Grid2>
        </Box>
    );
};

export default InvoiceDetails;
import { Box, Button, Modal, TextField, Typography } from "@mui/material";
import { useCallback } from "react";
import './EditCustomerModal.css';
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { useState } from "react";
import isEmpty from "validator/lib/isEmpty";
import isEmail from "validator/lib/isEmail";
import isMobilePhone from "validator/lib/isMobilePhone";
import isPostalCode from "validator/lib/isPostalCode";
import { useEffect } from "react";
import { addCustomer, editCustomer } from "../../api/contacts";
import { phoneNumberReformatter } from "../../misc/utilityFunctions";
import AddressInput from "../util/addressInput";

const EditCustomerModal = ({showModal, setShowModal, editCustomerData = null}) => {
    
    const [customerData, setCustomerData] = useState({
        first_name: '',
        last_name: '',
        phone: '',
        email: '',
        street_address: '',
        city: '',
        state: '',
        zip_code: '',
    });
    const [customerValidation, setCustomerValidation] = useState({
        first_name: false,
        last_name: false,
        phone: false,
        email: false,
        street_address: false,
        city: false,
        state: false,
        zip_code: false,
    });
    const [saveText, setSaveText] = useState('Save');
    const [isEditMode, setIsEditMode] = useState(false);

    const handleClose = useCallback(() => {
        setCustomerData({
            first_name: '',
            last_name: '',
            phone: '',
            email: '',
            street_address: '',
            city: '',
            state: '',
            zip_code: '',
        });
        setCustomerValidation({
            first_name: false,
            last_name: false,
            phone: false,
            email: false,
            street_address: false,
            city: false,
            state: false,
            zip_code: false,
        });
        setIsEditMode(false);
        setSaveText('Save');
        setShowModal(false);
    }, [setShowModal]);

    const customerValidator = useCallback(() => {
        if (isEmpty(customerData.first_name)) {
            setCustomerValidation(customerValidation => ({...customerValidation, first_name: true}));
            return false;
        }
        if (isEmpty(customerData.last_name)) {
            setCustomerValidation(customerValidation => ({...customerValidation, last_name: true}));
            return false;
        }
        if (!isMobilePhone(customerData.phone, ['en-US', 'en-CA'])) {
            setCustomerValidation(customerValidation => ({...customerValidation, phone: true}));
            return false;
        }
        if (!isEmail(customerData.email)) {
            setCustomerValidation(customerValidation => ({...customerValidation, email: true}));
            return false;
        }
        if (isEmpty(customerData.street_address)) {
            setCustomerValidation(customerValidation => ({...customerValidation, street_address: true}));
            return false;
        }
        if (isEmpty(customerData.city)) {
            setCustomerValidation(customerValidation => ({...customerValidation, city: true}));
            return false;
        }
        if (isEmpty(customerData.state)) {
            setCustomerValidation(customerValidation => ({...customerValidation, state: true}));
            return false;
        }
        if (!(isPostalCode(customerData?.zip_code, 'US') || isPostalCode(customerData?.zip_code, 'CA'))) {
            setCustomerValidation(customerValidation => ({...customerValidation, zip_code: true}));
            return false;
        }
        return true;
    }, [customerData]);

    const customerSubmitter = useCallback(async () => {
        if (customerValidator()) {
            setSaveText('Saving...');
            let fixedCustomerData = {
                ...customerData,
                phone: phoneNumberReformatter(customerData?.phone),
            }
            let response;
            if (isEditMode) {
                response = await editCustomer(fixedCustomerData);
            } else {
                response = await addCustomer(fixedCustomerData);
            }
            if (response) {
                setSaveText('Saved Successfully!');
                setTimeout(handleClose, 1000);
            } else {
                setSaveText('Failed to Save!');
            }
        }
    }, [customerData, customerValidator, handleClose, isEditMode]);

    // clear error statuses
    useEffect(() => {
        if (customerValidation?.first_name && !isEmpty(customerData?.first_name)) {
            setCustomerValidation(customerValidation => ({...customerValidation, first_name: false}));
            return;
        }
        if (customerValidation?.last_name && !isEmpty(customerData?.last_name)) {
            setCustomerValidation(customerValidation => ({...customerValidation, last_name: false}));
            return;
        }
        if (customerValidation?.phone && isMobilePhone(customerData.phone, ['en-US', 'en-CA'])) {
            setCustomerValidation(customerValidation => ({...customerValidation, phone: false}));
            return;
        }
        if (customerValidation?.email && isEmail(customerData.email)) {
            setCustomerValidation(customerValidation => ({...customerValidation, email: false}));
            return;
        }
        if (customerValidation?.street_address && !isEmpty(customerData?.street_address)) {
            setCustomerValidation(customerValidation => ({...customerValidation, street_address: false}));
            return;
        }
        if (customerValidation?.city && !isEmpty(customerData?.city)) {
            setCustomerValidation(customerValidation => ({...customerValidation, city: false}));
            return;
        }
        if (customerValidation?.state && !isEmpty(customerData?.state)) {
            setCustomerValidation(customerValidation => ({...customerValidation, state: false}));
            return;
        }
        if (customerValidation?.zip_code && (isPostalCode(customerData?.zip_code, 'US') || isPostalCode(customerData?.zip_code, 'CA'))) {
            setCustomerValidation(customerValidation => ({...customerValidation, zip_code: false}));
            return;
        }
    }, [customerData, customerValidation]);

    // import data if in edit mode
    useEffect(() => {
        if (editCustomerData !== null) {
            const {user_id, ...data} = editCustomerData;
            setIsEditMode(true);
            setCustomerData(data);
        }
    }, [editCustomerData]);

    return (
        <Modal
            open={showModal}
            onClose={handleClose}
        >
            <Box className="big-modal-container">
                <Grid2 container gap={4} flexGrow={1} justifyContent={'space-between'}>
                    <Grid2 xs={12}>
                        <Typography variant="h5" color={'primary'} fontWeight={'bold'}>
                            {editCustomerData ? 'Edit Customer Info' : 'Create New Customer'}
                        </Typography>
                    </Grid2>
                    <Grid2 lg={5.5} md={5.5} xs={12}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            color="primary"
                            type="text"
                            value={customerData?.first_name}
                            error={customerValidation?.first_name}
                            helperText={customerValidation?.first_name ? "Please enter a first name" : ''}
                            label="First Name"
                            placeholder="Enter First Name"
                            onChange={event => setCustomerData({...customerData, first_name: event.target.value})}
                        />
                    </Grid2>
                    <Grid2 lg={5.5} md={5.5} xs={12}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            color="primary"
                            type="text"
                            value={customerData?.last_name}
                            error={customerValidation?.last_name}
                            helperText={customerValidation?.last_name ? "Please enter a last name" : ''}
                            label="Last Name"
                            placeholder="Enter Last Name"
                            onChange={event => setCustomerData({...customerData, last_name: event.target.value})}
                        />
                    </Grid2>
                    <Grid2 xs={12}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            color="primary"
                            type="text"
                            value={customerData?.phone}
                            error={customerValidation?.phone}
                            helperText={customerValidation?.phone ? "Please enter a valid phone number" : ''}
                            label="Phone Number"
                            placeholder="Enter Phone Number"
                            onChange={event => setCustomerData({...customerData, phone: event.target.value})}
                        />
                    </Grid2>
                    <Grid2 xs={12}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            color="primary"
                            type="text"
                            value={customerData?.email}
                            error={customerValidation?.email}
                            helperText={customerValidation?.email ? "Please enter a valid email address" : ''}
                            label="Email Address"
                            placeholder="Enter Email Address"
                            onChange={event => setCustomerData({...customerData, email: event.target.value})}
                        />
                    </Grid2>
                    <Grid2 xs={12}>
                        <AddressInput
                            value={customerData?.street_address}
                            setValue={(address, city, state, zip) => address ? setCustomerData({
                                ...customerData,
                                street_address: address,
                                city: city,
                                state: state,
                                zip_code: zip
                            }) : setCustomerData({
                                ...customerData,
                                street_address: address
                            })}
                            label="Street Address"
                            placeholder="Enter Street Address"
                            error={customerValidation?.street_address}
                            helperText={customerValidation?.street_address ? "Please enter a valid street address" : ''}
                        />
                    </Grid2>
                    <Grid2 xs={12} container justifyContent={'space-between'} rowGap={4}>
                        <Grid2 lg={3.5} md={3.5} xs={12}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                color="primary"
                                type="text"
                                value={customerData?.city}
                                error={customerValidation?.city}
                                helperText={customerValidation?.city ? "Please enter a city" : ''}
                                label="City"
                                placeholder="Enter City"
                                onChange={event => setCustomerData({...customerData, city: event.target.value})}
                            />
                        </Grid2>
                        <Grid2 lg={3.5} md={3.5} xs={12}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                color="primary"
                                type="text"
                                value={customerData?.state}
                                error={customerValidation?.state}
                                helperText={customerValidation?.state ? "Please enter a state" : ''}
                                label="State"
                                placeholder="Enter State"
                                onChange={event => setCustomerData({...customerData, state: event.target.value})}
                            />
                        </Grid2>
                        <Grid2  lg={3.5} md={3.5} xs={12}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                color="primary"
                                type="text"
                                value={customerData?.zip_code}
                                error={customerValidation?.zip_code}
                                helperText={customerValidation?.zip_code ? "Please enter a valid zip code" : ''}
                                label="Zip Code"
                                placeholder="Enter Zip Code"
                                onChange={event => setCustomerData({...customerData, zip_code: event.target.value})}
                            />
                        </Grid2>
                    </Grid2>
                    <Grid2 xs={12} container justifyContent={'space-between'}>
                        <Grid2 xs={5.5}>
                            <Button
                                variant="contained"
                                fullWidth
                                onClick={async () => await customerSubmitter()}
                            >
                                {saveText}
                            </Button>
                        </Grid2>
                        <Grid2 xs={5.5}>
                            <Button
                                variant="outlined"
                                fullWidth
                                onClick={handleClose}
                            >
                                Cancel
                            </Button>
                        </Grid2>
                    </Grid2>
                </Grid2>
            </Box>
        </Modal>
    );
};

export default EditCustomerModal;
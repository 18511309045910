import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import LoadingScreen from "../util/loadingScreen";


const AuthRoutes = () => {
    const { user, loading, registering } = useAuth();
    return loading 
        ? <LoadingScreen /> 
        : user 
            ? registering 
                ? <Outlet/> 
                : <Navigate to={'/payments'}/> 
            : <Outlet />
};

export default AuthRoutes;
import React, {useEffect,useState} from 'react';
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import fetchCoordinates from '../../api/maps';


function Map(props) {
    const { user, mapId } = props;
    const [center, setCenter] = useState({ lat: 0, lng: 0 });
    const googleMap_API_Key = process.env.REACT_APP_GOOGLE_MAP_KEY;

    const mapStyles = {
        height: "250px",
        width: "100%"
    };

    async function changeToCoordinates() {
        if (user) {
            const address = `${user.location.address_line_1}+${user.location.city}+${user.location.state}+${user.location.zip_code}`;
            try {
                const res = await fetchCoordinates(address, googleMap_API_Key);
                if (res.data.status === "OK") {
                    const location = res.data.results[0].geometry.location;
                    const coordinates = { lat: location.lat, lng: location.lng };
                    setCenter(coordinates);
                } else {
                    console.error(`Geocode error: ${res.data.status}`);
                }
            } catch (error) {
                console.error(`Geocode API error: ${error}`);
            }
        }
    }


    useEffect(() => {
        if (user) {
            changeToCoordinates();
        }
    }, [user]);

    return (
        <div style={{ marginTop:'20px', height: '200px', width: '100%' }}>
					<LoadScript
        		googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAP_KEY}>
            {center.lat !== 0 && center.lng !== 0 && // only render map when coordinates are loaded
						<GoogleMap
								mapContainerStyle={mapStyles}
								zoom={15}
								center={center}
								mapId={mapId}
						>
							<Marker position={center} />
						</GoogleMap>}
					</LoadScript>
        </div>
    );

}

export default Map;

import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import theme from "../../constants/theme";


const SendPaymentStatusTag = ({ status }) => {
    const [statusText, setStatusText] = useState('Loading...');
    const [tagColor, setTagColor] = useState('white');

    useEffect(() => {
        switch (status) {
            case 'IN_PROCESS':
                setTagColor(theme.palette.grey[50]);
                setStatusText('In Process');
                break;
            case 'PENDING':
                setTagColor(theme.palette.secondary.main);
                setStatusText('Pending');
                break;
            case 'PAID':
                setTagColor(theme.palette.success.main);
                setStatusText('Paid');
                break;
            case 'UNPAID':
                setTagColor('#B7DEE3');
                setStatusText('Sent');
                break;
            case 'PRINTED':
                setTagColor('#00C49C');
                setStatusText('Deposited');
                break;
            case 'VOID':
                setTagColor('#FFC49C');
                setStatusText('Voided');
                break;
            // case 'IN_DRAFT':
            //     setTagColor(theme.palette.grey[50]);
            //     setStatusText('In Draft');
            //     break;
            // case 'REJECTED':
            //     setTagColor(theme.palette.error.main);
            //     setStatusText('Rejected');
            //     break;
            default:
                break;
        }
    }, [status]);

    return (
        <Box sx={{ borderRadius: '4px', px: '8px', py: '4px', backgroundColor: tagColor }}>
            <Typography fontSize={12}>
                {statusText}
            </Typography>
        </Box>
    );
};

export default SendPaymentStatusTag;
import { useEffect, useState, useContext } from 'react';
import { Box, Button, Divider, Typography, List, ListItem, ListItemText, ListItemIcon } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import Stack from '@mui/material/Stack';
import CheckIcon from '@mui/icons-material/Check';

import AccountSideBar from '../../components/account/AccountSideBar';
import AppDrawer from '../../components/appdrawer/appdrawer';
import { getUser, getLinkForSubscription, cancelSubscriptionPlan } from '../../api/user';
import { AppContext } from '../../contexts/AppContext';

const ManagePlan = () => {
    const { isTablet } = useContext(AppContext);
    const [user, setUser] = useState();
    
    const fetchUser = () => {
        getUser().then((res) => {
            setUser(res.data);
        });
    }

    useEffect(() => {
        fetchUser();
    }, [])

    const basicPlanItems = [
        {
            text: 'Client Contact Management', 
        }, 
        {
            text: 'Estimates & Invoices via SMS and Email', 
        },
        {
            text: 'Markups Management', 
        },
        {
            text: 'Real-time earnings report', 
        },
        {
            text: 'Sending Card Payment Links (3.4% + 30c)', 
        },
        {
            text: 'Tap to get paid (3.2% + 15c)', 
        },
        {
            text: 'ACH Payment (1.3% with $30 max cap)', 
        },
    ];

    const proPlanItems = [
        {
            text: 'Everything on the Basic Plan', 
        }, 
        {
            text: 'Instant Pay Out to all of your subcontractors, workers, and vendors with just one email', 
        },
        {
            text: 'QuickBooks Integration, automatically sync all your records from Tapper to QuickBooks Online', 
        },
    ];

    const handleUpgradePlan = () => {
        getLinkForSubscription().then((res) => {
            window.location.href = res.data;
        });
    }

    const handleDowngradePlan = () => {
        cancelSubscriptionPlan().then((res) => {
            fetchUser();
        }).catch((error) => {
            console.log(error.response);
        })
    }

    const handleClickManageSubscription = () => {
        window.open(`${process.env.REACT_APP_STRIPE_DASHBOARD}?prefilled_email=${user?.email}`);
    }

    return (
        <Box sx={{ display: 'flex', }}>
            <AppDrawer/>
            <Grid2 xs container p={4} gap={4}>

                <Grid2 container xs={12} direction={'column'} alignItems={'flex-start'}>
                    <Typography color={'primary'} variant='h3' fontWeight={'bold'}>
                        Account
                    </Typography>
                    <Typography color={'primary'} variant='body2'>
                        Manage your account info and subscriptions
                    </Typography>
                </Grid2>

                <AccountSideBar />

                {!isTablet && <Grid2 xs={'auto'}>
                    <Divider orientation="vertical" variant="fullWidth" sx={{ bgcolor: 'black'}}/>
                </Grid2>}

                <Grid2 xs container p={1} gap={10}>
                    <Grid2 lg={5} md={12} xs={12} borderRadius={4} variant='grey' sx={{borderColor: 'primary.main', borderWidth: 'thin'}}>
                        <Typography 
                            variant='h5' 
                            borderRadius={2} 
                            bgcolor={'primary.main'} 
                            color={'white'}
                            sx={{ width: 'fit-content', fontWeight: 'bolder', padding: '10px', margin: '15px'}}
                        > 
                            Basic Plan
                        </Typography>
                        <Typography
                            variant='h3'    
                            sx={{ width: 'fit-content', fontWeight: 'bolder', padding: '10px', margin: '15px'}}
                        > 
                           Free 
                        </Typography>
                        <Grid2 xs={'auto'}>
                            <Divider orientation='horizontal' variant='middle' sx={{ width: '90%', bgcolor: 'black'}}/>
                        </Grid2>
                        <List sx={{paddingBotom: '100px'}}>
                            {basicPlanItems.map((listItem, index) => (
                                <ListItem key={index}>
                                    <ListItemIcon>
                                        <CheckIcon sx={{color: 'primary.main'}}/>
                                    </ListItemIcon>
                                    <ListItemText 
                                        primary={listItem.text}
                                    />
                                </ListItem>
                            ))}
                        </List>
                        <Grid2 xs={'auto'}>
                            <Divider orientation='horizontal' variant='middle' sx={{ width: '90%', bgcolor: 'black'}}/>
                        </Grid2>
                        {user && user.plantype === false && <Typography 
                            variant='h6'    
                            sx={{ color: 'primary.main', fontWeight: 'bolder', padding: '10px', margin: '15px'}}
                        > 
                           You are on the Basic Plan 
                        </Typography>}
                        {user && user.plantype === true && <Button 
                            variant='contained'    
                            color='primary'
                            sx={{ margin: '25px'}}
                            onClick={() => handleDowngradePlan()}
                        > 
                           Downgrade To Basic Plan 
                        </Button>}
                    </Grid2>
                    <Grid2 lg={5} md={12} xs={12} borderRadius={4} variant='grey' sx={{borderColor: 'primary.main', borderWidth: 'thin'}}>
                        <Typography 
                            variant='h5' 
                            borderRadius={2} 
                            bgcolor={'primary.main'} 
                            color={'white'}
                            sx={{ width: 'fit-content', fontWeight: 'bolder', padding: '10px', margin: '15px'}}
                        > 
                            Pro Plan
                        </Typography>
                        <Stack direction='horizontal' spacing={2} sx={{ padding: '10px', margin: '15px'}}>
                            <Typography
                                variant='h3'    
                                sx={{ width: 'fit-content', fontWeight: 'bolder'}}
                            > 
                            $39.00
                            </Typography>
                            <Typography
                                variant='h6'    
                                sx={{ width: 'fit-content', paddingLeft: '10px', fontWeight: 'bolder', color: 'grey'}}
                            > 
                            /month
                            </Typography>
                        </Stack>
                        <Grid2 xs={'auto'}>
                            <Divider orientation='horizontal' variant='middle' sx={{ width: '90%', bgcolor: 'black', alignSelf: 'flex-end'}}/>
                        </Grid2>
                        <List sx={{paddingBottom: '150px'}}>
                            {proPlanItems.map((listItem, index) => (
                                <ListItem key={index}>
                                    <ListItemIcon>
                                        <CheckIcon sx={{color: '#36d160'}}/>
                                    </ListItemIcon>
                                    <ListItemText 
                                        primary={listItem.text}
                                    />
                                </ListItem>
                            ))}
                        </List>
                        <Grid2 xs={'auto'}>
                            <Divider orientation='horizontal' variant='middle' sx={{ width: '90%', bgcolor: 'black'}}/>
                        </Grid2>
                        {user && user.plantype === true && <Typography 
                            variant='h6'    
                            sx={{ color: 'primary.main', fontWeight: 'bolder', padding: '10px', margin: '15px'}}
                        > 
                           You are on the Pro Plan 
                        </Typography>}
                        {user && user.plantype === false && <Button 
                            variant='contained'    
                            color='primary'
                            sx={{ margin: '25px'}}
                            onClick={() => handleUpgradePlan()}
                        > 
                           Upgrade To Pro Plan 
                        </Button>}
                    </Grid2>
                    {user && user.plantype === true && <Grid2 container xs={12} sx={{':hover': {cursor: 'pointer'}}}>
                        <Typography variant="h6" onClick={() => handleClickManageSubscription()}><u>Manage subscription payment methods</u></Typography>
                    </Grid2>}
                </Grid2>
            </Grid2>    
        </Box>
    );
};

export default ManagePlan;
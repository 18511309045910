import axios from 'axios';

export async function createReferral(referral) {
    return axios.post(`${process.env.REACT_APP_SERVER_URL}/referrals/referral`, referral)
        .then(res => {
            return res.data;
        })
};

export async function verifyReferral(verificationCode) {
    return axios.post(`${process.env.REACT_APP_SERVER_URL}/referrals/verify-referral`, verificationCode)
        .then(res => {
            return res.data;
        })
};



import { Box, Button, Divider, ImageList, ImageListItem, Typography } from "@mui/material";
import { useCallback, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AppDrawer from "../appdrawer/appdrawer";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { useState } from "react";
import { getEstimateOrInvoice, sendEstimate, sendEstimateOrInvoiceReminder } from "../../api/estimates";
import { ChevronLeft, ChevronRightSharp, Edit, Send } from "@mui/icons-material";
import EstimateStatusTag from "./EstimateStatusTag";
import { getUser } from "../../api/user";
import { USDollar } from "../../constants/theme";


/**
 * Rough design:
 * {Title/Back | Edit Estimate button}
 * {Estimate details}
 * {Job Item array}
 * {Attachments | Cost + send button}
 */

const EstimateDetails = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [estimateInfo, setEstimateInfo] = useState({});
    const [userInfo, setUserInfo] = useState({});
    const [sendButtonText, setSendButtonText] = useState('Send Estimate');

    useEffect(() => {
        (async () => {
            let response = await getEstimateOrInvoice(id);
            if (response) {
                setEstimateInfo(response.data);
                setSendButtonText(response.data.invoice.status === 'SENT' ? 'Send Again' : 'Send Estimate');
            } else {
                setEstimateInfo({});
            }
            response = await getUser();
            if (response) {
                setUserInfo(response.data);
            } else {
                setUserInfo({});
            }
        })();
    },[id]);

    const materialTotal = estimateInfo?.invoice?.job_items.reduce((accum, current) => (accum + current?.merchandise_cost), 0)
    const laborTotal = estimateInfo?.invoice?.job_items.reduce((accum, current) => (accum + current?.labor_cost), 0)

    // [TODO] update send function to use invoices/sending for CREATED
    const handleSendButton = useCallback(async () => {
        setSendButtonText('Sending ...');
        let response;
        if (estimateInfo?.invoice?.status === 'CREATED') {
            response = await sendEstimate(
                estimateInfo?.invoice?.id, 
                estimateInfo?.invoice?.email, 
                estimateInfo?.invoice?.total_amount,
            );
        } else if (estimateInfo?.invoice?.status === 'SENT') {
            response = await sendEstimateOrInvoiceReminder(estimateInfo?.invoice?.id);
        }
        if (response) {
            setSendButtonText('Sent Successfully!');
        } else {
            setSendButtonText('Failed to Send');
        }
    }, [estimateInfo]);

    return (
        <Box sx={{ display: 'flex', }}>
            <AppDrawer/>
            <Grid2 container padding={4} rowGap={6} flexGrow={1}>
                <Grid2 xs={12} container alignItems={'flex-end'}>
                    <Grid2 container xs={6} direction={'column'} alignItems={'flex-start'}>
                        <Button startIcon={<ChevronLeft/>} onClick={() => navigate('/estimates')}>
                            Back
                        </Button>
                        <Typography color={'primary'} variant="h3" fontWeight={'bold'}>
                            Estimate Details
                        </Typography>
                    </Grid2>
                    {estimateInfo?.invoice?.status !== 'ACCEPTED' &&
                    <Grid2 xs={6} container justifyContent={'flex-end'}>
                        <Button
                            variant="outlined"
                            startIcon={<Edit/>}
                            onClick={() => navigate(`/estimates/edit/${id}`)}
                        >
                            {(estimateInfo?.invoice?.status === 'SENT' || estimateInfo?.invoice?.status === 'REJECTED') 
                                ? 'Change Order' 
                                : 'Edit Estimate'}
                        </Button>
                    </Grid2>}
                </Grid2>
                <Grid2 xs={12} container gap={6}>
                    <Grid2 xs={12} container alignItems={'flex-end'}>
                        <Grid2 xs={6} container alignItems={'center'} gap={2}>
                            <Typography variant="h4">
                                Proposal #{estimateInfo?.invoice?.invoices_number}
                            </Typography>
                            <EstimateStatusTag status={estimateInfo?.invoice?.status}/>
                        </Grid2>
                        <Grid2 xs={6} container justifyContent={'flex-end'}>
                            <Typography variant="body1" color={'primary'}>
                                DATE: {new Date(estimateInfo?.invoice?.created_at).toLocaleDateString('en-US', {
                                    month: 'short', year:'numeric', day:'numeric', hour: 'numeric', minute: 'numeric', second:'numeric'
                                })}
                            </Typography>
                        </Grid2>
                    </Grid2>
                    <Grid2 xs={6} container gap={3} >
                        <img 
                            src={estimateInfo?.logo}
                            alt=""
                            width={170}
                            height={170}
                        />
                        <Grid2 container direction={'column'} alignItems={'flex-start'} justifyContent={'flex-end'} gap={0.5}>
                            <Typography variant="h5" fontWeight={'bold'}>
                                {userInfo?.company_information?.name}
                            </Typography>
                            <Typography>
                                {userInfo?.location?.address_line_1} 
                            </Typography>
                            <Typography>
                                {userInfo?.location?.city}, {userInfo?.location?.state}, {userInfo?.location?.zip_code}
                            </Typography>
                            <Typography>
                                {userInfo?.company_information?.phone}
                            </Typography>
                            <Typography>
                                {userInfo?.company_information?.email}
                            </Typography>
                        </Grid2>
                    </Grid2>
                    <Grid2 xs={5} container gap={3} >
                        <Divider orientation="vertical" variant="fullWidth" sx={{ bgcolor: 'black'}}/>
                        <Grid2 container direction={'column'} alignItems={'flex-start'} gap={0.5}>
                            <Typography fontSize={14} fontWeight={'bold'} color={'primary'}>
                                INVOICE FOR
                            </Typography>
                            <Typography variant="h5" fontWeight={'bold'}>
                                {estimateInfo?.invoice?.client_name}
                            </Typography>
                            <Typography>
                                {estimateInfo?.invoice?.street_address} 
                            </Typography>
                            <Typography>
                                {estimateInfo?.invoice?.city}, {estimateInfo?.invoice?.state}, {estimateInfo?.invoice?.zip_code}
                            </Typography>
                            <Typography>
                                {estimateInfo?.invoice?.phone}
                            </Typography>
                            <Typography>
                                {estimateInfo?.invoice?.email}
                            </Typography>
                        </Grid2>
                    </Grid2>
                </Grid2>
                <Grid2 xs={12} container>
                    {/**Job Item Array */}
                    <Grid2 xs={6} container p={1} className={'job-item-header-left'}>
                        <Typography color={'#858585'}>Item Name</Typography>
                    </Grid2>
                    <Grid2 xs={6} container justifyContent={'flex-end'} p={1} className={'job-item-header-right'}>
                        <Typography color={'#858585'}>{estimateInfo?.invoice?.showing_item_amount && `Amount`}</Typography>
                    </Grid2>
                    {estimateInfo?.invoice?.job_items.map((item, index) => (
                        <Grid2 key={index} xs={12} container justifyContent={'center'}>
                            <Grid2 xs={6} container p={1}>
                                <Typography color={'primary'} fontWeight={'bold'}>
                                    {item?.job}
                                </Typography>
                            </Grid2>
                            <Grid2 xs={6} container p={1} justifyContent={'flex-end'}>
                                <Typography color={'primary'} fontWeight={'bold'}>
                                    {estimateInfo?.invoice?.showing_item_amount && 
                                    USDollar.format(item?.total_amount_with_markup)}
                                </Typography>
                            </Grid2>
                            {item?.details.map((it, idx) => (
                                <Grid2 key={idx} xs={12} container justifyContent={'center'}>
                                    <Grid2 xs={'auto'} container p={0.25}>
                                        <ChevronRightSharp color={'primary.light'}/>
                                    </Grid2>
                                    <Grid2 xs container p={0.25}>
                                        <Typography color={'primary.light'}>{it?.item}</Typography>
                                    </Grid2>
                                    <Grid2 xs container p={0.25} justifyContent={'flex-end'}>
                                        <Typography color={'primary.light'}>x{it?.quantity}</Typography>
                                    </Grid2>
                                </Grid2>
                            ))}
                            <Grid2 xs={12}>
                                <Divider sx={{ bgcolor: 'gray',}}/>
                            </Grid2>
                        </Grid2>
                    ))}
                </Grid2>
                <Grid2 xs={12} container justifyContent={'space-between'} alignItems={'flex-start'}>
                    <Grid2 xs={5} container>
                        {/**Note + Attachments */}
                        <Grid2 xs={12} container direction={'column'} alignItems={'flex-start'} >
                            <Typography color={'secondary.dark'}>
                                Notes
                            </Typography>
                            <Typography>
                                {estimateInfo?.invoice?.notes ?? ''}
                            </Typography>
                        </Grid2>
                        <Grid2 xs={12} container direction={'column'} alignItems={'flex-start'}>
                            <Typography color={'secondary.dark'}>
                                Attachments
                            </Typography>
                            {estimateInfo?.invoice?.attach_photos
                            ? <ImageList>
                                {estimateInfo?.invoice?.attach_photos.map((url, index) => (
                                    <ImageListItem key={index}>
                                        <img
                                            src={url}
                                            alt=""
                                            loading="lazy"
                                        />
                                    </ImageListItem>
                                ))}
                            </ImageList>
                            : <Typography>N/A</Typography>
                            }
                        </Grid2>
                    </Grid2>
                    <Grid2 xs={6} container rowGap={1} alignItems={'flex-end'} >
                        {/** Estimated Total */}
                        <Grid2 xs={6} container>
                            <Typography color={'primary'}> 
                                Estimated Total
                            </Typography>
                        </Grid2>
                        <Grid2 xs={6} container justifyContent={'flex-end'}>
                            <Typography variant="h5" color={'primary'} fontWeight={'bold'}>
                                {USDollar.format(estimateInfo?.invoice?.total_amount)}
                            </Typography>
                        </Grid2>
                        <Grid2 xs={12}>
                            <Divider sx={{bgcolor: 'gray'}} />
                        </Grid2>
                        {/** Material Cost */}
                        <Grid2 xs={6} container>
                            <Typography color={'secondary.dark'}> 
                                Material Cost
                            </Typography>
                        </Grid2>
                        <Grid2 xs={6} container justifyContent={'flex-end'}>
                            <Typography color={'secondary.dark'}>
                                {USDollar.format(materialTotal)}
                            </Typography>
                        </Grid2>
                        {/** Labor Cost */}
                        <Grid2 xs={6} container>
                            <Typography color={'secondary.dark'}> 
                                Labor Cost
                            </Typography>
                        </Grid2>
                        <Grid2 xs={6} container justifyContent={'flex-end'}>
                            <Typography color={'secondary.dark'}>
                                {USDollar.format(laborTotal)}
                            </Typography>
                        </Grid2>
                        <Grid2 xs={12} container>
                            <Typography color={'primary.light'}>
                                *Costs are not shown to clients.
                            </Typography>
                        </Grid2>
                        {(estimateInfo?.invoice?.status === 'SENT' || estimateInfo?.invoice?.status === 'CREATED') &&
                        <Grid2 xs={12} container justifyContent={'center'}>
                            <Button fullWidth variant="contained" startIcon={<Send/>} onClick={handleSendButton}>
                                {sendButtonText}
                            </Button>
                        </Grid2>}
                    </Grid2>
                </Grid2>
            </Grid2>
        </Box>
    );
};

export default EstimateDetails;
import { React, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Stack from 'react-bootstrap/Stack';
import { BsXCircleFill, BsFillCheckCircleFill } from 'react-icons/bs';

import { verifyReferral } from '../../api/referral';

import './createReferral.css'

function VerifyReferralModal() {
  const [show, setShow] = useState(false);
  const [showSuccessMsg, setShowSuccessMsg] = useState(false);
  const successMsg = 'Verified referral successfully! Your Amazon Gift Card has been sent to your email'
  const [showErrorMsg, setShowErrorMsg] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  async function handleSubmit(event) {
    event.preventDefault();
    try{
      const resp = await verifyReferral({
        verification_code: event.target.form.elements.verificationCode.value
      })
      setErrorMsg('');
      setShowErrorMsg(false)
      setShowSuccessMsg(true)
    } catch (e) {
      setShowSuccessMsg(false);
      setErrorMsg(e.response.data.message);
      setShowErrorMsg(true);
    }
  }
  const handleShow = () => setShow(true);
  const handleClose = () => {
    setShow(false);
    setErrorMsg('');
    setShowErrorMsg(false);
    setShowSuccessMsg(false);
  }

  return (
    <>
      <Button className="tapper-btn-primary" variant="primary" onClick={handleShow}>
        Verify Referral
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>Verify Referral</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Form id='verify-referral-form'>
                <Form.Group className="mb-1" size="sm" controlId="verificationCode">
                    <Form.Label size="sm">Verification Code</Form.Label>
                    <Form.Control type="text" size="sm"/>
                </Form.Group>
            </Form>
            { showSuccessMsg
                ?
                  <Stack direction='horizontal'>
                    <BsFillCheckCircleFill style={{color: "green"}}/>
                    <p style={{fontSize: 'smaller', paddingLeft: '5px'}}>{successMsg}</p>
                  </Stack>
                : <p></p>
            }
            { showErrorMsg
                ?
                  <Stack direction='horizontal'>
                    <BsXCircleFill style={{color: "red"}}/>
                    <p style={{fontSize: 'smaller', paddingLeft: '5px'}}>{errorMsg}</p>
                  </Stack>
                : <p></p>
            }
        </Modal.Body>
        <Modal.Footer>
          <Button className="tapper-btn-secondary" variant="secondary" size="sm" onClick={handleClose}>
            Close
          </Button>
          <Button className="tapper-btn-primary" variant="primary" size="sm" type="submit" form='verify-referral-form' onClick={handleSubmit}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default VerifyReferralModal;
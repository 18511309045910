export default function BankTransferIcon() {
    return (
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Bank">
        <g id="Group 222">
        <path id="Vector 10" d="M3.7793 14.375V14C3.7793 14 10.2793 14 12.9558 13.25C14.4716 12.8252 16.7793 12.125 16.7793 12.125L8.36753 11.75C8.36753 11.75 15.5998 11.1086 15.6322 10.625C15.6646 10.1414 14.8675 8.75 14.8675 8.75L19.8381 11.75V14.375H3.7793Z" fill="#DBF9F3"/>
        <path id="Vector 11" d="M3.39648 22.625V20H7.22001V19.25C7.22001 19.25 11.4259 19.625 14.8671 17.75C16.1054 17.0753 16.7788 14.375 16.7788 14.375H17.5435V20H19.8377V22.625H3.39648Z" fill="#DBF9F3"/>
        <path id="Rectangle 165" d="M3.375 11.993H20.2435M3.375 11.993V14.2136C3.375 14.4166 3.5396 14.5812 3.74265 14.5812H6.7487M3.375 11.993L11.4514 6.54813C11.5737 6.46566 11.7335 6.4643 11.8572 6.54467L20.2435 11.993M20.2435 11.993V14.2136C20.2435 14.4166 20.0789 14.5812 19.8759 14.5812H17.2447M17.2447 14.5812V20.0003M17.2447 14.5812H11.9967M6.7487 14.5812V20.0003M6.7487 14.5812H11.9967M6.7487 20.0003H3.74265C3.5396 20.0003 3.375 20.1649 3.375 20.368V22.2209C3.375 22.424 3.5396 22.5886 3.74265 22.5886H19.8759C20.0789 22.5886 20.2435 22.424 20.2435 22.2209V20.368C20.2435 20.1649 20.0789 20.0003 19.8759 20.0003H17.2447M6.7487 20.0003H11.9967M17.2447 20.0003H11.9967M11.9967 14.5812V20.0003" stroke="#587E81" strokeWidth="0.975" strokeLinecap="round"/>
        <path id="Rectangle 166" d="M13.1209 9.72013L11.8393 8.77743C11.7097 8.68211 11.5332 8.68211 11.4036 8.77743L10.1221 9.72013" stroke="#587E81" strokeWidth="0.975" strokeLinecap="round"/>
        <path id="Ellipse 53" d="M2.25 8.25C3.85891 4.57103 7.53334 2 11.8088 2C16.0843 2 19.7587 4.57103 21.3676 8.25M21.3676 8.25L18.3088 6.875M21.3676 8.25L21.75 5" stroke="#587E81" strokeWidth="0.975" strokeLinecap="round"/>
        </g>
        </g>
        </svg>
    );
}
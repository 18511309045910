import React from 'react';
import './reviewForm.css'
import axios from 'axios';
import { RotatingLines } from "react-loader-spinner";
import { useLocation, Navigate } from "react-router-dom";
import Navbar from '../components/review/Navbar';

function withLocation(Component) {
    return props => <Component {...props} location={useLocation()} />;
}

class ReviewForm extends React.Component {

    constructor(props) {
        super(props);

        const query = new URLSearchParams(this.props.location.search);

        const name = query.get('name');
        const address = query.get('address');
        const userId = query.get('user_id');

        this.state = {
            user: {
                "company_information": {
                    "name": '',
                    "email": '',
                    "phone": ''
                },
                "location": {
                    "city": '',
                    "state": '',
                    "zip_code": '',
                    "address_line_1": 'New York',
                    "address_line_2": ''
                },
            },
            userId,
            logo: '',
            rating_profi: 0,
            hover_profi: 0,
            rating_quality: 0,
            hover_quality: 0,
            rating_resp: 0,
            hover_resp: 0,
            rating_punc: 0,
            hover_punc: 0,
            comments: '',
            showingFiles: [],
            uploadingFiles: [],
            isDisabled: false,
            name,
            address
        };

        this.addFiles = this.addFiles.bind(this);
        this.sendRequest = this.sendRequest.bind(this);
    }

    componentDidMount() {
        this.getUser();
        this.getUserLogo();
    }

    getUserLogo = async () => {
        const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/users/logo`, {
            headers: {
                'user-id': this.state.userId
            }
        });
        this.setState({ logo: response.data.logoURL });
    };

    async getUser() {
        const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/users`, {
            headers: {
                'user-id': this.state.userId
            }
        });
        this.setState({ user: response.data });
    }

    addFiles(e) {
        let showingFiles = [];
        let uploadingFiles = [];
        for (let i = 0; i < e.target.files.length; i++) {
            showingFiles.push(URL.createObjectURL(e.target.files[i]));
            uploadingFiles.push(e.target.files[i]);
        }
        this.setState({ showingFiles });
        this.setState({ uploadingFiles });
    }

    sendRequest(event) {
        event.preventDefault();
        this.setState({ isDisabled: true });
        let bodyFormData = new FormData();
        bodyFormData.append('user_id', this.state.userId);
        bodyFormData.append('client_name', this.state.name);
        bodyFormData.append('review', this.state.comments);
        bodyFormData.append('rating', Number((this.state.rating_profi + this.state.rating_punc + this.state.rating_quality + this.state.hover_resp) / 4).toFixed(2));
        bodyFormData.append('address', this.state.address);
        this.state.uploadingFiles.forEach(file => bodyFormData.append('review_images', file));

        axios.post(`${process.env.REACT_APP_SERVER_URL}/review/add-review`, bodyFormData, {
            headers: {
                'Access-Control-Allow-Origin': '*'
            }
        }).then(() => window.location = `/review/${this.state.userId}`);
    }

    render() {
        return (
            <div>
                <div className="sticky top-0 z-20">
                    <Navbar />
                </div>
                <h1 className="greeting">Review for {this.state.user.company_information ? this.state.user.company_information.name : ' '}</h1>
                <center><img src={this.state.logo} style={{ maxWidth: '200px', maxHeight: '200px', objectFit: 'fill' }} alt='' /></center>
                <form className="review-form" onSubmit={this.sendRequest}>
                    <div className="starRating">
                        <span className="greeting" >Professionalism</span>
                        {[...Array(5)].map((star, index) => {
                            index += 1;
                            return (
                                <button
                                    type="button"
                                    key={index}
                                    className={index <= (this.state.hover_profi || this.state.rating_profi) ? "on" : "off"}
                                    onClick={() => this.setState({ rating_profi: index })}
                                    onMouseEnter={() => this.setState({ haver_profi: index })}
                                    onMouseLeave={() => this.setState({ haver_profi: this.state.rating_profi })}
                                >
                                    <span className="star">&#9733;</span>
                                </button>
                            );
                        })}
                    </div>
                    <div className="starRating">
                    <span className="greeting" >Work Quality</span>
                        {[...Array(5)].map((star, index) => {
                            index += 1;
                            return (
                                <button
                                    type="button"
                                    key={index}
                                    className={index <= (this.state.hover_quality || this.state.rating_quality) ? "on" : "off"}
                                    onClick={() => this.setState({ rating_quality: index })}
                                    onMouseEnter={() => this.setState({ haver_quality: index })}
                                    onMouseLeave={() => this.setState({ haver_quality: this.state.rating_quality })}
                                >
                                    <span className="star">&#9733;</span>
                                </button>
                            );
                        })}
                    </div>
                    <div className="starRating">
                    <span className="greeting" >Responsiveness</span>
                        {[...Array(5)].map((star, index) => {
                            index += 1;
                            return (
                                <button
                                    type="button"
                                    key={index}
                                    className={index <= (this.state.hover_resp || this.state.rating_resp) ? "on" : "off"}
                                    onClick={() => this.setState({ rating_resp: index })}
                                    onMouseEnter={() => this.setState({ hover_resp: index })}
                                    onMouseLeave={() => this.setState({ hover_resp: this.state.rating_resp })}
                                >
                                    <span className="star">&#9733;</span>
                                </button>
                            );
                        })}
                    </div>
                    <div className="starRating">
                    <span className="greeting" >Punctuality</span>
                        {[...Array(5)].map((star, index) => {
                            index += 1;
                            return (
                                <button
                                    type="button"
                                    key={index}
                                    className={index <= (this.state.hover_punc || this.state.rating_punc) ? "on" : "off"}
                                    onClick={() => this.setState({ rating_punc: index })}
                                    onMouseEnter={() => this.setState({ haver_punc: index })}
                                    onMouseLeave={() => this.setState({ haver_punc: this.state.rating_punc })}
                                >
                                    <span className="star">&#9733;</span>
                                </button>
                            );
                        })}
                    </div>
                    <label className="greeting">Comments:</label>
                    <textarea placeholder="Comments" className="comment-textarea" onChange={e => this.setState({ comments: e.target.value })} />
                    <div className="files-block">
                        {this.state.showingFiles.map((file, index) => <img src={file} key={index} alt={index.toString()} className="file-image" />)}
                    </div>
                    <label className="label">
                        <input type="file" accept=".png, .jpg, .jpeg, .svg" onChange={this.addFiles} multiple />
                        <span>Attach job photo</span>
                    </label>
                    <button type="submit" disabled={this.state.isDisabled} className="submit-button">
                        {!this.state.isDisabled ? 'Submit' : <RotatingLines strokeColor="black" strokeWidth="3" animationDuration="1" width="25" visible={true} />}
                    </button>
                </form>
            </div>
        )
    }

}

export default withLocation(ReviewForm);

import { Search } from "@mui/icons-material";
import { InputAdornment, TextField, Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { useState } from "react";
import { USDollar } from "../../constants/theme";
import { DataGrid } from "@mui/x-data-grid";
import EstimateMenuButton from "./EstimateMenuButton";
import { useEffect } from "react";
import { getEstimates, searchEstimates } from "../../api/estimates";
import EstimateStatusTag from "./EstimateStatusTag";

const EstimatesTable = () => {

    const [paginationModel, setPaginationModel] = useState({
        pageSize: 10,
        page: 0,
    });
    const [estimates, setEstimates] = useState([]);
    const [totalEstimateCount, setTotalEstimateCount] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [search, setSearch] = useState('');
    const [refreshTable, setRefreshTable] = useState(false); // value irrelevant, used to trigger table re-renders
    
    const cols = [
        {
            field: 'client_name', 
            headerName: 'Client Name', 
            flex: 35,
        },
        {
            field: 'total_amount', 
            headerName: 'Total Amount', 
            flex: 20, 
            valueGetter: (params) => (USDollar.format(params.value))},
        {
            field: 'created_at', 
            headerName: 'Date', 
            flex: 20, 
            valueGetter: (params) => (new Date(params.value).toLocaleDateString('en-US', {
                month: 'short', year:'numeric', day:'numeric', hour: 'numeric', minute: 'numeric', second:'numeric'
            }))
        },
        {
            field: 'status',
            headerName: 'Status',
            flex: 10,
            renderCell: ({row}) => (<EstimateStatusTag key={row.index} status={row.status}/>)
        },
        {
            field: 'action', 
            headerName: 'Action', 
            flex: 10, 
            renderCell: ({row}) => (
                <EstimateMenuButton 
                    key={row.index} 
                    estimateInfo={row} 
                    setRefreshTable={setRefreshTable}
                />
            )
        }
    ];

    useEffect(() => {
        (async () => {
            // [TODO] Update this for totalEstimateCount when API gets updated
            setIsLoading(true);
            let response;
            if (search === '') {
                response = await getEstimates(1, 1);
                if (response) {
                    setTotalEstimateCount(response.data.count);
                } else {
                    setTotalEstimateCount(0);
                }
                response = await getEstimates(paginationModel.page + 1, paginationModel.pageSize);
                if (response) {
                    setEstimates(response.data.result);
                } else {
                    setEstimates([]);
                }
            } else {
                response = await searchEstimates(search);
                if (response) {
                    setEstimates(response.data);
                }
            }
            setIsLoading(false);
        })();
    }, [paginationModel, search, refreshTable]);

    return (
        <Grid2 container xs={12} rowGap={3}>
            <Grid2 container xs={12} justifyContent={'flex-start'}>
                <TextField
                    variant="outlined"
                    color="secondary"
                    type="text"
                    value={search}
                    label="Search"
                    placeholder="Search Estimates"
                    onChange={event => setSearch(event.target.value)}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Search color="secondary"/>
                            </InputAdornment>
                        ),
                    }}
                />
            </Grid2>
            <Grid2 xs={12} className="data-table-wrapper">
                {!isLoading && estimates.length === 0 ? 
                    <Typography variant="h4">No data</Typography>
                    : <DataGrid
                        className="data-table"
                        rows={estimates}
                        rowCount={search === '' ? totalEstimateCount : undefined}
                        columns={cols}
                        pageSizeOptions={[5, 10, 20]}
                        loading={isLoading}
                        paginationMode={(search === '' ? "server" : "client")}
                        paginationModel={paginationModel}
                        onPaginationModelChange={setPaginationModel}
                        disableRowSelectionOnClick
                    />
                }
            </Grid2>
        </Grid2>
    );
};

export default EstimatesTable;
import axios from "axios";

const fetchCoordinates = (address, apiKey) => {
    return axios.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${apiKey}`)
        .then(res => {
            // handle success
            return res;
        })
        .catch(error => {
            console.log(error);
        });
}

export default fetchCoordinates;

import React from 'react';

import Stack from 'react-bootstrap/Stack';

import CreateReferralModal from '../components/referral/createReferral';
import VerifyReferralModal from '../components/referral/verifyReferral';
import TapperNavbar from '../components/navbar/navbar';

import "./referral.css"

function Referral() {
  return (
    <div className="d-flex justify-content-center">
        <TapperNavbar></TapperNavbar>
        <div className="referral d-flex justify-content-center">
            <Stack className="referral-stack d-flex justify-content-center" gap={1}>
                <div className="p-2">
                    <img
                    alt=""
                    src="/amazon-logo.png"
                    width="200px"
                    height="200px"
                    className="d-inline-block align-top"
                    />{' '}
                </div>
                <div className="p-2">
                    <h2 className="bold">
                    Refer a Home Service Pro now to get a $25 <br/>Amazon Gift Card, each!
                    </h2>
                </div>
                <Stack className="p-2 d-flex justify-content-left" direction="horizontal">
                    <h3 className="step">Step 1:</h3>
                    <p className="step-text text-no-wrap">
                        Enter your information and the Pro's email to create a referral request.
                    </p>
                </Stack>
                <div className="p-2 d-flex justify-content-center">
                    <CreateReferralModal></CreateReferralModal>
                </div>
                <Stack className="p-2 d-flex justify-content-left" direction="horizontal">
                    <h3 className="step">Step 2:</h3>
                    <p className="step-text text-no-wrap">
                        Ask the Pro to sign up for Tapper (QR code below) and process a payment for no less than $100.
                    </p>
                </Stack>
                <div className="d-flex justify-content-center">
                    <img
                        alt=""
                        src="/tapper-qr-code.png"
                        width="100px"
                        height="100px"
                    />{' '}

                </div>
                <Stack className="p-2 d-flex justify-content-left" direction="horizontal">
                    <h3 className="step">Step 3:</h3>
                    <p className="step-text text-no-wrap">
                        Click on "Verify" below. An email with an Amazon Gift Card claim code will be sent to both you and the Pro via email respectively.
                    </p>
                </Stack>
                <div className="p-2 d-flex justify-content-center">
                    <VerifyReferralModal></VerifyReferralModal>
                </div>
            </Stack>
        </div>
    </div>

        

  );
}

export default Referral;

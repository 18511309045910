import React, { useEffect, useState,} from 'react';
import { useParams } from 'react-router-dom';


import TapperNavbar from '../components/navbar/navbar';
import ColoredLine from '../components/util/coloredLine';

import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Gallery from "react-photo-gallery";
import { BsDownload, BsCaretRightFill } from 'react-icons/bs';


import fetchInvoice from '../api/invoice';
import fetchUser from '../api/user';

import "./estimatePreview.css"
import '../components/referral/createReferral.css'

function PreviewEstimate() {
  const { userId, estimateId } = useParams();
  const [estimate, setEstimate] = useState();
  const [user, setUser] = useState();
  const [attachments, setAttachments] = useState([]);

  async function getEstimate() {
    const res = await fetchInvoice(userId, estimateId);
    setEstimate(res.data);
    
    var attachmentsList = []
    res.data.invoice.attach_photos.forEach((src, index) => {
      attachmentsList.push(
        {
          src: src,
          width: 3,
          height: 3
        }
      )
    });
    setAttachments(attachmentsList);
  }

  async function getUser() {
    const res = await fetchUser(userId);
    setUser(res.data);
  }

  useEffect(() => {
    getEstimate();
    getUser();
  }, [])

  return (
    <div className='preview d-flex justify-content-center'>
      <TapperNavbar/>
        {user && estimate
        ? 
          <div className='preview-container d-flex flex-column'>
            <Button className='tapper-btn-primary' id='download' href={estimate.invoice.link}>
                <BsDownload style={{marginRight: '10px'}}/>
                Download
            </Button>
          <div className='document d-flex flex-column'>
            <span className='document-header d-flex flex-row justify-content-between items-center'>
              <div className='document-header-text tapper-green'>
                <p className='text-xl bold'>{user.company_information.name}</p>
                <p>{user.location.address_line_1}, {user.location.address_line_2}</p>
                <p>{user.location.city} {user.location.state} {user.location.zip_code}</p>
                <p>{user.company_information.phone}</p>
                <p>{user.company_information.email}</p>
              </div>
              <img className='logo' alt='' src={estimate.logo}/>
            </span>
            <ColoredLine color={"grey"} height={3}/>
              {estimate.invoice.invoices_type === 'ESTIMATE'
              ? <span className='document-header d-flex flex-row justify-content-between tapper-green'>
                <p className='text-xl bold'>Estimate for:</p>
                <p className='text-md bold'>#{estimate.invoice.invoices_number}</p>
              </span> 
              : <span className='document-header d-flex flex-row justify-content-between tapper-green'>
                <p className='text-xl bold'>Invoice for:</p>
                <p className='text-md bold'>#{estimate.invoice.invoices_number}</p>
              </span> 
              }
            <span className='document-header d-flex flex-row justify-content-between tapper-green'>
              <div id='client-info'>
                <p className='text-lg'>{estimate.invoice.client_name}</p>
                <p>{estimate.invoice.street_address}</p>
                <p>{estimate.invoice.city} {estimate.invoice.state} {estimate.invoice.zip_code}</p>
              </div>
              <div id='date'>
                <p>Date:</p>
                <p>{new Date(estimate.invoice.created_at).toLocaleDateString('en-US')}</p>
              </div>
            </span>
              <Table className='items-table'>
                <thead>
                  <tr>
                    <th className='job'>Job Description</th>
                    <th className='amount'>{estimate.invoice.showing_item_amount && `Amount`}</th>
                  </tr>
                </thead>
                <tbody>
                  { estimate.invoice.job_items.map((job_item, i)=>{
                    return <>
                      <tr>
                        <td className='job'>{job_item.job}</td>
                        <td className='amount'>{estimate.invoice.showing_item_amount && `$${job_item.total_amount_with_markup}`}</td>
                      </tr>
                      {job_item.details.length > 0 && <tr>
                        <td colSpan={5}>
                          <Table className='details-table'>
                            <tbody>
                            { job_item.details.map((detail, j)=>{
                              return <>
                                <tr>
                                  <td className='job'>
                                    <span className='d-flex flex-row flex-start'>
                                      <BsCaretRightFill className='detail-caret'/>
                                      <p>{detail.item}</p>
                                    </span>
                                  </td>
                                  <td className='amount'>X{detail.quantity}</td>
                                </tr>
                              </>
                              })
                            }
                            </tbody>
                          </Table>
                        </td>
                      </tr>}
                    </> 
                    })
                  }
                  </tbody>
                  <tfoot>
                    <tr>
                      <th className='job text-lg bold'>Total</th>
                      <th className='amount text-lg bold'>${estimate.invoice.total_amount}</th>
                    </tr>
                  </tfoot>
              </Table>
              <p className='preview-body-header'>Notes:</p>
              <p className='preview-body-text'>{estimate.invoice.notes}</p>
              <p className='preview-body-header'>Attachments:</p>
              <div id='image-viewer'>
                 {/* {estimate.invoice.attach_photos.map((src, index) => (
                  <img
                    src={ src }
                    onClick={ () => openImageViewer(index) }
                    width="300px"
                    key={ index }
                    style={{ margin: '2px' }}
                    alt=""
                  />
                ))}

                {isViewerOpen && (
                  <ImageViewer
                    src={ estimate.invoice.attach_photos }
                    currentIndex={ currentImage }
                    disableScroll={ true }
                    closeOnClickOutside={ true }
                    onClose={ closeImageViewer }
                  />
                )} */}
                <Gallery photos={attachments} />
              </div>
          </div>
        </div>

        : <p>Unable to find estimate</p>
        }
    </div>
  );
}

export default PreviewEstimate;

// Function to filter out null or empty string properties
export function filterObjectProperties(object) {
    return Object.keys(object).reduce((accumulator, key) => {
        if (object[key] !== null && object[key] !== '') {
            accumulator[key] = object[key];
        }
        return accumulator;
    }, {});
}

// Function to convert "empty" object properties to null
export function nullifyObjectProperties(object) {
    return Object.keys(object).reduce((accumulator, key) => {
        if (object[key] !== null && object[key] !== '') {
            accumulator[key] = object[key];
        } else {
            accumulator[key] = null;
        }
        return accumulator;
    }, {});
}

// Function to convert phone number to XXX-XXX-XXXX format
export function phoneNumberReformatter(phoneNumber) {
    // Define the regex pattern to match the phone number
    const regex = /^((\+1|1)?( |-)?)?(\([2-9][0-9]{2}\)|[2-9][0-9]{2})( |-)?([2-9][0-9]{2}( |-)?[0-9]{4})$/;

    // Check if the provided phone number matches the regex pattern
    if (regex.test(phoneNumber)) {
        // Replace the phone number with the simplified format
        const fix = phoneNumber.replace(
            /^((\+1|1)?( |-)?)?(\([2-9][0-9]{2}\)|[2-9][0-9]{2})( |-)?([2-9][0-9]{2}( |-)?[0-9]{4})$/,
            "$4_$6"
        );

        let [firstThree, lastSeven] = fix.split('_');
        
        // remove brackets if any
        let firstThreeArray = firstThree.split('');
        if (firstThreeArray[0] === '(') {
            firstThreeArray.splice(0,1);
        }
        if (firstThreeArray[firstThreeArray.length - 1] === ')') {
            firstThreeArray.pop();
        }

        // remove excess spaces or hyphens if any
        let lastSevenArray = lastSeven.split('');
        if (lastSevenArray[3] === ' ' || lastSevenArray[3] === '-') {
            lastSevenArray.splice(3, 1);
        }

        let midThreeArray = lastSevenArray.slice(0, 3);
        let lastFourArray = lastSevenArray.slice(3);

        firstThree = firstThreeArray.join('');
        let midThree = midThreeArray.join('');
        let lastFour = lastFourArray.join('');

        return [firstThree, midThree, lastFour].join('-');
    } else {
        throw new Error('Invalid phone number format for phoneNumberReformatter');
    }
}
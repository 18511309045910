import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { Button, Divider, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { updateCheckbookBusiness, updateCheckbookIndividual } from '../../api/payout';
import AddressInput from '../../components/util/addressInput';

const SendingSettingsBusiness = ({user}) => {
    const [dob, setDob] = useState(user?.user?.dob ? user?.user?.dob?.split(" ")[0] : '2000-01-01');
    const [dobError, setDobError] = useState(false);
    const [firstName, setFirstName] = useState(user?.user?.first_name);
    const [firstNameError, setFirstNameError] = useState(false);
    const [lastName, setLastName] = useState(user?.user?.last_name);
    const [lastNameError, setLastNameError] = useState(false);
    const [ssn, setSsn] = useState(user?.user?.ssn);
    const [ssnError, setSsnError] = useState(false);

    const [businessName, setBusinessName] = useState(user?.user?.business_name);
    const [businessNameError, setBusinessNameError] = useState(false);
    const [taxId, setTaxId] = useState(user?.merchant?.tax_id);
    const [taxIdError, setTaxIdError] = useState(false);
    const [website, setWebsite] = useState(user?.merchant?.website);
    const [websiteError, setWebsiteError] = useState(false);
    const [address, setAddress] = useState(user?.merchant?.address?.line_1);
    const [addressError, setAddressError] = useState(false);
    const [city, setCity] = useState(user?.merchant?.address?.city);
    const [cityError, setCityError] = useState(false);
    const [state, setState] = useState(user?.merchant?.address?.state);
    const [stateError, setStateError] = useState(false);
    const [zip, setZip] = useState(user?.merchant?.address?.zip);
    const [zipError, setZipError] = useState(false);

    const resetErrorFields = () => {
        setFirstNameError(false);
        setLastNameError(false);
        setDobError(false);
        setSsnError(false);

        setAddressError(false);
        setCityError(false);
        setStateError(false);
        setZipError(false);
        setBusinessNameError(false);
        setTaxIdError(false);
        setWebsiteError(false);
    }

    const businessUpdateValidate = () => {
        const taxRegex = /^\d{9}$/;
        const urlRegex = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.[a-z]{2,}(:[0-9]+)?(\/.*)?$/;
        if (!address) {
            setAddressError(true);
            return false;
        }
        if (!city) {
            setCityError(true);
            return false;
        }
        if (!state) {
            setStateError(true);
            return false;
        }
        if (!zip) {
            setZipError(true);
            return false;
        }
        if (!businessName) {
            setBusinessNameError(true);
            return false;
        }
        if (!taxRegex.test(taxId)) {
            setTaxIdError(true);
            return false;
        }
        if (!urlRegex.test(website) || !website) {
            setWebsiteError(true);
            return false;
        }
        return true;
    }

    const personalUpdateValidate = () => {
        const regex = /^\d{4}$/;
        if (!firstName) {
            setFirstNameError(true);
            return false;
        }
        if (!lastName) {
            setLastNameError(true);
            return false;
        }
        if (!dob) {
            setDobError(true);
            return false;
        }
        if (!regex.test(ssn)) {
            setSsnError(true);
            return false;
        }
        return true;
    }

    const submitUpdateBusiness = () => {
        resetErrorFields();
        if (!businessUpdateValidate()) return;
        updateCheckbookBusiness(businessName, address, taxId, website, city, state, zip).then(result => {
            alert('Updated Successfully!');
        })
        .catch(error => {
        });
    }

    const submitUpdatePersonal = () => {
        resetErrorFields();
        if (!personalUpdateValidate()) return;
        updateCheckbookIndividual(firstName, lastName, dob, ssn).then(result => {
            alert('Updated Successfully!');
        })
        .catch(error => {
        });
    }

    return (
        <Grid2 xs={12} container rowGap={2} justifyContent={'space-between'}>
            <Grid2 xs={12} container>
                <Typography variant='h6' color={'primary'} fontWeight={'bold'}>You are registered as Business</Typography>
            </Grid2>
            <Grid2 xs={12} container>
                <Typography variant='button' color={'primary'} fontWeight={'bold'}>Personal Information</Typography>
            </Grid2>
            <Grid2 lg={5.5} md={12} xs={12}>
                <TextField
                    variant="outlined"
                    fullWidth
                    color="primary"
                    type="text"
                    label="First Name"
                    placeholder="Enter First Name..."
                    error={firstNameError}
                    helperText={firstNameError ? "First Name is required." : ''}
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                />
            </Grid2>
            <Grid2 lg={5.5} md={12} xs={12}>
                <TextField
                    variant="outlined"
                    fullWidth
                    color="primary"
                    type="text"
                    label="Last Name"
                    placeholder="Enter Last Name..."
                    error={lastNameError}
                    helperText={lastNameError ? "Last Name is required." : ''}
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                />
            </Grid2>
            <Grid2 lg={5.5} md={12} xs={12}>
                <TextField
                    variant="outlined"
                    fullWidth
                    color="primary"
                    type="date"
                    label="Date of Birth *"
                    placeholder="MM/DD/YYYY"
                    error={dobError}
                    helperText={dobError ? "Date of Birth is required." : ''}
                    value={dob}
                    onChange={(e) => setDob(e.target.value)}
                />
            </Grid2>
            <Grid2 lg={5.5} md={12} xs={12}></Grid2>
            <Grid2 lg={5.5} md={12} xs={12}>
                <TextField
                    variant="outlined"
                    fullWidth
                    color="primary"
                    type="text"
                    label="Last 4 digits of SSN *"
                    placeholder="Enter SSN..."
                    value={ssn}
                    error={ssnError}
                    helperText={ssnError ? "Please a valid SSN number" : ''}
                    onChange={(e) => setSsn(e.target.value)}
                />
            </Grid2>
            <Grid2 lg={5.5} md={12} xs={12}></Grid2>
            <Grid2 xs={12} container justifyContent={'space-between'}>
                <Grid2 xs={12}>
                    <Button
                        variant="contained"
                        fullWidth
                        onClick={() => submitUpdatePersonal()}
                    >
                        Save Changes
                    </Button>
                </Grid2>
            </Grid2>
            <Grid2 xs={12} pt={1}>
                <Divider variant="fullWidth" sx={{ bgcolor: 'black'}}/>
            </Grid2>
            <Grid2 xs={12} container>
                <Typography variant='button' color={'primary'} fontWeight={'bold'}>Business Information</Typography>
            </Grid2>
            <Grid2 lg={5.5} md={12} xs={12}>
                <TextField
                    variant="outlined"
                    fullWidth
                    color="primary"
                    type="text"
                    label="Business Legal Name"
                    placeholder="Enter Name..."
                    value={businessName}
                    onChange={(e) => setBusinessName(e.target.value)}
                    error={businessNameError}
                    helperText={businessNameError ? "Business Name is required." : ''}
                />
            </Grid2>
            <Grid2 lg={5.5} md={12} xs={12}></Grid2>
            <Grid2 lg={5.5} md={12} xs={12}>
                <TextField
                    variant="outlined"
                    fullWidth
                    color="primary"
                    type="text"
                    label="Business Tax ID"
                    placeholder="Enter Business Tax ID..."
                    error={taxIdError}
                    helperText={taxIdError ? "Please input valid Tax ID (9 digits)." : ''}
                    value={taxId}
                    onChange={(e) => setTaxId(e.target.value)}
                />
            </Grid2>
            <Grid2 lg={5.5} md={12} xs={12}></Grid2>
            <Grid2 lg={5.5} md={12} xs={12}>
                <TextField
                    variant="outlined"
                    fullWidth
                    color="primary"
                    type="text"
                    label="Business Website"
                    placeholder="Enter Business Website..."
                    error={websiteError}
                    helperText={websiteError ? "Please input valid website address." : ''}
                    value={website}
                    onChange={(e) => setWebsite(e.target.value)}
                />
            </Grid2>
            <Grid2 lg={5.5} md={12} xs={12}></Grid2>
            <Grid2 lg={5.5} md={12} xs={12}>
                <AddressInput
                    value={address}
                    setValue={(address, city, state, zip) => {
                        if(address) {
                            setAddress(address);
                            setCity(city);
                            setState(state);
                            setZip(zip);
                        }
                        else {
                            setAddress(address);
                        }
                    }}
                    label="Business Address"
                    placeholder="Enter Business Address"
                    error={addressError}
                    helperText={addressError ? "Address is required." : ''}
                />
            </Grid2>
            <Grid2 xs={12} container justifyContent={'space-between'} rowGap={4}>
                <Grid2 lg={3.5} md={12} xs={12}>
                    <TextField
                        variant="outlined"
                        fullWidth
                        color="primary"
                        type="text"
                        label="City"
                        placeholder="Enter City"
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                        error={cityError}
                        helperText={cityError ? "City is required." : ''}
                    />
                </Grid2>
                <Grid2 lg={3.5} md={12} xs={12}>
                    <TextField
                        variant="outlined"
                        fullWidth
                        color="primary"
                        type="text"
                        label="State"
                        placeholder="Enter State"
                        value={state}
                        onChange={(e) => setState(e.target.value)}
                        error={stateError}
                        helperText={stateError ? "State is required." : ''}
                    />
                </Grid2>
                <Grid2 lg={3.5} md={12} xs={12}>
                    <TextField
                        variant="outlined"
                        fullWidth
                        color="primary"
                        type="text"
                        label="Zip Code"
                        placeholder="Enter Zip Code"
                        value={zip}
                        onChange={(e) => setZip(e.target.value)}
                        error={zipError}
                        helperText={zipError ? "Zip Code is required." : ''}
                    />
                </Grid2>
            </Grid2>
            <Grid2 xs={12} container justifyContent={'space-between'}>
                <Grid2 xs={12}>
                    <Button
                        variant="contained"
                        fullWidth
                        onClick={() => submitUpdateBusiness()}
                    >
                        Save Changes
                    </Button>
                </Grid2>
            </Grid2>
            <Grid2 xs={12} pt={1}>
                <Divider variant="fullWidth" sx={{ bgcolor: 'black'}}/>
            </Grid2>
        </Grid2>
    );
};

export default SendingSettingsBusiness;


import axios from 'axios';

const fetchInvoice = (userId, id) => {
    return axios.get(`${process.env.REACT_APP_SERVER_URL}/invoices/${id}`, {
        headers: {
            'user-id': userId 
        }
    })
    .then(res => {
        console.log("***********" + JSON.stringify(res, null, 2));
        return res;
    })
    .catch(err => {
        console.error(err);
    });
};

export async function getInvoices(page, size) {
    try {
        return await axios.get(`${process.env.REACT_APP_SERVER_URL}/invoices/for-web?page=${page}&size=${size}&type=INVOICE`);
    } catch (error) {
        console.error('error fetching invoices: ', error);
        return null;
    }
}

export async function searchInvoices(search) {
    try {
        return await axios.post(`${process.env.REACT_APP_SERVER_URL}/invoices/searching?search=${search}&type=INVOICE`);
    } catch (error) {
        console.error('error searching invoices: ', error);
        return null;
    }
}

export async function sendInvoice(id, email, amount, isPLink = false, method = 'CARD & ACH') {
    let body = {
        id,
        email,
        amount,
        is_include_payment_link: isPLink,
        is_sms: false,
    };
    if (isPLink) body['payment_method'] = method;
    try {
        return await axios.post(`${process.env.REACT_APP_SERVER_URL}/invoices/sending?type=INVOICE`, body);
    } catch (error) {
        console.error(`error sending invoice #${id}: `, error);
        return null;
    }
}

export default fetchInvoice;
import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Stack from 'react-bootstrap/Stack';
import { BsFillClipboardCheckFill } from 'react-icons/bs';


class SuccessCreate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            verificationCode: props.verificationCode
        }
    }

    copyToClipboard = () => navigator.clipboard.writeText(this.state.verificationCode);

    render() {
        return (
            <div
                className="modal show"
                style={{ display: 'block', position: 'initial' }}
            >
                <Modal.Body>
                    <svg style={{ marginLeft: 'auto', marginRight: 'auto' }} xmlns="http://www.w3.org/2000/svg" height="100" width="100" viewBox="0 0 48 48" aria-hidden="true">
                        <circle fill="#5bb543" cx="24" cy="24" r="22" />
                        <path fill="none" stroke="#FFF" stroke-width="6" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M14 27l5.917 4.917L34 17" />
                    </svg>
                    <div style={{ textAlign: 'center' }}>
                        Please save the below refferal verification code:<br/>
                        {this.state.verificationCode}
                        <Button className='tapper-btn-secondary' style={{margin: '10px'}} onClick={this.copyToClipboard}>
                            <BsFillClipboardCheckFill />
                        </Button>
                    </div>
                </Modal.Body>
            </div>
        );
    }
}

export default SuccessCreate;

import axios from 'axios';

export async function createCheckbookWallet(isBusiness, firstName, lastName, address, city, state, zip, ssn, dob, businessName, taxId, website) {
    return await axios.post(`${process.env.REACT_APP_SERVER_URL}/users/create-wallet`, { 
        is_business: isBusiness,
        first_name: firstName,
        last_name: lastName,
        address: address,
        city: city,
        state: state,
        zip: zip,
        ssn: ssn,
        dob: dob,
        business_name: businessName,
        tax_id: taxId,
        website: website
      });
};

export async function getPlaidLinkToken() {
    return await axios.post(`${process.env.REACT_APP_SERVER_URL}/payout/create-link-token`, {});
}

export async function setPinCode(pin) {
    return await axios.post(`${process.env.REACT_APP_SERVER_URL}/users/set-pin-code`, {
        pin: pin
    });
}

export async function getCheckList(page, size, option, search = null) {
    return await axios.get(`${process.env.REACT_APP_SERVER_URL}/payout/get-check-list/${search ? search : ''}?page=${page}&size=${size}&option=${option}`);
}

export async function createTopupCheckout(recipient, accountType, accountNumber, routingNumber, name, amount, description) {
    return await axios.post(`${process.env.REACT_APP_SERVER_URL}/payout/create-direct-check`, {
        recipient: recipient,
        account_type: accountType,
        account_number: accountNumber,
        routing_number: routingNumber,
        name: name,
        amount: amount,
        description: description
    });
}

export async function createSendPaymentCheckout(account, amount, name, recipient) {
    return await axios.post(`${process.env.REACT_APP_SERVER_URL}/payout/create-digital-check`, {
        account: account,
        amount: amount,
        name: name,
        recipient: recipient
    });
}

export async function exchangeTokens(publicToken, accountIds) {
    return await axios.post(`${process.env.REACT_APP_SERVER_URL}/payout/exchange-tokens`, {
        public_token: publicToken,
        account_ids: accountIds
    });
}

export async function addBankAccounts() {
    return await axios.post(`${process.env.REACT_APP_SERVER_URL}/payout/add-bank-accounts`, {});
}

export async function getAccountWallet() {
    return await axios.get(`${process.env.REACT_APP_SERVER_URL}/payout/get-account-wallet`);
}

export async function getBankAccounts() {
    return await axios.get(`${process.env.REACT_APP_SERVER_URL}/payout/get-bank-accounts`);
}

export async function createDigitalCheck(account, amount, email) {
    return await axios.post(`${process.env.REACT_APP_SERVER_URL}/payout/create-digital-check`, {
        account: account,
        amount: amount,
        name: email,
        recipient: email
    });
}

export async function createDirectCheck(accountType, accountNumber, routingNumber, amount) {
    return await axios.post(`${process.env.REACT_APP_SERVER_URL}/payout/create-direct-check`, {
        recipient: "harry.xu@tappernow.com",
        account_type: "BUSINESS",
        account_number: accountNumber,
        routing_number: routingNumber,
        name: "Tapper",
        amount: amount,
        description: "Top up"
    });
}

export async function getCheckbookUser() {
    return await axios.get(`${process.env.REACT_APP_SERVER_URL}/payout/get-checkbook-user`);
}

export async function updateCheckbookBusiness(businessName, address, taxId, website, city, state, zip) {
    return await axios.post(`${process.env.REACT_APP_SERVER_URL}/payout/update-checkbook-business-user`, {
        business_name: businessName,
        address: address,
        tax_id: taxId,
        website: website,
        city: city,
        state: state,
        zip: zip
    });
}

export async function updateCheckbookIndividual(firstName, lastName, dob, ssn, address, city, state, zip) {
    return await axios.post(`${process.env.REACT_APP_SERVER_URL}/payout/update-checkbook-individual-user`, {
        first_name: firstName,
        last_name: lastName,
        dob: dob,
        ssn: ssn,
        address: address,
        city: city,
        state: state,
        zip: zip
    });
}

export async function updatePinCode(oldPin, newPin) {
    return await axios.post(`${process.env.REACT_APP_SERVER_URL}/users/update-pin-code`, {
        old_pin: oldPin,
        new_pin: newPin
    });
}
import React from 'react';
import { styled } from '@mui/material/styles';
import './NavbarForm.css'
import Rating from '@mui/material/Rating';
import StarIcon from '@mui/icons-material/Star';
import logoPlaceholder from '../../img/logo-placeholder.svg';
import Divider from '@mui/material/Divider';

const StyledRating = styled(Rating)({
  '& .MuiRating-iconFilled': {
    color: '#294D51',
    fontSize: 36
  },
});

class ReviewHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = props.reviewState;
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps.reviewState);  
  }

  render() {
    return (
      <div className="reviewHeader name-section">
        <div className='business' style={{ display: 'flex', width: '100%', alignItems: 'center' }}>
          <div className='logoSection' style={{
            width: '120px', minWidth: '120px', marginRight: '10px', display: 'flex',
            flexWrap: 'wrap'
          }}>
            <img src={this.state.user && this.state.logo !== "" ? this.state.logo : logoPlaceholder} style={{ width: '120px', height: '120px', marginRight: '10px' }} alt='' />
          </div>
          <div>
            <div className='businessLogo' style={{ display: 'flex', alignItems: 'start', width: '100%', fontWeight: 500, fontSize: '48px' }}>
              {this.state.user ? this.state.user.company_information.name : 'Loading...'}
            </div>
            <div className='reviewSummary' style={{ display: 'flex', alignItems: 'center', height: '30px', fontWeight: 'bold', fontSize: '20px' }}>
              {this.state.reviews.length !== 0 ? (
                <>
                  <div style={{ display: 'flex' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <StyledRating
                        name="text-feedback"
                        value={this.state.avgRating}
                        readOnly
                        precision={0.01}
                        emptyIcon={<StarIcon style={{ opacity: 0.55, fontSize: 36 }} fontSize="inherit" />}
                      />
                      <span className='rating-info-label'>{this.state.avgRating.toFixed(2)}</span>
                    </div>
                  </div>
                  <div>
                    <span className='rating-info-label mx-2'> ({this.state.reviewNum} reviews)</span>
                  </div>
                </>
              ) : (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {this.state.reviewNum} reviews
                </div>
              )}
            </div>
            <div className='industryName' style={{ display: 'flex', alignItems: 'start', height: '30px', width: '100%', marginTop: '0px', fontWeight: '500', fontSize: '20px' }}>
              {this.state.user ? this.state.user.company_information.service : 'Plumbing, Water Heater Installation/Repair'}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ReviewHeader;

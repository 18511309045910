import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { Button, Divider, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { updateCheckbookIndividual } from '../../api/payout';
import AddressInput from '../../components/util/addressInput';

const SendingSettingsIndividual = ({user}) => {
    const [dob, setDob] = useState(user?.user?.dob ? user?.user?.dob : '2000-01-01');
    const [dobError, setDobError] = useState(false);
    const [firstName, setFirstName] = useState(user?.user?.first_name);
    const [firstNameError, setFirstNameError] = useState(false);
    const [lastName, setLastName] = useState(user?.user?.last_name);
    const [lastNameError, setLastNameError] = useState(false);
    const [ssn, setSsn] = useState(user?.user?.ssn);
    const [ssnError, setSsnError] = useState(false);
    const [address, setAddress] = useState(user?.merchant?.address?.line_1);
    const [addressError, setAddressError] = useState(false);
    const [city, setCity] = useState(user?.merchant?.address?.city);
    const [cityError, setCityError] = useState(false);
    const [state, setState] = useState(user?.merchant?.address?.state);
    const [stateError, setStateError] = useState(false);
    const [zip, setZip] = useState(user?.merchant?.address?.zip);
    const [zipError, setZipError] = useState(false);

    const resetErrorFields = () => {
        setFirstNameError(false);
        setLastNameError(false);
        setSsnError(false);
        setDobError(false);
        setAddressError(false);
        setCityError(false);
        setStateError(false);
        setZipError(false);
    }

    const registerValidate = () => {
        const regex = /^\d{4}$/;
        if (!firstName) {
            setFirstNameError(true);
            return false;
        }
        if (!lastName) {
            setLastNameError(true);
            return false;
        }
        if (!dob) {
            setDobError(true);
            return false;
        }
        if (!regex.test(ssn)) {
            setSsnError(true);
            return false;
        }
        if (!address) {
            setAddressError(true);
            return false;
        }
        if (!city) {
            setCityError(true);
            return false;
        }
        if (!state) {
            setStateError(true);
            return false;
        }
        if (!zip) {
            setZipError(true);
            return false;
        }
        return true;
    }

    const submitUpdateIndividual = () => {
        resetErrorFields();
        if (!registerValidate()) return;
        updateCheckbookIndividual(firstName, lastName, dob, ssn, address, city, state, zip).then(result => {
            alert('Updated Successfully!');
        })
        .catch(error => {
        });
    }

    return (
        <Grid2 xs={12} container rowGap={2} justifyContent={'space-between'}>
            <Grid2 xs={12} container>
                <Typography variant='h6' color={'primary'} fontWeight={'bold'}>You are registered as Individual</Typography>
            </Grid2>
            <Grid2 xs={12} container>
                <Typography variant='button' color={'primary'} fontWeight={'bold'}>Individual Information</Typography>
            </Grid2>
            <Grid2 lg={5.5} md={12} xs={12}>
                <TextField
                    variant="outlined"
                    fullWidth
                    color="primary"
                    type="text"
                    label="First Name"
                    placeholder="Enter First Name..."
                    error={firstNameError}
                    helperText={firstNameError ? "First Name is required." : ''}
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                />
            </Grid2>
            <Grid2 lg={5.5} md={12} xs={12}>
                <TextField
                    variant="outlined"
                    fullWidth
                    color="primary"
                    type="text"
                    label="Last Name"
                    placeholder="Enter Last Name..."
                    error={lastNameError}
                    helperText={lastNameError ? "Last Name is required." : ''}
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                />
            </Grid2>
            <Grid2 lg={5.5} md={12} xs={12}>
                <TextField
                    variant="outlined"
                    fullWidth
                    color="primary"
                    type="date"
                    label="Date of Birth *"
                    placeholder="MM/DD/YYYY"
                    error={dobError}
                    helperText={dobError ? "Date of Birth is required." : ''}
                    value={dob}
                    onChange={(e) => setDob(e.target.value)}
                />
            </Grid2>
            <Grid2 lg={5.5} md={12} xs={12}></Grid2>
            <Grid2 lg={5.5} md={12} xs={12}>
                <TextField
                    variant="outlined"
                    fullWidth
                    color="primary"
                    type="text"
                    label="Last 4 digits of SSN *"
                    placeholder="Enter SSN..."
                    value={ssn}
                    error={ssnError}
                    helperText={ssnError ? "Please a valid SSN number" : ''}
                    onChange={(e) => setSsn(e.target.value)}
                />
            </Grid2>
            <Grid2 lg={5.5} md={12} xs={12}></Grid2>
            <Grid2 lg={5.5} md={12} xs={12}>
                <AddressInput
                    value={address}
                    setValue={(address, city, state, zip) => {
                        if(address) {
                            setAddress(address);
                            setCity(city);
                            setState(state);
                            setZip(zip);
                        }
                        else {
                            setAddress(address);
                        }
                    }}
                    label="Street Address"
                    placeholder="Enter Street Address"
                    error={addressError}
                    helperText={addressError ? "Address is required." : ''}
                />
            </Grid2>
            <Grid2 xs={12} container justifyContent={'space-between'} rowGap={4}>
                <Grid2 lg={3.5} md={12} xs={12}>
                    <TextField
                        variant="outlined"
                        fullWidth
                        color="primary"
                        type="text"
                        label="City"
                        placeholder="Enter City"
                        error={cityError}
                        helperText={cityError ? "City is required." : ''}
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                    />
                </Grid2>
                <Grid2 lg={3.5} md={12} xs={12}>
                    <TextField
                        variant="outlined"
                        fullWidth
                        color="primary"
                        type="text"
                        label="State"
                        placeholder="Enter State"
                        error={stateError}
                        helperText={stateError ? "State is required." : ''}
                        value={state}
                        onChange={(e) => setState(e.target.value)}
                    />
                </Grid2>
                <Grid2 lg={3.5} md={12} xs={12}>
                    <TextField
                        variant="outlined"
                        fullWidth
                        color="primary"
                        type="text"
                        label="Zip Code"
                        placeholder="Enter Zip Code"
                        error={zipError}
                        helperText={zipError ? "Zip Code is required." : ''}
                        value={zip}
                        onChange={(e) => setZip(e.target.value)}
                    />
                </Grid2>
            </Grid2>
            <Grid2 xs={12} container justifyContent={'space-between'}>
                <Grid2 xs={12}>
                    <Button
                        variant="contained"
                        fullWidth
                        onClick={() => submitUpdateIndividual()}
                    >
                        Save Changes
                    </Button>
                </Grid2>
            </Grid2>
            <Grid2 xs={12} pt={1}>
                <Divider variant="fullWidth" sx={{ bgcolor: 'black'}}/>
            </Grid2>
        </Grid2>
    );
};

export default SendingSettingsIndividual;

import axios from "axios";

export async function getCustomers(page, size, search) {
    try {
        return await axios.get(`${process.env.REACT_APP_SERVER_URL}/contacts/for-web?page=${page}&size=${size}&search=${search}`);
    } catch (error) {
        console.log('error fetching customers: ', error);
    }
}

export async function addCustomer(addCustomerBody) {
    try {
        return await axios.post(`${process.env.REACT_APP_SERVER_URL}/contacts`, addCustomerBody);
    } catch (error) {
        console.log('failed to add new customer: ', error);
        return null;
    }
}

export async function editCustomer(editCustomerBody) {
    try {
        return await axios.put(`${process.env.REACT_APP_SERVER_URL}/contacts`, editCustomerBody);
    } catch (error) {
        console.log(`failed to edit customer #${editCustomerBody.id}: `, error);
        return null;
    }
}

export async function deleteCustomer(ID) {
    try {
        return await axios.delete(`${process.env.REACT_APP_SERVER_URL}/contacts/${ID}`);
    } catch (error) {
        console.log(`error deleting customer #${ID}: `, error);
        return null;
    }
}

export async function getCustomerInvoices(page, size, customerID) {
    try {
        return await axios.get(`${process.env.REACT_APP_SERVER_URL}/invoices/for-web?page=${page}&size=${size}&contact_id=${customerID}&type=INVOICE`);
    } catch (error) {
        console.log(`failed to fetch invoices for contact #${customerID}:`, error);
        return null;
    }
}

export async function getCustomerEstimates(page, size, customerID) {
    try {
        return await axios.get(`${process.env.REACT_APP_SERVER_URL}/invoices/for-web?page=${page}&size=${size}&contact_id=${customerID}&type=ESTIMATE`);
    } catch (error) {
        console.log(`failed to fetch estimates for contact #${customerID}:`, error);
        return null;
    }
}